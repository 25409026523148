import { APIEndpointEnum, INHOUSE_GAME_API } from 'config/constants/server'
import { HUSD_TOKEN, USD_CURRENCY } from 'config/constants/tokens'
import { Token } from 'config/types'
import { InhouseGameType } from 'config/types/game'
import GameService from 'services/GameService'
import { BaseResponse } from 'services/types'
import { INHOUSE_GAME_LAUNCH_MAPPER_KEY, MINE_GAME_SIZE } from 'views/InhouseGame/Mine/config'
import {
  MineBet,
  MineBetResponse,
  MineBlock,
  MineBlockResult,
  MineDraftBet,
  InhouseLaunchSession,
} from 'views/InhouseGame/Mine/config/types'
import { InhouseBetResponse } from 'views/InhouseGame/config/types'
import { BIG_ZERO } from 'config/constants/number'
import { range } from 'utils'
import BaseInhouseGameService from './BaseInhouseGameService'

class MineGameService extends BaseInhouseGameService {
  _game = InhouseGameType.Mine
  _currentTokenSession = HUSD_TOKEN
  _sessionId = null

  async _verify(gameCode: string, playToken: Token): Promise<InhouseLaunchSession> {
    const result = await GameService.createGameSession(gameCode, playToken.code, playToken.network).call()

    const { gameUrl } = result?.data || {}
    const res: BaseResponse<InhouseLaunchSession> = await this._get(
      gameUrl,
      {},
      { withCredentials: true },
      INHOUSE_GAME_LAUNCH_MAPPER_KEY,
    )

    if (res.code !== 'success') return null

    this._currentTokenSession = playToken
    this._sessionId = res.data.sessionId

    return res.data
  }

  public async launchGame(gameCode: string, playToken: Token): Promise<InhouseLaunchSession> {
    return this._verify(gameCode, playToken)
  }

  public async submitBet(
    draftBet: MineDraftBet,
    gameCode?: string,
    playToken?: Token,
  ): Promise<InhouseBetResponse<MineBet>> {
    const { bet, result }: InhouseBetResponse<MineBetResponse> = await this._submitBet(
      draftBet.betAmount,
      {
        mines: draftBet.mines,
      },
      false,
      gameCode,
      playToken,
    )

    return {
      result,
      bet: bet && {
        betAmount: draftBet.betAmount,
        id: bet.id,
        mines: draftBet.mines,
        result: bet.result,
      },
    }
  }

  public async openBlock(ids: number[], gameCode: string, bet: MineBet): Promise<{ isDiamond: boolean }> {
    const result = await this.authCallback(
      gameCode,
      this._currentTokenSession,
      async () => {
        return this._request(
          APIEndpointEnum.MineGameOpenBlock,
          {
            data: {
              fields: ids,
            },
            session_id: this._sessionId,
            round_id: bet.id,
            game_code: this._game,
          },
          {
            excludeErrors: ['error_auth', 'error_invalid_data'],
            baseURL: INHOUSE_GAME_API,
            disabledToast: true,
          },
        )
      },
      {
        msg: 'Cannot open block mine game',
        tag: 'inhouse-game-api',
      },
    )
    return result?.code === 'success' && { isDiamond: !!result?.data.response }
  }

  public override async finishBet(betId: string): Promise<MineBet> {
    if (!betId || betId == '0') return null

    const result = await this._request(
      APIEndpointEnum.InhouseFinishBet,
      {
        round_id: betId,
      },
      {
        excludeErrors: ['error_auth'],
        baseURL: INHOUSE_GAME_API,
        disabledToast: true,
      },
    )

    const bet = result?.code === 'success' && result.data
    if (bet) bet.id = betId
    return bet
  }

  public async verifyResult(mines: number, clientSeed: string, serverSeed: string, nonce: number): Promise<MineBet> {
    const result = await this._verifyResult(clientSeed, serverSeed, nonce, {
      mines,
    })
    if (!result?.data?.mines) return null

    const minePositionIds = result.data.mines

    const defaultResult = range(0, MINE_GAME_SIZE * MINE_GAME_SIZE).map(
      () =>
        ({
          isUserOpened: true,
          value: MineBlockResult.Diamond,
        } as MineBlock),
    )

    for (let index = 0; index < minePositionIds.length; index++) {
      const element = minePositionIds[index]
      defaultResult[element] = {
        ...defaultResult[element],
        value: MineBlockResult.Mine,
      }
    }

    return {
      id: null,
      betAmount: { amount: BIG_ZERO, token: USD_CURRENCY },
      mines,
      result: defaultResult,
      isCompleted: true,
    }
  }
}

const instance = new MineGameService()
export default instance
