import { howlMapper } from 'config/constants/audio'
import { InhouseGameDetails } from 'config/types/game'
import React, { createContext, useCallback, useContext, useMemo, useRef, useState } from 'react'
import PlinkoGameService from 'services/InhouseGameService/PlinkoGameService'
import { useTokenSelected } from 'state/session/hooks'
import { delayed } from 'utils'
import { forkjoinRequest } from 'utils/requestHelper'
import { InhouseBetResponse, InhouseGameBetResult } from 'views/InhouseGame/config/types'
import { PlinkoBet, PlinkoDraftBet } from '../types/Bet'
import { PlinkoEventContext } from './PlinkoEventProvider'

interface IPlinkoActionContext {
  isPlaying: boolean
  submitBet: (bet: PlinkoDraftBet, volumeEnable?: boolean) => Promise<InhouseGameBetResult>
  finishBet: (bet: PlinkoBet) => void
}

export const PlinkoActionContext = createContext<IPlinkoActionContext>({
  submitBet: async () => null,
  finishBet: () => {},
  isPlaying: false,
})

const PlinkoActionProvider: React.FC<React.PropsWithChildren & { game: InhouseGameDetails }> = ({ children, game }) => {
  const { newBet, completedBet, setCompletedBet, setNewBet } = useContext(PlinkoEventContext)
  const [isBetSubmitting, setBetSubmitting] = useState(null)

  const playToken = useTokenSelected()

  const processingBetCountRef = useRef(0)
  const isLockedBetRef = useRef(false)

  const processingBetCount = useMemo(() => {
    return processingBetCountRef.current
  }, [newBet, completedBet])

  const submitBet = useCallback(
    async (newBet: PlinkoDraftBet, volumeEnable?: boolean) => {
      if (isLockedBetRef.current) return
      setBetSubmitting(true)
      isLockedBetRef.current = true

      if (volumeEnable) {
        howlMapper.InhouseGameBetPlay?.play()
      }

      const { bet, result } = await _handleBet(newBet)

      if (result === InhouseGameBetResult.Succeed) {
        processingBetCountRef.current++
        setNewBet(bet)
      }

      setBetSubmitting(false)
      isLockedBetRef.current = false
      return result
    },
    [setNewBet, playToken],
  )

  const finishBet = useCallback(
    (bet: PlinkoBet) => {
      setCompletedBet(bet)
      processingBetCountRef.current--
      if (bet.disabledDisplay || bet.betAmount.amount.isNaN() || bet.betAmount.amount.eq(0)) {
        return
      }

      PlinkoGameService.finishBet(bet.id)
    },
    [setCompletedBet],
  )

  const _handleBet = async (draftBet: PlinkoDraftBet): Promise<InhouseBetResponse<PlinkoBet>> => {
    const [bet] = await forkjoinRequest([PlinkoGameService.submitBet(draftBet, game.code, playToken), delayed(100)])
    return bet
  }

  const value = useMemo(() => {
    return {
      finishBet,
      submitBet,
      isPlaying: isBetSubmitting || processingBetCount > 0,
    }
  }, [isBetSubmitting, processingBetCount > 0, finishBet, submitBet])

  return <PlinkoActionContext.Provider value={value}>{children}</PlinkoActionContext.Provider>
}

export default PlinkoActionProvider
