import { APIEndpointEnum, INHOUSE_GAME_API } from 'config/constants/server'
import { Token, TokenAmount } from 'config/types'
import { InhouseGameType } from 'config/types/game'
import { HunnyPlayRequest } from 'services/HunnyPlayRequest'
import { BaseResponse } from 'services/types'
import { logError } from 'utils/sentry'
import { InhouseBetResponse, InhouseGameBetResult, ProbilityFair } from 'views/InhouseGame/config/types'

abstract class BaseInhouseGameService extends HunnyPlayRequest {
  protected _game: InhouseGameType
  protected _currentTokenSession: Token
  protected _sessionId: number

  abstract _verify(gameCode: string, playToken: Token): Promise<any>

  public removeSession() {
    this._currentTokenSession = null
  }

  protected async authCallback<T>(
    gameCode: string,
    playToken: Token,
    request?: () => Promise<BaseResponse<T>>,
    errorLogger?: { msg: string; tag: string },
  ): Promise<BaseResponse<T>> {
    const refresh = async () => {
      const res = await this._verify(gameCode, playToken)
      const isSucceed = !!res
      if (!isSucceed) {
        const msg = errorLogger.msg || 'Cannot launch game'
        logError(msg, {
          message: msg,
          extra: { data: { betResponse: result } },
          tags: [errorLogger.tag || 'auth api'],
        })
      }
      return isSucceed
    }

    if (!this._currentTokenSession || this._currentTokenSession !== playToken) {
      const isSucceed = await refresh()
      if (!isSucceed) return
    }

    const result = await request()

    if (result?.code === 'error_auth') {
      const isSucceed = await refresh()
      if (isSucceed) {
        return request()
      }

      return null
    }

    return result
  }
  public async getSeed(gameCode: string, playToken: Token): Promise<ProbilityFair> {
    const result = await this.authCallback(
      gameCode,
      this._currentTokenSession || playToken,
      async () => {
        return this._request(
          APIEndpointEnum.GetSeed,
          {},
          {
            excludeErrors: ['error_auth'],
            baseURL: INHOUSE_GAME_API,
            disabledToast: true,
          },
        )
      },
      {
        msg: 'Cannot verify',
        tag: 'probability-fair-api',
      },
    )

    if (!result || result.code !== 'success') {
      return null
    }

    return result.data
  }

  public async updateSeed(gameCode: string, playToken: Token, clientSeed: string): Promise<ProbilityFair> {
    const result = await this.authCallback(
      gameCode,
      this._currentTokenSession || playToken,
      async () => {
        return this._request(
          APIEndpointEnum.UpdateSeed,
          { client_seed: clientSeed },
          {
            excludeErrors: ['error_auth'],
            baseURL: INHOUSE_GAME_API,
            disabledToast: true,
          },
        )
      },
      {
        msg: 'Cannot verify',
        tag: 'probability-fair-api',
      },
    )

    if (!result || result.code !== 'success') {
      return null
    }

    return result.data
  }

  protected async _submitBet(
    amount: TokenAmount,
    data: any,
    endRoundImmediately?: boolean,
    gameCode?: string,
    playToken?: Token,
  ): Promise<InhouseBetResponse<any>> {
    const token = amount.token
    const result = await this.authCallback(
      gameCode,
      playToken,
      async () => {
        return this._request(
          APIEndpointEnum.InhouseGameSubmitBet,
          {
            session_id: this._sessionId,
            bet_amount: amount.amount.gt(0) ? amount.amount.toString(10) : '0',
            currency: token.code,
            game_code: this._game,
            end_round: amount.amount.gt(0) ? !!endRoundImmediately : true,
            data,
          },
          {
            excludeErrors: ['error_auth'],
            baseURL: INHOUSE_GAME_API,
            disabledToast: true,
          },
        )
      },
      {
        msg: 'Cannot re-launch game',
        tag: 'inhouse-game-api',
      },
    )

    if (!result || result.code !== 'success') {
      return {
        result:
          result?.code === 'error_balance_not_enough'
            ? InhouseGameBetResult.InsufficientBalance
            : InhouseGameBetResult.Failed,
        bet: null,
      }
    }

    return {
      result: InhouseGameBetResult.Succeed,
      bet: result.data,
    }
  }

  public async _verifyResult(
    clientSeed: string,
    serverSeed: string,
    nonce: number,
    data?: any,
  ): Promise<BaseResponse<any>> {
    const result = await this._request(
      APIEndpointEnum.InhouseVerifyPayout,
      {
        client_seed: clientSeed,
        server_seed: serverSeed,
        nonce,
        game_code: this._game,
        data,
      },
      {
        baseURL: INHOUSE_GAME_API,
      },
    )
    if (!result || result.code !== 'success') {
      return null
    }

    return result
  }

  public async finishBet(betId: string): Promise<any> {
    if (!betId || betId == '0') return true

    const result = await this._request(
      APIEndpointEnum.InhouseFinishBet,
      {
        round_id: betId,
      },
      {
        excludeErrors: ['error_auth'],
        baseURL: INHOUSE_GAME_API,
        disabledToast: true,
      },
    )
    return !!result?.data
  }
}

export default BaseInhouseGameService
