import Box from 'components/Box/Box'
import { FlexProps } from 'components/Box/types'
import Avatar from 'components/Image/Avatar'
import Text from 'components/Text'
import { RefUserProfile } from 'config/types/profile'
import useModal from 'hooks/useModal'
import { RowMiddle } from 'layout/Components/Row'
import React from 'react'
import { Trans } from 'react-i18next'
import { Icons } from 'svgs'
import UserProfileModal from './UserProfileModal'

const UserDisplay: React.FC<
  {
    user: RefUserProfile
    size?: number
    fontSize?: string | string[]
    ellipsis?: number
  } & FlexProps
> = ({ user, size = 24, ellipsis, fontSize = '1em', ...props }) => {
  const [onPresentProfilePopup] = useModal(UserProfileModal)

  return !user.settings?.isHideName ? (
    <RowMiddle
      {...props}
      onClick={(e) => {
        e.stopPropagation()
        e.preventDefault()
        if (!user.code) return
        onPresentProfilePopup({
          code: user.code,
        })
      }}
      style={{ cursor: user.code ? 'pointer' : 'default' }}
    >
      {user.avatar && (
        <Box minWidth={size} minHeight={size} height={size} width={size}>
          <Avatar src={user.avatar} height={size} width={size} />
        </Box>
      )}
      <Text ellipsis={ellipsis} fontSize={fontSize} ml="6px">
        <Trans>{user.displayName}</Trans>
      </Text>
    </RowMiddle>
  ) : (
    <RowMiddle {...props}>
      <Box minWidth={size} minHeight={size} width={size} height={size}>
        <Icons.HiddenAvatar />
      </Box>
      <Text fontSize={fontSize} color="textAlt1" fontWeight={600} ml="6px">
        <Trans>Hidden</Trans>
      </Text>
    </RowMiddle>
  )
}

export default UserDisplay
