import { howlMapper } from 'config/constants/audio'
import React from 'react'
import { usePlinkoCompletedBetListener } from 'views/InhouseGame/Plinko/hooks'

const PayoutEventUpdater: React.FC<{
  volumeEnable?: boolean
}> = ({ volumeEnable }) => {
  usePlinkoCompletedBetListener((bet) => {
    const payoutElement = document.getElementById(`payout-${bet.payoutIndex}`)

    if (payoutElement) {
      if (volumeEnable) {
        howlMapper.PlinkoCompleted?.play()
      }
      if (payoutElement.style) {
        payoutElement.style.transform = 'translateY(7px)'
        const timeout = setTimeout(() => {
          payoutElement.style.transform = 'translateY(0px)'
        }, 100)

        return () => {
          clearTimeout(timeout)
          if (payoutElement) {
            payoutElement.style.transform = 'translateY(0px)'
          }
        }
      }
    }
  })

  return null
}

export default PayoutEventUpdater
