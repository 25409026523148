import Box from 'components/Box/Box'
import Grid from 'components/Box/Grid'
import Button from 'components/Button'
import Modal, { ModalProps } from 'components/Modal'
import Text from 'components/Text'
import { FIAT_CURRENCIES } from 'config/constants/fiat'
import { FiatCurrency } from 'config/types/fiat'
import { Trans } from 'react-i18next'
import { useUserSettings } from 'state/profile/hooks'
import styled from 'styled-components'
import theme from 'theme'
import FiatCurrencyDisplay from './FiatCurrencyDisplay'

const FiatModal: React.FC<ModalProps> = ({ onDismiss }) => {
  const { updateSetting } = useUserSettings()

  const handleUpdateCurrentFiat = (fiat: FiatCurrency) => {
    updateSetting({
      selectedFiatCurrency: fiat,
    })
    onDismiss()
  }

  return (
    <StyledModal onDismiss={onDismiss} width="568px" pb="40px">
      <Text fontSize="14px" fontWeight={600}>
        <Trans>Display in Fiat</Trans>
      </Text>
      <Text fontSize="14px" fontWeight={300} my="24px" lineHeight="normal" color={theme.colors.textTertiary}>
        <Trans>
          Please note that these are currency approximations. All bets & transactions will be calculated in crypto
          equivalent. For more details feel free to contact our live support.
        </Trans>
      </Text>
      <Box maxHeight="300px" overflowY="scroll" overflowX="hidden">
        <Grid gridTemplateColumns={'1fr 1fr 1fr 1fr'} gridGap="12px">
          {FIAT_CURRENCIES.map((item) => (
            <StyledFiatItem key={item.code} onClick={() => handleUpdateCurrentFiat(item)}>
              <FiatCurrencyDisplay fiat={item} imgSize={20} />
            </StyledFiatItem>
          ))}
        </Grid>
      </Box>
    </StyledModal>
  )
}
const StyledModal = styled(Modal)`
  padding: 40px 24px;

  box-sizing: border-box;

  background: ${({ theme: { colors } }) => colors.cardBackground};

  overflow-y: hidden;
  border-top-left-radius: ${({ theme }) => theme.radii.large};
  border-top-right-radius: ${({ theme }) => theme.radii.large};

  & .modal-closebutton {
    right: 12px;
    top: 12px;
  }

  ${({ theme: { mediaQueries } }) => mediaQueries.sm} {
    min-width: 600px;
    max-width: 600px;
    border-radius: ${({ theme }) => theme.radii.large};
  }
`
const StyledFiatItem = styled(Button)`
  background: ${({ theme }) => theme.colors.backgroundAlt5};
  height: 36px;
  /* width: 120px; */
  margin: 10px;
  margin: 0px;
`

export default FiatModal
