import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import { FlexProps } from 'components/Box/types'
import Image from 'components/Image/Image'
import OpenEffect from 'components/OpenEffect'
import Text from 'components/Text'
import TokenLogo from 'components/TokenLogo'
import { HUSD_TOKEN } from 'config/constants/tokens'
import { Token, TokenAmount } from 'config/types'
import useMatchBreakpoints from 'hooks/useMatchBreakpoints'
import useModal from 'hooks/useModal'
import React from 'react'
import { Trans } from 'react-i18next'
import styled from 'styled-components'
import { Icons } from 'svgs'
import { colors } from 'theme/colors'
import HUSDUnlock from 'views/HUSDUnlock'

import BigNumber from 'bignumber.js'
import { useValidatePlayToken } from 'state/app/hooks'
import TokenAmountFiatDisplay from 'views/Fiat/TokenAmountFiatDisplay'
import Column from '../Column'

export interface BalanceOptionItemProps {
  balance: TokenAmount
  bonus: BigNumber
  disabled?: boolean
}

interface OptionLayoutProps {
  hideTokenNameOnMobile?: boolean
  token: Token
}

const StyledTooltipContainer = styled(OpenEffect)`
  display: none;
  background: ${({ theme }) => theme.colors.backgroundAlt2};
  border-radius: 8px;
  width: 100%;
  position: absolute;
  top: 100%;
  z-index: 5;
  left: 0px;
`

const StyledWageringBonusContainer = styled(Flex)`
  align-items: center;
  margin-top: 4px;
  &:hover,
  &:focus {
    ${StyledTooltipContainer} {
      display: block;
    }
  }
`

export const TokenAmountLayout: React.FC<OptionLayoutProps & FlexProps> = ({
  token,
  hideTokenNameOnMobile,
  children,
  ...props
}) => {
  const { isMobile } = useMatchBreakpoints()

  const { isBlackList } = useValidatePlayToken(token)

  return (
    <Flex alignItems="flex-start" height="fit-content" justifyContent="space-between" width="100%" {...props}>
      <Flex alignItems="center">
        <TokenLogo token={token} minWidth={20} size={20} />
        {(!hideTokenNameOnMobile || !isMobile) && (
          <Column height="100%" ml="8px" alignItems={'flex-start'}>
            <Text fontSize="14px" bold color={'textTertiary'}>
              {token.name}
            </Text>
            {isBlackList && (
              <Text fontSize="12px" color="textSubtle">
                <Trans>Suspended</Trans>
              </Text>
            )}
          </Column>
        )}
      </Flex>
      {children}
    </Flex>
  )
}

const TokenAmountOptionItem: React.FC<{ item: BalanceOptionItemProps }> = ({ item }) => {
  const [onPressentHUSD] = useModal(HUSDUnlock)
  const { token } = item.balance

  return (
    <TokenAmountLayout id="balance-select-item" token={token} position="relative">
      <Flex flexDirection="column" justifyContent="center" alignItems="flex-end">
        <Flex height="20px" alignItems="center">
          <Text fontSize="14px" fontWeight="500" ml="6px" color="text">
            <TokenAmountFiatDisplay tokenAmount={item.balance} />
          </Text>
        </Flex>

        {item.bonus.gt(0) && (
          <StyledWageringBonusContainer
            ml="6px"
            onClick={(e) => (token !== HUSD_TOKEN ? e.stopPropagation() : onPressentHUSD())}
            style={{ cursor: token !== HUSD_TOKEN ? 'help' : 'pointer' }}
          >
            <Icons.InfoCircleIcon width="12px" fill={colors.textDisable} />
            {token !== HUSD_TOKEN && (
              <StyledTooltipContainer p="12px" openType="grow">
                <Text fontSize="12px" textAlign="left">
                  <Trans>
                    The Bonus balance includes Free Spins and Deposit Bonuses winnings. Once you meet the wagering
                    requirement, it will be transferred to your Main balance
                  </Trans>
                </Text>
              </StyledTooltipContainer>
            )}

            <Flex
              height="20px"
              ml="4px"
              border={`1px solid ${colors.backgroundAlt8}`}
              background={colors.backgroundAlt5}
              alignItems="center"
              px="4px"
              borderRadius="4px"
            >
              <Text bold fontSize="12px" color="warning" mr="4px">
                <TokenAmountFiatDisplay tokenAmount={{ token, amount: item.bonus }} />
              </Text>

              <Box ml="4px" width="12px">
                <Image src="/images/bonusCenter/small-gift.png" width={24} height={24} />
              </Box>
            </Flex>
          </StyledWageringBonusContainer>
        )}
      </Flex>
    </TokenAmountLayout>
  )
}

export default TokenAmountOptionItem
