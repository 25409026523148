import { DailyRewardPackageCode } from 'config/constants/dailyReward'
import { CurrentReward, DailyRewardPackage, RewardTypeEnum } from 'config/types/bonus/DailyReward'
import { useRequest } from 'hooks/useRequest'
import { useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import DailyRewardService from 'services/DailyRewardService'
import { useAppDispatch, useAppSelector } from 'state'
import { useAuth } from 'state/auth/hooks'
import { HunnyToast } from 'utils/toastify'
import { useUpdateHUSDBonus } from '.'
import { increaseTodayWagerAmount, updateDailyReward } from '../actions'

export const useDailyRewardCampaign = () => {
  const campaign = useAppSelector((app) => app.bonus.dailyReward)
  const { hasSession } = useAuth()

  const dispatch = useAppDispatch()

  const { execute } = useRequest()
  const fetchData = async () => {
    const res = await execute(DailyRewardService.getCampaign(hasSession))
    dispatch(updateDailyReward({ campaign: res?.data || null }))
  }

  return useMemo(() => ({ fetchData, campaign }), [campaign, hasSession])
}

export const useDailyRewardPackage = (packageCode: DailyRewardPackageCode) => {
  const { campaign } = useDailyRewardCampaign()
  return useMemo(() => campaign?.packages.find((item) => item.code === packageCode), [packageCode, campaign])
}

export const useTodayRewards = () => {
  const allWagerAmount = useAppSelector((state) => state.profile.wager.score)
  const todayWagerAmount = useAppSelector((app) => app.bonus.dailyReward?.userData?.todayWagerAmount) || 0

  const { hasSession } = useAuth()
  const { campaign, fetchData } = useDailyRewardCampaign()
  const { t } = useTranslation()
  const { fetchHUSDBonusBalance } = useUpdateHUSDBonus()

  const checkWaitingClaim = (item: DailyRewardPackage, isCompletedPackage: boolean, day: number) => {
    if (isCompletedPackage || item.isTodayClaimed) return false

    if (item.wagerRequired.allAmount.targetDay === day) {
      return (
        allWagerAmount >= item.wagerRequired.allAmount.amount &&
        todayWagerAmount >= item.wagerRequired.todayAmount.amount
      )
    }

    return todayWagerAmount >= item.wagerRequired.todayAmount.amount
  }

  const currentRewards: CurrentReward[] = useMemo(
    () =>
      campaign?.packages.map((item) => {
        const sortedReward = [...item.rewards]?.sort((item1, item2) => item1.day - item2.day)
        const isCompletedPackage = item.rewards[item.rewards.length - 1]?.userData?.isClaimed
        const reward = hasSession
          ? item.isTodayClaimed
            ? sortedReward.reverse().find((reward) => reward.userData?.isClaimed)
            : sortedReward.find((item) => !item.userData?.isClaimed)
          : sortedReward[0]

        return {
          packageCode: item.code,
          isWaitingClaim: checkWaitingClaim(item, isCompletedPackage, reward?.day),
          reward,
          progressWagerCondition:
            item.wagerRequired.todayAmount.amount > 0 && todayWagerAmount > 0
              ? (todayWagerAmount / item.wagerRequired.todayAmount.amount) * 100
              : 0,
          todayWagerAmountToClaim:
            Math.floor((item.wagerRequired.todayAmount.amount - Math.floor(todayWagerAmount * 100) / 100) * 100) / 100,
          allWagerAmountToClaim: item.wagerRequired.allAmount.amount,
          isCompletedPackage,
        }
      }),
    [campaign, todayWagerAmount, hasSession, allWagerAmount],
  )

  const claimReward = async (packageCode: DailyRewardPackageCode) => {
    const reward = currentRewards.find((reward) => reward.packageCode === packageCode)
    if (!reward?.isWaitingClaim) {
      throw new Error('This Reward is not eligible to claim')
    }

    const isSucceed = await DailyRewardService.claimReward(reward)
    await fetchData()

    if (isSucceed.code === 'success') {
      HunnyToast.success(`${t('You have received')} ${reward.reward.displayName}`)
      if (reward.reward.rewardType === RewardTypeEnum.HUSDLock) await fetchHUSDBonusBalance()
      return true
    }
    return false
  }
  return useMemo(() => ({ claimReward, currentRewards }), [currentRewards])
}

export const useDailyCheckinInitial = () => {
  const { fetchData } = useDailyRewardCampaign()
  const { isSigned } = useAuth()

  useEffect(() => {
    const currentTimeInDay = Date.now() % (1000 * 60 * 60 * 24)
    const timeLeft = 1000 * 60 * 60 * 24 - currentTimeInDay

    const timeout = setTimeout(() => {
      if (isSigned) fetchData()
    }, timeLeft)

    fetchData()

    return () => clearTimeout(timeout)
  }, [isSigned])
}

export const useDailyCheckinWagerProcessSyncUp = () => {
  const totalWager = useAppSelector((state) => state.profile.wager.score)
  const cachedTotalWagerRef = useRef(totalWager)

  const dispatch = useAppDispatch()

  useEffect(() => {
    const wagerMargin = totalWager - cachedTotalWagerRef.current
    dispatch(
      increaseTodayWagerAmount({
        amount: wagerMargin,
      }),
    )
    cachedTotalWagerRef.current = totalWager
  }, [totalWager])
}
