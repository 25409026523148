import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import Image from 'components/Image/Image'
import { ModalBody, ModalProps } from 'components/Modal'
import Modal from 'components/Modal/Modal'
import Text from 'components/Text/Text'
import React from 'react'
import { Trans } from 'react-i18next'
import styled from 'styled-components'
import { BoxProps } from 'components/Box/types'

const FreespinBonusHeader: React.FC<ModalProps & BoxProps & { isZeroAmount?: boolean }> = ({
  onDismiss,
  isZeroAmount,
  children,
  ...props
}) => {
  return (
    <StyledModal onDismiss={onDismiss} {...props}>
      <StyledModalBody borderRadius="0px" height="100%">
        <Flex flexDirection="column" alignItems="center" overflow="hidden">
          <Box width="130px" height="120px">
            <Image src="/images/bonusCenter/gift-header-title.png" width={300} height={300} />
          </Box>
          <Text mt="20px" fontWeight="700" fontSize="20px">
            {isZeroAmount ? <Trans>A Twist of Fate!</Trans> : <Trans>You got a Bonus</Trans>}
          </Text>
          <Text
            fontSize="14px"
            fontWeight="400"
            color="textSubtle"
            mt="8px"
            textAlign="center"
            maxWidth="320px"
            marginX="auto"
            lineHeight="17.07px"
          >
            {isZeroAmount ? (
              <Trans>
                Turn that ZERO into a HERO! Despite the unlucky spin result. we will give you a special prize with the
                maximum reward of this bonus!
              </Trans>
            ) : (
              <Trans>Congrats! You have used all Free Spins and recieve a bonus</Trans>
            )}
          </Text>
        </Flex>
        {children}
      </StyledModalBody>
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  padding: 24px;

  ${({ theme }) => theme.mediaQueries.sm} {
    min-width: 360px !important;
    max-width: 360px !important;
  }
`

const StyledModalBody = styled(ModalBody)`
  overflow-y: unset !important;
`
export default FreespinBonusHeader
