import Box from 'components/Box/Box'
import { BoxProps } from 'components/Box/types'
import { QRCodeSVG } from 'qrcode.react'
import styled from 'styled-components'

const QRCode = ({ url, image, size = 380, ...props }: { url: string; image?: string; size?: number } & BoxProps) => (
  <StyledWrapper {...props}>
    <QRCodeSVG
      value={url}
      size={size}
      level="H"
      includeMargin
      imageSettings={
        image
          ? {
              src: image,
              x: undefined,
              y: undefined,
              height: 60,
              width: 60,
              excavate: true,
            }
          : undefined
      }
    />
  </StyledWrapper>
)

const StyledWrapper = styled(Box)`
  overflow: hidden;
  flex: unset;
  svg {
    border-radius: ${({ theme }) => theme.radii.large};
  }
`
export default QRCode

