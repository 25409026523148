export const diceGameReturnToPlayer = 99

export const multiplierMax = 9900
export const multiplierMin = 1.0102

export enum DiceGameTargetType {
  Over = 1,
  Under = 2,
}

export const diceTargetSlideMinValue = 2
export const diceTargetSlideMaxValue = 98
