import React from 'react'
import styled from 'styled-components'
import Box from './Box'
import { BoxProps } from './types'

const BoxGradient = React.forwardRef<
  HTMLDivElement,
  {
    bgFirst?: string
    bgSec?: string
  } & BoxProps
>(({ bgFirst, bgSec, borderRadius = '12px', children, ...props }, ref) => {
  return (
    <WrapperBoxGradient background={bgFirst} ref={ref} borderRadius={borderRadius} {...props}>
      <ContentBoxGradient background={bgSec} borderRadius={borderRadius}>
        <Box position="relative" zIndex={2}>
          {children}
        </Box>
      </ContentBoxGradient>
    </WrapperBoxGradient>
  )
})

export const WrapperBoxGradient = styled(Box)`
  border-radius: 12px;
  background: ${({ theme, background }) => background || theme.colors.gradient.aqua};
  box-sizing: content-box;
  position: relative;
  overflow: hidden;
  padding: 0.6 1px;
`

export const ContentBoxGradient = styled(Box)`
  border-radius: 12px;
  border: 1px solid #000;
  background: ${({ theme, background }) => background || theme.colors.background};
  box-shadow: none;
  outline: none;
  border: none;
  width: 100%;
  height: 100%;
  background-clip: padding-box;
  border: solid 1px transparent;
  z-index: 1;

  &:after {
    content: ' ';
    background: rgba(255, 255, 255, 0.04);
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    box-shadow: none;
    outline: none;
    border: none;
    z-index: 0;
  }
`
export default BoxGradient
