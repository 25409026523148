import { InhouseGameType } from 'config/types/game'
import { BaseResponse } from 'services/types'
import { range } from 'utils'
import { MINE_GAME_SIZE } from 'views/InhouseGame/Mine/config'
import { InhouseLaunchSession, MineBlockResult } from 'views/InhouseGame/Mine/config/types'
import { RiskMaperEnum } from 'views/InhouseGame/Plinko/components/GameBoard/config'
import {
  DiceProbilityFairBetInfo,
  InhouseGameProbilityFairBetInfo,
  LimboProbilityFairBetInfo,
  MineProbilityFairBetInfo,
  PlinkoProbilityFairBetInfo,
  ProbilityFair,
  WheelProbilityFairBetInfo,
} from 'views/InhouseGame/config/types'
import BigNumber from 'bignumber.js'
import { USD_CURRENCY } from 'config/constants/tokens'
import { DiceSubmitMapper } from './Dice'
import { LimboSubmitMapper } from './Limbo'
import { MineSubmitMapper, parseCurrentBet, parseFinishedBet } from './Mines'
import { PlinkoSubmitMapper } from './Plinko'
import { WheelSubmitMapper } from './Wheel'

export const InhouseGameSubmitMapper = (rawResponse: string): BaseResponse<any> => {
  const response = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'error',
      data: null,
    }
  }

  switch (response.data?.payout_result.game_code) {
    case InhouseGameType.Plinko:
      return PlinkoSubmitMapper(response)
    case InhouseGameType.Limbo:
      return LimboSubmitMapper(response)
    case InhouseGameType.Dice:
      return DiceSubmitMapper(response)
    case InhouseGameType.Wheel:
      return WheelSubmitMapper(response)
    case InhouseGameType.Mine:
      return MineSubmitMapper(response)
    default:
      return {
        ...response,
      }
  }
}

export const InhouseGamePlayMapper = (rawResponse: string): BaseResponse<boolean> => {
  const response = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'error',
      data: null,
    }
  }

  switch (response.data?.payout_result.game_code) {
    case InhouseGameType.Mine:
      return {
        ...response,
        data: {
          response: response.data?.game_result.payout > 0,
        },
      }

    default:
      return {
        ...response,
        data: false,
      }
  }
}

export const GetSeedMapper = (rawResponse: string): BaseResponse<ProbilityFair> => {
  const response = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'error',
      data: null,
    }
  }

  return {
    code: response.code,
    data: response.data && {
      currentSeed: {
        clientSeed: response.data.active_seed.client_seed,
        hashedServerSeed: response.data.active_seed.server_seed_hash,
        nonce: response.data.active_seed.nonce,
      },
      nextSeed: {
        clientSeed: response.data.next_seed.client_seed,
        hashedServerSeed: response.data.next_seed.server_seed_hash,
        nonce: response.data.next_seed.nonce,
      },
      lockedByGames: response.data.playing_game_codes || [],
    },
  }
}

export const GetBetMapper = (rawResponse: string): BaseResponse<InhouseGameProbilityFairBetInfo> => {
  const response = JSON.parse(rawResponse)

  if (!response || !response.data) {
    return {
      code: response?.code || 'error',
      data: null,
    }
  }

  let baseBet: InhouseGameProbilityFairBetInfo = {
    type: response.data.game_info.code,
    seed: {
      clientSeed: response.data.seed.client_seed,
      nonce: response.data.seed_nonce,
      serverSeed: response.data.seed.server_seed,
      hashedServerSeed: response.data.seed.server_seed_hash,
    },
    payout: new BigNumber(response.data.payout),
    betAmount: {
      amount: new BigNumber(response.data.bet_amount).abs(),
      token: USD_CURRENCY,
    },
  }

  switch (baseBet.type) {
    case InhouseGameType.Plinko:
      baseBet = {
        ...baseBet,
        data: {
          row: response.data.player_data.bet_data.rows,
          risk: RiskMaperEnum[response.data.player_data.bet_data.risk],
          payout: Number(response.data.player_data.payout_data.payout),
        },
      } as PlinkoProbilityFairBetInfo
      break

    case InhouseGameType.Limbo:
      baseBet = {
        ...baseBet,
        data: {
          targetMultiplier: Number(response.data.player_data.bet_data.payout),
          resultMultiplier: Number(response.data.player_data.payout_data.payout),
        },
      } as LimboProbilityFairBetInfo

      break
    case InhouseGameType.Dice:
      baseBet = {
        ...baseBet,
        data: {
          target: {
            type: response.data.player_data.bet_data.roll,
            value: Number(response.data.player_data.bet_data.target),
          },
          result: Number(response.data.player_data.payout_data.result),
        },
      } as DiceProbilityFairBetInfo

      break

    case InhouseGameType.Wheel:
      baseBet = {
        ...baseBet,
        data: {
          risk: response.data.player_data.bet_data.risk,
          segments: response.data.player_data.bet_data.segments,
          result: Number(response.data.player_data.payout_data.result),
        },
      } as WheelProbilityFairBetInfo

      break

    case InhouseGameType.Mine:
      baseBet = {
        ...baseBet,
        data: {
          mines: Number(response.data.player_data.bet_data.mines),
          bet: parseFinishedBet(response.data),
        },
      } as MineProbilityFairBetInfo

      break
    default:
      break
  }
  return {
    code: response.code,
    data: response.data && baseBet,
  }
}

export const InhouseFinishBetMapper = (rawResponse: string): BaseResponse<any> => {
  const response = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'error',
      data: false,
    }
  }

  const gameCode = response.data?.game_code

  switch (gameCode) {
    case InhouseGameType.Mine:
      const defaultResult = range(0, MINE_GAME_SIZE * MINE_GAME_SIZE).map(() => MineBlockResult.Diamond)

      if (response?.data?.system_data?.game_data?.mines) {
        const mines = response.data.system_data.game_data.mines
        for (let index = 0; index < mines.length; index++) {
          const element = mines[index]
          defaultResult[element] = MineBlockResult.Mine
        }
      }
      return {
        code: response.code,
        data: response.data && parseFinishedBet(response.data),
      }

    default:
      return {
        code: response.code,
        data: response.code === 'success',
      }
  }
}

export const InhouseGameLaunchMapper = (rawResponse: any): BaseResponse<InhouseLaunchSession> => {
  const response = JSON.parse(rawResponse)

  if (!response || !response.data) {
    return {
      code: response?.code || 'error',
      data: null,
    }
  }
  return {
    ...response,
    data: response.data && {
      sessionId: response.data.session_id,
      userBalance: response.data.balance,
      bet: parseCurrentBet(response.data.playing_round),
      gameSettings: {
        maxPayoutUsd: parseFloat(response.data.game_info?.setting?.max_payout_usd || 0),
        minBetAmountUsd: parseFloat(response.data.game_info?.setting?.min_bet_amount_usd || 0),
        maxBetAmountUsd: parseFloat(response.data.game_info?.setting?.max_bet_amount_usd || 0),
      },
    },
  }
}
