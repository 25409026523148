import styled, { css } from 'styled-components'
import { StyleToggleProps } from './types'

export const Handle = styled.div`
  cursor: pointer;
  position: absolute;
  transition: 200ms ease-in;

  z-index: 1;
  border-radius: ${({ theme }) => theme.radii.extraLarge};
`

const StyledToggle = styled.div<StyleToggleProps & { width: string; $disabled?: boolean; $isBlur: boolean }>`
  align-items: center;
  background-color: ${({ theme, $checked, $checkedBackgroundColor, $backgroundColor, $disabled, $isBlur }) =>
    theme.colors[
      ($checked && $isBlur && $disabled) || ($checked && !$disabled)
        ? $checkedBackgroundColor || $backgroundColor
        : $backgroundColor
    ]};

  border-radius: 40px;
  cursor: pointer;
  display: inline-flex;
  position: relative;
  transition: background-color 200ms;
  width: ${({ width }) => width};
  height: calc(${({ width }) => width} / 1.8);
  border-radius: ${({ theme }) => theme.radii.extraLarge};
  overflow: hidden;

  ${Handle} {
    height: calc(calc(${({ width }) => width} / 1.8) - 4px);
    width: calc(calc(${({ width }) => width} / 1.8) - 4px);
    ${({ $checked }) =>
      $checked
        ? css`
            right: 2px;
          `
        : css`
            left: 2px;
          `};
    top: 2px;

    background-color: ${({ theme, $checked, $checkedColor, $defaultColor, $disabled, $isBlur }) =>
      theme.colors[($checked && $isBlur && $disabled) || ($checked && !$disabled) ? $checkedColor : $defaultColor]};
  }

  input {
    opacity: 0;
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 3;

    cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};

    &:hover + ${Handle}:not(:disabled):not(:checked) {
      background-color: ${({ theme, $disabled }) => !$disabled && theme.colors.text};
    }
  }
  opacity: ${({ $isBlur }) => ($isBlur ? 0.3 : 1)};
`

export default StyledToggle
