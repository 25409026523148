import Box from 'components/Box/Box'
import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { PopperProps, Position, PositionProps } from './typpes'

const getLeft = ({ position }: PositionProps) => {
  if (position === 'top-right') {
    return '100%'
  }

  if (position === 'top-left') {
    return '0%'
  }

  if (position === 'bottom-left') {
    return '0%'
  }

  if (position === 'bottom-right') {
    return 'unset'
  }

  return '50%'
}

const getRight = ({ position }: PositionProps) => {
  if (position === 'bottom-right') {
    return '0%'
  }

  return 'unset'
}

const getTransformWhenActive = ({ position }: any) => {
  if (position === 'bottom-left' || position === 'bottom-right' || position === 'top-left') {
    return 'translate(0%, 0%)'
  }

  return 'translate(-50%, 0%)'
}

const getBottom = ({ position }: PositionProps) => {
  if (position === 'top' || position === 'top-right' || position === 'top-left') {
    return '100%'
  }
  return 'auto'
}

const StyleContainer = styled(Box)<{ $disabled?: boolean }>`
  cursor: ${({ $disabled }) => (!$disabled ? 'pointer' : 'not-allowed')};
  width: 100%;
  height: 100%;
`

export const StyledMenuDropdownContainer = styled.div<{ position: Position }>`
  flex-direction: column;
  position: absolute;
  overflow-y: auto;
  z-index: ${({ theme }) => theme.zIndices.dropdown};
  transition: ${({ theme }) => theme.transitions.fast};

  bottom: ${getBottom};
  left: 50%;

  display: flex;
  transform: ${getTransformWhenActive};
  overflow: visible;
  min-width: 100%;

  ${({ theme }) => theme.mediaQueries.sm} {
    width: max-content;
    left: ${getLeft};
    right: ${getRight};
  }
`

export const StyledMenuDropdownWrapper = styled(Box)`
  position: relative;
  width: 100%;
  height: 100%;
`

const Popper: React.FC<PopperProps> = ({
  children,
  dropdownContent,
  dropdownPosition = 'bottom',
  open,
  setOpen,
  disabled,
  className,
  ...props
}) => {
  const container = useRef(null)
  const dropdown = useRef(null)

  useEffect(() => {
    function handleClickOutside(e: MouseEvent) {
      if (dropdown.current && !dropdown.current.contains(e.target) && !container.current.contains(e.target)) {
        setOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside, {
      passive: true,
    })

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [dropdown, container])

  const handleToggle = (e: any) => {
    if (disabled) return

    if (dropdown.current && dropdown.current.contains(e.target)) {
      return
    }
    setOpen(!open)
  }

  return (
    <StyledMenuDropdownWrapper ref={container} onClick={handleToggle}>
      <StyleContainer $disabled={disabled} className={className} {...props}>
        {children}
      </StyleContainer>

      {open && (
        <StyledMenuDropdownContainer ref={dropdown} position={dropdownPosition}>
          {React.isValidElement(dropdownContent) ? (
            React.cloneElement(dropdownContent, {
              onDismiss: () => setOpen(false),
            } as any)
          ) : (
            <>{dropdownContent}</>
          )}
        </StyledMenuDropdownContainer>
      )}
    </StyledMenuDropdownWrapper>
  )
}

export default Popper
