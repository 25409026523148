import Box from 'components/Box/Box'
import Button from 'components/Button/Button'
import Heading from 'components/Heading/Heading'
import CircleLoader from 'components/Loader/CircleLoader'
import Dots from 'components/Loader/Dots'
import Text from 'components/Text/Text'
import { WalletInfo } from 'config/types/wallet'
import { ColumnCenter } from 'layout/Components/Column'
import { RowCenter } from 'layout/Components/Row'
import React from 'react'
import { Trans } from 'react-i18next'
import styled from 'styled-components'
import { getIcon } from 'svgs'
import theme from 'theme'

const WalletConnecting: React.FC<{ wallet: WalletInfo; isLoading: boolean; retry: () => void }> = ({
  wallet,
  isLoading,
  retry,
}) => {
  const Icon = getIcon(wallet.icon)

  return (
    <RowCenter justifyContent={['flex-start', '', 'center']} flexDirection="column" height="100%">
      <ColumnCenter>
        <StyledIcon>
          <Icon width="40px" />
        </StyledIcon>

        <Heading ml={isLoading ? '20px' : 0} mt="16px">
          {isLoading ? (
            <>
              <Trans>Opening</Trans> <Dots>{wallet.name}</Dots>
            </>
          ) : (
            <>
              <Trans>Open</Trans> {wallet.name}
            </>
          )}
        </Heading>
        <Text my="8px" small color="textSubtle">
          <Trans>Confirm connection in the extension</Trans>
        </Text>

        <Box my="8px">
          {isLoading ? (
            <RowCenter height="36px">
              <CircleLoader scale={1.5} />
            </RowCenter>
          ) : (
            <Button
              width="100px"
              height="34px"
              style={{
                borderRadius: theme.radii.extraLarge,
              }}
              onClick={retry}
            >
              <Text fontWeight={600} small>
                <Trans>Retry</Trans>
              </Text>
            </Button>
          )}
        </Box>
      </ColumnCenter>
    </RowCenter>
  )
}

const StyledIcon = styled(RowCenter)`
  background: #fff;
  border-radius: ${({ theme }) => theme.radii.default};

  width: 60px;
  height: 60px;
`

export default WalletConnecting
