import { BIG_ZERO } from 'config/constants/number'
import { TokenAmount } from 'config/types'
import { InhouseGameDetails, InhouseGameType } from 'config/types/game'
import useDebounceCallback from 'hooks/useDebounceCallback'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import DiceGameService from 'services/InhouseGameService/DiceGameService'
import LimboGameService from 'services/InhouseGameService/LimboGameService'
import MineGameService from 'services/InhouseGameService/MineGameService'
import PlinkoGameService from 'services/InhouseGameService/PlinkoGameService'
import WheelGameService from 'services/InhouseGameService/WheelGameService'
import { useAuth } from 'state/auth/hooks'
import { useTokenSelected } from 'state/session/hooks'
import { InhouseLaunchSession } from '../Mine/config/types'
import { Risk } from './components/GameBoard/config'
import { PlinkoActionContext } from './context/PlinkoActionProvider'
import { PlinkoEventContext } from './context/PlinkoEventProvider'
import { PlinkoBet, PlinkoDraftBet } from './types/Bet'
import { useP5 } from '../hooks/useP5'
import { useMatter } from '../hooks/useMatter'

export const usePlinkoGameStatus = () => {
  const { isPlaying } = useContext(PlinkoActionContext)

  return useMemo(
    () => ({
      isPlaying,
    }),
    [isPlaying],
  )
}

export const usePlinkoAction = () => {
  const { submitBet, finishBet } = useContext(PlinkoActionContext)

  const handleBet = useCallback(
    async (
      betAmount: TokenAmount,
      risk: Risk,
      row: number,
      options: {
        instanceBetEnable?: boolean
        volumeEnable?: boolean
      },
    ) => {
      const draftBet: PlinkoDraftBet = {
        betAmount,
        risk,
        row,
        disabledDisplay: options?.instanceBetEnable,
      }

      return submitBet(draftBet, options?.volumeEnable)
    },
    [submitBet],
  )

  return useMemo(() => {
    return {
      submitBet: handleBet,
      finishBet,
    }
  }, [handleBet, finishBet])
}

export const usePlinkoBetListener = (subscribe: (bet: PlinkoBet) => void | (() => void)) => {
  const { newBet } = useContext(PlinkoEventContext)
  useEffect(() => {
    if (!newBet) return
    const dismissFn = subscribe(newBet)
    return dismissFn
  }, [newBet])
}

export const usePlinkoCompletedBetListener = (subscribe: (bet: PlinkoBet) => void | (() => void)) => {
  const { completedBet } = useContext(PlinkoEventContext)

  useEffect(() => {
    if (!completedBet) return
    const dismissFn = subscribe(completedBet)
    return dismissFn
  }, [completedBet])
}

const DEFAULT_SESSION_BET = {
  sessionId: '',
  bet: null,
  userBalance: BIG_ZERO,
  gameSettings: {
    maxPayoutUsd: 0,
    minBetAmountUsd: 0,
    maxBetAmountUsd: 0,
  },
}

export const useInhouseSession = ({ game }: { game: InhouseGameDetails }) => {
  const { isSigned } = useAuth()
  const tokenSelected = useTokenSelected()

  const [session, setSesson] = useState<InhouseLaunchSession>(DEFAULT_SESSION_BET)

  const [status, setStatus] = useState<'unknown' | 'loading' | 'launched' | 'clear'>('unknown')

  const service = useMemo(() => {
    switch (game.additionalData?.inhouseGameType) {
      case InhouseGameType.Plinko:
        return PlinkoGameService
      case InhouseGameType.Limbo:
        return LimboGameService
      case InhouseGameType.Dice:
        return DiceGameService
      case InhouseGameType.Wheel:
        return WheelGameService
      case InhouseGameType.Mine:
        return MineGameService
      default:
        return null
    }
  }, [game])

  const fetch = useCallback(async () => {
    try {
      if (!service) return

      setStatus('loading')

      if (isSigned && tokenSelected && service && service.launchGame) {
        const result = await service.launchGame(game.code, tokenSelected)
        if (result?.sessionId) {
          setSesson(result)
          setStatus('launched')
        } else {
          setStatus('clear')
          setSesson(DEFAULT_SESSION_BET)
          service.removeSession()
        }
      } else if (!isSigned && service.removeSession) {
        service.removeSession()
        setStatus('clear')
        setSesson({
          ...DEFAULT_SESSION_BET,
          gameSettings: session?.gameSettings || DEFAULT_SESSION_BET.gameSettings,
        })
      }
    } catch {
      setStatus('clear')
      setSesson(DEFAULT_SESSION_BET)
    }
  }, [isSigned, service])

  const debounceCallback = useDebounceCallback()

  useEffect(() => {
    debounceCallback(() => fetch(), 200)
  }, [isSigned])

  return useMemo(
    () => ({
      isLoading: status === 'loading',
      isLaunched: status === 'launched',
      isClear: status === 'clear',
      ...session,
    }),
    [session, status],
  )
}

export const useLoadCdnPartyPlinko = () => {
  const { isLoaded } = useP5()
  const { Matter } = useMatter()

  return useMemo(() => isLoaded && Matter, [isLoaded, Matter])
}
