import Modal, { ModalProps } from 'components/Modal/Modal'
import React from 'react'
import styled from 'styled-components'
import Heading from 'components/Heading'
import ModalBody from 'components/Modal/components/ModalBody'
import { ModalHeader } from 'components/Modal'
import theme from 'theme'
import IconButton from 'components/Button/IconButton'
import { Icons } from 'svgs'
import { Trans } from 'react-i18next'
import Image from 'components/Image'
import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import Text from 'components/Text'
import UnlockFormulaDisplay from 'views/HUSDUnlock/components/HUSDClaim/UnlockFormulaDisplay'

const AbountHUSDModal: React.FC<ModalProps> = ({ onDismiss }) => {
  return (
    <StyledModal onDismiss={onDismiss}>
      <ModalHeader>
        <IconButton ml="24px" onClick={onDismiss}>
          <Icons.BackIcon width="24px" height="24px" />
        </IconButton>
        <Heading scale="xs" ml="12px">
          <Trans>About $HUSD</Trans>
        </Heading>
      </ModalHeader>
      <ModalBody borderRadius={theme.radii.modal} height="100%" p={['12px', '24px']}>
        <Flex justifyContent="center">
          <Image width={120} height={60} src="/images/HUSD-coin-group.png" alt="husd group" />
        </Flex>

        <Text small color="text" bold mt="20px" lineHeight="16px">
          <Trans>What is HUSD</Trans>?
        </Text>

        <Text small color="textSubtle" mt="20px" lineHeight="16px">
          <Trans>
            HUSD (HunnyPlayDollar) is a special currency launched by HUNNYPLAY. 1 HUSD = 1 USD. You can play games or
            swap to other stable coins.
          </Trans>
        </Text>

        <Text small color="text" bold mt="20px" lineHeight="16px">
          <Trans>How to unlock HUSD</Trans>?
        </Text>

        <Text small color="textSubtle" mt="20px" lineHeight="16px">
          <Trans>
            Locked HUSD is obtained through certain bonuses, such as deposit bonus and lucky spin(coming soon).
          </Trans>
        </Text>

        <Text small color="textSubtle" mt="20px" lineHeight="16px">
          <Trans>Unlocking HUSD is unlock proportionally through wager.</Trans>
        </Text>

        <Box mt="20px" background="rgba(198, 215, 254, 0.04)" borderRadius="4px" p="12px 0px">
          <UnlockFormulaDisplay color="text" />
        </Box>

        <Text small color="text" bold mt="20px" lineHeight="16px">
          <Trans>How to claim unlocked HUSD</Trans>?
        </Text>

        <Text small color="textSubtle" mt="20px" lineHeight="16px">
          <Trans>
            As you wager, your HUSD locked amount will be unlocked automatically and you can click “Claim” to claim the
            amount of your HUSD balance.
          </Trans>
        </Text>

        <Text small color="textSubtle" mt="20px" lineHeight="16px">
          <Trans>That’s it! Your HUSD is now immediately available to use</Trans>!
        </Text>

        <Text small color="text" bold mt="20px" lineHeight="16px">
          <Trans>Can HUSD be exchanged to any other currency?</Trans>
        </Text>

        <Text small color="textSubtle" mt="20px" lineHeight="16px">
          <Trans>
            Sure! The most exciting thing is that the value of 1 HUSD is bound to the price of 1 USDT, and you can swap
            HUSD into other stable coin at any time.
          </Trans>
        </Text>
      </ModalBody>
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  padding-bottom: 48px;

  ${({ theme }) => theme.mediaQueries.sm} {
    min-width: 428px !important;
    max-width: 428px !important;
  }
`

export default AbountHUSDModal
