import { Token } from 'config/types'
import { ChainIdEnum } from '../network'

const path = '/images/token'

const tokens: Record<string, Token> = {
  ETH: {
    coinGeckoInfo: {
      id: 'ethereum',
    },
    logo: `${path}/eth.png`,
    name: 'ETH',
    code: 'ETH',
    network: ChainIdEnum.OP_TESTNET,
    isNative: true,
    decimals: 18,
  },
  OP: {
    coinGeckoInfo: {
      id: 'optimism',
    },
    logo: `${path}/op.png`,
    name: 'OP',
    code: 'OP',
    network: ChainIdEnum.OP_TESTNET,
    address: '0xaa5f3e2e31a13adde216a7dc4550872cc253254f',
    decimals: 18,
    isStable: true,
  },
  USDC: {
    coinGeckoInfo: {
      id: 'usd-coin',
    },
    logo: `${path}/usdc.png`,
    name: 'USDC',
    code: 'USDC',
    network: ChainIdEnum.OP_TESTNET,
    address: '0x536ae5308db81aefc83470424e41c6b9b6a668b8',
    decimals: 6,
    isStable: true,
  },
}

export default tokens
