import Box from 'components/Box/Box'
import Modal, { ModalBody, ModalProps } from 'components/Modal'
import Text from 'components/Text'
import React from 'react'
import { Trans } from 'react-i18next'
import styled from 'styled-components'
import Security2FA from './2FA'

const SettingSecurityModal: React.FC<ModalProps> = ({ onDismiss }) => {
  return (
    <StyledModal id="popup-signwallet" onDismiss={onDismiss} p="0 !important">
      <ModalBody p="20px">
        <Text color="textTertiary" fontWeight={600} fontSize="14px" lineHeight="normal" letterSpacing={-0.28} mb="20px">
          <Trans>Security</Trans>
        </Text>
        <Box
          style={{
            background: 'rgba(35, 44, 61, 0.20)',
            borderRadius: '12px',
          }}
          p="20px"
        >
          <Security2FA />
        </Box>
      </ModalBody>
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  ${({ theme }) => theme.mediaQueries.sm} {
    min-width: 440px !important;
    max-width: 440px !important;
  }
`

export default SettingSecurityModal
