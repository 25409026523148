import Box from 'components/Box/Box'
import { BoxProps } from 'components/Box/types'
import Button from 'components/Button/Button'
import Image from 'components/Image/Image'
import OpenEffect from 'components/OpenEffect'
import Text from 'components/Text/Text'
import { RouteConfig } from 'config/constants/route'
import { useRouter } from 'hooks/useRouter'
import { ColumnCenter } from 'layout/Components/Column'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import base from 'theme/base'

const RedeemBonusSucceed: React.FC<BoxProps & { onDismiss: () => void; btnText: string; description: string }> = ({
  onDismiss,
  btnText,
  description,
  ...props
}) => {
  const router = useRouter()
  const { t } = useTranslation()

  return (
    <StyledContainer {...props}>
      <Box width={80} height={80}>
        <Image src="/images/success-icon-alt.png" width={160} height={160} />
      </Box>

      <ColumnCenter mt="12px" p="12px" borderRadius="5px">
        <Text fontSize="20px" fontWeight={800} mb={2}>
          <Trans>Success</Trans>!
        </Text>

        <Text small lineHeight="18px" color="textTertiary" maxWidth={420} textAlign="center">
          {t(description)}
        </Text>
      </ColumnCenter>

      <Button
        onClick={() => {
          router.push(RouteConfig.BonusCenter)
          onDismiss()
        }}
        height="48px"
        mt="24px"
        width="312px"
        style={{
          borderRadius: base.radii.tiny,
        }}
      >
        <Text small bold>
          {t(btnText)}
        </Text>
      </Button>
    </StyledContainer>
  )
}

const StyledContainer = styled(OpenEffect).attrs({ openType: 'grow' })`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export default RedeemBonusSucceed
