import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import Text from 'components/Text'
import React from 'react'
import { toast, ToastOptions } from 'react-toastify'
import { getIcon, Icons } from 'svgs'
import { colors } from 'theme/colors'
import Image from 'components/Image/Image'
import theme from '../theme'

export const toastContainerStyle = {
  backgroundColor: `${colors.inputAlt}`,
  borderRadius: `${theme.radii.default}`,
  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
}

export function showToast(
  content: string | React.ReactNode,
  config?: ToastOptions,
  progressColor: keyof typeof colors = 'info',
) {
  return toast(content, {
    style: toastContainerStyle,
    progressStyle: { height: 2, background: colors[progressColor] as string },
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    ...config,
  })
}

const ToastTemplate: React.FC<{
  icon: keyof typeof Icons | React.ReactNode
  color: keyof typeof colors
  content: string
  desc?: string
}> = ({ icon, color, content, desc }) => {
  const Icon = typeof icon === 'string' ? getIcon(icon) : icon
  return (
    <Box>
      <Flex alignItems="flex-start">
        <Box width="24px" height="24px">
          {typeof icon === 'string' ? <Icon width="24px" height="24px" fill={colors[color]} /> : icon}
        </Box>
        <Box ml="8px" mt="4px">
          <Text small bold color={color}>
            {content}
          </Text>
          {desc && (
            <Text small color="textSubtle" mt="6px" lineHeight="1.05em">
              {desc}
            </Text>
          )}
        </Box>
      </Flex>
    </Box>
  )
}

export const HunnyToast = {
  showReward: (content: string, desc?: string, config?: ToastOptions) => {
    return showToast(
      <ToastTemplate
        icon={
          <Box width="24px" height="24px">
            <Image width={24} height={24} src="/images/gift_box.png" />
          </Box>
        }
        color="warning"
        content={content}
        desc={desc}
      />,
      config,
      'warning',
    )
  },

  show: (content: string, desc?: string, config?: ToastOptions) => {
    return showToast(<ToastTemplate icon="InfoIcon" color="info" content={content} desc={desc} />, config, 'info')
  },
  error: (content: string, desc?: string, config?: ToastOptions) => {
    return showToast(
      <ToastTemplate icon="CloseCircleIcon" color="error" content={content} desc={desc} />,
      config,
      'error',
    )
  },
  success: (content: string, desc?: string, config?: ToastOptions) => {
    return showToast(
      <ToastTemplate icon="SuccessIcon" color="success" content={content} desc={desc} />,
      config,
      'success',
    )
  },
  warn: (content: string, desc?: string, config?: ToastOptions) => {
    return showToast(
      <ToastTemplate icon="WarningIcon" color="warning" content={content} desc={desc} />,
      config,
      'warning',
    )
  },

  showNoti: (content: string, config: { type?: 'alerts' | 'banners' } = {}) => {
    const { type } = config

    return showToast(
      <Box
        background="rgba(255, 255, 255, 1)"
        p="12px"
        style={{ backdropFilter: 'blur(6px)', cursor: 'default' }}
        borderRadius="8px"
        border={`1px solid ${colors.textSubtle}`}
      >
        <Text color="#161C24" fontSize="12px" fontWeight="400" lineHeight="normal">
          {content}
        </Text>
      </Box>,

      {
        autoClose: type === 'alerts' ? false : 5000,
        closeOnClick: true,
        hideProgressBar: true,
        closeButton: type === 'alerts' && (
          <Flex
            background={colors.textTertiary}
            position="absolute"
            borderRadius="40px"
            width="18px"
            height="18px"
            right="-6px"
            top="-9px"
            justifyContent="center"
            alignItems="center"
            style={{ boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.12) !important' }}
          >
            <Icons.CloseIcon fill={colors.textAlt1} width="14px" height="14px" />
          </Flex>
        ),
        bodyStyle: {
          padding: '0px',
        },
        style: {
          overflow: 'unset',
          padding: '0px',
          minHeight: '0px',
          background: 'transparent',
          maxWidth: '320px',
        },
      },
      'warning',
    )
  },
  dismiss: (toastId: React.ReactText) => {
    toast.dismiss(toastId)
  },
}
