import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import Button from 'components/Button'
import Image from 'components/Image'
import CircleLoader from 'components/Loader/CircleLoader'
import Modal from 'components/Modal'
import ModalBody from 'components/Modal/components/ModalBody'
import { ModalProps } from 'components/Modal/Modal'
import Text from 'components/Text'
import { WalletInfo, WalletType } from 'config/types/wallet'
import useModal from 'hooks/useModal'
import useNewUserJourney from 'hooks/useNewUserJourney'
import useQueryParam from 'hooks/useQueryParam'
import { useReferralCode } from 'hooks/useReferralCode'
import { useRequest } from 'hooks/useRequest'
import useSignMessage from 'hooks/useSignMessage'
import { useBuildTeleAppData } from 'hooks/useTelegram'
import useWalletAccount from 'hooks/useWalletAccount'
import { RowMiddle } from 'layout/Components/Row'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { Trans } from 'react-i18next'
import AuthenticationService from 'services/AuthenticationService'
import { SignInByWalletPayload } from 'services/types'
import { useAppDispatch } from 'state'
import { setIsSigned } from 'state/app/actions'
import { useAuth, useClientLogin } from 'state/auth/hooks'
import { useUserOnboardingStatus } from 'state/profile/hooks'
import { updateDeviceUid } from 'state/session/actions'
import styled from 'styled-components'
import { shortedAddress } from 'utils'
import { getID } from 'utils/fingerprint'
import { getHUSDBonus } from 'utils/signupBonus'
import { getTrackingWalletName } from 'utils/trackingWallet'
import ProvideTotpCodeModal from 'views/Profile/Settings/components/SettingSecurity/2FA/ProvideTotpAuthCodeModal'

type SignInByWalletProps = {
  wallet: WalletInfo
  signUpPromoCode?: string
}
const SignInByWallet: React.FC<ModalProps<SignInByWalletProps>> = ({ data, onDismiss }) => {
  const { wallet, signUpPromoCode } = data
  const refCode = useReferralCode()
  const router = useRouter()
  const { removeParam } = useQueryParam()

  const accounts = useWalletAccount()
  const { execute } = useRequest()
  const signMessage = useSignMessage(wallet.type)
  const [signing, setSigning] = useState(false)
  const login = useClientLogin()

  const { isSigned } = useAuth()
  const dispatch = useAppDispatch()
  const address = accounts[wallet.type]
  const bonus = getHUSDBonus()
  const [onPresentProvideTotpCodeModal] = useModal(ProvideTotpCodeModal)
  const startJourney = useNewUserJourney()
  const { update } = useUserOnboardingStatus()
  const { webAppData } = useBuildTeleAppData()

  useEffect(() => {
    if (!address || isSigned) {
      onDismiss()
    }
  }, [address, wallet, isSigned])

  const handleSignIn = async (messageSignature, acceptMessage, totpAuthCode?: string) => {
    const deviceUid = await getID()
    const params: SignInByWalletPayload = {
      deviceUid,
      address,
      messageSignature,
      acceptMessage,
      totpAuthCode,
      webAppData,
    }

    if (refCode.type === 'affilka') {
      params.affilkaCode = refCode.refCode
    } else if (refCode.type === 'hunnyplay') {
      params.referralCode = refCode.refCode
    }
    if (bonus) {
      params.husdBonusAmount = bonus.amount || 0
    }

    const result = await AuthenticationService.signInByWallet(params)

    if (result.data?.uid) {
      const walletTracked = wallet.type === WalletType.SOL ? wallet.name : await getTrackingWalletName(wallet)
      dispatch(updateDeviceUid({ deviceUid }))
      login(
        {
          authBy: result.data.authBy,
          uid: result.data.uid,
          username: address,
          wallet: {
            name: wallet.name,
            type: wallet.type,
            connectorId: wallet.adapter.name,
            address,
            trackingName: walletTracked,
          },
          email: null,
        },
        { event: result.data.isSignUp ? 'register' : 'login' },
      )

      if (result.data?.isSignUp) {
        update(true)
        const { bonus } = router.query

        startJourney({
          husdBonusAmount: result?.data?.husdBonusAmount,
          bonusCode: bonus?.toString() || signUpPromoCode,
        })
        removeParam('bonus')
      }

      dispatch(setIsSigned({ isSigned: true, atTime: new Date().getTime() }))
    }

    return result
  }

  const handleSignWallet = async () => {
    setSigning(true)

    // TODO check type error
    const {
      data: { acceptMessageText, acceptMessage, isRequire2FA },
    } = await execute(AuthenticationService.getMessageToWalletSign({ address, walletType: wallet.type }))
    const messageSignature = await signMessage(acceptMessageText)
    if (!messageSignature) {
      setSigning(false)
      return
    }
    if (isRequire2FA) {
      onPresentProvideTotpCodeModal({
        onTotpAuthCodeConfirmed: async (code) => {
          const response = await handleSignIn(messageSignature, acceptMessage, code)
          return response
        },
      })
    } else {
      handleSignIn(messageSignature, acceptMessage)
    }

    setSigning(false)
  }

  return (
    <StyledModal id="popup-signwallet" onDismiss={onDismiss} p="0 !important">
      <ModalBody>
        <Box p="40px 34px">
          <Flex alignItems="center" flexDirection="column">
            {signing ? (
              <Image alt="join" width={80} height={80} src="/images/signing.png" />
            ) : (
              <Image alt="join" width={80} height={80} src="/images/join.png" />
            )}
            <Text bold fontSize="18px" lineHeight="24px" mt="16px">
              <Trans>Signing with this address</Trans>
            </Text>
            <Text bold fontSize="18px" mt="2px">
              {shortedAddress(address)}
            </Text>

            <Text color="textSubtle" fontSize="14px" lineHeight={1.2} mt="28px" textAlign="center">
              <Trans>
                You need to sign a message on your wallet to prove ownership of the address you are connected with.
              </Trans>
            </Text>

            <StyledButton id="signmessage-button" width="100%" mt="40px" onClick={handleSignWallet} disabled={signing}>
              {signing ? (
                <RowMiddle>
                  <Box mr="8px">
                    <CircleLoader />
                  </Box>
                  <Trans>Waiting for Signature</Trans>
                </RowMiddle>
              ) : (
                <Trans>Sign Message</Trans>
              )}
            </StyledButton>
          </Flex>
        </Box>
      </ModalBody>
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  ${({ theme }) => theme.mediaQueries.sm} {
    min-width: 440px !important;
    max-width: 440px !important;
  }
`

const StyledButton = styled(Button)`
  font-size: 12px;
  font-weight: bold;

  &:disabled {
    background-color: ${({ theme }) => theme.colors.primaryDisabled};
  }
`

export default SignInByWallet
