import Flex from 'components/Box/Flex'
import { FlexProps } from 'components/Box/types'
import Image from 'components/Image/Image'
import Link from 'components/Link/Link'
import OpenEffect from 'components/OpenEffect'
import Text from 'components/Text/Text'
import { ColumnCenter } from 'layout/Components/Column'
import { RowBetween, RowCenter } from 'layout/Components/Row'
import { Trans } from 'react-i18next'
import styled from 'styled-components'
import { Icons } from 'svgs'
import { colors } from 'theme/colors'

const BuyCryptoPlatformCard: React.FC<
  { logo: string; name: string; description: string; href: string } & FlexProps
> = ({ logo, name, description, href, ...props }) => {
  return (
    <Link href={href} external>
      <StyledPlatFormWrap {...props}>
        <RowCenter>
          <Image src={logo} width={32} height={32} />
          <Flex flexDirection="column" ml="4px">
            <Text fontSize="14px" color="textTertiary" fontWeight={600} lineHeight="normal">
              {name}
            </Text>
            <Text color="textAlt1" fontSize="12px" fontWeight={400} lineHeight="normal">
              {description}
            </Text>
          </Flex>
        </RowCenter>

        <StyleRightArrowIcon fill={colors.textTertiary} />
      </StyledPlatFormWrap>
    </Link>
  )
}

const DepositByBuyCrypto = () => {
  return (
    <StyledContainer pt={[0, , '22px']} openType="grow" duration={0.4}>
      <ColumnCenter>
        <Image src="/images/credit-card.png" width={120} height={120} />
      </ColumnCenter>

      <Text fontSize={'14px'} fontWeight={300} lineHeight="normal" my="20px" textAlign={['center', 'center', 'left']}>
        <Trans
          i18nKey="buyCryptoByFiatDescription"
          components={{ highlight_tag: <Text as="span" fontSize="inherit" fontWeight={600} color="primary" /> }}
        />
      </Text>

      <BuyCryptoPlatformCard
        logo="/images/0x0.png"
        name="Moonpay"
        description="Apple Pay | Card"
        href="https://buy.moonpay.com/"
      />
      <BuyCryptoPlatformCard
        logo="/images/paybis.png"
        name="Paybis"
        description="Apple Pay | Card"
        my="12px"
        href="https://paybis.com/"
      />
      <BuyCryptoPlatformCard
        logo="/images/chainbis.png"
        name="Chainbits"
        description="Apple Pay | Card"
        href="https://buy.chainbits.com/"
      />
    </StyledContainer>
  )
}

const StyledContainer = styled(OpenEffect)`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.mediaQueries.xs} {
    min-height: 450px;
  }
`
const StyledPlatFormWrap = styled(RowBetween)`
  max-height: 58px;
  border: ${({ theme: { colors } }) => `1px solid ${colors.textDisable}`};
  border-radius: ${({ theme: { radii } }) => radii.small};
  padding: 12px;
  padding-right: 18px;
  background: #161c24;
`

const StyleRightArrowIcon = styled(Icons.ArrowRight)`
  transform: scale(1.5) !important;
  cursor: pointer;
`

export default DepositByBuyCrypto

