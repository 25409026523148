import Heading from 'components/Heading'
import Modal, { ModalBody, ModalProps } from 'components/Modal'
import { InhouseGameDetails } from 'config/types/game'
import Column from 'layout/Components/Column'
import { RowMiddle } from 'layout/Components/Row'
import React, { useState } from 'react'
import { Trans } from 'react-i18next'
import { useAuth } from 'state/auth/hooks'
import { Icons } from 'svgs'
import theme from 'theme'
import { InhouseGameProbilityFairBetInfo } from 'views/InhouseGame/config/types'
import { InHouseIndicator, InHouseTab, InHouseTabs } from '../styled'
import SeedFairness from './SeedFairness'
import VerifyForm from './VerifyForm'

enum PairTab {
  Seed = 0,
  Verify = 1,
}

const FairnessModal: React.FC<
  ModalProps<{ game?: InhouseGameDetails; inhouseBet?: InhouseGameProbilityFairBetInfo }>
> = ({ onDismiss, data }) => {
  const { isSigned } = useAuth()
  const { game, inhouseBet } = data
  const [tab, setTab] = useState(isSigned && game ? PairTab.Seed : PairTab.Verify)

  return (
    <Modal
      onDismiss={onDismiss}
      maxWidth={['', '', '420px !important']}
      minWidth={['', '', '420px !important']}
      p="24px 24px 24px"
    >
      <RowMiddle>
        <Icons.FairNessIcon width="16px" height="16px" fill={theme.colors.textSubtle} />
        <Heading scale="xs" ml="8px">
          <Trans>Fairness</Trans>
        </Heading>
      </RowMiddle>
      <ModalBody
        maxHeight="550px"
        mt="8px"
        p="24px"
        mx="-24px"
        width="calc(100% + 48px) !important"
        borderRadius="0 !important"
      >
        <InHouseTabs
          mb={['0', '', '', '', '20px']}
          value={tab}
          onTagChanged={setTab}
          backgroundBlurColor={theme.colors.background}
          indicator={<InHouseIndicator />}
        >
          <InHouseTab value={PairTab.Seed} $active={tab === PairTab.Seed} disabled={!isSigned || !game}>
            <Trans>Seeds</Trans>
          </InHouseTab>
          <InHouseTab value={PairTab.Verify} $active={tab === PairTab.Verify}>
            <Trans>Verify</Trans>
          </InHouseTab>
        </InHouseTabs>

        <Column>
          {tab === PairTab.Seed && <SeedFairness game={game} />}
          {tab === PairTab.Verify && (
            <VerifyForm initialInhouseGameType={game?.additionalData?.inhouseGameType} initialBet={inhouseBet} />
          )}
        </Column>
      </ModalBody>
    </Modal>
  )
}

export default FairnessModal
