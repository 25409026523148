import Heading from 'components/Heading'
import Modal, { ModalBody, ModalProps } from 'components/Modal'
import Text from 'components/Text'
import Column from 'layout/Components/Column'
import { useState } from 'react'
import { AuthModalPageEnums } from 'views/Authentication/types'
import styled from 'styled-components'
import { WalletInfo } from 'config/types/wallet'
import { Trans } from 'react-i18next'
import WalletAuth from '.'

const WalletAuthModal: React.FC<
  ModalProps<{
    onCallbackConnect: (data: { account: string; wallet: WalletInfo }) => void
  }>
> = ({ onDismiss, data: { onCallbackConnect } }) => {
  const [isShowMoreWallet, setIsShowMoreWallet] = useState<boolean>(false)
  const [_, setSelectedWallet] = useState<boolean>(false)

  return (
    <Modal
      maxWidth={['100% !important', '', '436px !important']}
      minWidth={['100% !important', '', '436px !important']}
      onDismiss={onDismiss}
    >
      <StyledModalBody p="24px">
        <Heading scale="xs">Connect wallet</Heading>

        <Column>
          <Text color="textSubtle" small bold mt="20px">
            <Trans>Please connect your account's wallet to use this feature</Trans>:
          </Text>
          <Column p={['20px 0', '', '20px']}>
            <WalletAuth
              page={AuthModalPageEnums.LOG_IN}
              isShowMore={isShowMoreWallet}
              setShowMore={setIsShowMoreWallet}
              setSelectedWallet={setSelectedWallet}
              isOnlyReConnect
              onCallbackReConnect={onCallbackConnect}
            />
          </Column>
        </Column>
      </StyledModalBody>
    </Modal>
  )
}

const StyledModalBody = styled(ModalBody)`
  border: 1px solid var(--sub-272-f-52, #272f52);
  background: linear-gradient(0deg, #191d25 -5.56%, #1a2233 108.98%);
`
export default WalletAuthModal
