import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import Text from 'components/Text'
import React from 'react'
import { Trans } from 'react-i18next'
import styled from 'styled-components'
import { ActivitiesStatisticsCard } from './ActivitiesStatisticsCard'
import { UserTipInformationType } from './type'

const ActivitiesStatistics: React.FC<UserTipInformationType> = ({ userSentTipAmount, userReceivedTipAmount }) => {
  return (
    <Box>
      <Text small color="textTertiary" mb="18px" fontWeight={700}>
        <Trans>Activities</Trans>
      </Text>

      <StyledCardContainer background="rgba(35, 44, 61, 0.2)">
        <ActivitiesStatisticsCard
          title="Tips Sent"
          value={userSentTipAmount ?? '0'}
          width="calc((100% - 12px) / 2)"
          px="12px"
          py="8px"
        />
        <Box height="28px" borderLeft="1px solid #232C3D" />
        <ActivitiesStatisticsCard
          title="Tips Received"
          value={userReceivedTipAmount ?? '0'}
          width="calc((100% - 12px) / 2)"
          px="12px"
          py="8px"
        />
      </StyledCardContainer>
    </Box>
  )
}

const StyledCardContainer = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 12px;
`

export default ActivitiesStatistics
