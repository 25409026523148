import Box from 'components/Box/Box'
import Image from 'components/Image'

const INCLINATION_VALUE = 16

const DisplayBonus: React.FC<{
  enableVerticalAlignment?: boolean
  width: number
  height: number
  bonusImage: string
}> = ({ enableVerticalAlignment, width, height, bonusImage }) => {
  return (
    <Box width={width} height={height}>
      <Image
        width={width * 4}
        height={height * 4}
        src={bonusImage}
        style={
          enableVerticalAlignment && {
            transform: `rotate(-${INCLINATION_VALUE}deg)`,
          }
        }
      />
    </Box>
  )
}

export default DisplayBonus
