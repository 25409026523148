import React from 'react'
import theme from 'theme'
import { ImageProps } from './types'
import Image from './Image'

const Avatar: React.FC<ImageProps> = ({ style, ...props }) => {
  return (
    <Image
      {...props}
      style={{
        overflow: 'hidden',
        borderRadius: '50%',
        background: theme.colors.backgroundAvatar,
        ...style,
      }}
    />
  )
}

export default Avatar
