import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import Button from 'components/Button'
import Image from 'components/Image'
import CircleLoader from 'components/Loader/CircleLoader'
import Modal from 'components/Modal'
import { ModalProps } from 'components/Modal/Modal'
import ModalBody from 'components/Modal/components/ModalBody'
import Text from 'components/Text'
import useSignMessage from 'hooks/useSignMessage'
import { RowMiddle } from 'layout/Components/Row'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useWalletInfo } from 'state/auth/hooks'
import styled from 'styled-components'
import { Icons } from 'svgs'
import theme from 'theme'
import { colors } from 'theme/colors'
import { shortedAddress } from 'utils'
import { formatUsername } from 'utils/stringHelper'

type WalletEnable2FASignMessageModalProps = {
  messageText: string
  onSignSuccess: (signature: string) => void
}
const WalletEnable2FASignMessageModal: React.FC<ModalProps<WalletEnable2FASignMessageModalProps>> = ({
  data,
  onDismiss,
}) => {
  const { messageText, onSignSuccess } = data
  const { walletInfo, onConnectByWalletType, isWrongAddress, account, wallet } = useWalletInfo()
  const [signing, setSigning] = useState(false)
  const signMessage = useSignMessage(walletInfo.type)
  const { t } = useTranslation()

  const handleSignWallet = async () => {
    setSigning(true)
    const signature = await signMessage(messageText)

    if (signature) {
      onSignSuccess(signature)
      onDismiss()
    }
    setSigning(false)
  }

  return (
    <StyledModal id="popup-signwallet" onDismiss={onDismiss} p="0 !important">
      <ModalBody>
        <Box p="40px 34px">
          <Flex alignItems="center" flexDirection="column">
            {signing ? (
              <Image alt="join" width={80} height={80} src="/images/signing.png" />
            ) : (
              <Image alt="join" width={80} height={80} src="/images/join.png" />
            )}
            <Text bold fontSize="18px" lineHeight="24px" mt="16px">
              <Trans>Signing with this address</Trans>
            </Text>
            <Text bold fontSize="18px" mt="2px">
              {shortedAddress(account)}
            </Text>

            {!isWrongAddress && (
              <Text color="textSubtle" fontSize="14px" lineHeight={1.2} mt="28px" textAlign="center">
                <Trans>
                  You need to sign a message on your wallet to prove ownership of the address you are connected with.
                </Trans>
              </Text>
            )}

            {isWrongAddress && (
              <>
                <Flex
                  alignItems="center"
                  border={`1px solid ${colors.warning}`}
                  padding="12px"
                  borderRadius={theme.radii.small}
                  mt="12px"
                >
                  <Box mr="12px">
                    <Icons.WarningIcon width="24px" fill={colors.warning} />
                  </Box>

                  <Text bold fontSize="12px" color="warning" textAlign="center" lineHeight="16px">
                    {t(
                      'It seems your wallet connected is active different address, please switch to account {{account}}',
                      {
                        account: formatUsername(wallet.address),
                      },
                    )}
                  </Text>
                </Flex>

                {walletInfo.adapter instanceof WalletConnectConnector && (
                  <Button mt="12px" width="100%" onClick={onConnectByWalletType}>
                    <Text bold fontSize="14px" color="text">
                      <Trans>Switch account</Trans>
                    </Text>
                  </Button>
                )}
              </>
            )}

            <StyledButton
              id="signmessage-button"
              width="100%"
              mt="40px"
              onClick={handleSignWallet}
              disabled={signing || isWrongAddress}
            >
              {signing ? (
                <RowMiddle>
                  <Box mr="8px">
                    <CircleLoader />
                  </Box>
                  <Trans>Waiting for Signature</Trans>
                </RowMiddle>
              ) : (
                <Trans>Sign Message</Trans>
              )}
            </StyledButton>
          </Flex>
        </Box>
      </ModalBody>
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  ${({ theme }) => theme.mediaQueries.sm} {
    min-width: 440px !important;
    max-width: 440px !important;
  }
`

const StyledButton = styled(Button)`
  font-size: 12px;
  font-weight: bold;

  &:disabled {
    background-color: ${({ theme }) => theme.colors.primaryDisabled};
  }
`

export default WalletEnable2FASignMessageModal
