export const wheelGameReturnToPlayer = 99
export const WHEEL_ANIMATE_DURATION = 4000

export enum WheelGameRiskEnum {
  Low = 0,
  Medium = 1,
  High = 2,
}

export const WHEEL_RISKS = [
  {
    name: 'High',
    value: WheelGameRiskEnum.High,
  },
  {
    name: 'Medium',
    value: WheelGameRiskEnum.Medium,
  },
  {
    name: 'Low',
    value: WheelGameRiskEnum.Low,
  },
]

export const WHEEL_SEGMENTS = [
  {
    name: '10',
    value: 10,
  },
  {
    name: '20',
    value: 20,
  },
  {
    name: '30',
    value: 30,
  },
  {
    name: '40',
    value: 40,
  },
  {
    name: '50',
    value: 50,
  },
]

const WHEEL_PAYOUT_LOW_RISK = [
  {
    payout: 0,
    rate: 2 / 10,
    color: '#808080',
  },
  {
    payout: 1.2,
    rate: 7 / 10,
    color: '#ccc1f0',
  },
  {
    payout: 1.5,
    rate: 1 / 10,
    color: '#06C270',
  },
]

export const WHEEL_PAYOUTS = {
  [WheelGameRiskEnum.Low]: {
    10: WHEEL_PAYOUT_LOW_RISK,
    20: WHEEL_PAYOUT_LOW_RISK,
    30: WHEEL_PAYOUT_LOW_RISK,
    40: WHEEL_PAYOUT_LOW_RISK,
    50: WHEEL_PAYOUT_LOW_RISK,
  },
  [WheelGameRiskEnum.Medium]: {
    10: [
      {
        payout: 0,
        rate: 5 / 10,
        color: '#808080',
      },
      {
        payout: 1.9,
        rate: 1 / 10,
        color: '#ccc1f0',
      },
      {
        payout: 1.5,
        rate: 2 / 10,
        color: '#06C270',
      },
      {
        payout: 2.0,
        rate: 1 / 10,
        color: '#F3BF4F',
      },
      {
        payout: 3.0,
        rate: 1 / 10,
        color: '#FF85CB',
      },
    ],
    20: [
      {
        payout: 0,
        rate: 10 / 20,
        color: '#808080',
      },
      {
        payout: 1.8,
        rate: 1 / 20,
        color: '#ccc1f0',
      },
      {
        payout: 1.5,
        rate: 2 / 20,
        color: '#06C270',
      },
      {
        payout: 2.0,
        rate: 6 / 20,
        color: '#F3BF4F',
      },
      {
        payout: 3.0,
        rate: 1 / 20,
        color: '#FF85CB',
      },
    ],
    30: [
      {
        payout: 0,
        rate: 15 / 30,
        color: '#808080',
      },
      {
        payout: 1.7,
        rate: 1 / 30,
        color: '#ccc1f0',
      },
      {
        payout: 1.5,
        rate: 6 / 30,
        color: '#06C270',
      },
      {
        payout: 2.0,
        rate: 6 / 30,
        color: '#F3BF4F',
      },
      {
        payout: 3.0,
        rate: 1 / 30,
        color: '#FF85CB',
      },
      {
        payout: 4.0,
        rate: 1 / 30,
        color: '#d97038',
      },
    ],
    40: [
      {
        payout: 0,
        rate: 20 / 40,
        color: '#808080',
      },
      {
        payout: 1.6,
        rate: 1 / 40,
        color: '#ccc1f0',
      },
      {
        payout: 1.5,
        rate: 8 / 40,
        color: '#06C270',
      },
      {
        payout: 2.0,
        rate: 7 / 40,
        color: '#F3BF4F',
      },
      {
        payout: 3.0,
        rate: 4 / 40,
        color: '#FF85CB',
      },
    ],
    50: [
      {
        payout: 0,
        rate: 25 / 50,
        color: '#808080',
      },
      {
        payout: 5.0,
        rate: 1 / 50,
        color: '#49a1b8',
      },
      {
        payout: 1.5,
        rate: 13 / 50,
        color: '#06C270',
      },
      {
        payout: 2.0,
        rate: 8 / 50,
        color: '#F3BF4F',
      },
      {
        payout: 3.0,
        rate: 3 / 50,
        color: '#FF85CB',
      },
    ],
  },
  [WheelGameRiskEnum.High]: {
    10: [
      {
        payout: 0,
        rate: 9 / 10,
        color: '#808080',
      },
      {
        payout: 9.9,
        rate: 1 / 10,
        color: '#ED6A5E',
      },
    ],
    20: [
      {
        payout: 0,
        rate: 19 / 20,
        color: '#808080',
      },
      {
        payout: 19.8,
        rate: 1 / 20,
        color: '#ED6A5E',
      },
    ],
    30: [
      {
        payout: 0,
        rate: 29 / 30,
        color: '#808080',
      },
      {
        payout: 29.7,
        rate: 1 / 30,
        color: '#ED6A5E',
      },
    ],
    40: [
      {
        payout: 0,
        rate: 39 / 40,
        color: '#808080',
      },
      {
        payout: 39.6,
        rate: 1 / 40,
        color: '#ED6A5E',
      },
    ],
    50: [
      {
        payout: 0,
        rate: 49 / 50,
        color: '#808080',
      },
      {
        payout: 49.5,
        rate: 1 / 50,
        color: '#ED6A5E',
      },
    ],
  },
}

export const WHEEL_RISK_DEFAULT = WheelGameRiskEnum.Medium
export const WHEEL_SEGMENTS_DEFAULT = 30
