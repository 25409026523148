import BigNumber from 'bignumber.js'
import { USD_CURRENCY } from 'config/constants/tokens'
import { BaseResponse } from 'services/types'
import { range } from 'utils'
import { MINE_DEFAULT_BLOCK, MINE_GAME_SIZE } from 'views/InhouseGame/Mine/config'
import {
  MineBet,
  MineBetResponse,
  MineBlock,
  MineBlockResult
} from 'views/InhouseGame/Mine/config/types'


export const MineSubmitMapper = (response: any): BaseResponse<MineBetResponse> => {
  return {
    ...response,
    data: response.data &&
      response.data.payout_result && {
        id: response.data.payout_result.round_id,
        betAmount: {
          amount: null,
          token: USD_CURRENCY,
        },
        result: range(0, MINE_GAME_SIZE * MINE_GAME_SIZE).map(() => MINE_DEFAULT_BLOCK),
        userBalance: new BigNumber(response.data.payout_result.balance),
      },
  }
}

export const parseCurrentBet = (response: any): MineBet => {
  const defaultResult = range(0, MINE_GAME_SIZE * MINE_GAME_SIZE).map(() => MINE_DEFAULT_BLOCK)

  if (response?.data?.state_data?.selected_fields) {
    for (let index = 0; index < response.data.state_data.selected_fields.length; index++) {
      const fields = response.data.state_data.selected_fields[index]
      defaultResult[fields] = {
        isUserOpened: true,
        value: MineBlockResult.Diamond,
      }
    }
  }

  return (
    response &&
    response.round_id && {
      id: response.round_id,
      betAmount: {
        amount: new BigNumber(response.bet_amount).abs(),
        token: USD_CURRENCY,
      },
      mines: response.data?.bet_data?.mines,
      result: defaultResult,
      isCompleted: false,
    }
  )
}

export const parseFinishedBet = (response: any): MineBet => {
  const defaultResult = range(0, MINE_GAME_SIZE * MINE_GAME_SIZE).map(
    () =>
      ({
        isUserOpened: false,
        value: MineBlockResult.Diamond,
      } as MineBlock),
  )

  if (response?.player_data?.state_data?.selected_fields) {
    for (let index = 0; index < response.player_data.state_data.selected_fields.length; index++) {
      const fields = response.player_data.state_data.selected_fields[index]
      defaultResult[fields] = {
        isUserOpened: true,
        value: MineBlockResult.Diamond,
      }
    }
  }

  if (response?.system_data?.game_data?.mines) {
    const mines = response.system_data.game_data.mines
    for (let index = 0; index < mines.length; index++) {
      const element = mines[index]
      defaultResult[element] = {
        ...defaultResult[element],
        value: MineBlockResult.Mine,
      }
    }
  }

  return (
    response && {
      id: response.round_id,
      betAmount: {
        amount: new BigNumber(response.bet_amount).abs(),
        token: USD_CURRENCY,
      },
      mines: response?.bet_data?.mines,
      result: defaultResult,
      multiplier: Number(response?.player_data?.payout_data?.payout || 0),
      isCompleted: true,
    }
  )
}
