export const QUERY_TRANSACTION_INTERVAL = 5000

export enum TransactionReceiptStatusEnum {
  SUCCESS = 1,
  FAILED = 0,
}

export enum QueryDepositStatusEnums {
  Init = 1,
  ScanFailed = -2,
  InvalidData = -3,
  InvalidMinimumFee = -4,
  Success = 10,
}

export enum HusdUnlockTxnStatusEnums {
  All = -2,
  Received = 1,
  Outcome = -1,
  Promotion = 1000,
  Claimed = 1001,
  LuckySpin = 1002,
  Reward = 1004,
  VoucherReward = 1006,
  SignUpBonus = 1008,
  Airdrop = 1010,
  DailyCheckin = 1012,
  SurveySignUp = 1014,
}
