import Text from 'components/Text'
import useModal from 'hooks/useModal'
import Column from 'layout/Components/Column'
import { RowMiddle } from 'layout/Components/Row'
import { Trans } from 'react-i18next'
import { useAuthBy } from 'state/auth/hooks'
import { useUserSettings } from 'state/profile/hooks'
import { Icons } from 'svgs'
import { colors } from 'theme/colors'
import WarningOnTelegramModal from 'views/Authentication/components/Wallet/WarningOnTelegram/WarningOnTelegramModal'
import { useIsDisabledFeaturedOnTelegram } from 'hooks/useTelegram'
import Disable2FAModal from './Disable2FAModal'
import TraditionalEnable2FAButton from './TraditionalEnable2FAButton'
import TwoFactorAuthenSetting from './TwoFactorAuthenSetting'
import WalletEnable2FAButton from './WalletEnable2FAButton'
import { StyledButton } from './styled'

const Security2FA = () => {
  const { userSettings } = useUserSettings()
  const isActive2FA = userSettings.isActive2FA
  const { isAuthByEmail, isAuthByWallet, isAuthByTelegram } = useAuthBy()

  const [onPresentDisable2FAModal] = useModal(Disable2FAModal)
  const [onPresentWarningTelegram] = useModal(WarningOnTelegramModal)
  const isDisabledFeaturedOnTelegram = useIsDisabledFeaturedOnTelegram()

  /*

    NOTE: telegram account and email account have the same component and same api. only different text
  
  */

  return (
    <Column flex={1}>
      <RowMiddle mb="6px">
        <Text fontSize="14px" lineHeight="normal" fontWeight={400} letterSpacing={-0.28} mr="4px">
          {isActive2FA && <Trans>Two Factor Authentication is set</Trans>}
          {!isActive2FA && <Trans>Two Factor Authentication is not set</Trans>}
        </Text>
        {isActive2FA && <Icons.SuccessIcon width="16px" height="16px" fill={colors.success} />}
      </RowMiddle>

      <Text color="textAlt1" fontSize="12px" fontWeight={400} letterSpacing={-0.24}>
        <Trans>
          Enable Two-factor Authentication using authenticator app to protect your account from unauthorized access.
        </Trans>
      </Text>

      {isDisabledFeaturedOnTelegram ? (
        <StyledButton variant="primary" onClick={onPresentWarningTelegram}>
          <Text>
            <Trans>{'Enable'}</Trans>
          </Text>
        </StyledButton>
      ) : (
        <>
          {(isAuthByEmail || isAuthByTelegram) && (
            <TraditionalEnable2FAButton
              onDisable2FA={() => {
                onPresentDisable2FAModal(null, true)
              }}
            />
          )}
          {isAuthByWallet && (
            <WalletEnable2FAButton
              onDisable2FA={() => {
                onPresentDisable2FAModal(null, true)
              }}
            />
          )}
        </>
      )}

      <TwoFactorAuthenSetting mt="20px" />
    </Column>
  )
}

export default Security2FA

