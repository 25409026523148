import { StaticJsonRpcProvider } from '@ethersproject/providers'
import { ChainIdEnum, NETWORK_MAP } from 'config/constants/network'
import { clusterApiUrl } from '@solana/web3.js'
import { web3 } from '@project-serum/anchor'
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base'

export class SimpleRpcProvider {
  private static _simpleRpcProviderMap = {}

  public static get(chainId: ChainIdEnum) {
    if (!this._simpleRpcProviderMap[chainId]) {
      if (chainId === ChainIdEnum.SOL_TESTNET || chainId === ChainIdEnum.SOL) {
        const endpoint =
          chainId === ChainIdEnum.SOL_TESTNET
            ? clusterApiUrl(WalletAdapterNetwork.Testnet)
            : 'https://mainnet.helius-rpc.com/?api-key=124415ed-3fef-4fe5-bbd5-015d24af3d96'
        this._simpleRpcProviderMap[chainId] = new web3.Connection(endpoint, {
          confirmTransactionInitialTimeout: 60 * 10 * 1000,
        })
      } else if (chainId === ChainIdEnum.BTC || chainId === ChainIdEnum.BTC_TESTNET) {
        this._simpleRpcProviderMap[chainId] = null
      } else if (chainId === ChainIdEnum.TRX || chainId === ChainIdEnum.TRX_TESTNET) {
        this._simpleRpcProviderMap[chainId] = null
      } else if (chainId === ChainIdEnum.TON || chainId === ChainIdEnum.TON_TESTNET) {
        this._simpleRpcProviderMap[chainId] = null
      } else {
        const rpcCollections = NETWORK_MAP[chainId]?.rpcCollections || []
        const selectedRpcUrl = getRandomRpcFromList(rpcCollections)
        this._simpleRpcProviderMap[chainId] = new StaticJsonRpcProvider(selectedRpcUrl)
      }
    }

    return this._simpleRpcProviderMap[chainId]
  }
}

export const getRandomRpcFromList = (rpcCollections: string[]) => {
  if (rpcCollections.length) {
    const randomRpc = rpcCollections[Math.floor(Math.random() * 3)]

    if (randomRpc) return randomRpc
    return rpcCollections[0]
  }

  return ''
}

export const getSimplerRpcProvider = (chainId: ChainIdEnum) => {
  return SimpleRpcProvider.get(chainId)
}
