import { APIEndpointEnum } from 'config/constants/server'
import { BannerDetailType, HomeCategory } from 'config/types/home'
import { HunnyPlayRequest } from './HunnyPlayRequest'
import { BaseResponse, HunnyRequest, Paging } from './types'

class HomeService extends HunnyPlayRequest {
  public getBannerList(): HunnyRequest<BaseResponse<BannerDetailType>> {
    const result = this._post(APIEndpointEnum.BannerList)
    return result
  }

  public getCategoryList(): HunnyRequest<BaseResponse<Paging<HomeCategory>>> {
    const result = this._post(APIEndpointEnum.HomeCategoryList)
    return result
  }
}

const instance = new HomeService()

export default instance
