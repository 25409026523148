import { APIEndpointEnum, BASE_API } from 'config/constants/server'
import { ManagerOptions, SocketOptions } from 'socket.io-client'
import { BaseSocket } from './base'

export class PrivateSocket extends BaseSocket {
  constructor() {
    super(BASE_API)
  }

  protected _buildConfig(): Partial<ManagerOptions & SocketOptions> {
    const config: any = {
      path: APIEndpointEnum.PrivateSocket,
    }

    const appSite = typeof window !== 'undefined' ? window.location.origin : ''

    if (appSite !== BASE_API) {
      config.withCredentials = true
    }
    return config
  }
}

const privateSocket = new PrivateSocket()
export default privateSocket
