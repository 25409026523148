import { RefToken, Token } from '.'
import { ProfileTierInfo } from './profile'

export enum ReferralCampaignVersion {
  Old = 1,
  New = 2,
}

export enum CampaignRate {
  // apply for new refferral (Depend on wager)
  Sports = 40,
  Slot = 18,
  Live = 18,
  Originals = 6,

  // Only apply for old refferral (Depend on win bet)
  Old = 1.5,
}

export const commissionSharePercent = [0, 10, 25, 50]

export const MAX_LIMIT_CREATE_CAMPAIGN = 100
export const MAX_CAMPAIGN_RATE = CampaignRate.Slot
export const MAX_CAMPAIGN_REWARD = 4000

export interface ReferralMetaData {
  totalReferral: number
  totalRewardsUSD: number
  currenciesConvert: RefToken[]
}

export interface ReferralLiveReward {
  avatar: string
  amountUSD: string
}

export interface ReferralStats {
  token: Token
  gameBetSum: string
  gameBetSumUSD: string
  gameComissionSum: string
  gameComissionSumUSD: string
}

export interface ReferralBalanceTokenAmount {
  token: Token
  amount: string
  convertedValue?: string
}

export enum ReferralBonusTypeEnums {
  Commission = 1,
  Bonus = 2,
}

export interface ReferralTxnBonusClaim {
  token: Token
  amount: string
  amountUSD: string
  type: string
  createTime: number
}

export interface ReferralOverviewResponse {
  referrer: {
    avatar: string
    displayName: string
    userCode: string
    tierId: number

    commissionEarnUsd: string
  } | null
  campaign: ReferralCampaign
  userReferredCount: number
  totalProfitUSD: string
  gameCommissionClaimedUSD: string
}
export interface ReferralOverview {
  referrer: {
    avatar: string
    displayName: string
    userCode: string
    userTier: ProfileTierInfo
    commissionEarnUsd: string
  } | null
  campaign: ReferralCampaign
  userReferredCount: number
  totalProfitUSD: string
  gameCommissionClaimedUSD: string
}

export interface ReferralBonus {
  token: Token
  lockedAmount: string
  unlockedAmount: string
}

export interface ReferralCampaign {
  id: number
  uid: number
  code: string
  name: string

  userCommission: number
  shareCommission: number

  depositCount: number
  depositFirstCount: number
  depositFirstUSD
  refereeCount: number

  commissionEarnedUSD: number
  referralBonusUSD: number
  totalDepositUSD: string
  totalWagerUSD: string
  totalProfitUSD: string

  commissionRate: number

  createTime: number
  isDefault?: boolean
  userReferredCount?: number
  statsTimeRange?: {
    from: number
    to: number
  }
}

export interface ReferralCampaignStats extends ReferralStats {
  depositTime: number
  depositFirstTime: number
  depositFirstSum: number
  depositSum: number
}

export interface Referee {
  user: {
    avatar: string
    displayName: string
    registeredAt: number
    tierId: number
    userCode: string
  }
  referrerUserCode: string
  createTime: number

  totalDepositUSD: number
  totalWagerUSD: number
  totalProfitUSD: number
  commissionEarnedUSD: number
  referralBonusUSD: number

  campaign: ReferralCampaign
}

export interface systemReferralCode {
  code: string
}
