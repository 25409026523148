import BigNumber from 'bignumber.js'
import { FiatCurrency } from 'config/types/fiat'

export const USD_FIAT_CURRENCY: FiatCurrency = {
  code: 'USD',
  logo: '/images/fiat/usd.png',
  symbol: '$',
  displayDecimals: 2,
  usdPrice: new BigNumber(1),
}

export const FIAT_CURRENCIES: FiatCurrency[] = [
  USD_FIAT_CURRENCY,
  {
    code: 'EUR',
    logo: '/images/fiat/euro.png',
    symbol: '€',
    displayDecimals: 2,
  },
  {
    code: 'JPY',
    logo: '/images/fiat/yen.png',
    symbol: '¥',
    displayDecimals: 0,
  },
  {
    code: 'BRL',
    logo: '/images/fiat/brl.png',
    symbol: 'R$',
    displayDecimals: 2,
  },
  {
    code: 'GBP',
    logo: '/images/fiat/pound.png', //
    symbol: '£',
    displayDecimals: 2,
  },
  {
    code: 'INR',
    logo: '/images/fiat/rupee.png', //
    symbol: '₹',
    displayDecimals: 1,
  },
  {
    code: 'CAD',
    logo: '/images/fiat/cad.png',
    symbol: 'CA$',
    displayDecimals: 2,
  },
  {
    code: 'HKD',
    logo: '/images/fiat/hkd.png',
    symbol: 'HK$',
    displayDecimals: 2,
  },
  {
    code: 'KRW',
    logo: '/images/fiat/won.png',
    symbol: '₩',
    displayDecimals: 2,
  },
  {
    code: 'SGD',
    logo: '/images/fiat/sgd.png',
    symbol: 'S$',
    displayDecimals: 2,
  },
  {
    code: 'AUD',
    logo: '/images/fiat/aud.png',
    symbol: 'A$',
    displayDecimals: 2,
  },
  {
    code: 'CZK',
    logo: '/images/fiat/koruna.png',
    symbol: 'Kč',
    displayDecimals: 1,
  },
  {
    code: 'TWD',
    logo: '/images/fiat/twd.png',
    symbol: 'NT$',
    displayDecimals: 1,
  },
  {
    code: 'PHP',
    logo: '/images/fiat/peso.png',
    symbol: '₱',
    displayDecimals: 1,
  },
  {
    code: 'THB',
    logo: '/images/fiat/baht.png',
    symbol: '฿',
    displayDecimals: 1,
  },
  {
    code: 'VND',
    logo: '/images/fiat/dong.png',
    symbol: '₫',
    displayDecimals: 0,
  },
  {
    code: 'ARS',
    logo: '/images/fiat/ars.png',
    symbol: 'ARS',
    displayDecimals: 1,
  },
  {
    code: 'RUB',
    logo: '/images/fiat/ruble.png',
    symbol: 'RUB',
    displayDecimals: 1,
  },
  {
    code: 'IDR',
    logo: '/images/token/idr.png',
    symbol: 'Rp ',
    displayDecimals: 0,
  },
  {
    code: 'MYR',
    logo: '/images/token/myr.png',
    symbol: 'Rm ',
    displayDecimals: 2,
  },
]

