export enum StatisticsTimeFilterEnum {
  All,
  ThreeDaysAgo = 3,
  SevenDaysAgo = 7,
  ThirtyDaysAgo = 30,
}

export type StatisticsTimeFilterType = {
  value: StatisticsTimeFilterEnum
  content: string
}

export const StatisticsTimeFilterList: StatisticsTimeFilterType[] = [
  {
    value: StatisticsTimeFilterEnum.All,
    content: 'All',
  },
  {
    value: StatisticsTimeFilterEnum.ThreeDaysAgo,
    content: '3 days',
  },
  {
    value: StatisticsTimeFilterEnum.SevenDaysAgo,
    content: '7 days',
  },
  {
    value: StatisticsTimeFilterEnum.ThirtyDaysAgo,
    content: '30 days',
  },
]
