import { APIEndpointEnum } from 'config/constants/server'
import { LuckySpinStatusProps, SpinPrizeInfo, SpinPrizeTransaction } from 'config/types/luckyspin'
import { OtherTransactionFilters } from 'views/Profile/Transactions/types'
import { formatFitlerTransaction } from 'utils/transaction'
import { HunnyPlayRequest } from './HunnyPlayRequest'
import { BaseResponse, HunnyRequest, Paging } from './types'

class LuckySpinService extends HunnyPlayRequest {
  public getPrizeTransactions(limit?: number): HunnyRequest<BaseResponse<SpinPrizeTransaction[]>> {
    const result = this._post(APIEndpointEnum.SpinPrizeTransactions, {
      paging: {
        limit: limit || 5,
        offset: 0,
      },
    })

    return result
  }

  public getMyPrizeTransactions(payload: {
    limit: number
    offset: number
    filters?: OtherTransactionFilters
  }): HunnyRequest<BaseResponse<Paging<SpinPrizeTransaction>>> {
    const result = this._post(APIEndpointEnum.SpinMyPrizeTransactions, {
      paging: {
        limit: payload.limit,
        offset: payload.offset,
      },
      filter: formatFitlerTransaction(payload.filters),
    })

    return result
  }

  public getExternalPrizeList(): HunnyRequest<BaseResponse<{ items: SpinPrizeInfo[] }>> {
    const result = this._post(APIEndpointEnum.SpinExternalPrizeList)

    return result
  }

  public getInternalPrizeList(): HunnyRequest<BaseResponse<{ items: SpinPrizeInfo[]; nextSpinTime: number }>> {
    const result = this._post(APIEndpointEnum.SpinInternalPrizeList)

    return result
  }

  public play(): HunnyRequest<BaseResponse<SpinPrizeInfo>> {
    const result = this._post(APIEndpointEnum.SpinPlay)
    return result
  }

  public getSpinsLeft(): HunnyRequest<BaseResponse<LuckySpinStatusProps>> {
    const result = this._post(APIEndpointEnum.SpinFreePlayTimes)

    return result
  }
}

const instance = new LuckySpinService()
export default instance
