import BigNumber from 'bignumber.js'
import { USD_CURRENCY } from 'config/constants/tokens'
import { BaseResponse } from 'services/types'
import { LimboBetResponse } from 'views/InhouseGame/Limbo/config/types'

export const LimboSubmitMapper = (response: any): BaseResponse<LimboBetResponse> => {
  return {
    ...response,
    data: response.data &&
      response.data.payout_result && {
        id: response.data.payout_result.round_id,
        betAmount: {
          amount: new BigNumber(response.data.payout_result.payout),
          token: USD_CURRENCY,
        },
        resultMultiplier: Number(response.data.game_result.payout),
        userBalance: new BigNumber(response.data.payout_result.balance),
      },
  }
}

export const InhouseFinishBetMapper = (rawResponse: string): BaseResponse<boolean> => {
  const response = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'error',
      data: false,
    }
  }

  return {
    code: response.code,
    data: response.code === 'success',
  }
}
