/* eslint-disable no-async-promise-executor */
import { FetchingStatus } from 'config/constants'
import { Token } from 'config/types'
import { useSigner } from 'packages/wagmi/hooks/useSigner'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAppSelector } from 'state'
import { approve } from 'utils/infura'
import { isReject } from 'utils/wallet'
import { useAnalytics } from './useAnalytics'

function useApproveDepositContract(token: Token) {
  const signer = useSigner()
  const [approveProcess, setApproveProcess] = useState<FetchingStatus>(FetchingStatus.Unknown)
  const { recordEvent } = useAnalytics()

  const tokenInfoes = useAppSelector((state) => state.app.tokenInfoes)
  const tokenDepositInfo = useMemo(() => {
    if (!token) return null

    const spender = tokenInfoes.find(
      (tokenInfo) => tokenInfo.network === token.network && tokenInfo.token === token.code,
    )?.contractAddress

    if (!spender) return null

    return {
      spender,
      token,
    }
  }, [token])

  useEffect(() => {
    setApproveProcess(FetchingStatus.Unknown)
  }, [tokenDepositInfo])

  const approveToken = useCallback(async () => {
    try {
      if (!tokenDepositInfo) {
        return null
      }

      if (tokenDepositInfo.token.isNative) {
        return null
      }

      recordEvent('deposit-approve-submitted', {})

      setApproveProcess(FetchingStatus.Fetching)
      const hash = await approve(signer, tokenDepositInfo.token, tokenDepositInfo.spender)
      setApproveProcess(FetchingStatus.Fetched)

      if (hash) {
        recordEvent('deposit-approve-success', {})
      } else {
        recordEvent('deposit-approve-failure', {})
      }

      return hash
    } catch (e: any) {
      setApproveProcess(FetchingStatus.Unknown)
      if (isReject(e)) {
        recordEvent('deposit-approve-reject', {})
      } else {
        recordEvent('deposit-approve-failure', {})
      }

      return null
    }
  }, [tokenDepositInfo, signer])

  return {
    approve: approveToken,
    approveProcess,
  }
}

export default useApproveDepositContract
