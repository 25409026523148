import { BaseResponse } from 'services/types'

export const CheckSurveyMapper = (rawResponse: string) => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)
  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: {
      isAnswered: response.data?.is_answered || !response.data?.can_answer, // isAnswered will show or hide survey bonus
    },
  }
}
