import Box from 'components/Box/Box'
import Image from 'components/Image'

const HUSDLockRewardThumbnail: React.FC<{ size?: number }> = ({ size = 24 }) => {
  return (
    <Box width={size} height={size}>
      <Image src="/images/dailyCheckin/HUSD_locked.png" width={400} height={400} />
    </Box>
  )
}

export default HUSDLockRewardThumbnail
