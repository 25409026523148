import BigNumber from 'bignumber.js'
import { TokenAmount } from 'config/types'
import { InhouseGameType } from 'config/types/game'
import { DiceGameTargetType } from '../Dice/config'
import { MineBet } from '../Mine/config/types'
import { Risk } from '../Plinko/components/GameBoard/config'
import { WheelGameRiskEnum } from '../Wheel/config'
import { WheelSegments } from '../Wheel/config/types'

export type GameInHouseConfig = {
  volumeEnable?: boolean
  instanceBetEnable?: boolean
  maxBetButtonEnable?: boolean
  hotkeyEnable?: boolean
}

export type ProbilityFairSeed = {
  clientSeed: string
  serverSeed?: string
  hashedServerSeed: string
  nonce: number
}

export type ProbilityFair = {
  currentSeed: ProbilityFairSeed
  nextSeed: ProbilityFairSeed
  lockedByGames: InhouseGameType[]
}

export type InhouseGameProbilityFairBetInfo<T = any> = {
  type: InhouseGameType
  seed: ProbilityFairSeed
  payout: BigNumber
  betAmount: TokenAmount
  data?: T
}

export type PlinkoProbilityFairBetInfo = InhouseGameProbilityFairBetInfo<{
  row: number
  risk: Risk
  payout: number
}> & {
  type: InhouseGameType.Plinko
}

export type LimboProbilityFairBetInfo = InhouseGameProbilityFairBetInfo<{
  targetMultiplier: number
  resultMultiplier: number
}> & {
  type: InhouseGameType.Limbo
}

export type DiceProbilityFairBetInfo = InhouseGameProbilityFairBetInfo<{
  target: {
    type: DiceGameTargetType
    value: number
  }
  result: number
}> & {
  type: InhouseGameType.Limbo
}

export type MineProbilityFairBetInfo = InhouseGameProbilityFairBetInfo<{
  mines: number
  bet: MineBet
}> & {
  type: InhouseGameType.Mine
}

export type WheelProbilityFairBetInfo = InhouseGameProbilityFairBetInfo<{
  segments: WheelSegments
  risk: WheelGameRiskEnum
  result: number
}> & {
  type: InhouseGameType.Wheel
}

export enum InhouseGameBetBoardTab {
  Manual = 0,
  Auto = 1,
}

export type InhouseBetResponse<T> = {
  bet: T
  result: InhouseGameBetResult
}

export enum InhouseGameBetResult {
  Succeed = 0,
  InsufficientBalance = 1,
  Failed = 2,
}
