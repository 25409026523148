import { useEffect, useMemo, useState } from 'react'
import ProfileService from 'services/ProfileService'
import { useReferralCode } from './useReferralCode'
import { useRequest } from './useRequest'

export const useReferrerInfo = (): { type: string; refCode: string; displayName: string } => {
  const { execute } = useRequest()
  const { refCode, type } = useReferralCode()

  const [displayName, setDisplayName] = useState<string>(null)

  useEffect(() => {
    if (type === 'affilka' || !refCode) return
    const fetch = async () => {
      const res = await execute(ProfileService.getDisplayNameByReferralCode(refCode))
      if (res?.data) {
        setDisplayName(res?.data)
      }
    }
    fetch()
  }, [refCode, type])

  return useMemo(
    () => ({
      type,
      refCode,
      displayName,
    }),
    [refCode, type, displayName],
  )
}
