import { CountryEnum } from './country'
import tokens from './tokens/fiat'

export enum BalanceTypeEnums {
  Wallet,
  System,
}

export enum PaymentMethodEnum {
  Deposit,
  Withdraw,
}

export const FiatCurrencyCountryCodes = {
  [tokens.MYR.code]: CountryEnum.Myanmar,
  [tokens.THB.code]: CountryEnum.Thailand,
  [tokens.IDR.code]: CountryEnum.Indonesia,
}

export const RequiredProvidePhoneNumberBankCodes = ['PPTP']

