import React, { CSSProperties, useEffect, useState } from 'react'
import { Tooltip } from 'react-tooltip'

export interface TooltipConfig {
  id: string
  place: 'bottom' | 'top' | 'left' | 'right'
  getContent?: any
  className?: string
  classNameArrow?: string
  dependencies?: any[]
  style?: CSSProperties
  clickable?: boolean
  children?: string | JSX.Element
  offset?: number
}

const HunnyTooltip: React.FC<TooltipConfig> = ({
  id,
  place,
  getContent,
  className,
  classNameArrow,
  style,
  dependencies = [],
  clickable = false,
  children,
  offset,
}) => {
  const [rebuild, setRebuild] = useState(true)

  useEffect(() => {
    setRebuild(true)
    const timeout = setTimeout(() => {
      setRebuild(false)
    }, 10)
    return () => {
      clearTimeout(timeout)
    }
  }, dependencies)

  return (
    !rebuild && (
      <Tooltip
        id={id}
        place={place}
        content={getContent}
        className={className}
        style={style}
        clickable={clickable}
        classNameArrow={classNameArrow}
        offset={offset}
      >
        {children}
      </Tooltip>
    )
  )
}

export default HunnyTooltip

