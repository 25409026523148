import Box from 'components/Box/Box'
import useComponentOnScreen from 'hooks/useComponentOnScreen'
import useNextImage from 'hooks/useNextImage'
import React from 'react'
import styled from 'styled-components'
import StyledNextImage from './StyledNextImage'
import observerOptions from './observerOptions'
import { BaseImageProps } from './types'

export const BackgroundImage: React.FC<BaseImageProps> = ({ src, alt, children, ...props }) => {
  const { isLoaded, ref } = useComponentOnScreen(observerOptions)
  const { isNextImgSupport, imgProps } = useNextImage(src)

  return isNextImgSupport ? (
    <Box ref={ref} position="relative" {...props}>
      {isLoaded && (
        <StyledNextImage
          quality={100}
          src={src}
          alt={alt || 'Hunny Play'}
          priority
          objectFit="cover"
          layout="fill"
          {...imgProps}
        />
      )}
      {children}
    </Box>
  ) : (
    <StyledWrapper src={src} ref={ref} {...props}>
      {children}
    </StyledWrapper>
  )
}

const StyledWrapper = styled(Box)<{ src }>`
  background-image: url(${({ src }) => src});
`

export default BackgroundImage
