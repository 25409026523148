import { Token } from 'config/types'
import { ChainIdEnum } from '../network'

const path = '/images/token'

const tokens: Record<string, Token> = {
  BNB: {
    coinGeckoInfo: {
      id: 'binancecoin',
    },
    logo: `${path}/bnb.png`,
    name: 'BNB',
    code: 'BNB',
    network: ChainIdEnum.BNB_TESTNET,
    isNative: true,
    decimals: 18,
  },
  HUNNY: {
    coinGeckoInfo: {
      id: 'pancake-hunny',
    },
    logo: `${path}/hunny.png`,
    name: 'HUNNY',
    code: 'HUNNY',
    network: ChainIdEnum.BNB_TESTNET,
    address: '0x626BaE0ACE95fA9076d9D7803348E2307275bd24',
    decimals: 18,
  },
  USDT: {
    coinGeckoInfo: {
      id: 'tether',
    },
    logo: `${path}/usdt.png`,
    name: 'USDT',
    code: 'USDT',
    network: ChainIdEnum.BNB_TESTNET,
    address: '0xE032c45447e7D398e2f2F9F7Cd19202E7D8Bf844',
    decimals: 18,
    isStable: true,
  },
  CAKE: {
    coinGeckoInfo: {
      id: 'pancakeswap-token',
    },
    logo: `${path}/cake.png`,
    name: 'CAKE',
    code: 'CAKE',
    network: ChainIdEnum.BNB_TESTNET,
    address: '0x31d060249e87bd61b94ecD01fB75a8A0Cd129307',
    decimals: 18,
  },
  BUSD: {
    coinGeckoInfo: {
      id: 'binance-usd',
    },
    logo: `${path}/busd.png`,
    name: 'BUSD',
    code: 'BUSD',
    network: ChainIdEnum.BNB_TESTNET,
    address: '0x384F15cFFa429B7294056aA63ee16414c2681028',
    decimals: 18,
    isStable: true,
  },
  LOVE: {
    coinGeckoInfo: {
      id: 'hunny-love-token',
    },
    logo: `${path}/love.png`,
    name: 'LOVE',
    code: 'LOVE',
    network: ChainIdEnum.BNB_TESTNET,
    address: '0x7825749af0E73c23CCC703efcc9946F01De66f39',
    decimals: 18,
  },
  BABY: {
    coinGeckoInfo: {
      id: 'babyswap',
    },
    logo: `${path}/baby.png`,
    name: 'BABY',
    code: 'BABY',
    network: ChainIdEnum.BNB_TESTNET,
    address: '0xd9f171df7329845e58a2267ec43402a27c9567a9',
    decimals: 18,
  },
  VAI: {
    coinGeckoInfo: {
      id: 'vai',
    },
    logo: `${path}/vai.png`,
    name: 'VAI',
    code: 'VAI',
    network: ChainIdEnum.BNB_TESTNET,
    address: '0xEa5a5D7d7269cFE7eB46d6b4F17B8896660036D7',
    decimals: 18,
  },
  XVS: {
    coinGeckoInfo: {
      id: 'venus',
    },
    logo: `${path}/xvs.png`,
    name: 'XVS',
    code: 'XVS',
    network: ChainIdEnum.BNB_TESTNET,
    address: '0x80A602426bCbC8A6Db0e33589Ea3D46b867F87BC',
    decimals: 18,
  },
  TRX: {
    coinGeckoInfo: {
      id: 'tron',
    },
    logo: `${path}/trx.png`,
    name: 'TRX',
    code: 'TRX',
    network: ChainIdEnum.BNB_TESTNET,
    address: '0x35885e1a4D06d7D7B72925688b2179670b3A2318',
    decimals: 6,
  },
  ETH: {
    coinGeckoInfo: {
      id: 'ethereum',
    },
    logo: `${path}/eth.png`,
    name: 'ETH',
    code: 'ETH',
    network: ChainIdEnum.BNB_TESTNET,
    address: '0x2Dc972779644d59dD63dEa3A44BCA965124cE9b5',
    decimals: 18,
  },
  BTCB: {
    coinGeckoInfo: {
      id: 'bitcoin-bep2',
    },
    logo: `${path}/btc.png`,
    name: 'BTCB',
    code: 'BTCB',
    network: ChainIdEnum.BNB_TESTNET,
    address: '0x0F30c48E8046d85DdfCed3b3b660B90e4192A949',
    decimals: 18,
  },
  USDC: {
    coinGeckoInfo: {
      id: 'usd-coin',
    },
    logo: `${path}/usdc.png`,
    name: 'USDC',
    code: 'USDC',
    network: ChainIdEnum.BNB_TESTNET,
    address: '0x355EA4c248E19f9374d56a902A6DFB213A5885C8',
    decimals: 18,
  },
}

export default tokens
