import { howlMapper } from 'config/constants/audio'
import { InhouseGameDetails } from 'config/types/game'
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import MineGameService from 'services/InhouseGameService/MineGameService'
import { useTokenSelected } from 'state/session/hooks'
import { delayed } from 'utils'
import { forkjoinRequest } from 'utils/requestHelper'
import { InhouseBetResponse, InhouseGameBetResult } from 'views/InhouseGame/config/types'
import { HunnyToast } from 'utils/toastify'
import { useTranslation } from 'react-i18next'
import { MineBet, MineBlockResult, MineDraftBet } from '../config/types'
import { MineEventContext } from './MineEventProvider'

interface IMineActionContext {
  isPlaying: boolean
  submitBet: (bet: MineDraftBet, volumeEnable?: boolean) => Promise<InhouseGameBetResult>
  finishBet: () => void
  initBet: (bet: MineBet) => void
  clearSession: () => void
  currentBlock?: {
    index: number
    isOpening: boolean
    result: MineBlockResult
  }
}

export const MineActionContext = createContext<IMineActionContext>({
  submitBet: async () => null,
  finishBet: () => {},
  isPlaying: false,
  currentBlock: null,
  initBet: () => {},
  clearSession: () => {},
})

const MineActionProvider: React.FC<React.PropsWithChildren & { game: InhouseGameDetails }> = ({ children, game }) => {
  const { currentBet, setCurrentBet, setCurrentBlockId } = useContext(MineEventContext)
  const [isPlaying, setIsPlaying] = useState(null)
  const { t } = useTranslation()
  const playToken = useTokenSelected()
  const isLockedBetRef = useRef(false)

  // Finish bet
  useEffect(() => {
    if (!currentBet || isPlaying) return

    const finish = async () => {
      const mineCompletedBet = await MineGameService.finishBet(currentBet.id)
      setCurrentBet(mineCompletedBet)
      setCurrentBlockId(null)
      isLockedBetRef.current = false
    }
    finish()
  }, [currentBet?.id, isPlaying])

  const finishBet = useCallback(async () => {
    setIsPlaying(false)
  }, [])

  // Submit bet
  const _handleBet = async (draftBet: MineDraftBet): Promise<InhouseBetResponse<MineBet>> => {
    const [bet] = await forkjoinRequest([MineGameService.submitBet(draftBet, game.code, playToken), delayed(100)])
    return bet
  }

  const submitBet = useCallback(
    async (newBet: MineDraftBet, volumeEnable?: boolean) => {
      if (isLockedBetRef.current) return
      setCurrentBet(null)
      setIsPlaying(true)
      isLockedBetRef.current = true

      if (volumeEnable) {
        howlMapper.InhouseGameBetPlay?.play()
      }
      const { bet, result } = await _handleBet(newBet)

      if (result === InhouseGameBetResult.Succeed) {
        setCurrentBet(bet)
      } else {
        setIsPlaying(false)
        isLockedBetRef.current = false
        if (result !== InhouseGameBetResult.InsufficientBalance) {
          HunnyToast.error(t('Something went wrong'))
        }
      }
      return result
    },
    [setCurrentBet, playToken],
  )

  const initBet = useCallback(
    async (currentBet: MineBet) => {
      if (!currentBet) return
      setCurrentBet(currentBet)
      setIsPlaying(true)
      isLockedBetRef.current = true
    },
    [setCurrentBet],
  )

  const clearSession = useCallback(async () => {
    if (!currentBet) return
    MineGameService.removeSession()
    setCurrentBet(null)
    setIsPlaying(false)
    isLockedBetRef.current = false
  }, [currentBet, setCurrentBet])

  const value = useMemo(() => {
    return {
      finishBet,
      submitBet,
      initBet,
      clearSession,
      isPlaying: isPlaying && !!currentBet,
    }
  }, [isPlaying, !!currentBet, finishBet, submitBet])

  return <MineActionContext.Provider value={value}>{children}</MineActionContext.Provider>
}

export default MineActionProvider
