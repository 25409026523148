import { howlMapper } from 'config/constants/audio'
import { TokenAmount } from 'config/types'
import { useImmutableValueReference } from 'hooks/useImmutableValueReference'
import { useCallback, useContext, useEffect, useMemo } from 'react'
import { MineBet, MineBlockResult, MineDraftBet } from './config/types'
import { MineActionContext } from './context/MineActionProvider'
import { MineEventContext } from './context/MineEventProvider'
import { MINE_GAME_SIZE } from './config'

export const useMineGameStatus = () => {
  const { isPlaying } = useContext(MineActionContext)

  return useMemo(
    () => ({
      isPlaying,
    }),
    [isPlaying],
  )
}

export const useMineAction = () => {
  const { submitBet, finishBet } = useContext(MineActionContext)

  const handleBet = useCallback(
    async (
      betAmount: TokenAmount,
      mines: number,
      options: {
        instanceBetEnable?: boolean
        volumeEnable?: boolean
      },
    ) => {
      const draftBet: MineDraftBet = {
        betAmount,
        mines,
        disabledDisplay: options?.instanceBetEnable,
      }

      return submitBet(draftBet, options?.volumeEnable)
    },
    [submitBet],
  )

  return useMemo(
    () => ({
      submitBet: handleBet,
      finishBet,
    }),
    [handleBet, finishBet],
  )
}

export const useMineBetListener = (subscribe: (bet: MineBet) => void | (() => void)) => {
  const { currentBet } = useContext(MineEventContext)

  useEffect(() => {
    if (!currentBet) return
    const dismissFn = subscribe(currentBet)
    return dismissFn
  }, [currentBet])
}

export const useCurrentMineBet = () => {
  const { currentBet } = useContext(MineEventContext)

  return currentBet
}

export const useMineBlockOpenedListener = (subscribe: (id: number) => void | (() => void)) => {
  const { currentBlockId } = useContext(MineEventContext)

  useEffect(() => {
    if (currentBlockId === null) return
    const dismissFn = subscribe(currentBlockId)
    return dismissFn
  }, [currentBlockId])
}

export const useOpenMineBlock = () => {
  const { setCurrentBlockId } = useContext(MineEventContext)

  return useCallback(
    (id: number) => {
      setCurrentBlockId(id)
    },
    [setCurrentBlockId],
  )
}

export const useUpdateMineBlockResult = () => {
  const { isPlaying } = useMineGameStatus()
  const isPlayingRef = useImmutableValueReference(isPlaying)
  const { currentBet, setCurrentBet } = useContext(MineEventContext)
  const currentBetRef = useImmutableValueReference(currentBet)

  const { finishBet } = useContext(MineActionContext)

  return useCallback(
    (id: number, value: MineBlockResult, options?: { volumeEnable?: boolean }) => {
      if (!isPlayingRef.current) return
      const updatedBet: MineBet = {
        ...currentBetRef.current,
        result: [...currentBetRef.current.result],
      }
      updatedBet.result[id] = {
        isUserOpened: true,
        value,
      }

      setCurrentBet(updatedBet)

      if (
        value === MineBlockResult.Mine ||
        updatedBet.result.filter((item) => item.value === MineBlockResult.Diamond).length + updatedBet.mines >=
          MINE_GAME_SIZE * MINE_GAME_SIZE
      ) {
        finishBet()
      }

      if (options?.volumeEnable) {
        if (value === MineBlockResult.Diamond) {
          howlMapper.MineDiamond.play()
        } else {
          howlMapper.MineEffect.play()
        }
      }
    },
    [setCurrentBet],
  )
}

export const useMineCompletedBetListener = (subscribe: (bet: MineBet) => void | (() => void)) => {
  const { currentBet } = useContext(MineEventContext)

  useEffect(() => {
    if (currentBet) {
      if (currentBet.isCompleted) {
        const dismissFn = subscribe(currentBet)
        return dismissFn
      }
    }
  }, [currentBet])
}
export const useClearFinishBet = () => {
  const { setCurrentBet } = useContext(MineEventContext)
  const { isPlaying } = useMineGameStatus()

  return useCallback(() => {
    if (isPlaying) return
    setCurrentBet(null)
  }, [setCurrentBet, isPlaying])
}

export const useMineGameClearSession = () => {
  const { setCurrentBet } = useContext(MineEventContext)
  const { isPlaying } = useMineGameStatus()

  return useCallback(() => {
    if (isPlaying) return
    setCurrentBet(null)
  }, [setCurrentBet, isPlaying])
}

export const useInitMineGameBet = () => {
  const { initBet, clearSession } = useContext(MineActionContext)

  return { initBet, clearSession }
}
