import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import StyledNextImage from './StyledNextImage'

export const NextImageLoaderCallback: React.FC<{
  imgs: {
    src: string
    width: number
    height: number
    original?: boolean
  }[]
  timeout?: number
  onLoaded?: () => void
}> = ({ imgs, timeout, onLoaded }) => {
  const skipTimeOutRef = useRef(null)
  const isEmitLoaded = useRef(false)

  useEffect(() => {
    if (timeout && onLoaded) {
      skipTimeOutRef.current = setTimeout(() => {
        if (!isEmitLoaded.current) {
          if (onLoaded) onLoaded()
          isEmitLoaded.current = true
        }
      }, timeout)
      return () => {
        if (skipTimeOutRef.current) clearTimeout(skipTimeOutRef.current)
      }
    }
  }, [timeout])

  const loaderRef = useRef(
    imgs.reduce((result, value) => {
      result[value.src] = false
      return result
    }, {}),
  )

  const updateLoader = (key: string) => {
    loaderRef.current[key] = true
    if (!Object.values(loaderRef.current).includes(false)) {
      if (!isEmitLoaded.current) {
        if (onLoaded) onLoaded()
        isEmitLoaded.current = true
        if (skipTimeOutRef.current) clearTimeout(skipTimeOutRef.current)
      }
    }
  }

  return (
    <StyledContainer>
      {imgs.map((img) => (
        <StyledNextImage
          src={img.src}
          alt={'hunnyplay preload image'}
          height={img.height}
          width={img.width}
          quality={img.original ? 100 : 80}
          priority
          onLoadingComplete={() => {
            updateLoader(img.src)
          }}
          objectFit="fill"
        />
      ))}
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  opacity: 0;
  pointer-events: none;
  position: absolute;
  height: 0px;
  overflow: hidden;
`
export default NextImageLoaderCallback
