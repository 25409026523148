import { Token } from 'config/types'
import { ChainIdEnum } from '../network'

const path = '/images/token'

const tokens: Record<string, Token> = {
  ETH: {
    coinGeckoInfo: {
      id: 'ethereum',
    },
    logo: `${path}/eth.png`,
    name: 'ETH',
    code: 'ETH',
    network: ChainIdEnum.ARB_TESTNET,
    isNative: true,
    decimals: 18,
  },
  ARB: {
    coinGeckoInfo: {
      id: 'arbitrum',
    },
    logo: `${path}/arb.png`,
    name: 'ARB',
    code: 'ARB',
    network: ChainIdEnum.ARB_TESTNET,
    address: '0x8d591c4b8512cd5764a53719379d005bb63f7b94',
    decimals: 18,
    isStable: true,
  },
  USDC: {
    coinGeckoInfo: {
      id: 'usd-coin',
    },
    logo: `${path}/usdc.png`,
    name: 'USDC',
    code: 'USDC',
    network: ChainIdEnum.ARB_TESTNET,
    address: '0x536ae5308db81aefc83470424e41c6b9b6a668b8',
    decimals: 6,
    isStable: true,
  },
}

export default tokens
