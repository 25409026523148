import { Token } from 'config/types'
import { InhouseGameType } from 'config/types/game'
import GameService from 'services/GameService'
import { WheelBet, WheelBetResponse, WheelDraftBet, WheelSegments } from 'views/InhouseGame/Wheel/config/types'
import { InhouseBetResponse } from 'views/InhouseGame/config/types'
import { WheelGameRiskEnum } from 'views/InhouseGame/Wheel/config'
import { InhouseLaunchSession } from 'views/InhouseGame/Mine/config/types'
import { BaseResponse } from 'services/types'
import { INHOUSE_GAME_LAUNCH_MAPPER_KEY } from 'views/InhouseGame/Mine/config'
import BaseInhouseGameService from './BaseInhouseGameService'

class WheelGameService extends BaseInhouseGameService {
  _game = InhouseGameType.Wheel
  _currentTokenSession = null
  _sessionId = null

  async _verify(gameCode: string, playToken: Token): Promise<InhouseLaunchSession> {
    const result = await GameService.createGameSession(gameCode, playToken.code, playToken.network).call()
    const { gameUrl } = result?.data || {}
    const res: BaseResponse<InhouseLaunchSession> = await this._get(
      gameUrl,
      {},
      { withCredentials: true },
      INHOUSE_GAME_LAUNCH_MAPPER_KEY,
    )
    this._currentTokenSession = playToken
    this._sessionId = res.data?.sessionId

    return res.data
  }

  public async launchGame(gameCode: string, playToken: Token): Promise<InhouseLaunchSession> {
    return this._verify(gameCode, playToken)
  }

  public async submitBet(
    draftBet: WheelDraftBet,
    gameCode?: string,
    playToken?: Token,
  ): Promise<InhouseBetResponse<WheelBet>> {
    const { bet, result }: InhouseBetResponse<WheelBetResponse> = await this._submitBet(
      draftBet.betAmount,
      {
        risk: draftBet.risk,
        segments: draftBet.segments,
      },
      draftBet.disabledDisplay,
      gameCode,
      playToken,
    )
    return {
      result,
      bet: bet && {
        ...draftBet,
        id: bet.id,
        result: bet.result,
      },
    }
  }

  public async verifyResult(
    risk: WheelGameRiskEnum,
    segments: WheelSegments,
    clientSeed: string,
    serverSeed: string,
    nonce: number,
  ): Promise<number> {
    const result = await this._verifyResult(clientSeed, serverSeed, nonce, { risk, segments })
    return result?.data?.result && Number(result?.data?.result)
  }
}

const instance = new WheelGameService()
export default instance
