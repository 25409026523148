import { FIAT_CURRENCIES } from 'config/constants/fiat'
import { ChainIdEnum } from 'config/constants/network'
import tokens from 'config/constants/tokens'
import { Token } from 'config/types'
import {
  CashCommonBonus,
  DepositCashCommonBonus,
  DepositFreeSpinCommonBonus,
  FreeSpinCommonBonus,
} from 'config/types/bonus'
import {
  CashBonus,
  DepositCashBonus,
  DepositFreespinBonus,
  FreespinBonus,
  ICashoutMilestone,
  WageringConditionBonus,
} from 'config/types/bonus/userBonus'
import { FiatCurrency } from 'config/types/fiat'
import { delineate } from './formatBalance'

export const isReachMilestone = (bonus: any, progress?: number) => {
  if (bonus instanceof WageringConditionBonus && bonus?.isActiveMilestone) {
    const reachedMilestone: ICashoutMilestone = bonus.reachedMilestoneDetails.reduce(
      (_, item) => (item.wagerPercentage <= (progress || bonus.progress) ? item : _),
      null,
    )
    return reachedMilestone
  }
  return null
}

/// Bonus type detect helper
export const isWageringBonus = (bonus: any) => bonus instanceof WageringConditionBonus

export const isFreeSpinBonus = (bonus: any) =>
  bonus instanceof FreeSpinCommonBonus ||
  bonus instanceof DepositFreeSpinCommonBonus ||
  bonus instanceof DepositFreespinBonus ||
  bonus instanceof FreespinBonus

export const isWagerBonusFiatReward = (bonus) =>
  isWagerCashBonus(bonus) &&
  tokens[ChainIdEnum.FIAT]?.[bonus?.bonusAmount?.token?.code] &&
  bonus?.bonusAmount?.token?.code !== 'USD'

export const isFreeSpinBonusFiatReward = (bonus: any) =>
  isFreeSpinBonus(bonus) && tokens[ChainIdEnum.FIAT]?.[bonus.freeSpinCurrency] && bonus.freeSpinCurrency !== 'USD'

export const isWagerCashBonus = (bonus: any): boolean =>
  bonus instanceof DepositCashBonus ||
  bonus instanceof DepositCashCommonBonus ||
  bonus instanceof CashBonus ||
  bonus instanceof CashCommonBonus

export const wagerBonusFiatReward = (bonus: any): FiatCurrency | null =>
  isWagerBonusFiatReward(bonus) && FIAT_CURRENCIES.find((item) => item.code === bonus?.bonusAmount?.token?.code)

export const freeSpinBonusFiatReward = (bonus: any): FiatCurrency | null => {
  if (isFreeSpinBonusFiatReward(bonus)) {
    return FIAT_CURRENCIES.find((item) => item.code === (bonus as any)?.freeSpinCurrency)
  }
  return null
}

export const estimateFiatDecimalValues = (fiatToken: Token) => (fiatToken?.code === 'IDR' ? 0 : 2)

export const formatDisplayEstimateFiat = (
  estimateAmount: string | number,
  currency: string,
  optionalStringTemplate?: string,
  decimalDigitTake?: number,
) => {
  const selectedFiat = FIAT_CURRENCIES.find((item) => item.code === currency)
  const displayDecimal = decimalDigitTake || (selectedFiat?.code === 'IDR' ? 0 : 2)

  if (optionalStringTemplate)
    return optionalStringTemplate
      .replace('{{currency}}', selectedFiat.symbol)
      .replace('{{estimateAmount}}', delineate(estimateAmount?.toString(), displayDecimal))
  return `(~${selectedFiat?.symbol} ${delineate(estimateAmount?.toString(), displayDecimal)})`
}

