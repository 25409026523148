import { BoxProps } from 'components/Box/types'
import IconButton from 'components/Button/IconButton'
import Heading from 'components/Heading'
import Modal from 'components/Modal/Modal'
import ModalBody from 'components/Modal/components/ModalBody'
import { RowMiddle } from 'layout/Components/Row'
import React from 'react'
import { Trans } from 'react-i18next'
import styled from 'styled-components'
import { Icons } from 'svgs'
import theme from 'theme'

const ForgotPasswordModal: React.FC<{ onDismiss: () => void; label: string } & BoxProps> = ({
  onDismiss,
  label,
  children,
  ...props
}) => {
  return (
    <StyledModal onDismiss={onDismiss} disableClose {...props}>
      <ModalBody borderRadius={theme.radii.modal} height="100%" p="24px">
        <RowMiddle mb="24px">
          <IconButton onClick={onDismiss}>
            <Icons.BackIcon width="24px" height="24px" />
          </IconButton>
          <Heading scale="xs" ml="4px">
            <Trans>{label}</Trans>
          </Heading>
        </RowMiddle>
        {children}
      </ModalBody>
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  ${({ theme }) => theme.mediaQueries.sm} {
    min-width: 440px !important;
    max-width: 440px !important;
  }
`

export default ForgotPasswordModal
