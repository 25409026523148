import { forwardRef } from 'react'
import { InputProps } from '.'
import { StyledInput } from './styled'

const NumericalInput = forwardRef<
  HTMLInputElement,
  InputProps & {
    placeholder?: string
    tabIndex: number
    autoFocus?: boolean
    pattern?: string
    minLenght?: number
    maxLength?: number
  }
>(
  (
    { value, onValueChanged, autoFocus = true, pattern = '^[0-9]*[.,]?[0-9]*$', minLenght, maxLength, ...props },
    ref,
  ) => {
    const enforcer = (nextUserInput: string) => {
      const inputRegex = RegExp(pattern)
      if (nextUserInput === '' || inputRegex.test(nextUserInput)) {
        onValueChanged(nextUserInput)
      }
    }

    return (
      <StyledInput
        ref={ref}
        {...props}
        value={value}
        inputMode="decimal"
        type="text"
        minLength={minLenght || 1}
        maxLength={maxLength || 48}
        pattern={pattern}
        autoFocus={autoFocus}
        onValueChanged={(value) => {
          // replace commas with periods, because we exclusively uses period as the decimal separator
          enforcer(value.replace(/,/g, '.'))
        }}
      />
    )
  },
)

export default NumericalInput

