import { breakpointNumberList } from 'theme/base'
import { HunnyTheme } from 'theme/types'

export const buildResponsiveStyle = (theme: HunnyTheme, cssProperty: string, value: string | string[]) => {
  if (!Array.isArray(value)) return `${cssProperty}: ${value};`

  const mediaQueries = Object.values(theme.mediaQueries)

  return value.reduce((preValue, item, index) => {
    if (index === 0) {
      // xs
      return `${cssProperty}: ${item};`
    }

    if (!mediaQueries[index]) return preValue

    return `
    ${preValue}
    ${mediaQueries[index]} {
      ${cssProperty}: ${item};
    }`
  }, '')
}

export const buildResponsiveStyledSystemWithExceptedComponent = (
  responsive: any[],
  exceptedWidth: number,
  exceptedBreakPoint?: number,
  _breakpointMap?: number[],
) => {
  const windowWidth = typeof window != 'undefined' ? window.document.body.clientWidth : 0
  const breakpointMap = _breakpointMap || breakpointNumberList
  const remainedWidth = windowWidth - exceptedWidth

  let currentResponsiveIndex = [...breakpointMap, Number.MAX_SAFE_INTEGER].findIndex((value) => windowWidth <= value)
  let resultResponsiveIndex = [...breakpointMap, Number.MAX_SAFE_INTEGER].findIndex((value) => remainedWidth <= value)

  currentResponsiveIndex =
    currentResponsiveIndex > responsive.length - 1 ? responsive.length - 1 : currentResponsiveIndex
  resultResponsiveIndex = resultResponsiveIndex > responsive.length - 1 ? responsive.length - 1 : resultResponsiveIndex

  if (responsive[currentResponsiveIndex] === responsive[resultResponsiveIndex]) return responsive

  const exceptedBreakPointIndex = breakpointMap.findIndex((item) => item >= exceptedBreakPoint)

  return breakpointMap.reduce((preValue, item, index) => {
    if (exceptedBreakPoint && index <= exceptedBreakPointIndex) {
      preValue.push(responsive[index])
    } else if (resultResponsiveIndex >= index) preValue.push(responsive[index])
    return preValue
  }, [])
}

// TODO implement later
export const buildResponsiveStyledSystemWithExceptedWidth = (responsive: any[]) => {
  return responsive
}

export const detectResponsiveBreakpoint = (responsive: any[]) => {
  const windowWidth = typeof window != 'undefined' ? window.document.body.clientWidth : 0
  const breakPointIndex = breakpointNumberList.findIndex((item) => item >= windowWidth)

  return responsive[breakPointIndex] || responsive[responsive.length - 1]
}
