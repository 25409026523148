import React, { createContext, useMemo, useState } from 'react'
import { WheelBet } from '../config/types'

interface IWheelEventContext {
  newBet: WheelBet
  completedBet: WheelBet
  setNewBet: (newBet: WheelBet) => void
  setCompletedBet: (completedBet: WheelBet) => void
}

export const WheelEventContext = createContext<IWheelEventContext>({
  newBet: null,
  completedBet: null,
  setNewBet: () => {},
  setCompletedBet: () => {},
})

const WheelEventProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [newBet, setNewBet] = useState(null)
  const [completedBet, setCompletedBet] = useState(null)

  const value = useMemo(
    () => ({
      newBet,
      completedBet,
      setNewBet,
      setCompletedBet,
    }),
    [newBet, completedBet, setNewBet, setCompletedBet],
  )

  return <WheelEventContext.Provider value={value}>{children}</WheelEventContext.Provider>
}

export default WheelEventProvider
