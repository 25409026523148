import { BIG_ZERO } from 'config/constants/number'
import {
  MyTierInfo,
  ProfileGettingStartedTaskInfo,
  ProfileStatisticBonusInfoes,
  ProfileStatisticGameDetailInfo,
  ProfileStatisticGameInfoes,
  PublicUserProfile,
  TipStatistic,
} from 'config/types/profile'
import { parseTierInfos } from 'services/mapper/utils'
import { BaseResponse, Paging, UserAvatar } from 'services/types'
import { parseBetDetailsItemMapper } from './Game'

export const UserDisplayNameMapper = (rawResponse: string): BaseResponse<any> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response)
    return {
      code: 'network_error',
      data: null,
    }
  return {
    code: response.code,
    message: response.message,
    data: response.data && response.data.display_name,
  }
}

export const UserTierMapper = (rawResponse: string): BaseResponse<MyTierInfo> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response)
    return {
      code: 'network_error',
      data: null,
    }

  const tierInfos = response.data?.tier || {}
  const statsListInfos = response.data?.stats_list || []

  const tier = parseTierInfos(tierInfos)

  const totalWager =
    statsListInfos.reduce((state, stats) => {
      return state.plus(stats?.game_bet_usd_sum || 0)
    }, BIG_ZERO) || BIG_ZERO

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      tier,
      totalWager: totalWager.toString(),
    },
  }
}

export const UserFavoriteGameCodesMapper = (rawResponse: string): BaseResponse<Paging<string>> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response)
    return {
      code: 'network_error',
      data: null,
    }

  return {
    code: response.code,
    message: response.message,
    data: {
      paging: {
        limit: response.data.paging.limit,
        offset: response.data.paging.offset,
        total: response.data.paging.total,
      },
      items: response.data.game_codes,
    },
  }
}

export const UserGettingStartedTaskMapper = (rawResponse: string): BaseResponse<ProfileGettingStartedTaskInfo> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response)
    return {
      code: 'network_error',
      data: null,
    }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      isCheckoutWelcomePack: response.data.is_checked_out_welcome_package,
      isMakeFirstDeposit: response.data.is_deposited,
      isPlaceFirstBet: response.data.is_played_game,
      isClaimed: response.data.is_claimed_getting_started || false,
    },
  }
}

export const UserProfileStatisticGameInfoesMapper = (rawResponse: string): BaseResponse<ProfileStatisticGameInfoes> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)
  if (!response)
    return {
      code: 'network_error',
      data: null,
    }
  const statisticGameInfoes = convertStatisticGame(response.data)

  return {
    code: response.code,
    message: response.message,
    data: response.data && statisticGameInfoes,
  }
}

export const UserProfileMapper = (
  rawResponse: string,
): BaseResponse<
  PublicUserProfile & {
    totalWager: string
    totalBet: number
    bonusesInfo: ProfileStatisticBonusInfoes
    tipStatistics: TipStatistic
  }
> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)
  if (!response)
    return {
      code: 'network_error',
      data: null,
    }
  const statisticGameInfoes = convertStatisticGame(response.data)
  const isHideStatistic = response.data?.settings.hide_statistic === 1

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      ...statisticGameInfoes,
      registerDate: new Date(response.data.register_time * 1000),
      levelId: response.data.tier_id,
      displayName: response.data?.display_name,
      avatar: response.data?.avatar_url,
      settings: {
        isHideStatistic,
      },
      bigWinGameInfo: statisticGameInfoes.bigWinGameInfo && {
        ...statisticGameInfoes.bigWinGameInfo,
        betDetail: parseBetDetailsItemMapper({
          ...response.data.big_win,
          avatar_url: response.data?.avatar_url,
          display_name: response.data?.display_name,
        }),
      },
      luckyWinGameInfo: statisticGameInfoes.luckyWinGameInfo && {
        ...statisticGameInfoes.bigWinGameInfo,
        betDetail: parseBetDetailsItemMapper({
          ...response.data.lucky_win,
          avatar_url: response.data?.avatar_url,
          display_name: response.data?.display_name,
        }),
      },
      bonusesInfo: {
        cashbackAmount: Number(response.data.bonus_statistic.cashback_usd) || 0,
        husdBonusAmount: Number(response.data.bonus_statistic.husd_bonus) || 0,
        levelUpBonusAmount: Number(response.data.bonus_statistic.level_up_bonus) || 0,
        referralAmount: Number(response.data.bonus_statistic.referral_usd) || 0,
        voucherBonusAmount: Number(response.data.bonus_statistic.voucher_usd) || 0,
        dailyCheckinAmount: Number(response.data.bonus_statistic.daily_checkin_usd) || 0,
      },

      tipStatistics: {
        sendAmountInUsd: response.data.tip_statistic.sent,
        receiveAmountInUsd: response.data.tip_statistic.received,
      },

      totalWager: response.data.total_wager,
      totalBet: response.data.bet_count,
    },
  }
}

const convertStatisticGame = (data: any): ProfileStatisticGameInfoes => {
  return {
    playedGameCount: data.played_games,
    bigWinGameInfo: data.big_win
      ? {
          amount: Number(data.big_win.payout_usd) - Number(data.big_win.bet_amount_usd) || 0,
          gameName: data.big_win.game_info.name || '',
          updateTime: Number(data.big_win.create_time) * 1000 || null,
          betDetail: parseBetDetailsItemMapper(data.big_win),
        }
      : null,
    luckyWinGameInfo: data.lucky_win
      ? {
          amount: Number(data.lucky_win.payout_usd || 0),
          gameName: data.lucky_win.game_info.name || '',
          updateTime: Number(data.lucky_win.create_time) * 1000 || null,
          betDetail: parseBetDetailsItemMapper(data.lucky_win),
        }
      : null,
    mostWageredGameInfoes:
      data.most_wager_games
        ?.sort((prev, current) => (Number(prev.wager_usd) > Number(current.wager_usd) ? -1 : 1))
        ?.map(
          (game) =>
            ({
              gameName: game.name,
              amount: game.wager_usd,
              slug: game.slug,
              thumbnail: game.banner_url,
              updateTime: 132342423,
            } as ProfileStatisticGameDetailInfo),
        ) || null,
    mostWinGameInfoes:
      data.most_win_games
        ?.filter((game) => game && Number(game.payout_usd) - Number(game.wager_usd) > 0)
        ?.sort((prev, current) => {
          const prevGameProfite = Number(prev.payout_usd) - Number(prev.wager_usd)
          const currentGameProfite = Number(current.payout_usd) - Number(current.wager_usd)
          return prevGameProfite > currentGameProfite ? -1 : 1
        })
        ?.map(
          (game) =>
            ({
              gameName: game.name,
              amount: Number(game.payout_usd) - Number(game.wager_usd),
              slug: game.slug,
              thumbnail: game.banner_url,
              updateTime: 132342423,
            } as ProfileStatisticGameDetailInfo),
        ) || null,
  }
}
export const UserProfileStatisticBonusMapper = (rawResponse: string): BaseResponse<ProfileStatisticBonusInfoes> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response)
    return {
      code: 'network_error',
      data: null,
    }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      cashbackAmount: Number(response.data.cashback_usd) || 0,
      husdBonusAmount: Number(response.data.husd_bonus) || 0,
      levelUpBonusAmount: Number(response.data.level_up_bonus) || 0,
      referralAmount: Number(response.data.referral_usd) || 0,
      voucherBonusAmount: Number(response.data.voucher_usd) || 0,
      dailyCheckinAmount: Number(response.data.daily_checkin_usd) || 0,
    },
  }
}

export const AvatarMapper = (rawResponse: string): BaseResponse<Paging<UserAvatar>> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response)
    return {
      code: 'network_error',
      data: null,
    }

  return {
    code: response.code,
    message: response.message,
    data: {
      paging: {
        limit: response.data.paging.limit,
        offset: response.data.paging.offset,
        total: response.data.paging.total,
      },
      items: response.data.items.map((item) => ({
        id: item.id,
        name: item.name,
        url: item.url,
      })),
    },
  }
}

export const UserTipStatisticsMapper = (rawResponse: string): BaseResponse<TipStatistic> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)
  if (!response)
    return {
      code: 'network_error',
      data: null,
    }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      sendAmountInUsd: response.data.sent || '',
      receiveAmountInUsd: response.data.received || '',
    },
  }
}

export const UserTotalWagerMapper = (rawResponse: string): BaseResponse<number> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response)
    return {
      code: 'network_error',
      data: null,
    }

  return {
    code: response.code,
    message: response.message,
    data: (response.data && response.data.total_wager_usd) || 0,
  }
}
