import BigNumber from 'bignumber.js'
import Box from 'components/Box/Box'
import CopyButton from 'components/Button/CopyButton'
import Avatar from 'components/Image/Avatar'
import Text from 'components/Text'
import { ChainIdEnum } from 'config/constants/network'
import tokens from 'config/constants/tokens'
import { TipDetails } from 'config/types/payment'
import { TipTransactionFilterTypeEnums } from 'config/types/transaction'
import useModal from 'hooks/useModal'
import { ColumnCenter } from 'layout/Components/Column'
import { RowBetween, RowCenter, RowMiddle } from 'layout/Components/Row'
import { Trans, useTranslation } from 'react-i18next'
import { useTiers } from 'state/app/hooks'
import styled from 'styled-components'
import base from 'theme/base'
import { colors } from 'theme/colors'
import { revalidateHunnyLevel } from 'utils'
import { formatDisplayFullDateTime } from 'utils/dateHelper'
import { getNetworkIncludeFiatInfo } from 'utils/network'
import { DisplayAmount } from 'views/Profile/Transactions/components/styled'
import UserProfileModal from 'views/Profile/components/UserProfileModal'

const StyledMessage = styled(Text)`
  max-width: 60%;
  word-break: break-word;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: -0.28;
  color: ${({ theme: { colors } }) => colors.textTertiary};
`

const StyledUserProfileContainer = styled(RowCenter)`
  cursor: pointer;
  max-width: 70%;
  margin-left: 2px;
`

interface TipDetailInfoProps {
  details: TipDetails
  target: TipTransactionFilterTypeEnums
  title?: string
  hideCreateTime?: boolean
}

const TipDetailInfoes: React.FC<TipDetailInfoProps> = ({ details, title, target, hideCreateTime = false }) => {
  const { t } = useTranslation()
  const isDisplayedForSender = target === TipTransactionFilterTypeEnums.Send
  const userLabel = t(isDisplayedForSender ? 'Receiver' : 'Sender')
  const userInfo = isDisplayedForSender ? details.receiver : details.sender
  const [onPresentUserProfileModal] = useModal(UserProfileModal)
  const displayTitleByUserType = isDisplayedForSender ? 'Send Tip' : 'Receive Tip'
  const tiers = useTiers()
  const dataTier = tiers.find((tier) => tier.id === userInfo.levelId)

  const token = tokens[ChainIdEnum[details.network]]?.[details.currency]
  const { network } = getNetworkIncludeFiatInfo(token.network)
  const displayNetwork = token.code === 'HUSD' ? 'HPN' : network?.networkInfo?.displayName

  return (
    <ColumnCenter
      p="12px"
      width="100%"
      my="24px"
      border={`1px solid ${colors.modalHeaderBackground}`}
      borderRadius={base.radii.small}
    >
      <Text color="textSubtle" fontWeight={500} fontSize="14px" letterSpacing={-0.28} lineHeight="normal">
        <Trans>{title || displayTitleByUserType || 'Tip'}</Trans>
      </Text>

      <RowCenter width="100%">
        <Text fontSize="24px" lineHeight="normal" letterSpacing={-0.48} fontWeight={800}>
          <DisplayAmount
            tokenAmount={{
              token,
              amount: BigNumber(details.amount),
            }}
            my="7px"
          />
        </Text>
      </RowCenter>

      <RowBetween width="100%" mt="20px">
        <Text color="textSubtle" fontSize="14px" lineHeight="normal" fontWeight={300} letterSpacing={-0.28}>
          {userLabel}
        </Text>

        <StyledUserProfileContainer onClick={() => onPresentUserProfileModal({ code: userInfo.code })}>
          <ColumnCenter position="relative" width="20px" height="20px">
            <Avatar src={userInfo.avatar} width={20} height={20} />
            <RowCenter
              minWidth="14px"
              height="12px"
              borderRadius="5px"
              background="linear-gradient(250deg, #F953C6 -15.74%, #B91D73 124.92%)"
              border="1px solid #1D2533"
              padding="2px"
              position="absolute"
              bottom="-6px"
              left="50%"
              style={{
                transform: 'translateX(-50%)',
              }}
            >
              <Text fontSize="8px" fontWeight={700} letterSpacing={-0.16}>
                {revalidateHunnyLevel(dataTier?.level)}
              </Text>
            </RowCenter>
          </ColumnCenter>
          <Text fontSize="14px" lineHeight="20px" fontWeight={400} letterSpacing={-0.28} color="textTertiary" ml="4px">
            {userInfo.displayName}
          </Text>
        </StyledUserProfileContainer>
      </RowBetween>

      <RowBetween width="100%" mt="12px">
        <Text color="textSubtle" fontSize="14px" lineHeight="normal" fontWeight={300} letterSpacing={-0.28}>
          <Trans>Network</Trans>
        </Text>

        <Text fontSize="14px" lineHeight="20px" fontWeight={400} letterSpacing={-0.28} color="textTertiary">
          {displayNetwork}
        </Text>
      </RowBetween>

      {!!details.message?.length && (
        <RowBetween width="100%" mt="12px">
          <Text color="textSubtle" fontSize="14px" lineHeight="normal" fontWeight={300} letterSpacing={-0.28}>
            <Trans>Message</Trans>
          </Text>

          <StyledMessage>{details.message}</StyledMessage>
        </RowBetween>
      )}

      <Box
        width="100%"
        height="1px"
        background={colors.buttonBackgroundAlt2}
        mt="12px"
        display={details.id || !hideCreateTime ? 'block' : 'none'}
      />

      {details.createTime && !hideCreateTime && (
        <RowBetween width="100%" mt="12px">
          <Text color="textAlt1" fontSize="14px" lineHeight="normal" fontWeight={300} letterSpacing={-0.28}>
            <Trans>Date</Trans>
          </Text>

          <Text fontSize="14px" lineHeight="20px" fontWeight={400} letterSpacing={-0.28} color="textAlt1">
            {formatDisplayFullDateTime(new Date(details.createTime))}
          </Text>
        </RowBetween>
      )}

      {details.id && (
        <RowBetween width="100%" mt="12px">
          <Text color="textAlt1" fontSize="14px" lineHeight="normal" fontWeight={300} letterSpacing={-0.28}>
            <Trans>Tip ID</Trans>
          </Text>
          <RowMiddle>
            <Text mr="8px" fontSize="1em" lineHeight="1em">
              <Text fontSize="14px" lineHeight="20px" fontWeight={400} letterSpacing={-0.28} color="textAlt1">
                {details.id?.toString()}
              </Text>
            </Text>
            <CopyButton text={details.id?.toString()} width="20px" height="20px" />
          </RowMiddle>
        </RowBetween>
      )}
    </ColumnCenter>
  )
}

export default TipDetailInfoes

