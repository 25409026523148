import Text from 'components/Text'
import { ColumnCenter } from 'layout/Components/Column'
import { Trans } from 'react-i18next'
import styled from 'styled-components'
import { Icons } from 'svgs'

const TipAcceptModal: React.FC = () => {
  return (
    <StyledContainer position="absolute" background="rgba(0, 0, 0, 0.25)" justifyContent="center !important">
      <ColumnCenter p="20px" width="fit-content !important" border="1px dashed #ADADAD" borderRadius="12px">
        <Icons.LockIcon width="40px" height="40px" fill="#fff" />
        <Text
          mt="16px"
          fontSize={['12px', '12px', '14px']}
          color="text"
          fontWeight={700}
          lineHeight="normal"
          letterSpacing="-0.28px"
          textAlign="center"
        >
          <Trans>Available at VIP 3</Trans>
        </Text>

        <Text
          mt="8px"
          fontSize="14px"
          color="textTertiary"
          fontWeight={400}
          lineHeight="normal"
          letterSpacing="-0.28px"
          textAlign="center"
          maxWidth="220px"
        >
          <Trans>Comeback later to explore this feature</Trans>
        </Text>
      </ColumnCenter>
    </StyledContainer>
  )
}

const StyledContainer = styled(ColumnCenter)`
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(2px);
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
`

export default TipAcceptModal
