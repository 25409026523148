import Box from 'components/Box/Box'
import Button from 'components/Button'
import Image from 'components/Image'
import { ModalProps } from 'components/Modal'
import Text from 'components/Text'
import TransText from 'components/Text/TransText'
import { RouteConfig } from 'config/constants/route'
import { useRouter } from 'hooks/useRouter'
import { ColumnCenter } from 'layout/Components/Column'
import { Trans } from 'react-i18next'
import theme from 'theme'
import { TransactionWrapperModal } from 'views/Profile/Transactions/components/styled'

const DailyCheckinNonCampaign: React.FC<ModalProps> = ({ onDismiss }) => {
  const router = useRouter()

  return (
    <TransactionWrapperModal
      title=""
      onDismiss={onDismiss}
      p="40px 24px"
      minWidth={['', '', '428px !important']}
      background={theme.colors.cardBackground}
      overflow="unset !important"
    >
      <ColumnCenter>
        <TransText fontSize="20px" fontWeight={800} textTransform="uppercase">
          Exiting news!
        </TransText>
        <Text fontSize="14px" fontWeight={400} color="textTertiary" mt="8px" textAlign="center">
          <Trans>
            Get ready for even bigger rewards coming your way in the next few days. Keep in touch and stay tuned!
          </Trans>{' '}
          🤟
        </Text>
        <Box width={['80%', '', '', '320px']}>
          <Image src="/images/bonus/gift-box.png" width={320} height={160} my="40px" />
        </Box>
        {!(router.pathname === RouteConfig.BonusCenter) && (
          <Button
            width="100%"
            onClick={() => {
              router.push(RouteConfig.BonusCenter)
              onDismiss()
            }}
          >
            <TransText>Go to Bonus Center</TransText>
          </Button>
        )}
      </ColumnCenter>
    </TransactionWrapperModal>
  )
}

export default DailyCheckinNonCampaign
