import Box from 'components/Box/Box'
import { BoxProps } from 'components/Box/types'
import CopyButton from 'components/Button/CopyButton'
import { StyledControlContainer } from 'components/FormControl/styled'
import { InputProps } from 'components/Input'
import { FieldState } from 'hooks/useForm'
import { RowMiddle } from 'layout/Components/Row'
import { Trans } from 'react-i18next'
import styled, { css } from 'styled-components'
import { Colors } from 'theme/types'
import { InHouseGameInput, InHouseGameNumericalInput, InHouseLabel, InhouseGameInputContainer } from '../styled'

const StyledInhouseGameInputContainer = styled(InhouseGameInputContainer)<{ $disabled?: boolean }>`
  ${({ $disabled }) =>
    $disabled &&
    css`
      background: ${({ theme }) => theme.colors.backgroundAlt9};
      cursor: default;
      user-select: none;
      caret-color: transparent;
    `}
`

export const ControlContainerFair = styled(StyledControlContainer)``

export const FairInfo: React.FC<
  {
    label: string
    value: string
    labelColor?: keyof Colors
    disabledCopy?: boolean
  } & BoxProps
> = ({ label, value, labelColor, disabledCopy, ...props }) => {
  return (
    <ControlContainerFair overflow="hidden" state={null} {...props}>
      <InHouseLabel mr="auto" color={(labelColor || 'textTertiary') as any}>
        <Trans>{label}</Trans>
      </InHouseLabel>
      <StyledInhouseGameInputContainer height="44px" $disabled>
        <RowMiddle width="100%" px="8px">
          <InHouseGameInput
            px="0"
            autoFocus={false}
            tabIndex={1}
            value={value}
            onValueChanged={() => {}}
            placeholder={value}
          />
          {!disabledCopy && (
            <Box pl="8px" minWidth={16}>
              <CopyButton text={value} />
            </Box>
          )}
        </RowMiddle>
      </StyledInhouseGameInputContainer>
    </ControlContainerFair>
  )
}

export const FairInfoChange: React.FC<
  {
    label: string
    state: FieldState
    type?: 'string' | 'number'
  } & BoxProps &
    InputProps
> = ({ label, state, type = 'string', value, validators, onValueChanged, onErrorChanged, style, ...props }) => {
  return (
    <ControlContainerFair state={state} {...props}>
      <InHouseLabel>
        <Trans>{label}</Trans>
      </InHouseLabel>
      <StyledInhouseGameInputContainer height="44px" style={style}>
        {type === 'string' ? (
          <InHouseGameInput
            px="8px"
            autoFocus={false}
            tabIndex={1}
            value={value}
            validators={validators}
            onErrorChanged={onErrorChanged}
            onValueChanged={onValueChanged}
            validateOnchange
          />
        ) : (
          <InHouseGameNumericalInput
            px="8px"
            autoFocus={false}
            tabIndex={1}
            value={value}
            validators={validators}
            onErrorChanged={onErrorChanged}
            onValueChanged={onValueChanged}
            validateOnchange
            placeholder={''}
            style={{
              textAlign: 'left',
            }}
          />
        )}
      </StyledInhouseGameInputContainer>
    </ControlContainerFair>
  )
}
