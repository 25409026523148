import { useUserOnboardingStatus } from 'state/profile/hooks'
import { delayed } from 'utils'
import { verifyValidHUSDBonus } from 'utils/signupBonus'
import useAutoRedeemBonusModal from 'views/AutoRedeemBonusModal'
import useSignUpSuccessWithBonusModal, {
  SignUpSuccessWithBonusModalProps,
} from 'views/Boarding/components/SignUpSuccessWithBonusModal'
import { useRedeemBonus } from 'views/BonusCenter/hooks'
import { useAnalytics } from './useAnalytics'
import useQueryParam from './useQueryParam'

const useNewUserJourney = () => {
  const { removeParam } = useQueryParam()
  const [handlePresentSignUpSuccessWithBonusModal] = useSignUpSuccessWithBonusModal()
  const [handlePresentAutoClaimBonus] = useAutoRedeemBonusModal()
  const redeem = useRedeemBonus()
  const { update } = useUserOnboardingStatus()
  const { addMetaEvent } = useAnalytics()

  const start = async (config: { husdBonusAmount?: number; bonusCode?: string }) => {
    addMetaEvent('CompleteRegistration')
    // Clear all action
    removeParam('action')

    const husdBonusAmount = config?.husdBonusAmount
    const bonusCode = config?.bonusCode

    const isClaimBonusSucceed = await _presentClaimBonus(bonusCode)
    if (!isClaimBonusSucceed) {
      await _presentSignUpWithBonusModal(husdBonusAmount)
    }

    update(false)
  }

  // Step 1.2
  const _presentSignUpWithBonusModal = async (husdBonusAmount: number) => {
    let signUpBonusModalData: SignUpSuccessWithBonusModalProps = null
    if (husdBonusAmount) {
      if (verifyValidHUSDBonus(husdBonusAmount)) {
        signUpBonusModalData = {
          isSignUpWithBonus: true,
          husdBonusAmount,
        }
      }
    } else {
      signUpBonusModalData = { isSignUpWithBonus: false }
    }

    const modal = handlePresentSignUpSuccessWithBonusModal(signUpBonusModalData)
    await modal.waitingClose()
  }

  // Step 1.1
  const _presentClaimBonus = async (bonusCode: string) => {
    if (!bonusCode) return false

    const response = await redeem(bonusCode, 50)
    if (!response) {
      const modal = handlePresentAutoClaimBonus({
        code: bonusCode,
        redeem: async (_, time) => {
          await delayed(time)
          return null
        },
      })

      await modal.waitingClose()

      return true
    }

    return false
  }

  return start
}

export default useNewUserJourney

