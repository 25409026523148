import React, { createContext, useMemo, useState } from 'react'
import { MineBet } from '../config/types'

interface IMineEventContext {
  currentBet: MineBet
  setCurrentBet: (newBet: MineBet) => void
  currentBlockId: number
  setCurrentBlockId: (id: number) => void
}

export const MineEventContext = createContext<IMineEventContext>({
  currentBet: null,
  currentBlockId: null,
  setCurrentBet: () => {},
  setCurrentBlockId: () => {},
})

const MineEventProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [currentBet, setCurrentBet] = useState<MineBet>(null)
  const [currentBlockId, setCurrentBlockId] = useState(null)

  const value = useMemo(
    () => ({
      currentBet,
      setCurrentBet,
      currentBlockId,
      setCurrentBlockId,
    }),
    [currentBet, setCurrentBet, currentBlockId, setCurrentBlockId],
  )

  return <MineEventContext.Provider value={value}>{children}</MineEventContext.Provider>
}

export default MineEventProvider
