import Modal, { ModalBody, ModalProps } from 'components/Modal'
import React from 'react'
import WarningOnTelegram from './WarningOnTelegram'

const WarningOnTelegramModal: React.FC<ModalProps> = ({ onDismiss }) => {
  return (
    <Modal
      minWidth={['100% !important', '', '440px !important']}
      maxWidth={['100% !important', '', '440px !important']}
      onDismiss={onDismiss}
    >
      <ModalBody p="24px 24px 32px">
        <WarningOnTelegram />
      </ModalBody>
    </Modal>
  )
}

export default WarningOnTelegramModal
