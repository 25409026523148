import Box from 'components/Box/Box'
import { Checkbox } from 'components/Checkbox'
import { InputMessage, InputContainer, InputLabel, StyledInput } from 'components/Input/styled'
import { FieldState } from 'hooks/useForm'
import styled from 'styled-components'
import { HunnyTheme } from 'theme/types'

const getControlDirtyStyles = ({
  state,
  theme,
  $disabled,
}: {
  theme: HunnyTheme
  state: FieldState
  $disabled?: boolean
}) => {
  const isError = state?.errors?.length > 0

  if (!state || !state.isDirty || !isError || $disabled) return ''

  return `
    ${InputMessage} {
      color: ${theme.colors.error} !important;
    }

    ${InputContainer} {
      background-color: rgb(30, 18, 18) !important;
       border-color: ${theme.colors.error} !important;
     }

    ${Checkbox} {
      border-color: ${theme.colors.error} ;
    }
  `
}

export const StyledControlContainer = styled(Box)<{ state: FieldState; $disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  vertical-align: top;
  position: relative;
  width: 100%;

  ${InputLabel} {
    color: ${({ theme }) => theme.colors.textSubtle};
  }

  ${StyledInput} {
    height: 54px;
  }

  .display-when-active {
    visibility: hidden;
    pointer-events: none;
    transition: ${({ theme }) => theme.transitions.fast};
    opacity: 0;
  }

  &:focus-within,
  &:hover {
    ${InputContainer} {
      border-color: ${({ theme }) => theme.colors.strokeAlt};

      svg {
        fill: ${({ theme }) => theme.colors.text};
      }
    }

    ${InputLabel} {
      color: ${({ theme }) => theme.colors.text};
    }
  }

  &:focus-within {
    .display-when-active {
      visibility: visible;
      pointer-events: auto;
      opacity: 1;
    }
  }

  ${getControlDirtyStyles}
`
