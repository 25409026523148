import { Token } from 'config/types'
import { ChainIdEnum } from '../network'

const path = '/images/token'

const tokens: Record<string, Token> = {
  TON: {
    coinGeckoInfo: {
      id: 'toncoin',
    },
    logo: `${path}/ton.png`,
    name: 'TON',
    code: 'TON',
    network: ChainIdEnum.TON_TESTNET,
    isNative: true,
    decimals: 9,
  },
}

export default tokens
