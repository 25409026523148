import GameService from 'services/GameService'
import BetDetailsModal from 'views/Profile/Transactions/components/Bet/BetDetailsModal'
import { useMemo } from 'react'
import useModal from './useModal'

const useOpenBetDetails = () => {
  const [onPresentBetTransactionDetailModal, dismiss] = useModal(BetDetailsModal)

  const presentBetModal = async (betId: number) => {
    if (betId) {
      const response = await GameService.getGameBetDetails(betId).call()
      if (response.data?.betId) onPresentBetTransactionDetailModal({ betTxn: response.data })
    }
  }

  return useMemo(() => ({ presentBetModal, dismiss }), [onPresentBetTransactionDetailModal])
}

export default useOpenBetDetails
