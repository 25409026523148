import BigNumber from 'bignumber.js'
import { ChainIdEnum } from 'config/constants/network'
import { Token } from 'config/types'
import { TipTransactionFilterTypeEnums } from 'config/types/transaction'
import useModal from 'hooks/useModal'
import useQueryParam from 'hooks/useQueryParam'
import { useRequest } from 'hooks/useRequest'
import { useRouter } from 'hooks/useRouter'
import { useEffect, useMemo, useState } from 'react'
import PaymentService from 'services/PaymentService'
import TipDetailModal from './components/TipDetailModal'

export const usePresentTipDetailModal = () => {
  const router = useRouter()
  const { execute } = useRequest()
  const [onPresentTipDetailModal] = useModal(TipDetailModal)
  const { removeParam } = useQueryParam()

  const handlePresentTipDetailModal = async () => {
    const { id } = router.query
    if (id) {
      removeParam('id')
      const response = await execute(PaymentService.getTipDetail(id as string))
      if (response?.data) {
        onPresentTipDetailModal({
          details: response.data,
          target: TipTransactionFilterTypeEnums.Receive,
        })
      }
    }
  }

  return handlePresentTipDetailModal
}

export const useLockAmountTipInfo = (token: Token) => {
  const [lockAmount, setLockAmount] = useState({ lockBonusAmount: new BigNumber(0), bonusId: 0 })
  const { execute } = useRequest()

  useEffect(() => {
    const fetch = async () => {
      const response = await execute(
        PaymentService.getTipInfo({
          currency: token.code,
          network: ChainIdEnum[token.network],
        }),
      )

      if (response?.data) {
        setLockAmount({
          lockBonusAmount: new BigNumber(response.data.lockBonusAmount),
          bonusId: response.data.voucherId,
        })
      }
    }

    fetch()
  }, [token])

  return useMemo(() => ({ lockAmount }), [lockAmount])
}

