import Box from 'components/Box/Box'
import Button from 'components/Button'
import Link from 'components/Link'
import Text from 'components/Text'
import { useCloseAllModal } from 'hooks/useModal'
import { ColumnCenter } from 'layout/Components/Column'
import { Trans } from 'react-i18next'
import styled from 'styled-components'
import { Icons } from 'svgs'
import theme from 'theme'
import { colors } from 'theme/colors'

const DailyCheckinInfomation: React.FC<{ finishTime: string; rewardNumberOfPackage: number }> = ({
  finishTime,
  rewardNumberOfPackage,
}) => {
  const closeModal = useCloseAllModal()

  return (
    <ColumnCenter>
      <Text fontSize="32px" fontWeight={700} lineHeight="44px" color={theme.colors.text} textTransform="uppercase">
        <Trans>Daily Checkin</Trans>
      </Text>
      <Text
        fontSize="12px"
        fontWeight={300}
        letterSpacing={-0.24}
        mt="4px"
        color={theme.colors.textTertiary}
        textAlign="center"
        lineHeight="normal"
      >
        <Trans
          i18nKey="loginToClaimMultiDays"
          values={{
            finishTime,
            rewardNumberOfPackage,
          }}
          components={{
            highlight_tag: <Text as="span" fontSize="12px" color={colors.text} fontWeight={600} />,
          }}
        />
      </Text>

      <Button
        style={{ padding: '0px', background: 'none', height: 'fit-content', marginTop: '2px' }}
        onClick={() => closeModal()}
      >
        <StyledRedirectText
          href="https://hunnyplay.io/en/news/daily-wins-check-in-hunnyplay-every-day-for-amazing-rewards"
          width="inherit"
          external
          color="strokeAlt3"
          disabledDefaultStyle
        >
          <Text fontSize="12px" fontWeight={400} letterSpacing={-0.24} mr="4px" color="inherit">
            <Trans>Learn more</Trans>
          </Text>
          <Box mt="1px">
            <Icons.ArrowRight width={10} height={10} fill={colors.strokeAlt3} />
          </Box>
        </StyledRedirectText>
      </Button>
    </ColumnCenter>
  )
}

const StyledRedirectText = styled(Link)`
  &:hover {
    text-decoration: underline;
  }
`

export default DailyCheckinInfomation
