import Box from 'components/Box/Box'
import { BoxProps } from 'components/Box/types'
import Button from 'components/Button'
import CircleLoader from 'components/Loader/CircleLoader'
import Text from 'components/Text'
import useForm from 'hooks/useForm'
import useModal from 'hooks/useModal'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useUserSettings } from 'state/profile/hooks'
import { colors } from 'theme/colors'
import { HunnyToast } from 'utils/toastify'
import ProvideTotpAuthCodeModal from './ProvideTotpAuthCodeModal'
import TwoFactorConfigToggle from './TwoFactorConfigToggle'

const TwoFactorAuthenSetting: React.FC<BoxProps> = ({ ...props }) => {
  const [submiting, setSubmiting] = useState(false)
  const {
    userSettings: { isEnable2FAForLogin, isEnable2FAForWithdraw, isEnable2FAForTip, isActive2FA },
    update2FASetting,
  } = useUserSettings()
  const [onPresentProvideTotpAuthCodeModal] = useModal(ProvideTotpAuthCodeModal)
  const { t } = useTranslation()

  const { states, controls } = useForm({
    isEnableForLogin: {
      value: isEnable2FAForLogin,
      validateOnChange: true,
    },
    isEnableForWithdraw: {
      value: isEnable2FAForWithdraw,
      validateOnChange: true,
    },
    isEnableForTip: {
      value: isEnable2FAForTip,
      validateOnChange: true,
    },
  })

  const isEnableForLogin = states.isEnableForLogin.value as unknown as boolean
  const isEnableForWithdraw = states.isEnableForWithdraw.value as unknown as boolean
  const isEnableForTip = states.isEnableForTip.value as unknown as boolean

  const handleDisableSaveBtn = () => {
    if (submiting || !isActive2FA) return true

    if (
      isEnableForLogin !== isEnable2FAForLogin ||
      isEnableForWithdraw !== isEnable2FAForWithdraw ||
      isEnableForTip !== isEnable2FAForTip
    )
      return false

    return true
  }

  const handleUpdate2FASettings = () => {
    if (submiting) return

    setSubmiting(true)

    const params = {} as any

    if (isEnableForLogin !== isEnable2FAForLogin) params.isEnable2FAForLogin = isEnableForLogin
    if (isEnableForWithdraw !== isEnable2FAForWithdraw) params.isEnable2FAForWithdraw = isEnableForWithdraw
    if (isEnableForTip !== isEnable2FAForTip) params.isEnable2FAForTip = isEnableForTip

    onPresentProvideTotpAuthCodeModal({
      onTotpAuthCodeConfirmed: async (code) => {
        const response = await update2FASetting({
          ...params,
          otpAuthCode: code,
        })
        if (response.code === 'success') HunnyToast.success(t('Update Two Factor Authentication successfully'))
        return response
      },
    })

    setSubmiting(false)
  }

  return (
    <Box
      as="form"
      onSubmit={(e) => {
        e.preventDefault()
        handleUpdate2FASettings()
        return
      }}
    >
      <Box p="12px" border={`1px solid ${colors.buttonBackgroundAlt2}`} borderRadius="12px" {...props}>
        <TwoFactorConfigToggle
          control={{
            ...controls.isEnableForLogin,
            disabled: !isActive2FA,
          }}
          isBlur={!isActive2FA && controls.isEnableForLogin.value}
          content="Enable 2FA for logging-in"
        />
        <TwoFactorConfigToggle
          control={{
            ...controls.isEnableForWithdraw,
            disabled: !isActive2FA,
          }}
          isBlur={!isActive2FA && controls.isEnableForWithdraw.value}
          content="Enable 2FA for withdrawing"
        />
        <TwoFactorConfigToggle
          control={{
            ...controls.isEnableForTip,
            disabled: !isActive2FA,
          }}
          isBlur={!isActive2FA && controls.isEnableForTip.value}
          content="Enable 2FA for tipping"
        />

        <Button
          type="submit"
          width="160px"
          height="32px"
          mt="20px"
          disabled={handleDisableSaveBtn()}
          style={{
            backgroundColor: !isActive2FA ? '#393B47' : '',
          }}
        >
          {submiting && (
            <Box mr="12px">
              <CircleLoader />
            </Box>
          )}
          <Text fontSize="14px" fontWeight={600} color={handleDisableSaveBtn() ? 'textSubtle' : 'text'}>
            <Trans>Save</Trans>
          </Text>
        </Button>
      </Box>
    </Box>
  )
}

export default TwoFactorAuthenSetting
