import { BoxProps } from 'components/Box/types'
import { Position } from 'components/Popper/typpes'
import Text from 'components/Text'
import { INHOUSE_GAMES, InhouseGameType } from 'config/types/game'
import Column from 'layout/Components/Column'
import { RowMiddle } from 'layout/Components/Row'
import React, { useMemo } from 'react'
import { Trans } from 'react-i18next'
import { InHouseLabel, WrapperFilter } from 'views/InhouseGame/components/styled'

const GameSelect: React.FC<
  {
    inhouseGameType: InhouseGameType
    onSelect: (inhouseGameType: InhouseGameType) => void
    disabled?: boolean
    dropdownPosition?: Position
  } & Omit<BoxProps, 'onSelect'>
> = ({ inhouseGameType, onSelect, disabled, dropdownPosition, height = '40px', ...props }) => {
  const selected = useMemo(
    () => INHOUSE_GAMES.find((game) => game.type === inhouseGameType) || INHOUSE_GAMES[0],
    [inhouseGameType],
  )
  const options = useMemo(
    () => (selected ? INHOUSE_GAMES.filter((item) => item !== selected) : INHOUSE_GAMES),
    [selected],
  )

  return (
    <Column {...props}>
      <InHouseLabel>
        <Trans>Game</Trans>
      </InHouseLabel>
      <WrapperFilter
        disabled={disabled}
        options={options.map((item) => ({ value: item.type, ...item }))}
        onSelect={onSelect}
        OptionItemComponent={FilterOptionItem}
        height={height}
        dropdownPosition={dropdownPosition}
      >
        {selected && <FilterOptionItem item={selected} />}
      </WrapperFilter>
    </Column>
  )
}

const FilterOptionItem = ({ item }) => {
  return (
    <RowMiddle>
      <Text fontSize="1em">{item.name}</Text>
    </RowMiddle>
  )
}

export default GameSelect
