import { useRef } from 'react'
import { useIsomorphicEffect } from './useIsomorphicEffect'

export const useImmutableValueReference = (value: any) => {
  const valueRef = useRef(value)

  useIsomorphicEffect(() => {
    valueRef.current = value
  }, [value])

  return valueRef
}
