import Box from 'components/Box/Box'
import useDebounceCallback from 'hooks/useDebounceCallback'
import Column from 'layout/Components/Column'
import { useEffect, useState } from 'react'
import MineGameService from 'services/InhouseGameService/MineGameService'
import MinesSelect from 'views/InhouseGame/Mine/components/BetBoard/components/MinesSelect'
import MineGameGraphic from 'views/InhouseGame/Mine/components/GameBoard/components/MineGameGraphic'
import { MINES_DEFAULT } from 'views/InhouseGame/Mine/config'
import { MineProbilityFairBetInfo } from 'views/InhouseGame/config/types'

const MineVerifyForm: React.FC<{
  clientSeed: string
  serverSeed: string
  nonce: number
  initialMineBet?: MineProbilityFairBetInfo
  onVerified: (resultText: any) => void
}> = ({ onVerified, clientSeed, serverSeed, nonce, initialMineBet }) => {
  const [mines, setMines] = useState<number>(initialMineBet?.data.mines || MINES_DEFAULT)

  const debounceCallback = useDebounceCallback()

  useEffect(() => {
    debounceCallback(async () => {
      if (mines && clientSeed && serverSeed && nonce >= 0) {
        const bet = await MineGameService.verifyResult(mines, clientSeed, serverSeed, nonce)

        onVerified(
          <Box width="100%">
            <MineGameGraphic currentBet={bet} mines={mines} viewMode />
          </Box>,
        )
      } else onVerified(null)
    }, 300)
  }, [mines, clientSeed, serverSeed, nonce])

  return (
    <Column>
      <MinesSelect mines={mines} onSelect={setMines} mb="16px" height="44px" />
    </Column>
  )
}

export default MineVerifyForm
