import { ChainIdEnum } from 'config/constants/network'
import tokens from 'config/constants/tokens'
import { Token } from 'config/types'
import { PendingTransaction, TransactionStatusEnum } from 'config/types/transaction'
import { formatToApiNetworkField } from 'utils'

export const combineDepositHash = (hash: string, token: Token) => {
  return `${hash}__${token.code}__${token.network}`
}

export const getDepositHashInfo = (combinedHash: string) => {
  const [hash, tokenCode, network] = combinedHash.split('__')

  return { hash, token: tokens[network][tokenCode] as Token }
}

export const getApiStatusTransaction = (status: TransactionStatusEnum) =>
  status ? (PendingTransaction.includes(status) ? TransactionStatusEnum.PendingAPI : status) : null

export const formatFitlerTransaction = (filters) => {
  const fromTime = filters?.dateRange?.[0]?.getTime()
  const toTime = filters?.dateRange?.[1]?.getTime()
  const formatFilter = {
    currency: filters?.currency?.code || null,
    status: (filters?.status !== undefined && getApiStatusTransaction(filters?.status)) || filters?.statusBonus,
    network: formatToApiNetworkField(ChainIdEnum[filters?.currency?.network]) || null,
    from_time: fromTime > 0 ? Math.floor(fromTime / 1000) : null,
    to_time: toTime > 0 ? Math.floor(toTime / 1000) : null,
    provider_code: filters?.provider?.code || null,
    type: filters?.type || filters?.tipType || filters?.referralType,
    in_out_status: filters?.in_out_status || filters?.inOutStatusHusd,
    checkin_type: filters?.dailyRewardTransactionType,
  }

  const result = {}

  Object.keys(formatFilter).forEach((key) => {
    if (formatFilter[key] !== null) {
      result[key] = formatFilter[key]
    }
  })

  return result
}
