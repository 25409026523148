import useModal from 'hooks/useModal'
import { useCallback } from 'react'
import { useAppSelector } from 'state'
import { useAuth } from 'state/auth/hooks'
import LuckySpin from 'views/LuckySpin'

export function useOpenLuckySpinValidator() {
  const wallet = useAppSelector((state) => state.auth.wallet)
  const { isSigned } = useAuth()

  const check = useCallback(
    (callback?: () => void) => {
      // TODO implement after
      if (callback) callback()
    },
    [isSigned, wallet?.type],
  )

  return check
}

function useLuckySpinModal() {
  const [handlePresent, handleOnDimiss] = useModal(LuckySpin)

  const validator = useOpenLuckySpinValidator()

  const handlePresentWithValidateUser = useCallback(() => {
    validator(() => {
      handlePresent()
    })
  }, [validator])

  return [handlePresentWithValidateUser, handleOnDimiss]
}

export default useLuckySpinModal
