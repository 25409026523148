import Box from 'components/Box/Box'
import Grid from 'components/Box/Grid'
import { BoxProps } from 'components/Box/types'
import Text from 'components/Text'
import { RowCenter } from 'layout/Components/Row'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { WHEEL_PAYOUTS, WheelGameRiskEnum } from 'views/InhouseGame/Wheel/config'
import { WheelPayoutConfig, WheelSegments } from 'views/InhouseGame/Wheel/config/types'
import WheelPayoutEventUpdater from './WheelPayoutEventUpdater'
import WheelPayoutInfo from './WheelPayoutInfo'

const WheelPayoutGroup: React.FC<
  {
    segments: WheelSegments
    risk: WheelGameRiskEnum
    activePayout?: WheelPayoutConfig
    small?: boolean
  } & BoxProps
> = ({ segments, risk, activePayout, small, ...props }) => {
  const [hoverPayout, setHoverPayout] = useState<{
    payout: WheelPayoutConfig
    arrowPosition: number
  }>(null)

  const selectedPayoutValues = useMemo(() => WHEEL_PAYOUTS[risk][segments], [risk, segments])

  const onMouseOut = useCallback(() => {
    const activePayoutElement = activePayout && document.getElementById(`wheel-payout-${activePayout.payout}-display`)
    const payoutInfo = activePayoutElement
      ? {
          payout: activePayout,
          arrowPosition: activePayoutElement.offsetLeft + activePayoutElement.clientWidth / 2,
        }
      : null
    setHoverPayout(payoutInfo)
  }, [])

  useEffect(() => {
    if (activePayout) {
      const activePayoutElement = document.getElementById(`wheel-payout-${activePayout.payout}-display`)
      setHoverPayout(
        activePayoutElement
          ? {
              payout: activePayout,
              arrowPosition: activePayoutElement.offsetLeft + activePayoutElement.clientWidth / 2,
            }
          : null,
      )
    }
  }, [activePayout])

  return (
    <Box width="100%" {...props}>
      <RowCenter position="relative" width="100%">
        <StyledMultiplierGroup width="100%" gridGap={['6px', '8px', small ? '10px' : '16px']}>
          {selectedPayoutValues.map((payout) => (
            <StyledPayoutBox
              padding={['12px', '', small ? '12px' : '16px 12px']}
              className={activePayout?.payout === payout.payout ? 'highlight' : ''}
              id={`wheel-payout-${payout.payout}${activePayout ? '-display' : ''}`}
              key={`wheel-payout-${payout.payout}${activePayout ? '-display' : ''}`}
              width={`${100 / selectedPayoutValues.length}%`}
              onMouseOver={(e) => {
                setHoverPayout({
                  payout,
                  arrowPosition: e.currentTarget.offsetLeft + e.currentTarget.clientWidth / 2,
                })
              }}
              onMouseOut={onMouseOut}
              $color={payout.color}
            >
              <RowCenter height="100%" position="relative" zIndex="1">
                <Text
                  textAlign="center"
                  fontSize={['10px', '12px', small ? '12px' : '14px']}
                  fontWeight={[400, small ? 500 : 600]}
                >
                  {`${payout.payout.toFixed(2)}x`}
                </Text>
              </RowCenter>
            </StyledPayoutBox>
          ))}

          <WheelPayoutEventUpdater />
        </StyledMultiplierGroup>
        {hoverPayout && (
          <WheelPayoutInfo
            hideProfit={!!activePayout}
            payout={hoverPayout.payout}
            arrowPosition={hoverPayout.arrowPosition}
            segments={segments}
          />
        )}
      </RowCenter>{' '}
    </Box>
  )
}

const StyledMultiplierGroup = styled(Grid)`
  display: flex;
  justify-content: center;
  user-select: none;
  position: relative;
`

const StyledPayoutBox = styled(Box)<{ $color: string }>`
  transition: 0.1s;
  border-radius: ${({ theme }) => theme.radii.small};
  background-color: ${({ theme }) => theme.colors.backgroundAlt8};
  position: relative;
  overflow: hidden;
  cursor: help;

  &:after {
    transition: 0.1s ease;
    content: '';
    position: absolute;
    background-color: ${({ $color }) => $color};
    width: 100%;
    height: 10%;
    left: 0px;
    bottom: 0px;
  }

  &:hover {
    &:after {
      height: 100%;
    }
  }

  &.highlight {
    &:after {
      height: 100%;
    }
  }
`

export default WheelPayoutGroup
