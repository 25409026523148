import BigNumber from 'bignumber.js'
import Box from 'components/Box/Box'
import Button from 'components/Button'
import CopyButton from 'components/Button/CopyButton'
import Image from 'components/Image'
import Link from 'components/Link'
import { ModalProps } from 'components/Modal'
import Text from 'components/Text'
import { RouteConfig } from 'config/constants/route'
import { GameType } from 'config/types/game'
import { BetDetails } from 'config/types/profile'
import useMatchBreakpoints from 'hooks/useMatchBreakpoints'
import useModal from 'hooks/useModal'
import Column from 'layout/Components/Column'
import { RowBetween, RowCenter, RowMiddle } from 'layout/Components/Row'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useProviderInfo } from 'state/app/hooks'
import { useUserSettings } from 'state/profile/hooks'
import { Icons } from 'svgs'
import theme from 'theme'
import { formatDisplayFullDateTime } from 'utils/dateHelper'
import { getGameTypeDisplay } from 'utils/games'
import { formatRoute } from 'utils/stringHelper'
import { getBetShare } from 'utils/urlHelper'
import UserDisplay from 'views/Profile/components/UserDisplay'
import {
  DisplayMultiplier,
  FieldTitle,
  RowDisplayCurrencyAmountSymbolItem,
  SectionTransactionAmount,
  TransactionWrapperModal,
  WrapperContainerTransactionDetail,
} from '../styled'
import BetTransactionShareModal from './BetTransactionShareModal'
import ProbilityFairSession from './ProbilityFairSession'

const BetDetailsModal: React.FC<
  ModalProps<{
    betTxn: BetDetails
  }>
> = ({ data, onDismiss }) => {
  const { betTxn } = data
  const { t } = useTranslation()
  const { isDesktop } = useMatchBreakpoints()
  const {
    userSettings: { isDisplayInFiat },
  } = useUserSettings()

  const provider = useProviderInfo(betTxn?.game?.providerCode)
  const [onPresent] = useModal(BetTransactionShareModal)
  const formatMultiplier = new BigNumber(betTxn?.multiplier)

  return (
    betTxn && (
      <TransactionWrapperModal title={t('Bet Details')} onDismiss={onDismiss} p="24px" overflow="auto !important">
        <SectionTransactionAmount title={t('Payout')} amount={betTxn.payout.amount} token={betTxn.payout.token}>
          <RowBetween alignItems="flex-start !important">
            <FieldTitle>{t('Bet Amount')}</FieldTitle>

            <RowDisplayCurrencyAmountSymbolItem
              hideLogo={isDisplayInFiat}
              token={betTxn.bet.token}
              value={betTxn.bet.amount}
              additionalIcon={
                <Box marginRight="4px" height="16px">
                  <Icons.InfoCircleIcon fill={theme.colors.textAlt1} width="12px" height="12px" />
                </Box>
              }
            />
          </RowBetween>

          <RowBetween mt="12px">
            <FieldTitle>{t('Multiplier')}</FieldTitle>
            <DisplayMultiplier
              amount={betTxn.multiplier}
              fontSize="16px"
              color={formatMultiplier.gt(0) ? 'success' : 'textAlt1'}
              bold
            />
          </RowBetween>

          <Box height="1px" width="100%" style={{ background: '#232c3d8e' }} mt="12px" />

          <RowBetween mt="12px">
            <Text fontSize="1em" color="textAlt1" lineHeight={1.4}>
              {t('Player')}
            </Text>

            <UserDisplay user={betTxn.player} size={24} />
          </RowBetween>

          <RowBetween mt="12px">
            <Text fontSize="1em" color="textAlt1" lineHeight={1.4}>
              {t('Date')}
            </Text>

            <Text textAlign="right" fontWeight={300} fontSize="1em" color="textAlt1">
              {formatDisplayFullDateTime(new Date(betTxn.createTime))}
            </Text>
          </RowBetween>

          <RowBetween mt="12px">
            <Text fontSize="1em" color="textAlt1" lineHeight={1.4}>
              {t('Bet ID')}
            </Text>
            <RowMiddle>
              <Text fontSize="1em" color="textAlt1" fontWeight={300}>
                {betTxn.betId}
              </Text>
              <Box width="24px" height="24px">
                <CopyButton ml="6px" text={betTxn.betId.toString()} />
              </Box>
            </RowMiddle>
          </RowBetween>

          <Box mt="12px" width="100%">
            <Button
              width="100%"
              my="8px"
              style={{
                background: 'rgba(255, 255, 255, 0.12)',
                borderRadius: theme.radii.tiny,
                fontSize: '14px',
                lineHeight: '16px',
                fontWeight: 700,
              }}
              height={['32px', '', '40px']}
              onClick={() => {
                if (typeof window.navigator.share === 'function' && !isDesktop) {
                  window.navigator
                    .share({
                      title: document.title,
                      text: '',
                      url: getBetShare(betTxn.betId),
                    })
                    .then(() => {
                      onDismiss()
                    })
                    .catch((err) => console.error(err))
                } else {
                  onPresent({ bet: betTxn })
                }
              }}
            >
              {t('Share')}
            </Button>
          </Box>
        </SectionTransactionAmount>
        <WrapperContainerTransactionDetail mt="24px">
          <RowBetween>
            <RowMiddle mr="16px">
              <Box borderRadius={theme.radii.tiny} overflow="hidden">
                <Image src={betTxn.game.thumbnail} width={48} height={48} />
              </Box>
              <Column ml="8px">
                <Text small color="textTertiary" fontWeight={500} lineHeight={1.3} ellipsis={1}>
                  {betTxn.game.name}
                </Text>
                <Text fontSize="12px" lineHeight={1.3} ellipsis={1} color="textAlt1">
                  {getGameTypeDisplay(betTxn.game.type) ? `${getGameTypeDisplay(betTxn.game.type)} | ` : ''}
                  {provider.name}
                </Text>
              </Column>
            </RowMiddle>

            <RowMiddle minWidth="70px">
              <Text
                as={Link}
                href={formatRoute(RouteConfig.GameDetail, { slug: betTxn.game.slug })}
                disabledDefaultStyle
                color="primary"
                fontSize="12px"
                mr="4px"
                onClick={() => {
                  onDismiss()
                }}
              >
                {t('Play Now')}
              </Text>
              <Icons.ArrowRight
                fill={theme.colors.primary}
                style={{
                  transform: 'scale(1.1)',
                }}
              />
            </RowMiddle>
          </RowBetween>
        </WrapperContainerTransactionDetail>
        {betTxn.game?.type === GameType.InHouse && (
          <WrapperContainerTransactionDetail mt="24px">
            <ProbilityFairSession
              inhouseGameType={betTxn.game.inhouseGameType}
              inhouseBetId={betTxn.providerBetIdRef}
            />
          </WrapperContainerTransactionDetail>
        )}

        <RowCenter mt="24px">
          <Image src="/logo-text.png" width={120} height={32} alt="logo" />
        </RowCenter>
      </TransactionWrapperModal>
    )
  )
}

export default BetDetailsModal
