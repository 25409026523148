import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import Button from 'components/Button'
import Image from 'components/Image/Image'
import { ModalBody } from 'components/Modal'
import Modal, { ModalProps } from 'components/Modal/Modal'
import Text from 'components/Text'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export type ConfirmationProps = {
  buttonHandle: () => void
  buttonContent?: string
  desc: string
  title?: string
}
const ErrorModal: React.FC<ModalProps<ConfirmationProps>> = ({
  onDismiss,
  data: { desc, buttonHandle, buttonContent = 'Close', title = 'Opps!' },
}) => {
  const { t } = useTranslation()
  return (
    <StyledModal
      onDismiss={onDismiss}
      minWidth={['100% !important', '100% !important', '468px !important']}
      maxWidth={['100% !important', '100% !important', '468px !important']}
      p="0px !important"
    >
      <StyledModalBody borderRadius="0px" height="100%" p="24px 24px 40px 24px">
        <Flex flexDirection="column" alignItems="center" overflow="hidden">
          <Box width="80px" height="80px">
            <Image src="/images/failed.png" width={160} height={160} />
          </Box>
          <Text mt="20px" fontWeight="700" fontSize="20px">
            {t(title)}
          </Text>
        </Flex>
        <Text small color="textTertiary" textAlign="center" mt="8px" lineHeight="16px" fontWeight="300">
          {t(desc)}
        </Text>
        <Button
          maxWidth="250px"
          marginX="auto"
          width="100%"
          mt="40px"
          onClick={() => {
            buttonHandle()
            onDismiss()
          }}
        >
          <Text small bold>
            {t(buttonContent)}
          </Text>
        </Button>
      </StyledModalBody>
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  padding: 24px;

  ${({ theme }) => theme.mediaQueries.sm} {
    min-width: 420px !important;
  }
`

const StyledModalBody = styled(ModalBody)`
  overflow-y: unset !important;
`

export default ErrorModal
