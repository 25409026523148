import { featuredPostTag } from 'config/constants/blog'
import { DEFAULT_META } from 'config/meta'
import { BlogPost, BlogPostIncludeFieldResponse, BlogTag, BlogTagResponse } from 'config/types/blog'
import { BaseResponse, Paging } from 'services/types'

export const PostsMapper = (rawResponse: string): BaseResponse<Paging<BlogPost>> => {
  const response = JSON.parse(rawResponse)

  if (!response?.posts) {
    return {
      code: 'error',
      data: null,
    }
  }

  return {
    code: 'success',
    data: {
      items: response.posts.map(convertPost),
      paging: convertPaging(response.meta.pagination),
    },
  }
}

export const TagsMapper = (rawResponse: string): BaseResponse<Paging<BlogTag>> => {
  const response = JSON.parse(rawResponse)

  if (!response?.tags) {
    return {
      code: 'error',
      data: null,
    }
  }

  return {
    code: 'success',
    data: {
      items: response.tags.map(convertTag),
      paging: convertPaging(response.meta.pagination),
    },
  }
}

export const TagDetailsMapper = (rawResponse: string): BaseResponse<BlogTag> => {
  const response = JSON.parse(rawResponse)

  if (!response?.tags) {
    return {
      code: 'error',
      data: null,
    }
  }

  return {
    code: 'success',
    data: convertTag(response.tags[0]),
  }
}

export const PostDetailsMapper = (rawResponse: string): BaseResponse<BlogPost> => {
  const response = JSON.parse(rawResponse)

  if (!response?.posts) {
    return {
      code: 'error',
      data: null,
    }
  }

  return {
    code: 'success',
    data: convertPost(response.posts[0]),
  }
}

const convertPost = (post: BlogPostIncludeFieldResponse) => {
  const parsedTags = post.tags ? post.tags.map(convertTag) : []
  const featuredTag = post.featured ? [featuredPostTag] : []

  return {
    featured: post.featured || null,
    featureImageSrc: post.feature_image || null,
    html: post.html || null,
    id: post.id || null,
    publishedAt: (post.published_at && new Date(post.published_at).getTime()) || null,
    slug: post.slug || null,
    title: post.title || null,
    featureImageCaption: post.feature_image_caption || null,
    featureImageAlt: post.feature_image_alt || null,
    excerpt: post.excerpt,
    author: post.authors
      ? {
          id: post.authors[0].id,
          name: post.authors[0].name,
          profileImage: post.authors[0].profile_image,
        }
      : null,
    tags: [...parsedTags, ...featuredTag],
    seoProps: {
      title: post.meta_title || post.title,
      description: post.meta_description || post.excerpt,
      ogImage: post.og_image || post.feature_image,
      ogTitle: post.og_title || post.title,
      ogDescription: post.og_description || post.excerpt,
      twitterImage: post.twitter_image || post.feature_image,
      twitterTitle: post.twitter_title || post.title,
      twitterDescription: post.twitter_description || post.excerpt,
    },
  }
}

const convertTag = (tag: BlogTagResponse): BlogTag => ({
  id: tag.id,
  name: tag.name,
  slug: tag.slug,
  seoProps: {
    title: tag.meta_title || `News | ${tag.name} | HunnyPlay`,
    description: tag.meta_description || DEFAULT_META.description,
    ogImage: tag.og_image || DEFAULT_META.ogImage,
    ogTitle: tag.og_title || `News | ${tag.name} | HunnyPlay`,
    ogDescription: tag.og_description || DEFAULT_META.description,
    twitterImage: tag.twitter_image || DEFAULT_META.ogImage,
    twitterTitle: tag.twitter_title || `News | ${tag.name} | HunnyPlay`,
    twitterDescription: tag.twitter_description || DEFAULT_META.description,
  },
})

const convertPaging = (paging: any) => ({
  limit: paging.limit,
  offset: (paging.page - 1) * paging.limit,
  total: paging.total,
})
