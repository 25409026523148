import { Token } from 'config/types'
import { ChainIdEnum } from '../network'

const path = '/images/token'

const tokens: Record<string, Token> = {
  ETH: {
    coinGeckoInfo: {
      id: 'ethereum',
    },
    logo: `${path}/eth.png`,
    name: 'ETH',
    code: 'ETH',
    network: ChainIdEnum.ETH,
    isNative: true,
    decimals: 18,
  },
  USDT: {
    coinGeckoInfo: {
      id: 'tether',
    },
    logo: `${path}/usdt.png`,
    name: 'USDT',
    code: 'USDT',
    network: ChainIdEnum.ETH,
    address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    decimals: 6,
    isStable: true,
  },
  USDC: {
    coinGeckoInfo: {
      id: 'usd-coin',
    },
    logo: `${path}/usdc.png`,
    name: 'USDC',
    code: 'USDC',
    network: ChainIdEnum.ETH,
    address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    decimals: 6,
    isStable: true,
  },
}

export default tokens
