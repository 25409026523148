import useModal from 'hooks/useModal'
import { useDailyRewardCampaign } from 'state/bonus/hooks/dailyReward'
import DailyCheckinModal from './DailyCheckinModal'
import DailyCheckinNonCampaign from './DailyCheckinModal/DailyCheckinNonCampaign'

const DAILY_CHECKIN_MODAL_KEY = 'daily-checkin'
const DAILY_CHECKIN_NON_CAMPAIGN_MODAL_KEY = 'daily-checkin-non-campaign'

const useDailyCheckinModal = () => {
  const { campaign } = useDailyRewardCampaign()

  const dailyCheckinModal = useModal(DailyCheckinModal, DAILY_CHECKIN_MODAL_KEY)
  const dailyCheckinNonCampaignModal = useModal(DailyCheckinNonCampaign, DAILY_CHECKIN_NON_CAMPAIGN_MODAL_KEY)

  return campaign ? dailyCheckinModal : dailyCheckinNonCampaignModal
}

export default useDailyCheckinModal
