import { AuthMethod } from 'config/constants/auth'
import { LoginPayload } from 'config/types/auth'
import { RefUserProfile } from 'config/types/profile'
import { EventKey, useAnalytics } from 'hooks/useAnalytics'
import useAuthenticationModal from 'hooks/useAuthenticationModal'
import { useConnectWallet } from 'hooks/useConnectWallet'
import useSuggestedLogin from 'hooks/useSuggestedLogin'
import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import AuthenticationService from 'services/AuthenticationService'
import { useAppDispatch, useAppSelector } from 'state'
import { setIsSigned } from 'state/app/actions'
import { resetUserBonus } from 'state/bonus/actions'
import { updateAvailableCashbacks, updateTotalEarnedCashbacks } from 'state/cashback/actions'
import { useUnreadNotificationAmount } from 'state/notification/hooks'
import { resetProfile } from 'state/profile/actions'
import { useProfileTier, useUpdateFavoriteGames, useUpdateWageringBonusFeaturedGames } from 'state/profile/hooks'
import { updateDeviceUid } from 'state/session/actions'
import { getID } from 'utils/fingerprint'
import { logError } from 'utils/sentry'
import { removeHUSDBonusStorage } from 'utils/signupBonus'
import { HunnyToast } from 'utils/toastify'
import { AuthModalMethodEnums, AuthModalPageEnums } from 'views/Authentication/types'
import { useIsTelegramOrigin } from 'hooks/useTelegram'
import { WALLET_INFO } from 'config/constants/wallet'
import useWalletAccount from 'hooks/useWalletAccount'
import WalletAuthModal from 'views/Authentication/components/Wallet/WalletAuthModal'
import useModal from 'hooks/useModal'
import { WalletInfo, WalletType } from 'config/types/wallet'
import { getTrackingWalletName } from 'utils/trackingWallet'
import { login, logout } from './action'

export const useLogout = () => {
  const clientLogout = useClientLogout()
  const wallet = useAppSelector((state) => state.auth.wallet)
  const disconnect = useConnectWallet()

  return useCallback(async () => {
    const isLogout = await AuthenticationService.logout()

    if (isLogout) {
      if (disconnect?.[wallet?.type]) {
        await disconnect[wallet.type].disconnect()
      }

      clientLogout()
    }
  }, [wallet, disconnect])
}

export const useClientLogin = () => {
  const dispatch = useAppDispatch()

  const { recordEvent } = useAnalytics()
  const { clearSuggessLoginInfo } = useSuggestedLogin()

  const handleLogin = useCallback((payload: LoginPayload, trackingInfo: { event: EventKey } = { event: 'login' }) => {
    clearSuggessLoginInfo()

    recordEvent(
      trackingInfo.event,
      {
        loginType: payload.authBy,
      },
      payload.uid,
    )
    dispatch(login({ payload }))
  }, [])

  return handleLogin
}

export const useClientLogout = () => {
  const dispatch = useAppDispatch()
  const { updateUnreadPrivateAmount, updateUnreadSystemAmount } = useUnreadNotificationAmount()
  const { update: updateUserFavoriteGames } = useUpdateFavoriteGames()
  const { update: updateWageringBonusGameCodes } = useUpdateWageringBonusFeaturedGames()

  return useCallback(async () => {
    await dispatch(logout())
    await dispatch(resetProfile())
    await dispatch(resetUserBonus())
    await dispatch(setIsSigned({ isSigned: false }))
    await dispatch(updateTotalEarnedCashbacks({ totalEarnedCashbacks: [] }))
    await dispatch(updateAvailableCashbacks({ availableCashbacks: [] }))
    await updateUnreadPrivateAmount(0)
    await updateUnreadSystemAmount(0)
    await removeHUSDBonusStorage()
    await updateUserFavoriteGames([])
    await updateWageringBonusGameCodes([])
  }, [])
}

export const useAuthRefresh = () => {
  const dispatch = useAppDispatch()
  const deviceUid = useAppSelector((state) => state.session.deviceUid)
  const { uid, username } = useUserInfo()
  const { isAuthByWallet, isAuthByEmail, isAuthByTelegram } = useAuthBy()
  const isTelegramOrigin = useIsTelegramOrigin()

  const logout = useClientLogout()

  const [onPresentAuthModal] = useAuthenticationModal()

  const email = useAppSelector((state) => state.auth.email)
  const wallet = useAppSelector((state) => state.auth.wallet)

  const signedAtTime = useAppSelector((state) => state.app.signedAtTime)

  const { t } = useTranslation()

  const refresh = async () => {
    let _deviceUid = deviceUid

    if (!_deviceUid) {
      _deviceUid = await getID()
    }
    const result = await AuthenticationService.refresh(username, _deviceUid, uid)

    if (!result || result.code !== 'success') {
      const msg = 'Failed call refresh API'
      logError(msg, {
        message: msg,
        tags: ['session_expired', result?.code],
        extra: {
          errorCode: result?.code,
          isAuthByWallet,
          wallet,
          email,
          deviceUid,
          _deviceUid,
          signedAtTime,
        },
      })
    }

    if (
      result?.code !== 'success' ||
      !(isTelegramOrigin
        ? username
        : isAuthByWallet
        ? wallet
        : isAuthByEmail
        ? email
        : isAuthByTelegram
        ? username
        : '')
    ) {
      const msg = 'Your session expired. Please login again'
      HunnyToast.warn(t(msg))
      await logout()
      onPresentAuthModal({
        page: AuthModalPageEnums.LOG_IN,
        method: isAuthByWallet ? AuthModalMethodEnums.WEB3 : AuthModalMethodEnums.TRADITIONAL,
      })
    } else {
      if (!deviceUid) {
        dispatch(updateDeviceUid({ deviceUid: _deviceUid }))
      }
      dispatch(setIsSigned({ isSigned: true, atTime: new Date().getTime() }))
    }
  }

  return refresh
}

export const useRemainAccessTokenTimelife = () => {
  const refresh = useAuthRefresh()
  const { isSigned } = useAuth()

  useEffect(() => {
    if (!isSigned) return
    const interval = setInterval(refresh, 180000)

    return () => {
      clearInterval(interval)
    }
  }, [isSigned])
}

export const useAuth = () => {
  const { authBy, uid, username, hasSession } = useUserInfo()
  const signedAtTime = useAppSelector((state) => state.app?.signedAtTime)
  const isSigned = useAppSelector((state) => state.app?.isSigned)

  return useMemo(
    () => ({ isSigned, username, uid, authBy, hasSession, signedAtTime }),
    [isSigned, username, uid, authBy, signedAtTime, hasSession],
  )
}

export const useAuthBy = () => {
  const { authBy, hasSession } = useUserInfo()

  return useMemo(() => {
    const isAuthByEmail = hasSession && authBy === AuthMethod.Email
    const isAuthByWallet = hasSession && authBy === AuthMethod.Wallet
    const isAuthByTelegram = hasSession && authBy === AuthMethod.Telegram

    return {
      isAuthByTelegram,
      isAuthByEmail,
      isAuthByWallet,
    }
  }, [authBy, hasSession])
}
export const useUserInfo = () => {
  const authBy = useAppSelector((state) => state.auth?.authBy)
  const uid = useAppSelector((state) => state.auth?.uid)
  const username = useAppSelector((state) => state.auth?.username)
  const displayName = useAppSelector((state) => state.profile?.displayName)
  const avatar = useAppSelector((state) => state.profile?.avatar)
  const canUpdateDisplayNameAt = useAppSelector((state) => state.profile?.canUpdateDisplayNameAt)
  const userCode = useAppSelector((state) => state.profile?.userCode)
  const registeredDate = useAppSelector((state) => state.profile?.registeredDate)

  return useMemo(
    () => ({
      username,
      uid,
      authBy,
      hasSession: !!(username && uid),
      displayName: displayName || username,
      avatar,
      canUpdateDisplayNameAt,
      userCode,
      registeredDate,
    }),
    [username, uid, authBy, displayName, avatar, canUpdateDisplayNameAt, userCode],
  )
}

export const useRefMyProfile = (): RefUserProfile => {
  const { avatar, displayName } = useUserInfo()
  const { id } = useProfileTier()

  return useMemo(() => ({ avatar, displayName, levelId: id }), [avatar, displayName, id])
}

export const useWalletInfo = () => {
  const { wallet } = useAppSelector((state) => state.auth)
  const { uid, authBy } = useAuth()
  const accounts = useWalletAccount()
  const [onPresentAuthModal, onDimiss] = useModal(WalletAuthModal)
  const login = useClientLogin()
  const { t } = useTranslation()

  const walletInfo = useMemo(
    () =>
      wallet &&
      WALLET_INFO.find((item) => (wallet.name ? wallet.name === item.name : true) && item.type === wallet.type),
    [wallet],
  )

  const onCallbackConnect = useCallback(
    async ({
      account,
      wallet: walletReconnect,
      callbackReconnect,
    }: {
      account: string
      wallet: WalletInfo
      callbackReconnect?: () => void
    }) => {
      if (!walletReconnect || walletReconnect.type !== wallet.type || !account || !wallet.address) {
        HunnyToast.error(t('The wallet is incorrect. Please check your wallet again.'))
        return
      }

      const walletTracked =
        walletReconnect.type === WalletType.SOL ? walletReconnect.name : await getTrackingWalletName(walletReconnect)

      login(
        {
          authBy,
          uid: uid || null,
          username: wallet.address,
          wallet: {
            name: walletReconnect.name,
            type: walletReconnect.type,
            connectorId: walletReconnect.adapter.name,
            address: wallet.address,
            trackingName: walletTracked,
          },
          email: null,
        },
        { event: 'login' },
      )

      if (callbackReconnect) callbackReconnect()

      onDimiss()
    },
    [wallet, uid, t],
  )

  const onConnectByWalletType = useCallback(
    (data?: { callbackReconnect?: () => void } | any) => {
      onPresentAuthModal({
        onCallbackConnect: (connectResponse) =>
          onCallbackConnect({
            ...connectResponse,
            callbackReconnect: data.callbackReconnect,
          }),
      })
    },
    [onCallbackConnect],
  )

  return useMemo(() => {
    const account = accounts[wallet?.type]
    const isWrongAddress = account && account.toLowerCase() !== wallet.address.toLowerCase()

    return {
      wallet,
      walletInfo,
      isWrongAddress,
      account,
      onConnectByWalletType,
    }
  }, [walletInfo, onConnectByWalletType, wallet, accounts])
}
