import { DepositFiatTransaction } from 'config/types/transaction'
import { useMemo } from 'react'
import PaymentService from 'services/PaymentService'

export const useResumeProgressingDepositFiat = (transactionCreateTime: number) => {
  const countdownTime = new Date(
    new Date(transactionCreateTime).setHours(new Date(transactionCreateTime).getHours() + 1),
  )
  const isAbleToResume = countdownTime >= new Date()
  const handleResumeDepositFiat = async (transaction: DepositFiatTransaction, autoRedirect?: boolean) => {
    if (!isAbleToResume) return

    const response = await PaymentService.resumeDepositFiat({ txnHash: transaction.txnHash })
    if (response?.data) {
      if (autoRedirect) {
        const newWindow = (window as any)?.open()
        if (newWindow) {
          newWindow.document.write(response.data.content)
          newWindow.document.close()
        }
      }
    }

    return response.data
  }

  return useMemo(() => ({ countdownTime, onResume: handleResumeDepositFiat }), [countdownTime, handleResumeDepositFiat])
}

