import { BannerTypeEnum } from 'config/constants/banner'
import { useCallback, useEffect, useMemo, useState } from 'react'
import HomeService from 'services/HomeService'
import { useRequest } from './useRequest'

const SIGN_UP_BANNER_KEY = 'signUpBanner'
const SIGN_IN_BANNER_KEY = 'singnInBanner'

const useAuthBanner = () => {
  const { execute } = useRequest()

  const getExistedBanner = useCallback((key: string) => {
    const existedBanner = JSON.parse(sessionStorage.getItem(key))
    if (existedBanner) return existedBanner
    return null
  }, [])

  const sessionStorageSignInBanner = getExistedBanner(SIGN_IN_BANNER_KEY)
  const sessionStorageSignUpBanner = getExistedBanner(SIGN_UP_BANNER_KEY)

  const [banners, setBanners] = useState({
    signInBanner: sessionStorageSignInBanner || '',
    signUpBanner: sessionStorageSignUpBanner || '',
  })

  const fetch = useCallback(async () => {
    const response = await execute(HomeService.getBannerList())
    if (response?.data) {
      const signInBannerUrl = response.data?.[BannerTypeEnum.SignIn][0]?.imageSrc || ''
      const signUpBannerUrl = response.data?.[BannerTypeEnum.SignUp][0]?.imageSrc || ''

      sessionStorage.setItem(SIGN_IN_BANNER_KEY, JSON.stringify(signInBannerUrl))
      sessionStorage.setItem(SIGN_UP_BANNER_KEY, JSON.stringify(signUpBannerUrl))
      setBanners({ signInBanner: signInBannerUrl, signUpBanner: signUpBannerUrl })
    }
  }, [])

  useEffect(() => {
    if (!sessionStorageSignInBanner || !sessionStorageSignUpBanner) fetch()
    else {
      return () => {
        fetch()
      }
    }
  }, [])

  return useMemo(() => banners, [banners])
}
export default useAuthBanner
