import useDebounceCallback from 'hooks/useDebounceCallback'
import { RowCenter } from 'layout/Components/Row'
import { useEffect } from 'react'
import DiceGameService from 'services/InhouseGameService/DiceGameService'
import DiceTargetAdjuster from 'views/InhouseGame/Dice/components/GameBoard/components/DiceTargetAdjuster'
import { DiceGameTargetType } from 'views/InhouseGame/Dice/config'

const DiceVerifyForm: React.FC<{
  clientSeed: string
  serverSeed: string
  nonce: number
  onVerified: (result: any) => void
}> = ({ onVerified, clientSeed, serverSeed, nonce }) => {
  const debounceCallback = useDebounceCallback()

  useEffect(() => {
    debounceCallback(async () => {
      if (clientSeed && serverSeed && nonce >= 0) {
        const result = await DiceGameService.verifyResult(clientSeed, serverSeed, nonce)

        onVerified(
          <RowCenter height="100%" width="100%">
            <DiceTargetAdjuster
              value="0"
              targetType={DiceGameTargetType.Over}
              disabledAnimation
              betResult={
                {
                  result,
                  target: {
                    value: 0,
                    type: DiceGameTargetType.Over,
                  },
                } as any
              }
            />
          </RowCenter>,
        )
      } else onVerified(null)
    }, 300)
  }, [clientSeed, serverSeed, nonce])

  return null
}

export default DiceVerifyForm
