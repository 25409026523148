import { Trans } from 'react-i18next'

import Flex from 'components/Box/Flex'

import Text from 'components/Text'
import useModal from 'hooks/useModal'

import { useUserSettings } from 'state/profile/hooks'
import styled from 'styled-components'
import { Icons } from 'svgs'
import SettingSecurityModal from 'views/Profile/Settings/components/SettingSecurity/SettingSecurityModal'
import { SETTING_SECURITY_MODAL_KEY } from 'config/constants'

const Badge2FA = () => {
  const { userSettings } = useUserSettings()
  const [on2FAModal] = useModal(SettingSecurityModal, SETTING_SECURITY_MODAL_KEY)

  return (
    <Wrapper onClick={on2FAModal}>
      {userSettings?.isActive2FA ? (
        <>
          <Icons.ShieldTickIcon />
          <Text>
            <Trans>2FA: ON</Trans>
          </Text>
        </>
      ) : (
        <>
          <Icons.ShieldIcon />
          <Text>
            <Trans>2FA: OFF</Trans>
          </Text>
        </>
      )}
    </Wrapper>
  )
}

const Wrapper = styled(Flex)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.buttonBackgroundAlt2};
  border-radius: 4px;
  padding: 2px 4px;
  margin-left: 12px;
  cursor: pointer;

  ${Text} {
    font-size: 12px;
    margin-left: 2px;
    color: ${({ theme }) => theme.colors.textTertiary};
    line-height: 1;
  }
`

export default Badge2FA
