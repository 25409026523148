import { Token } from 'config/types'
import { ChainIdEnum } from '../network'

const path = '/images/token'

const tokens: Record<string, Token> = {
  ETH: {
    coinGeckoInfo: {
      id: 'ethereum',
    },
    logo: `${path}/eth.png`,
    name: 'ETH',
    code: 'ETH',
    network: ChainIdEnum.OP,
    isNative: true,
    decimals: 18,
  },
  OP: {
    coinGeckoInfo: {
      id: 'optimism',
    },
    logo: `${path}/op.png`,
    name: 'OP',
    code: 'OP',
    network: ChainIdEnum.OP,
    address: '0x4200000000000000000000000000000000000042',
    decimals: 18,
    isStable: true,
  },
  USDC: {
    coinGeckoInfo: {
      id: 'usd-coin',
    },
    logo: `${path}/usdc.png`,
    name: 'USDC',
    code: 'USDC',
    network: ChainIdEnum.OP,
    address: '0x0b2c639c533813f4aa9d7837caf62653d097ff85',
    decimals: 6,
    isStable: true,
  },
}

export default tokens
