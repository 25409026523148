export enum AuthModalPageEnums {
  LOG_IN = 'LOG_IN',
  SIGN_UP = 'SIGN_UP',
}

export enum AuthWalletStepEnums {
  WALLET_LIST = 'WALLET_LIST',
  CONNECTING = 'CONNECTING',
  SCAN_APP = 'SCAN_APP ',
  GET_APP = 'GET_APP ',
  INSTALL_APP = 'INSTALL_APP ',
}
export enum AuthModalMethodEnums {
  WEB3,
  TRADITIONAL,
}

