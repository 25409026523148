import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import BackgroundImage from 'components/Image/BackgroundImage'
import Text from 'components/Text'
import useAuthBanner from 'hooks/useAuthBanner'
import { useState } from 'react'
import { Trans } from 'react-i18next'
import styled from 'styled-components'
import { getHUSDBonus } from 'utils/signupBonus'
import BonusCountdown from './BonusCountdown'
import { AuthModalPageEnums } from '../types'

export interface IntroductionProps {
  title: string
  page: AuthModalPageEnums
}

const Introduction: React.FC<IntroductionProps> = ({ title, page }) => {
  const bonus = getHUSDBonus()
  const [isBonusExpired, setIsBonusExpired] = useState(bonus.isExpired)
  const { signUpBanner, signInBanner } = useAuthBanner()

  return (
    <Container src={`${page === AuthModalPageEnums.LOG_IN ? signInBanner : signUpBanner || ''}`}>
      <Box
        position="absolute"
        left={0}
        right={0}
        bottom={0}
        height={141}
        backgroundImage="linear-gradient(1deg,#16191ff7 2.84%,rgb(22 25 31 / 78%) 47.59%,rgba(22,25,31,0) 87.36%)"
      />

      <Content width="100%">
        <BonusCountdown onExpired={() => setIsBonusExpired(true)} />
        {isBonusExpired && (
          <>
            <Text fontSize={['16px']} textAlign="center" fontWeight="bold" lineHeight="20px">
              {title}
            </Text>
            <Text fontSize={['12px']} fontWeight={400} lineHeight="18px" textAlign="center">
              <Trans>Log in to find your dream ways of earning and gain full access to platform functions</Trans>
            </Text>
          </>
        )}
      </Content>
    </Container>
  )
}

const Container = styled(BackgroundImage)`
  display: none;
  box-sizing: border-box;
  background-size: cover;
  background-position: center center;
  border-top-left-radius: ${({ theme }) => theme.radii.extraLarge};
  border-bottom-left-radius: ${({ theme }) => theme.radii.extraLarge};
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 100%;

  ${({ theme: { mediaQueries } }) => mediaQueries.md} {
    display: flex;
  }
`

const Content = styled(Flex)`
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 18px;
  z-index: 1;
  padding-bottom: 12px;
  background: linear-gradient(0deg, #16191f 16.01%, rgba(22, 25, 31, 0.553598) 60.18%, rgba(22, 25, 31, 0) 98.03%);
`

export default Introduction
