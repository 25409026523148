import Box from 'components/Box/Box'
import Button from 'components/Button/Button'
import Image from 'components/Image/Image'
import Dots from 'components/Loader/Dots'
import Modal, { ModalBody, ModalProps } from 'components/Modal'
import OpenEffect from 'components/OpenEffect'
import Text from 'components/Text/Text'
import { useIsomorphicEffect } from 'hooks/useIsomorphicEffect'
import useModal from 'hooks/useModal'
import { ColumnCenter } from 'layout/Components/Column'
import { useState } from 'react'
import { Trans } from 'react-i18next'
import { useAuth } from 'state/auth/hooks'
import styled from 'styled-components'
import base from 'theme/base'
import RedeemBonusSucceed from './RedeemBonusSucceed'

interface AutoRedeemBonusModalProps {
  code: string
  redeem: (code: string, delay: number) => Promise<string>
}

enum ClaimStatusEnum {
  Claiming,
  Failed,
  Succeed,
}
const AutoRedeemBonusModal: React.FC<ModalProps<AutoRedeemBonusModalProps>> = ({ data, onDismiss }) => {
  const { code, redeem } = data
  const { isSigned } = useAuth()
  const [claimStatus, setClaimStatus] = useState(ClaimStatusEnum.Claiming)
  const [error, setError] = useState('')

  const handleRedeemBonus = async () => {
    const modalbackdrop = window.document.getElementById('modal-backdrop')
    if (modalbackdrop) {
      modalbackdrop.style.pointerEvents = 'none'
    }
    setClaimStatus(ClaimStatusEnum.Claiming)
    const response = await redeem(code, 2000)

    if (response) {
      setError(response)
      setClaimStatus(ClaimStatusEnum.Failed)
    } else {
      setError('')
      setClaimStatus(ClaimStatusEnum.Succeed)
    }

    if (modalbackdrop) {
      modalbackdrop.style.pointerEvents = 'all'
    }
  }

  useIsomorphicEffect(() => {
    if (isSigned && code) {
      handleRedeemBonus()
    }
  }, [code, isSigned])

  return (
    <Modal
      onDismiss={onDismiss}
      disableClose={claimStatus === ClaimStatusEnum.Claiming}
      minWidth={['100% !important', '100% !important', '468px !important']}
      maxWidth={['100% !important', '100% !important', '468px !important']}
      p={0}
    >
      <StyledModalBody p="24px 24px 40px 24px" overflowX="hidden" position="relative">
        {claimStatus === ClaimStatusEnum.Claiming && (
          <StyledContainer mt="24px">
            <Box width={120} height={120}>
              <Image src="/images/voucher_gift.png" width={240} height={240} />
            </Box>
            <ColumnCenter mt="26px">
              <Text fontSize="20px" fontWeight={700} mb={2} color="text">
                <Trans>Just a moment</Trans>!
              </Text>
              <Text small color="textTertiary" fontWeight={400}>
                <Dots>
                  <Trans>Your bonus is being delivered to you</Trans>
                </Dots>
              </Text>
            </ColumnCenter>
          </StyledContainer>
        )}

        {claimStatus === ClaimStatusEnum.Failed && (
          <StyledContainer mt="24px">
            <Box width={80} height={80}>
              <Image src="/images/error-icon.png" width={160} height={160} />
            </Box>

            <Text fontSize="20px" fontWeight={800} mt={20} mb={2}>
              Oops!
            </Text>

            <Box p="12px" borderRadius="5px">
              <Text small textAlign="center" lineHeight="18px" color="textTertiary">
                {error}
              </Text>
            </Box>

            <Button
              onClick={() => {
                onDismiss()
              }}
              height="48px"
              mt="40px"
              width="312px"
              style={{
                borderRadius: base.radii.tiny,
              }}
            >
              <Text small bold>
                <Trans>Close</Trans>
              </Text>
            </Button>
          </StyledContainer>
        )}

        {claimStatus === ClaimStatusEnum.Succeed && (
          <RedeemBonusSucceed
            btnText="Bonus Center"
            description="Congrats! The bonus has been added to your account. You can go to Bonus Center and use it now!"
            onDismiss={onDismiss}
            mt="24px"
          />
        )}
      </StyledModalBody>
    </Modal>
  )
}

const StyledModalBody = styled(ModalBody)`
  max-height: ${({ theme: { topbarHeight } }) => `calc(var(--screen-height) - ${topbarHeight})`};
`

const StyledContainer = styled(OpenEffect).attrs({ openType: 'grow' })`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const AUTO_REDEEM_BONUS = 'auto-redeem-bonus'

const useAutoRedeemBonusModal = () => {
  const modal = useModal(AutoRedeemBonusModal, AUTO_REDEEM_BONUS)

  return modal
}

export default useAutoRedeemBonusModal

