/* eslint-disable no-async-promise-executor */
import { ChainIdEnum } from 'config/constants/network'

import BigNumber from 'bignumber.js'
import HunnyPlaySolana from 'config/constants/idls/SolanaContract.json'

import { AnchorProvider, BN, setProvider } from '@project-serum/anchor'
import { seed } from '@project-serum/anchor/dist/cjs/idl'
import { getAssociatedTokenAddress as getAta, getOrCreateAssociatedTokenAccount, mintTo } from '@solana/spl-token'
import { WalletContextState } from '@solana/wallet-adapter-react'
import { Connection, Keypair, PublicKey } from '@solana/web3.js'
import {
  DEV_SUPPORTED_METHODS,
  HUNNYPLAY_SENDER_ACCOUNT,
  SEED_ARRAY,
  SOL_USDT_MINT_KEYPAIR,
  SYSTEM_PROGRAM_ID,
  TOKEN_PROGRAM_ACCOUNT,
  TOKEN_PROGRAM_ID,
} from 'config/constants/solanaConfig'
import { Token } from 'config/types'
import { Program } from '@coral-xyz/anchor'
import { getDecimalAmount } from './formatBalance'
import { getSimplerRpcProvider } from './providers'
import { logError } from './sentry'

export interface ICreateAccountWithSeedProps {
  programId: PublicKey
  lamports?: number
  sender?: Keypair
  wallet?: any
}

export const getOrCreatedAta = async (mintAccount: PublicKey, owner: PublicKey, connection: Connection) => {
  try {
    const infoToken = await getOrCreateAssociatedTokenAccount(
      connection,
      Keypair.fromSecretKey(new Uint8Array(SOL_USDT_MINT_KEYPAIR)),
      mintAccount,
      owner,
      false,
      'confirmed',
    )
    return infoToken.address
  } catch (error) {
    logError('getOrCreatedAta failed', error)
    return null
  }
}

export const findProgramAddress = async ({ programId, seeds }: ICreateAccountWithSeedProps & { seeds: string[] }) => {
  try {
    if (!programId || !seed) throw Error('Empty field')

    const parsedSeed = seeds?.map((item: any) => Buffer.from(item))
    const tokenAccounts = await PublicKey.findProgramAddress(parsedSeed, programId)

    return tokenAccounts.map((item) => item.toString())
  } catch (error) {
    logError('findProgramAddress failed', error)
    return null
  }
}

export const getAssociatedTokenAddress = async (mint: PublicKey, owner: PublicKey, allowOwnerOffCurve?: boolean) => {
  try {
    const infoToken = getAta(mint, owner, allowOwnerOffCurve)
    return infoToken
  } catch (error) {
    logError('getAssociatedTokenAddress failed', error)
    return null
  }
}

export const getDepositAccounts = async (wallet: WalletContextState, _value: number, token: Token) => {
  try {
    const connection = getSimplerRpcProvider(token.network)

    const programAddress = await findProgramAddress({
      seeds: SEED_ARRAY,
      programId: TOKEN_PROGRAM_ID,
    })

    const fromTokens = token.isNative
      ? token.address
      : await getAssociatedTokenAddress(new PublicKey(token.address), wallet.publicKey)
    const toToken = token.isNative
      ? token.address
      : await getAssociatedTokenAddress(new PublicKey(token.address), new PublicKey(programAddress[0]), true)

    if (DEV_SUPPORTED_METHODS['getOrCreateAssociatedAccount']) {
      await getOrCreatedAta(
        new PublicKey(DEV_SUPPORTED_METHODS['mintTo'].mintAddresses['USDT']),
        new PublicKey(programAddress[0]),
        connection,
      )
    }

    if (DEV_SUPPORTED_METHODS['mintTo'].active) {
      const associated = await getOrCreatedAta(
        new PublicKey(DEV_SUPPORTED_METHODS['mintTo'].mintAddresses['USDT']),
        wallet.publicKey,
        connection,
      )

      await mintTo(
        connection,
        Keypair.fromSecretKey(new Uint8Array(SOL_USDT_MINT_KEYPAIR)),
        new PublicKey(DEV_SUPPORTED_METHODS['mintTo'].mintAddresses['USDT']),
        new PublicKey(associated.toBase58()),
        Keypair.fromSecretKey(new Uint8Array(SOL_USDT_MINT_KEYPAIR)).publicKey,
        DEV_SUPPORTED_METHODS['mintTo'].mintValues['USDT'],
      )
    }

    return {
      user: wallet.publicKey,
      fromTokens,
      toTokens: toToken,
      mintAccount: token.address,
      pdaAccount: new PublicKey(programAddress[0]),
      tokenProgram: TOKEN_PROGRAM_ACCOUNT,
      hunnyPlayStateAccount: HUNNYPLAY_SENDER_ACCOUNT,
      systemProgram: SYSTEM_PROGRAM_ID,
      programId: TOKEN_PROGRAM_ID,
    }
  } catch (error) {
    logError('getDepositAccounts failed', error)
    return null
  }
}

export const deposit = (token: Token, amount: string, wallet: WalletContextState): Promise<string | null> =>
  new Promise(async (resolve, reject) => {
    try {
      const decimalAmount = getDecimalAmount(new BigNumber(amount), token.decimals)

      const value = decimalAmount.toNumber()

      const provider = new AnchorProvider(getSimplerRpcProvider(token.network), wallet, {
        preflightCommitment: 'recent',
        skipPreflight: token.network === ChainIdEnum.SOL_TESTNET,
      })

      setProvider(provider)
      const program = new Program(HunnyPlaySolana as any, TOKEN_PROGRAM_ID, provider as any)

      const depositAccountInfo = await getDepositAccounts(wallet, value, token)

      const signature = await program.rpc.deposit(new BN(value), {
        accounts: depositAccountInfo,
      })

      const isSuccessTransaction = !!signature

      if (!isSuccessTransaction) {
        logError('deposit solana failed', {
          user: {
            sender: wallet.publicKey?.toString(),
            token: token.code,
            chainId: token.network,
            amount,
          },
          tags: ['deposit_failed_receipt'],
        })
      }

      resolve(isSuccessTransaction ? signature : null)
    } catch (error) {
      logError('deposit failed', error)
      reject(error)
    }
  })
