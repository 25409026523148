import io, { Socket, SocketOptions, ManagerOptions } from 'socket.io-client'

export abstract class BaseSocket {
  protected abstract _buildConfig(): Partial<ManagerOptions & SocketOptions>

  private _amountEventListening = 0
  private _socket: Socket
  private _path: string

  public get Socket() {
    if (!this._socket) {
      this._build()
    }

    return this._socket
  }

  constructor(path: string) {
    this._path = path
  }

  protected _build() {
    this._socket = io(this._path, this._buildConfig())
    this._amountEventListening = 0
  }

  public close() {
    this._socket?.close()
    this._socket = null
  }

  public on(event: string, handler: (data: any) => void) {
    this.Socket.on(event, handler)
    this._amountEventListening++

    return true
  }

  public off(event: string, handler?: (data: any) => void) {
    this.Socket.off(event, handler)
    this._amountEventListening--
    if (this._amountEventListening <= 0) {
      this.close()
    }

    return true
  }
}
