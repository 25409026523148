import Box from 'components/Box/Box'
import styled from 'styled-components'
import { space, variant as StyledSystemVariant } from 'styled-system'
import { styleScales, styleVariants } from './themes'
import { ProgressProps, variants } from './types'

export const ProgressBar = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;

  background: ${({ theme: { colors } }) => colors.primary};
  height: 100%;
  transition: ${({ theme: { transitions } }) => transitions.medium};
`

interface StyledProgressProps {
  variant: ProgressProps['variant']
  scale: ProgressProps['scale']
}

const StyledProgress = styled(Box)<StyledProgressProps>`
  position: relative;
  flex: 1;

  background: ${({ theme: { colors } }) => colors.background};

  ${ProgressBar} {
    border-top-left-radius: ${({ variant }) => (variant === variants.FLAT ? '0' : '32px')};
    border-bottom-left-radius: ${({ variant }) => (variant === variants.FLAT ? '0' : '32px')};
  }

  ${StyledSystemVariant({
    variants: styleVariants,
  })}
  ${StyledSystemVariant({
    prop: 'scale',
    variants: styleScales,
  })}
  ${space}
`

export default StyledProgress
