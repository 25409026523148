import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import Button from 'components/Button'
import Text from 'components/Text'
import TokenLogo from 'components/TokenLogo'
import configTokens from 'config/constants/tokens'
import { SpinPrizeTransaction } from 'config/types/luckyspin'
import { usePublicSocket } from 'hooks/usePublicSocket'
import { useRequest } from 'hooks/useRequest'
import { useEffect, useState } from 'react'
import { Trans } from 'react-i18next'
import LuckySpinService from 'services/LuckySpinService'
import { parseSpinPrizeTransaction } from 'services/mapper/LuckySpin'
import styled, { css } from 'styled-components'
import theme from 'theme'
import hunnyKeyframes from 'theme/keyframes'
import { range } from 'utils'
import { parseToLockedHusd } from '../utils'

interface PrizeBottomSlide {
  isSpinning: boolean
  onDisplayPrizeWinnerModal: () => void
}

const PrizeBottomSlide: React.FC<PrizeBottomSlide> = ({ isSpinning, onDisplayPrizeWinnerModal }) => {
  const [transactions, setTransactions] = useState<SpinPrizeTransaction[]>([])
  const { execute } = useRequest()
  const socket = usePublicSocket()

  useEffect(() => {
    if (!socket) {
      return
    }

    socket.on('lucky_spin.rewarded', (data) => {
      if (data) {
        const parseData = parseSpinPrizeTransaction(data)
        if (parseData) {
          setTransactions((preState) => [parseData, ...preState])
        }
      }
    })

    return () => {
      if (socket) {
        socket.off('lucky_spin.rewarded')
      }
    }
  }, [socket])

  useEffect(() => {
    const fetch = async () => {
      const response = await execute(LuckySpinService.getPrizeTransactions())

      if (response?.data) {
        const transactions = range(0, 5)
          .map((_, index) => {
            if (response.data[index]) return response.data[index]

            return null
          })
          .filter((item) => item)
          .reverse()

        setTransactions(transactions)
      }
    }

    fetch()
  }, [])

  return (
    <StyledContainer
      flexDirection="column"
      alignItems="center"
      position="absolute"
      right={0}
      left={[0, 0, 0, 0, 'unset']}
      bottom={10}
      width="100%"
      $isSpinning={isSpinning}
      zIndex={10}
    >
      <Flex
        maxWidth={['100%', '100%', '100%', '100%', '70%']}
        width="100%"
        justifyContent={['center', 'center', 'center', 'center', 'flex-end']}
      >
        <Button
          variant="text"
          onClick={onDisplayPrizeWinnerModal}
          height={['fit-content !important', '', '', '', 'auto']}
        >
          <Text color="primary" fontSize={['14px', '', '', '', '16px']} fontWeight={400}>
            <Trans>Recent and Big win</Trans>
          </Text>
        </Button>
      </Flex>

      <StyledPrizeListContainer
        alignSelf={['center', 'center', 'center', 'center', 'flex-end']}
        mt={2}
        position="relative"
        className="prize-list-container"
        maxWidth="70%"
        overflow="hidden"
        justifyContent="flex-end"
      >
        {transactions.length
          ? transactions.map((prize) => {
              const token = parseToLockedHusd(configTokens[prize.network][prize.currency])
              if (token)
                return (
                  <Box key={`transaction-info-${prize.id}`}>
                    <Flex
                      alignItems="center"
                      justifyContent="center"
                      p={2}
                      minWidth={[100]}
                      minHeight={35}
                      borderRadius={theme.radii.extraLarge}
                      background="rgba(79, 93, 115, 0.33)"
                      mr={2}
                    >
                      <TokenLogo token={token} />
                      <Text fontSize="14px" ml={2} bold>
                        {prize?.amount}
                      </Text>
                    </Flex>
                  </Box>
                )

              return <></>
            })
          : null}
      </StyledPrizeListContainer>
    </StyledContainer>
  )
}

const StyledContainer = styled(Flex)<{ $isSpinning: boolean }>`
  ${({ $isSpinning }) =>
    $isSpinning
      ? css`
          animation: ${hunnyKeyframes.slideOutFadeDown} 0.35s ease-in-out forwards;
        `
      : ''};
`

const StyledPrizeListContainer = styled(Flex)`
  box-sizing: border-box;

  .containter {
    display: flex;

    align-items: center;
    justify-content: center;

    background-color: rgba(79, 93, 115, 0.33);
    margin: 0 3px;
    padding: 6px 22px;
    border-radius: 26px;

    box-sizing: border-box;

    .logo-container {
      position: relative;
    }

    .token-logo {
      max-width: 30px;
      max-height: 30px;
    }

    .network-logo {
      position: absolute;

      max-width: 15px;
      max-height: 15px;

      top: 0px;
      right: 0px;
    }

    .prize-value {
      font-size: 14px;
      font-weight: bold;

      margin-left: 6px;

      color: ${({ theme: { colors } }) => colors.text};
    }

    @keyframes horizontalScale {
      0% {
        transform: translateX(20px);
        width: 0px;
      }
      100% {
        transform: translateX(0px);
        width: 100px;
      }
    }

    animation: horizontalScale 0.35s cubic-bezier(0.05, 0.33, 0.27, 0.03) forwards !important;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;

    width: 100px;
    height: 100%;

    background: linear-gradient(90deg, rgb(0, 6, 41) 12.86%, rgba(2, 5, 20, 0));
  }
`

export default PrizeBottomSlide
