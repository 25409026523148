import { TokenAmount } from 'config/types'
import { useCallback, useContext, useEffect, useMemo } from 'react'
import { WheelGameRiskEnum } from './config'
import { WheelBet, WheelDraftBet, WheelSegments } from './config/types'
import { WheelActionContext } from './context/WheelActionProvider'
import { WheelEventContext } from './context/WheelEventProvider'

export const useWheelGameStatus = () => {
  const { isPlaying } = useContext(WheelActionContext)

  return useMemo(
    () => ({
      isPlaying,
    }),
    [isPlaying],
  )
}

export const useWheelAction = () => {
  const { submitBet, finishBet } = useContext(WheelActionContext)

  const handleBet = useCallback(
    async (
      betAmount: TokenAmount,
      risk: WheelGameRiskEnum,
      segments: WheelSegments,
      options: {
        instanceBetEnable?: boolean
        volumeEnable?: boolean
      },
    ) => {
      const draftBet: WheelDraftBet = {
        betAmount,
        risk,
        segments,
        disabledDisplay: options?.instanceBetEnable,
      }

      return submitBet(draftBet, options?.volumeEnable)
    },
    [submitBet],
  )

  return useMemo(
    () => ({
      submitBet: handleBet,
      finishBet,
    }),
    [handleBet, finishBet],
  )
}

export const useWheelBetListener = (subscribe: (bet: WheelBet) => void | (() => void)) => {
  const { newBet } = useContext(WheelEventContext)

  useEffect(() => {
    if (!newBet) return
    const dismissFn = subscribe(newBet)
    return dismissFn
  }, [newBet])
}

export const useWheelCompletedBetListener = (subscribe: (bet: WheelBet) => void | (() => void)) => {
  const { completedBet } = useContext(WheelEventContext)

  useEffect(() => {
    if (!completedBet) return
    const dismissFn = subscribe(completedBet)
    return dismissFn
  }, [completedBet])
}
