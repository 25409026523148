import BigNumber from 'bignumber.js'
import { useMemo } from 'react'
import { AppState, useAppSelector } from 'state'
import { useUserSettings } from 'state/profile/hooks'

const useSelectedFiatCurrency = () => {
  const {
    userSettings: { selectedFiatCurrency },
  } = useUserSettings()

  const fiatCurrencyConversionRateInUSD =
    useAppSelector((state: AppState) => state.app.fiatCurrencyConversionRateInUSD) || {}

  const fiatPriceInUSDRate = fiatCurrencyConversionRateInUSD[selectedFiatCurrency?.code]

  return useMemo(() => {
    return { ...selectedFiatCurrency, usdPrice: new BigNumber(fiatPriceInUSDRate) }
  }, [selectedFiatCurrency, fiatPriceInUSDRate])
}
export default useSelectedFiatCurrency
