import BigNumber from 'bignumber.js'
import { TokenAmount } from 'config/types'

export enum MineBlockResult {
  Unknow = 'unknow',
  Mine = 'mine',
  Diamond = 'diamond',
}

export type MineDraftBet = {
  betAmount: TokenAmount
  disabledDisplay?: boolean
  mines: number
}

export type MineBlock = {
  isUserOpened: boolean
  value: MineBlockResult
}

export type MineBet = MineDraftBet & {
  id: string
  result: MineBlock[]
  multiplier?: number
  isCompleted?: boolean
}

export type Mine = MineDraftBet & {
  id: string
  result: MineBlockResult[]
}

export type MineBetResponse = {
  id: string
  result: MineBlock[]
  betAmount: TokenAmount
  userBalance: BigNumber
}

export type InhouseGameSettings = {
  maxPayoutUsd: number
  minBetAmountUsd: number
  maxBetAmountUsd: number
}

export type InhouseLaunchSession = {
  sessionId: string
  bet: MineBet
  userBalance: BigNumber
  gameSettings: InhouseGameSettings
}
