import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import Button from 'components/Button'
import CircleLoader from 'components/Loader/CircleLoader'
import Text from 'components/Text'
import useModal from 'hooks/useModal'
import { RowBetween, RowCenter, RowMiddle } from 'layout/Components/Row'
import React, { useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import InhouseGameService from 'services/InhouseGameService/InhouseGameService'
import theme from 'theme'
import { getPayouts } from 'views/InhouseGame/Plinko/components/GameBoard/components/PayoutGroup'
import { risks } from 'views/InhouseGame/Plinko/components/GameBoard/config'
import FairnessModal from 'views/InhouseGame/components/FairnessModal'
import { FairInfo } from 'views/InhouseGame/components/FairnessModal/styled'
import { PlinkoProbilityFairBetInfo } from 'views/InhouseGame/config/types'

const PlinkoGameContent: React.FC<{ inhouseBetId: number; betDetails?: PlinkoProbilityFairBetInfo }> = ({
  inhouseBetId,
  betDetails,
}) => {
  const { t } = useTranslation()
  const [presentFairnessModal] = useModal(FairnessModal)

  const [bet, setBet] = useState<PlinkoProbilityFairBetInfo>(betDetails)

  const selectedPayout = useMemo(() => {
    if (!bet || !bet.data) return null
    const payouts = getPayouts(bet.data.row, bet.data.risk)

    return payouts.find((payout) => payout.multiplier === bet.data.payout)
  }, [bet])

  useEffect(() => {
    if (betDetails) return
    const fetch = async () => {
      const betDetails = await InhouseGameService.getBetInfo(inhouseBetId)
      if (betDetails) {
        setBet(betDetails as PlinkoProbilityFairBetInfo)
      }
    }

    fetch()
  }, [inhouseBetId, betDetails])
  return (
    <Box position="relative">
      {!bet && (
        <Box position="absolute" left="50%" top="50%" style={{ transform: 'translate(-50%, -50%)' }}>
          <CircleLoader />
        </Box>
      )}

      <Box style={{ opacity: bet ? 1 : 0 }}>
        <Flex justifyContent="center">
          <Box
            mt="12px"
            height="36px"
            width="36px"
            borderRadius="2px"
            background={`rgb(${selectedPayout?.color.r}, ${selectedPayout?.color.g}, ${selectedPayout?.color.b})`}
            style={{
              boxShadow: `0 0.2em 0 0 rgba(${selectedPayout?.color.r}, ${selectedPayout?.color.g}, ${selectedPayout?.color.b}, 0.6)`,
            }}
          >
            <RowCenter height="100%">
              <Text fontSize="12px" textAlign="center" fontWeight={700} color="#000">
                {`${selectedPayout?.multiplier}x`}
              </Text>
            </RowCenter>
          </Box>
        </Flex>

        <RowBetween mt="12px">
          <Text fontSize="1em" color="textAlt1" lineHeight={1.4}>
            <Trans>Risk</Trans>
          </Text>
          <RowMiddle>
            <Text fontSize="1em" color="textTertiary" fontWeight={300}>
              {t(risks.find((risk) => risk.value === bet?.data.risk)?.display)}
            </Text>
          </RowMiddle>
        </RowBetween>
        <RowBetween mt="12px">
          <Text fontSize="1em" color="textAlt1" lineHeight={1.4}>
            <Trans>Rows</Trans>
          </Text>
          <RowMiddle>
            <Text fontSize="1em" color="textTertiary" fontWeight={300}>
              {bet?.data.row}
            </Text>
          </RowMiddle>
        </RowBetween>

        <FairInfo labelColor="textAlt1" label="Client Seed" value={bet?.seed.clientSeed || ''} mt="24px" />
        <FairInfo
          labelColor="textAlt1"
          label="Server Seed (Hashed)"
          value={bet?.seed.hashedServerSeed || ''}
          mt="24px"
        />
        <FairInfo
          labelColor="textAlt1"
          label="Server Seed"
          value={bet?.seed.serverSeed || t("Seed hasn't been revealed yet")}
          mt="24px"
          disabledCopy={!bet?.seed.serverSeed}
        />
        <FairInfo labelColor="textAlt1" label="Nonce" value={bet?.seed.nonce.toString()} mt="24px" />

        {bet?.seed.serverSeed ? (
          <Box mt="12px" width="100%">
            <Button
              width="100%"
              my="8px"
              style={{
                background: 'rgba(255, 255, 255, 0.12)',
                borderRadius: theme.radii.tiny,
                fontSize: '14px',
                lineHeight: '16px',
                fontWeight: 700,
              }}
              height={['32px', '', '40px']}
              onClick={() => {
                presentFairnessModal({ inhouseBet: bet })
              }}
            >
              <Trans>Verify</Trans>
            </Button>
          </Box>
        ) : (
          <Text mt="12px" width="100%" fontSize="12px" color="textSubtle">
            <Trans>Server seed needs to be changed to verify bet...</Trans>
          </Text>
        )}
      </Box>
    </Box>
  )
}

export default PlinkoGameContent
