import { getNetworkInitForWagami } from 'config/constants/network'
import { configureChains, createConfig } from 'wagmi'
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import { BinanceWalletConnector } from './connectors/binanceWallet'
import { TrustWalletConnector } from './connectors/trustWallet'
import { ConnectorNameEnum } from './types'

export const { chains, publicClient, webSocketPublicClient } = configureChains(
  getNetworkInitForWagami(),
  [
    jsonRpcProvider({
      rpc: (chain) => ({ http: chain.rpcUrls.default.http[0] }),
    }),
  ],
  {
    batch: {
      multicall: false,
    },
  },
)

export const injectedConnector = new InjectedConnector({
  chains,
  options: {
    shimDisconnect: false,
  },
})

export const coinbaseConnector = new CoinbaseWalletConnector({
  chains,
  options: {
    appName: 'HunnyPlay',
    appLogoUrl: 'https://hunnyplay.io/logo-hunny-200.png',
  },
})

export const walletConnectConnector = new WalletConnectConnector({
  chains,
  options: {
    qrModalOptions: {
      themeVariables: {
        '--wcm-z-index': '100',
      },
    },
    metadata: {
      name: 'HunnyPlay | The best crypto gaming platform',
      description: 'HunnyPlay.io - The Best Crypto iGaming Platform',
      url: 'https://hunnyplay.io',
      icons: ['https://hunnyplay.io/logo-hunny-200.png'],
    },
    projectId: process.env.NEXT_PUBLIC_WALLETCONNECT_PROJECT_ID || 'e9153beb17e55170464dc6b554e74f41',
    showQrModal: true,
  },
})

export const walletConnectNoQRCode = new WalletConnectConnector({
  chains,
  options: {
    projectId: process.env.NEXT_PUBLIC_WALLETCONNECT_PROJECT_ID || 'e9153beb17e55170464dc6b554e74f41',
    showQrModal: false,
  },
})

export const metaMaskConnector = new MetaMaskConnector({
  chains,
  options: {
    shimDisconnect: false,
  },
})

export const trustWalletConnector = new TrustWalletConnector({
  chains,
  options: {
    shimDisconnect: false,
    shimChainChangedDisconnect: true,
  },
})

export const binanceWalletConnector = new BinanceWalletConnector({
  chains,
})

export const connectorsByName: { [connectorName in ConnectorNameEnum]?: any } = {
  [ConnectorNameEnum.Injected]: injectedConnector,
  [ConnectorNameEnum.WalletConnect]: walletConnectConnector,
  [ConnectorNameEnum.WalletConnectNoQrCode]: walletConnectNoQRCode,
  [ConnectorNameEnum.BSC]: binanceWalletConnector,
  [ConnectorNameEnum.MetaMask]: metaMaskConnector,
  [ConnectorNameEnum.TrustWallet]: trustWalletConnector,
}

export const wagmiConfig = createConfig({
  autoConnect: false,
  connectors: [
    metaMaskConnector,
    injectedConnector,
    coinbaseConnector,
    trustWalletConnector,
    binanceWalletConnector,
    walletConnectConnector,
  ],
  publicClient,
})
