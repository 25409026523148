import { useVariableLoadedListener } from 'hooks/useVariableLoadedListener'
import { useMemo, useRef } from 'react'

type Setup = (p5: any, canvasParentRef: Element) => void
type Draw = (p5: any) => void

export type P5Init = {
  setup: Setup
  draw: Draw
}

const clearChild = (container: HTMLElement) => {
  for (let i = 0; i < container.children.length; i++) {
    container.removeChild(container.children[i])
  }
}

export const useP5 = () => {
  const P5 = useVariableLoadedListener(() => window.p5)
  const p5InstanceRef = useRef<any>(null)

  const init = (setup: Setup, draw: Draw, container: HTMLElement) => {
    clearChild(container)
    p5InstanceRef.current = new P5((instance: any) => {
      instance.setup = () => setup(instance, container)
      instance.draw = () => draw(instance)
    }, container)
  }

  const destroy = () => {
    p5InstanceRef.current?.remove()
    p5InstanceRef.current = null
  }

  return useMemo(() => ({ init, destroy, instance: p5InstanceRef.current, isLoaded: !!P5 }), [P5])
}
