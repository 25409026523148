import React, { createContext, useMemo, useState } from 'react'
import { PlinkoBet } from '../types/Bet'

interface IPlinkoEventContext {
  newBet: PlinkoBet
  completedBet: PlinkoBet
  setNewBet: (newBet: PlinkoBet) => void
  setCompletedBet: (completedBet: PlinkoBet) => void
}

export const PlinkoEventContext = createContext<IPlinkoEventContext>({
  newBet: null,
  completedBet: null,
  setNewBet: () => {},
  setCompletedBet: () => {},
})

const PlinkoEventProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [newBet, setNewBet] = useState(null)
  const [completedBet, setCompletedBet] = useState(null)

  const value = useMemo(
    () => ({
      newBet,
      completedBet,
      setNewBet,
      setCompletedBet,
    }),
    [newBet, completedBet, setNewBet, setCompletedBet],
  )

  return <PlinkoEventContext.Provider value={value}>{children}</PlinkoEventContext.Provider>
}

export default PlinkoEventProvider
