import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import Button from 'components/Button'
import Image from 'components/Image'
import Modal, { ModalBody, ModalProps } from 'components/Modal'
import Text from 'components/Text'
import { BASE_API } from 'config/constants/server'
import Column from 'layout/Components/Column'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Icons } from 'svgs'
import { teleAppOpenLink } from 'utils/telegram'
import { StyledStroke } from 'views/Referral/components/styled'

const HowToLoginModal: React.FC<ModalProps> = ({ onDismiss }) => {
  const { t } = useTranslation()

  return (
    <Modal
      minWidth={['100% !important', '', '440px !important']}
      maxWidth={['100% !important', '', '440px !important']}
      onDismiss={onDismiss}
    >
      <ModalBody p="24px 24px 32px">
        <Flex>
          <Button onClick={onDismiss} pl="0 !important" height="16px" variant="text">
            <Icons.ArrowLeftIcon />
          </Button>
          <Text bold fontSize="18px">
            {t('How to Log in')}
          </Text>
        </Flex>

        <Column my="24px">
          <Text lineHeight={1.6} fontWeight={500} small color="textTertiary">
            {t(
              'If you want to log-in via external wallet, please connect your Telegram in your Account on Hunny Play.',
            )}
          </Text>

          <Image src="/images/login-3d.png" width={374} height={150} my="12px" />

          <Box as="ul">
            <Text color="textTertiary" lineHeight={1.4} small as="li">
              {t('Open the HunnyPlay website')};
            </Text>
            <Text color="textTertiary" lineHeight={1.4} small as="li">
              {t('Go to the Profile page')};
            </Text>
            <Text color="textTertiary" lineHeight={1.4} small as="li">
              {t('Click the “Connect” button in Telegram account section.')}
            </Text>
          </Box>

          <StyledStroke mt="12px" />
        </Column>

        <Button
          onClick={() => {
            teleAppOpenLink(`${BASE_API}?action=onLogin`)
          }}
          style={{ borderRadius: '4px' }}
        >
          <Text bold small>
            {t('Open HunnyPlay Website')}
          </Text>
        </Button>
      </ModalBody>
    </Modal>
  )
}

export default HowToLoginModal
