export const canvasWidth = 760
export const canvasHeight = 570
export const particleSizeMuliplier = 2.2
export const wallWidth = 10
export const startYPlinko = 35
export const gravityPlinko = 1

export const risks: {
  value: Risk
  display: string
}[] = [
  {
    value: 'low',
    display: 'Low',
  },
  {
    value: 'medium',
    display: 'Medium',
  },
  {
    value: 'high',
    display: 'High',
  },
]

export const rows = [
  {
    value: 8,
    display: 8,
  },
  {
    value: 9,
    display: 9,
  },
  {
    value: 10,
    display: 10,
  },
  {
    value: 11,
    display: 11,
  },
  {
    value: 12,
    display: 12,
  },
  {
    value: 13,
    display: 13,
  },
  {
    value: 14,
    display: 14,
  },
  {
    value: 15,
    display: 15,
  },
  {
    value: 16,
    display: 16,
  },
]

export const plinkoDistancesX = [0, 0, 0, 0, 0, 0, 0, 0, 72, 65, 58.6, 52.6, 47.6, 45, 41, 37.4, 36.2]
export const plinkoDistancesY = [0, 0, 0, 0, 0, 0, 0, 0, 72.3, 63.4, 56.6, 51, 47, 43, 39.8, 36.6, 34.6]
export const plinkoSizes = [0, 0, 0, 0, 0, 0, 0, 0, 8, 6.7, 6.5, 6, 5, 5, 5, 4.5, 4.5]
export const wallMultipleIndex = [0, 0, 0, 0, 0, 0, 0, 0, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5]

export const particleColor = {
  low: { r1: 255, g1: 191, b1: 3, r2: 255, g2: 153, b2: 0 },
  medium: { r1: 253, g1: 110, b1: 4, r2: 204, g2: 67, b2: 39 },
  high: { r1: 255, g1: 1, b1: 62, r2: 198, g2: 13, b2: 66 },
} as const

export type Risk = keyof typeof particleColor

export enum RiskMaperEnum {
  high = 2,
  low = 0,
  medium = 1,
}

export const payoutDefaultValue = [
  {
    low: [5.6, 2.1, 1.1, 1, 0.5, 1, 1.1, 2.1, 5.6],
    medium: [13, 3, 1.3, 0.7, 0.4, 0.7, 1.3, 3, 13],
    high: [29, 4, 1.5, 0.3, 0.2, 0.3, 1.5, 4, 29],
  },
  {
    low: [5.6, 2, 1.6, 1, 0.7, 0.7, 1, 1.6, 2, 5.6],
    medium: [18, 4, 1.7, 0.9, 0.5, 0.5, 0.9, 1.7, 4, 18],
    high: [43, 7, 2, 0.6, 0.2, 0.2, 0.6, 2, 7, 43],
  },
  {
    low: [8.9, 3, 1.4, 1.1, 1, 0.5, 1, 1.1, 1.4, 3, 8.9],
    medium: [22, 5, 2, 1.4, 0.6, 0.4, 0.6, 1.4, 2, 5, 22],
    high: [76, 10, 3, 0.9, 0.3, 0.2, 0.3, 0.9, 3, 10, 76],
  },
  {
    low: [8.4, 3, 1.9, 1.3, 1, 0.7, 0.7, 1, 1.3, 1.9, 3, 8.4],
    medium: [24, 6, 3, 1.8, 0.7, 0.5, 0.5, 0.7, 1.8, 3, 6, 24],
    high: [120, 14, 5.2, 1.4, 0.4, 0.2, 0.2, 0.4, 1.4, 5.2, 14, 120],
  },
  {
    low: [10, 3, 1.6, 1.4, 1.1, 1, 0.5, 1, 1.1, 1.4, 1.6, 3, 10],
    medium: [33, 11, 4, 2, 1.1, 0.6, 0.3, 0.6, 1.1, 2, 4, 11, 33],
    high: [170, 24, 8.1, 2, 0.7, 0.2, 0.2, 0.2, 0.7, 2, 8.1, 24, 170],
  },
  {
    low: [8.1, 4, 3, 1.9, 1.2, 0.9, 0.7, 0.7, 0.9, 1.2, 1.9, 3, 4, 8.1],
    medium: [43, 13, 6, 3, 1.3, 0.7, 0.4, 0.4, 0.7, 1.3, 3, 6, 13, 43],
    high: [260, 37, 11, 4, 1, 0.2, 0.2, 0.2, 0.2, 1, 4, 11, 37, 260],
  },
  {
    low: [7.1, 4, 1.9, 1.4, 1.3, 1.1, 1, 0.5, 1, 1.1, 1.3, 1.4, 1.9, 4, 7.1],
    medium: [58, 15, 7, 4, 1.9, 1, 0.5, 0.2, 0.5, 1, 1.9, 4, 7, 15, 58],
    high: [420, 56, 18, 5, 1.9, 0.3, 0.2, 0.2, 0.2, 0.3, 1.9, 5, 18, 56, 420],
  },
  {
    low: [15, 8, 3, 2, 1.5, 1.1, 1, 0.7, 0.7, 1, 1.1, 1.5, 2, 3, 8, 15],
    medium: [88, 18, 11, 5, 3, 1.3, 0.5, 0.3, 0.3, 0.5, 1.3, 3, 5, 11, 18, 88],
    high: [620, 83, 27, 8, 3, 0.5, 0.2, 0.2, 0.2, 0.2, 0.5, 3, 8, 27, 83, 620],
  },
  {
    low: [16, 9, 2, 1.4, 1.4, 1.2, 1.1, 1, 0.5, 1, 1.1, 1.2, 1.4, 1.4, 2, 9, 16],
    medium: [110, 41, 10, 5, 3, 1.5, 1, 0.5, 0.3, 0.5, 1, 1.5, 3, 5, 10, 41, 110],
    high: [1000, 130, 26, 9, 4, 2, 0.2, 0.2, 0.2, 0.2, 0.2, 2, 4, 9, 26, 130, 1000],
  },
]

export const payoutRateValue = [
  [0.3906, 3.125, 10.9375, 21.875, 27.3438, 21.875, 10.9375, 3.125, 0.3906],
  [0.1953, 1.7578, 7.0313, 16.4053, 24.6094, 24.6094, 16.4053, 7.0313, 1.7578, 0.1953],
  [0.0977, 0.9766, 4.3945, 11.7188, 20.5078, 24.6094, 20.5078, 11.7188, 4.3945, 0.9766, 0.0977],
  [0.0488, 0.5371, 2.6855, 8.0566, 16.1133, 22.5586, 22.5586, 16.1133, 8.0566, 2.6855, 0.5371, 0.0488],
  [0.0244, 0.293, 1.6113, 5.3711, 12.085, 19.3359, 22.5586, 19.3359, 12.085, 5.3711, 1.6113, 0.293, 0.0244],
  [0.0122, 0.1587, 0.9521, 3.4912, 8.728, 15.7104, 20.9473, 20.9473, 15.7104, 8.728, 3.4912, 0.9521, 0.1587, 0.0122],
  [
    0.0061, 0.0854, 0.5554, 2.2217, 6.1096, 12.2192, 18.3289, 20.9473, 18.3289, 12.2192, 6.1096, 2.2217, 0.5554, 0.0854,
    0.0061,
  ],
  [
    0.0031, 0.0458, 0.3204, 1.3885, 4.1656, 9.1644, 15.274, 19.6381, 19.6381, 15.274, 9.1644, 4.1656, 1.3885, 0.3204,
    0.0458, 0.0031,
  ],
  [
    0.0015, 0.0244, 0.1831, 0.8545, 2.7771, 6.665, 12.2192, 17.4561, 19.6381, 17.4561, 12.2192, 6.665, 2.7771, 0.8545,
    0.1831, 0.0244, 0.0015,
  ],
]

export const payoutResult = [
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  // row 8-done
  [
    [12.75, 41.15, 49.44, 87.02],
    [
      26.02, 40.94, 41.11, 41.2, 41.23, 41.22, 47.24, 49.57, 51.2, 56, 56.07, 77.65, 84.32, 103.91, 103.92, 113.5,
      113.56, 113.57, 113.58, 113.59, 113.6, 113.64, 113.63, 113.61, 113.62, 113.66, 118.23, 118.21, 118.43,
    ],
    [
      0.67, 1.47, 3.62, 4.18, 8.51, 13.54, 14.13, 14.37, 21.16, 21.3, 22.43, 22.8, 22.81, 31.55, 31.54, 32.28, 37.34,
      39.21, 39.29, 43.25, 44.4, 44.41, 44.46, 44.67, 44.86, 44.87, 44.9, 44.92, 45.22, 45.76, 46.53, 52.49, 52.87,
      56.22, 61.09, 71.79, 81.08, 86.09, 95.38, 103.07, 103.28, 103.63, 105.03, 106.16, 106.15, 112.8, 112.81, 112.82,
      113.08, 113.14, 113.17, 113.2, 113.72, 113.95, 121.57, 124.72, 140.24, 141.37,
    ],
    [
      2.73, 10.35, 13.88, 13.9, 15.31, 16.22, 16.69, 17.66, 17.94, 18.07, 18.65, 18.96, 19.3, 19.3, 21.23, 22.34, 23.17,
      23.1, 26.09, 26.62, 26.93, 32.27, 33.21, 33.6, 34, 34.2, 34.95, 35.32, 35.9, 36.49, 38.92, 44.82, 44.77, 44.83,
      45.66, 45.68, 46.8, 47.35, 47.34, 47.53, 48.72, 53.38, 56.15, 62.92, 67.44, 73.12, 80.1, 80.42, 81.06, 85.76,
      86.13, 93.3, 94.43, 98.01, 103.68, 104.32, 104.78, 105.55, 106.19, 106.49, 107.14, 110.12, 113, 115, 114.96,
      116.67, 116.86, 116.87, 116.9, 116.92, 117.33, 117.7, 117.76, 119.71, 119.84, 120.86, 121.84, 121.85, 121.87,
      122.15, 122.14, 123.51, 127.45, 129.81, 129.94, 129.94, 133.09, 134.46, 136.6, 137.79,
    ],
    [
      0.16, 1.9, 6.49, 12.91, 16.62, 16.87, 20.03, 21.28, 22.25, 22.48, 22.47, 22.5, 23.2, 24.27, 24.26, 24.32, 24.34,
      25.17, 25.18, 25.23, 25.96, 26.1, 32, 32.61, 32.61, 33.51, 34.68, 35.05, 34.88, 35.88, 35.95, 37.89, 38.39, 38.31,
      38.74, 41.83, 41.91, 41.93, 43.06, 46.08, 48.77, 50.7, 52.06, 52.34, 53.31, 54.19, 56.3, 57.09, 59.41, 63.67,
      69.27, 72.31, 80.97, 82.87, 88.16, 88.69, 89.94, 91.3, 93.13, 93.23, 98.94, 103.69, 104.33, 106.05, 106.12,
      106.95, 107.11, 107.32, 108.63, 109.39, 110, 115.31, 115.5, 116.04, 116.82, 116.77, 116.83, 117.68, 117.72,
      119.52, 119.5, 120.72, 121.97, 123.43, 125.13, 125.38, 126.86, 138.64,
    ],
    [
      17.11, 17.51, 19.85, 19.86, 20.13, 22.16, 22.29, 24.24, 25.08, 25.1, 25.13, 25.14, 25.33, 25.41, 27, 27.04, 29,
      30.71, 30.79, 31.88, 35.51, 35.81, 36.45, 38.32, 42.61, 43.99, 47.57, 48.7, 55.8, 56.24, 56.6, 60.86, 65.22,
      66.36, 66.88, 67.14, 69.84, 84.44, 88.62, 93.28, 94.47, 94.65, 94.66, 96.25, 97.17, 97.18, 97.23, 99.19, 103.08,
      106.1, 106.68, 107.05, 106.88, 107.8, 108, 108.52, 108.79, 109.73, 115.91, 118.9, 120.77, 122.7, 123.35, 124.06,
      124.34, 125.31, 125.78, 132.18, 132.9, 134.01, 136.61, 139.6, 141.53,
    ],
    [
      17.28, 20.43, 28.05, 28.28, 28.8, 28.83, 28.86, 29.18, 29.19, 29.2, 35.84, 36.97, 37.45, 46.62, 52.96, 55.91,
      69.42, 70.72, 71.26, 90.49, 92.63, 95.47, 96.3, 97.08, 97.1, 97.13, 97.14, 97.33, 97.48, 97.59, 97.6, 99.03,
      102.71, 102.79, 104.66, 109.72, 110.45, 110.46, 119.2, 119.19, 119.57, 120.7, 124.93, 125.3, 127.87, 129.79,
    ],
    [
      23.57, 23.77, 23.79, 28.36, 28.34, 28.37, 28.38, 28.39, 28.4, 28.41, 28.42, 28.43, 28.44, 28.5, 38.09, 38.08,
      62.54, 92.43, 94.76, 100.8, 101.06, 115.98, 128.33, 128.46, 128.83,
    ],
    [5.47, 89.03, 92.56, 100.37, 118.67],
  ],

  // row 9-done
  [
    [0.37, 7.4, 7.55, 15.62, 37.01, 37.04, 37.09, 37.1, 37.14, 37.11, 37.11, 37.15, 50.1, 53.44, 58.62, 82.9],
    [
      4.69, 4.81, 5, 8.59, 8.96, 10.2, 12.45, 14.53, 15.01, 15.54, 16.42, 17.32, 18.39, 21.87, 24.61, 27.56, 27.57,
      27.64, 27.67, 29.14, 33, 36.42, 37.16, 37.93, 41.51, 43.18, 43.2, 43.21, 43.22, 43.23, 43.24, 43.25, 43.28, 43.29,
      43.3, 43.31, 43.32, 43.33, 43.34, 43.35, 43.36, 43.37, 43.38, 43.42, 43.43, 43.47, 43.48, 50.48, 55.27, 57.25,
      58.57, 60.55, 68.73, 68.73, 71.23, 71.23, 71.89, 73.39, 79.98, 79.98, 80.62, 82.78, 85.82, 87.86, 98.77, 99.46,
      102.01, 102.04, 102.11, 116.84, 119.64, 121.8, 122.02, 125.39,
    ],
    [
      0.06, 0.25, 0.59, 3.04, 3.12, 7.21, 7.05, 7.46, 7.61, 10.93, 11.27, 11.23, 13.08, 13.2, 13.71, 13.59, 14.25,
      14.63, 15.47, 16.43, 17.15, 17.8, 17.8, 18.1, 18.63, 18.96, 18.99, 19.23, 21.16, 21.33, 24.31, 24.41, 24.36,
      24.69, 24.82, 24.83, 24.63, 24.9, 27.34, 30.44, 32.56, 33.66, 33.97, 36.48, 36.75, 37.56, 39.88, 42.27, 48.7,
      49.14, 50.61, 50.68, 52.25, 53.93, 56.85, 57.2, 57.52, 58.17, 58.6, 59.44, 60.57, 63.11, 64.04, 67.44, 67.72,
      69.81, 69.72, 71.88, 72.4, 72.34, 72.43, 73.96, 75.64, 78.1, 79.51, 80.01, 80.58, 80.54, 81.31, 82.31, 82.32,
      86.85, 87.69, 87.87, 87.92, 88.37, 89.43, 89.61, 92.56, 92.57, 92.64, 92.65, 92.67, 94.64, 99.03, 99.12, 100.72,
      100.83, 100.91, 101.25, 101.42, 101.79, 102.54, 102.62, 107.17, 107.33, 107.52, 108.18, 108.2, 108.21, 108.22,
      108.23, 108.24, 108.25, 108.28, 108.29, 108.3, 108.31, 108.32, 108.33, 108.34, 108.35, 108.36, 108.37, 108.38,
      108.42, 108.43, 108.47, 108.48, 114.15, 117.21, 122.27, 127.46,
    ],
    [
      0.3, 0.7, 0.75, 0.71, 1.33, 1.72, 2.17, 3.3, 3.52, 4.22, 4.46, 5.82, 6.35, 6.92, 7.02, 7.54, 7.59, 7.62, 7.87,
      7.91, 8.82, 10.4, 11.32, 12.05, 12.66, 12.8, 13.6, 14.06, 15.13, 15.34, 15.53, 15.76, 15.93, 16.01, 16.83, 17.19,
      17.14, 18.32, 18.54, 20.21, 21.47, 21.82, 24.71, 24.67, 24.76, 29.42, 29.87, 30.11, 31.19, 31.26, 31.48, 31.77,
      32.45, 32.46, 32.58, 33.14, 34.65, 34.57, 36, 36.11, 36.11, 36.03, 36.1, 36.8, 36.88, 37.81, 37.89, 39.59, 40.26,
      41.44, 43.12, 43.13, 44.51, 45.42, 47.7, 48.69, 50.54, 50.75, 51.48, 51.8, 51.92, 52.55, 53.22, 54.1, 54.84,
      55.33, 56.7, 56.71, 56.59, 57.11, 57.29, 57.45, 57.64, 58.08, 58.18, 58.77, 59.2, 59.55, 60.26, 60.92, 62.25,
      62.5, 63.33, 64.4, 65.76, 66.29, 66.69, 68.06, 70, 72.12, 72.21, 72.46, 73.03, 73.78, 78.03, 78.61, 78.71, 78.81,
      79.25, 79.63, 81.34, 81.43, 82.08, 82.75, 83.33, 83.63, 86.4, 86.87, 87, 87.27, 87.61, 88.28, 88.79, 88.77, 88.72,
      89.59, 89.41, 89.69, 89.9, 92.34, 93.91, 94.72, 97.1, 98.66, 100.92, 106.8, 107.07, 107.38, 107.75, 108.78,
      113.25, 114.33, 115.61, 119.38, 120.24, 121.85, 122.22, 122.63, 123.17, 123.9, 124.03, 126.47, 126.96, 127.98,
    ],
    [
      0.16, 1.81, 3.39, 4.2, 4.04, 4.87, 5.3, 6.19, 6.11, 8.12, 9.29, 10.26, 10.37, 11.26, 11.18, 11.44, 12.31, 12.73,
      13.18, 13.07, 13.95, 15.52, 17.11, 19.32, 21.46, 21.44, 21.43, 24.19, 24.48, 24.5, 24.52, 24.58, 25.53, 28.5,
      28.93, 29.22, 29.32, 29.74, 29.59, 30.32, 30.92, 30.87, 31.72, 32.06, 32.78, 32.86, 32.86, 32.98, 33.68, 36.05,
      38.28, 38.81, 39.64, 39.92, 40.46, 40.49, 40.48, 40.53, 41.09, 41.96, 42.37, 42.82, 43.82, 45.89, 46.75, 47.39,
      47.48, 49.05, 49.69, 50.05, 49.93, 50.34, 51.28, 52.74, 53.37, 53.71, 54.6, 55.31, 55.37, 55.44, 56.89, 57.1,
      57.4, 57.7, 58.91, 59.35, 62.29, 63.5, 63.58, 63.79, 64.21, 64.45, 66.33, 66.77, 67.12, 69.02, 69.37, 69.89,
      70.81, 70.82, 70.84, 72.41, 72.54, 72.84, 73.2, 74.02, 76.14, 76.4, 78.3, 79.46, 80.34, 81.83, 82.2, 83.32, 83.54,
      85.7, 85.98, 86.28, 86.44, 86.78, 87.38, 87.75, 87.78, 87.9, 88.01, 88.21, 88.55, 88.73, 88.78, 94.42, 95.67,
      95.76, 96.02, 98.32, 99.19, 99.62, 101.12, 101.11, 101.59, 103.72, 103.8, 105.16, 105.95, 106.64, 106.79, 107.77,
      108.17, 109.51, 109.72, 110.42, 112.7, 113.67, 113.78, 116.05, 116.43, 117.4, 117.55, 120.03, 120.63, 122.29,
      122.64, 123.08, 123.18, 124.55, 126.55, 127.04, 127.15, 128.85,
    ],
    [
      0.42, 2.49, 3.45, 3.64, 4.82, 4.92, 5.36, 5.71, 7.14, 7.37, 8.34, 9.47, 10.42, 10.45, 10.6, 11.34, 12.87, 14.31,
      14.33, 14.45, 14.79, 15.3, 17.58, 18.28, 18.49, 19.83, 21.21, 21.36, 22.05, 24.2, 24.28, 26.41, 28.43, 28.81,
      29.51, 30.12, 30.61, 30.85, 30.74, 31.31, 31.78, 31.71, 33.58, 39.27, 39.45, 40.24, 40.66, 41.22, 41.36, 42.3,
      42.79, 44.46, 44.68, 44.94, 44.98, 46.17, 47.66, 47.87, 50.27, 50.53, 50.78, 51.86, 53.98, 53.99, 54.38, 54.8,
      55.16, 55.46, 55.59, 56.65, 57.19, 57.18, 58, 57.9, 58.11, 58.67, 59.54, 60.68, 61.94, 62.52, 62.59, 62.57, 63.78,
      64.07, 64.11, 64.44, 64.39, 64.29, 65.58, 67.51, 67.69, 67.95, 69.63, 69.87, 70.3, 70.6, 70.86, 71.11, 72.63,
      72.69, 74.29, 75.26, 76.07, 76.33, 76.48, 76.48, 76.98, 76.98, 77.1, 77.25, 77.72, 77.95, 78.07, 79.29, 80.52,
      80.61, 81.25, 82.14, 82.11, 83.88, 84.18, 84.16, 86.91, 87.62, 88.36, 88.88, 88.92, 89.01, 89.19, 91.95, 94.32,
      94.79, 95.04, 95.14, 95.94, 96.15, 97.08, 97.22, 98.07, 98.17, 98.78, 98.68, 99.5, 102.47, 103.42, 103.48, 103.5,
      103.52, 103.81, 106.54, 106.56, 106.57, 109.12, 110.89, 112.39, 114.82, 114.93, 115.51, 117.74, 118.71, 119.03,
      118.94, 119.88, 121.81, 121.89, 122.7, 122.5, 123.13, 124.61, 125.27, 125.33, 125.38, 126.99, 127.33, 127.73,
    ],
    [
      1.53, 1.66, 4.83, 5.78, 6.15, 9.07, 11.15, 11.35, 11.43, 12.04, 12.03, 12.39, 13.37, 13.67, 13.84, 14.75, 14.89,
      19.22, 20.65, 20.62, 20.93, 21.2, 26.42, 27.08, 27.37, 29.34, 30.06, 30.34, 30.4, 31.88, 33.09, 34.09, 35.66,
      38.1, 38.41, 38.56, 39.21, 39.28, 39.58, 40.42, 41, 41.13, 45.25, 45.92, 46.57, 46.66, 48.37, 48.75, 48.99, 49.19,
      49.29, 49.39, 49.72, 52.07, 54.22, 55.54, 55.79, 55.88, 59.46, 59.94, 59.91, 59.88, 62.49, 62.73, 63.49, 64.36,
      64.28, 64.97, 65.02, 65.25, 65.5, 65.56, 66.45, 67.21, 67.59, 67.59, 68.45, 69.13, 69.82, 69.92, 70.36, 70.55,
      70.71, 70.76, 70.89, 71.29, 71.41, 72.61, 72.67, 73.16, 73.54, 74.67, 75.45, 76.2, 77.87, 80.3, 82.58, 82.98,
      83.49, 84.83, 86.08, 86.56, 87.67, 88.41, 88.84, 90.11, 90.19, 91.17, 91.89, 91.9, 92, 91.97, 93.34, 95.37, 97.55,
      98.13, 98.58, 103.24, 103.29, 103.33, 106.18, 106.53, 107.79, 109.46, 109.68, 110.81, 110.86, 111.17, 111.99,
      112.24, 112.25, 112.47, 112.66, 112.87, 113.94, 114.7, 114.76, 115.53, 115.78, 116.27, 116.62, 116.6, 116.92,
      118.98, 118.99, 120.09, 120.46, 120.98, 121.08, 121.65, 122.18, 123.11, 124.24, 124.48, 125.87, 126.2, 126.69,
      126.81, 127.57,
    ],
    [
      2.77, 5.73, 5.58, 10.83, 12.57, 13.85, 18, 19.52, 19.53, 19.57, 19.58, 19.62, 19.63, 19.64, 19.65, 19.66, 19.67,
      19.68, 19.69, 19.7, 19.71, 19.72, 19.75, 19.76, 19.77, 19.78, 19.79, 19.8, 19.8, 19.82, 20.67, 20.75, 20.83,
      25.38, 25.46, 26.22, 26.58, 26.4, 26.74, 27.09, 27.28, 28.88, 31.64, 35.33, 35.36, 35.36, 35.43, 35.44, 35.45,
      38.39, 40.15, 40.3, 40.81, 41.15, 45.22, 45.35, 45.69, 45.68, 46.58, 46.69, 47.42, 47.46, 47.88, 47.99, 48.49,
      49.9, 51.83, 52.36, 54.04, 55.6, 55.57, 56.12, 58.19, 58.28, 62.05, 65.19, 65.95, 66.32, 66.8, 69.83, 70.8, 71.15,
      72.85, 75.75, 76.37, 76.5, 76.8, 77.03, 77.39, 77.32, 78.37, 78.86, 79.3, 89.03, 91.28, 92.37, 94.03, 94.34,
      94.63, 95.44, 95.66, 96.76, 97.01, 97.49, 100.66, 103.1, 103.17, 103.18, 103.31, 103.37, 103.69, 105.19, 108.77,
      109.04, 109.01, 109.67, 110.2, 110.85, 111.57, 112.53, 113.75, 114.29, 114.45, 116.9, 117.27, 118.63, 119.39,
      120.54, 120.79, 120.95, 123, 123.67, 125.58, 127.56, 127.87,
    ],
    [
      4.56, 5.98, 6.2, 8.36, 25.89, 25.99, 28.54, 29.23, 42.15, 47.38, 48.02, 54.61, 56.11, 56.77, 70.75, 72.73, 72.73,
      77, 78.53, 84.52, 84.53, 84.57, 84.58, 84.62, 84.63, 84.64, 84.65, 84.66, 84.67, 84.68, 84.69, 84.7, 84.71, 84.72,
      84.75, 84.76, 84.77, 84.78, 84.79, 84.8, 84.82, 90.07, 90.84, 91.58, 98.86, 100.33, 100.35, 100.36, 100.43,
      100.44, 103.39, 106.13, 109.61, 110.66, 110.69, 110.68, 110.61, 111.58, 111.69, 112.46, 112.99, 115.79, 117.8,
      119.04, 119.33, 119.41, 122.98, 123.19, 123.31, 124.1,
    ],
    [22.93, 42.09, 45.1, 90.86, 90.85, 90.89, 90.9, 90.91, 90.96, 90.99, 110.59, 112.38, 120.45, 120.6],
  ],
  // row 10-done
  [
    [0.86, 1.51, 9.27, 9.27, 20.27],
    [
      1.05, 2.86, 3.86, 4.51, 10.84, 16.42, 16.3, 16.3, 17.67, 39.59, 39.72, 39.89, 41.36, 41.35, 42.9, 45.88, 53.79,
      60.36, 67.87, 74.97, 76.18, 88.36, 88.93, 90.89, 91.24, 93.73, 100.28, 101.78, 106.07, 109.02,
    ],
    [
      0.94, 1.97, 2.1, 2.54, 3.7, 4.17, 4.67, 5.74, 8.43, 8.71, 9.51, 11.27, 12.33, 13.63, 14.89, 15.35, 15.96, 17.73,
      18.27, 18.42, 19.16, 19.5, 20.24, 24.06, 28.38, 31.79, 31.8, 35.58, 35.72, 36.64, 36.76, 37.25, 39.4, 39.81,
      40.54, 41.08, 41.38, 42.17, 42.92, 43.15, 44.39, 44.4, 46.39, 46.5, 46.72, 47.28, 47.59, 48.67, 49.19, 56.77,
      57.78, 58.93, 59.12, 59.24, 59.62, 60.3, 60.29, 60.77, 61.46, 61.81, 69.44, 74.3, 74.28, 74.68, 77.17, 77.91,
      77.92, 79.3, 80.12, 83.57, 86, 86.36, 88.77, 89.76, 90.12, 91.08, 91.43, 101.21, 101.5, 104.55, 106.09, 107.27,
      108.24, 108.64, 108.99,
    ],
    [
      0.17, 0.35, 0.69, 0.77, 1.26, 1.47, 1.53, 1.85, 2.48, 2.55, 2.69, 3.15, 3.65, 4.16, 4.33, 4.75, 4.66, 6.61, 7.04,
      9.26, 9.63, 10.96, 11.24, 11.62, 11.89, 12.1, 12.72, 12.97, 13.89, 14.8, 15.57, 16.32, 17.63, 17.78, 17.94, 18.18,
      18.36, 20.41, 20.7, 20.71, 20.91, 20.97, 21.26, 23.86, 24.54, 24.85, 26.22, 26.6, 26.88, 27.05, 27.58, 27.69,
      27.97, 28.49, 29.46, 30.21, 31.84, 32.25, 33.27, 34.09, 34.68, 34.7, 37.45, 37.46, 37.5, 37.69, 37.7, 39.44,
      39.91, 41.13, 41.05, 42.63, 42.82, 43.22, 44.1, 45.98, 46, 46.17, 47.16, 47.6, 48.02, 48.23, 48.42, 48.66, 48.62,
      49.65, 50.93, 51.08, 51.22, 52.63, 53.94, 55.36, 55.61, 56.09, 56.86, 57.45, 57.75, 59.17, 59.34, 59.69, 59.94,
      62.17, 62.3, 63.44, 64.39, 65.75, 67.31, 68.11, 69.87, 69.77, 70.76, 70.93, 71.41, 73.28, 73.79, 74.48, 74.48,
      75.66, 76.14, 76.33, 76.66, 76.87, 77.14, 77.76, 77.87, 78.18, 78.38, 79.32, 80.58, 81.13, 82.03, 86.31, 89.41,
      89.34, 89.49, 89.69, 90.11, 90.14, 91.04, 98, 98.3, 98.31, 98.45, 99.9, 100.17, 100.98, 101.52, 101.75, 102.99,
      103.12, 103.45, 104.67, 105.88, 106.55, 108.33, 108.22, 109.16, 109.34, 112.88, 113.8, 114.03, 115.85, 116,
    ],
    [
      0.22, 0.51, 1.86, 2.04, 2.04, 2.01, 2.61, 4.92, 5.26, 5.55, 5.92, 5.91, 6.99, 7.95, 8.5, 8.55, 8.74, 9.08, 9.06,
      10.82, 11.1, 12.29, 12.4, 12.58, 13.04, 13.65, 14.3, 14.69, 14.79, 15.42, 15.62, 16.03, 16.21, 16.25, 16.44,
      17.55, 17.55, 18, 18.14, 18.17, 18.49, 18.59, 19.75, 22.58, 23.17, 23.62, 24.83, 25.32, 25.76, 25.69, 27.15, 27.4,
      27.42, 27.83, 28.02, 28.52, 29.66, 30.24, 30.59, 30.79, 30.87, 31.86, 32.76, 32.75, 32.86, 32.86, 33.09, 32.97,
      33.16, 33.67, 34.02, 33.99, 35.19, 35.5, 36.53, 36.99, 37.21, 37.52, 37.53, 37.55, 37.56, 37.63, 37.88, 39.33,
      39.69, 39.9, 41.82, 42.09, 42.28, 42.31, 43.02, 43.31, 43.18, 43.47, 43.48, 44.54, 45.41, 45.85, 46.06, 49.28,
      50.72, 50.88, 50.97, 51.16, 51.46, 51.9, 52.18, 52.37, 53.09, 56.1, 56.21, 56.82, 56.92, 58.15, 58.51, 59.16,
      58.99, 59.18, 59.33, 59.74, 61.4, 62.59, 64.86, 64.95, 65.64, 65.86, 66.27, 66.17, 66.33, 67.86, 68.23, 68.23,
      69.83, 70.07, 70.3, 70.67, 70.7, 71.32, 72.78, 73.06, 74.73, 74.7, 74.73, 74.91, 74.92, 76.96, 78.11, 78.33,
      78.46, 78.54, 78.95, 79.1, 79.08, 80.02, 80.37, 80.91, 81.65, 82.09, 81.98, 82.24, 82.46, 83.21, 83.3, 83.54,
      83.64, 83.99, 84.76, 86.97, 87.16, 90.42, 90.45, 92.05, 92.06, 92.08, 92.89, 92.99, 96.05, 96.06, 96.1, 96.29,
      96.3, 96.55, 98.13, 98.58, 99.27, 99.49, 99.29, 99.73, 101.23, 101.89, 102.28, 102.35, 102.38, 102.7, 103.42,
      103.67, 103.86, 104.58, 104.64, 104.6, 104.77, 104.98, 105.08, 105.47, 105.76, 106.44, 106.62, 108.32, 108.7,
      109.07, 111.37, 111.84, 111.85, 112.73, 113.69, 114.71, 115.45,
    ],
    [
      0.88, 0.74, 1.23, 1.48, 2.68, 3.16, 3.88, 4.42, 4.44, 5.44, 5.71, 6.73, 7.05, 7.23, 7.8, 7.42, 8.05, 8.22, 8.59,
      10.54, 11.42, 12.04, 12.89, 13.26, 13.5, 14.36, 14.29, 14.51, 15.06, 15.32, 15.89, 16.64, 16.64, 16.98, 17.26,
      17.58, 18.74, 18.97, 18.65, 18.72, 19.03, 19.07, 19.08, 19.28, 19.61, 22.3, 23.2, 23.27, 23.51, 23.65, 23.68,
      23.75, 23.85, 24.05, 24.51, 24.92, 24.9, 25.2, 25.28, 25.3, 25.8, 25.67, 25.98, 26.46, 26.59, 26.99, 27.52, 28.03,
      28.11, 28.28, 28.6, 28.77, 29.18, 29.43, 29.65, 30.18, 30.54, 30.67, 30.84, 30.85, 30.91, 31.17, 31.28, 31.31,
      31.3, 32.98, 33.14, 33.72, 33.65, 33.7, 34.46, 34.35, 34.96, 34.78, 34.8, 34.92, 35.52, 35.63, 36.11, 36.11,
      36.39, 36.33, 36.85, 37.36, 37.36, 37.79, 38.11, 38.11, 38.13, 38.43, 38.44, 38.46, 38.6, 39.05, 40.35, 40.39,
      40.48, 40.57, 41.18, 41.91, 42.43, 42.95, 43.41, 43.69, 44.19, 44.02, 44.42, 45.45, 45.53, 45.75, 45.78, 46.02,
      45.94, 47.22, 47.86, 48.1, 48.05, 48.85, 49.15, 49.26, 49.3, 49.86, 49.92, 50.06, 50.29, 50.48, 50.68, 50.75,
      50.79, 51.83, 51.88, 51.99, 53.15, 53.34, 54.17, 54.04, 54.84, 54.94, 55.06, 55.85, 55.93, 56.32, 56.74, 57.1,
      58.49, 59.26, 59.52, 59.97, 60.14, 60.31, 60.88, 62.55, 63.32, 63.53, 64.52, 64.72, 64.91, 65.14, 65.28, 65.34,
      65.34, 65.9, 65.94, 66.05, 67.15, 67.34, 67.34, 69.55, 69.37, 69.67, 69.78, 71.18, 71.33, 71.39, 72.25, 72.52,
      72.95, 73.29, 73.62, 74.63, 74.81, 74.83, 74.85, 76.15, 76.6, 76.76, 76.77, 76.88, 77.09, 77.2, 77.42, 78.3,
      79.84, 79.95, 80.07, 80.45, 80.46, 80.87, 81.01, 81.2, 81.66, 82.22, 83.23, 83.92, 83.84, 84.03, 84.36, 84.53,
      84.5, 85.12, 85.55, 86.14, 86.19, 86.62, 87.43, 87.41, 87.64, 88.07, 89.22, 89.53, 89.92, 90.28, 90.3, 90.69,
      91.15, 91.35, 91.45, 91.52, 91.69, 92, 91.93, 91.9, 95.31, 95.59, 95.92, 95.89, 96.12, 96.13, 96.14, 96.23, 96.48,
      97.62, 98.09, 98.22, 99.31, 99.88, 100.14, 100.69, 101.7, 101.94, 102.09, 103.75, 104.66, 105.17, 105.52, 105.48,
      106.38, 107.15, 107.4, 107.76, 108, 108.25, 108.15, 108.47, 109.49, 109.76, 110.78, 111.15, 111.36, 112.04, 112.5,
      112.51, 112.8, 113.57, 113.99, 114.83, 115.33, 115.76, 116.15,
    ],
    [
      0.4, 0.95, 0.97, 1.78, 5.38, 6.13, 6.76, 6.88, 8.58, 9.44, 10.12, 10.22, 10.48, 10.62, 10.6, 10.57, 11.88, 12.5,
      12.82, 12.92, 13.57, 13.97, 14.39, 15.71, 15.93, 15.94, 17.17, 17.04, 18.62, 18.9, 18.91, 19.1, 19.14, 19.15,
      22.21, 23.12, 23.15, 24.75, 25.89, 26.05, 26.36, 26.89, 26.87, 27.65, 28.14, 28.58, 28.59, 29.01, 29, 30.16,
      31.21, 31.66, 31.94, 31.9, 31.99, 32.96, 33.22, 34.54, 34.83, 35.09, 35.3, 35.55, 36.1, 36.96, 36.84, 37.03,
      38.24, 40.47, 42.36, 43.81, 43.88, 44.5, 44.53, 44.71, 45.02, 45.66, 46.01, 46.97, 46.98, 47.34, 47.63, 48.93,
      49.34, 50.34, 50.21, 52.46, 52.61, 53.8, 54.12, 54.09, 55.14, 56.25, 57.21, 57.87, 57.81, 58.38, 58.82, 59.04, 59,
      60.95, 61.01, 61.36, 62.83, 63.02, 63.03, 63.6, 63.74, 64.04, 65.92, 65.84, 65.84, 68.08, 69.14, 70.66, 71.81,
      72.02, 72.18, 72.43, 72.96, 73.11, 73.38, 73.92, 75.51, 75.58, 75.87, 77.57, 77.32, 77.62, 77.64, 77.65, 77.67,
      77.68, 77.94, 78.04, 78.45, 78.51, 78.97, 79.37, 79.66, 79.78, 81.21, 81.8, 81.78, 81.9, 82.04, 82.11, 82.19,
      82.23, 82.34, 82.45, 83.34, 83.52, 83.72, 83.97, 85.93, 86.25, 86.37, 86.41, 86.39, 86.72, 87.33, 87.37, 87.69,
      89.44, 89.51, 90.37, 91.58, 92.62, 92.54, 95.43, 95.45, 95.46, 95.9, 96.39, 97.03, 97.04, 97.06, 97.2, 97.19,
      97.65, 98.23, 98.76, 98.97, 98.95, 98.99, 99.17, 99.6, 99.78, 100.51, 100.41, 101.55, 102.16, 102.62, 102.8,
      103.76, 103.96, 104.23, 104.35, 105.73, 106.12, 106.7, 106.65, 106.99, 107.25, 109.28, 109.29, 109.94, 110.31,
      110.28, 110.48, 111.16, 111.61, 111.77, 111.91, 112.75, 113.16, 113.51, 113.65, 114.46, 115.08,
    ],
    [
      1.39, 2.87, 5.23, 6.04, 6.87, 6.58, 6.98, 7.55, 8.65, 9.01, 9.32, 10.53, 11.46, 12.21, 13.68, 13.45, 15.03, 14.98,
      15.3, 15.72, 15.73, 15.81, 17.34, 22.22, 24.16, 25.09, 25.06, 25.79, 25.71, 25.86, 27.44, 28.36, 29.03, 29.15,
      29.45, 29.86, 30.51, 33.17, 33.48, 35.7, 37.13, 37.44, 38.33, 38.54, 38.63, 39.06, 39.54, 42.27, 42.41, 44.03,
      44.27, 44.75, 45.25, 45.28, 45.09, 47.09, 47.89, 49.45, 50.81, 51.28, 51.76, 53.03, 53.33, 54.18, 55.38, 55.37,
      55.51, 56.88, 57.05, 58.26, 61.29, 61.93, 62.42, 62.43, 62.57, 63.98, 64.11, 64.12, 64.27, 65.06, 65.81, 66.12,
      66.54, 66.58, 66.78, 66.97, 67.18, 67.2, 68.04, 68.75, 68.61, 69.22, 69.2, 69.94, 71.1, 71.54, 71.98, 71.98,
      72.38, 72.88, 74.11, 74.15, 75.29, 75.3, 75.76, 77.5, 77.51, 77.7, 77.75, 77.86, 77.93, 78.85, 79.41, 80.04, 80.5,
      81.93, 82.42, 82.95, 84.65, 84.99, 85.23, 85.3, 86.84, 87.62, 88.09, 88.13, 88.1, 89.03, 89.24, 90.35, 90.66,
      91.34, 94.29, 94.49, 94.5, 94.79, 95.48, 96.84, 97.26, 97.42, 97.57, 98.88, 99.63, 100.07, 100.8, 101.31, 102.22,
      102.48, 103.17, 103.13, 103.15, 103.68, 103.95, 105.12, 105.57, 105.94, 107.82, 107.85, 108.16, 108.59, 110.45,
      111.19, 111.55, 112.28, 112.99, 113.53, 114.18, 114.59, 114.57, 115.03, 115.27, 116.01,
    ],
    [
      5.27, 6.21, 6.31, 6.55, 7.57, 7.93, 8.38, 10.65, 13.7, 13.99, 16.71, 16.94, 16.86, 23.77, 24.12, 24.36, 25.44,
      26.91, 28.19, 34.45, 43.72, 45.76, 51.86, 53.74, 54.36, 54.42, 55.42, 57.92, 59.58, 60.51, 60.78, 63.83, 66.53,
      67.61, 67.92, 68.48, 68.48, 68.7, 69.02, 69.76, 70.09, 70.81, 70.8, 71.56, 71.61, 72.05, 72.28, 72.36, 72.68,
      73.03, 74.66, 75.39, 78.05, 78.44, 79.51, 79.97, 82.4, 85.39, 85.45, 85.29, 87.03, 90.18, 91.14, 92.52, 92.85,
      94.96, 95.7, 96.04, 96.66, 96.93, 97.14, 97.47, 99.24, 99.86, 100.1, 101.07, 102.87, 104.02, 105.69, 106.49,
      106.77, 108.05, 109.46, 109.88, 110.55, 110.98, 111.03, 111.56, 111.5, 112.26, 112.97, 113.46, 113.73, 113.97,
      114.79, 115.98, 116.13,
    ],
    [
      9.13, 13.42, 14.92, 21.47, 22.03, 22.05, 24.31, 26.27, 34.6, 34.89, 40.23, 47.33, 61.41, 61.85, 72.3, 73.61,
      73.84, 75.31, 75.48, 75.48, 75.61, 80.48, 80.48, 85.31, 86.73, 97.53, 98.78, 103.36, 103.51, 104.36, 104.86,
      111.34, 112.34, 112.9, 113.34, 113.87, 115.16,
    ],
    [94.93, 105.93],
  ],
  // row 11-done
  [
    [17.73, 24.14, 58.66],
    [1.38, 3.72, 5.92, 9.4, 15.7, 24.84, 30.42, 39.54, 54.08, 55.97, 56.25, 67.09, 67.09, 87.3, 93.69],
    [
      2.28, 2.93, 4.28, 4.17, 9.71, 11.63, 12.65, 14.84, 14.94, 15.23, 15.22, 16.17, 17.17, 24.94, 25.04, 25.2, 25.99,
      30.61, 34.23, 35.7, 36.32, 37.04, 37.36, 37.36, 37.45, 38.6, 43.53, 43.64, 47.78, 50.69, 50.97, 51.29, 52.13,
      52.76, 56.32, 59.09, 59.52, 65.26, 70.42, 77.3, 77.73, 77.73, 77.87, 80.78, 83.62, 85.02, 87.36, 89.67, 92.3,
      93.95, 94.52, 97.83, 98.49,
    ],
    [
      0.21, 0.68, 0.99, 2.72, 3.05, 3.96, 4.35, 5.34, 5.74, 8.3, 8.42, 9.66, 10.31, 11.22, 12.43, 13.66, 14.45, 15.05,
      17.32, 17.51, 18.29, 18.02, 18.27, 18.38, 19.32, 21.39, 21.79, 21.94, 22.88, 22.91, 22.95, 22.96, 23.48, 23.63,
      24.37, 24.39, 24.93, 25.28, 26.39, 26.71, 29.1, 30.77, 31.09, 30.88, 31.02, 33.39, 33.61, 33.61, 35.11, 35.11,
      38.11, 38.11, 38.16, 38.52, 38.88, 39.46, 39.34, 41.39, 41.48, 41.69, 42.09, 42.04, 42.62, 42.94, 43.43, 45.38,
      47.88, 48.08, 49.11, 53.3, 53.5, 53.52, 54.1, 55.45, 55.56, 55.42, 55.78, 55.94, 57.3, 58.81, 60.53, 61.96, 67.54,
      67.82, 68.43, 69.79, 70.46, 71.05, 74.51, 76.77, 77.48, 77.48, 77.64, 78.21, 78.66, 78.59, 86.49, 88.3, 88.53,
      89.02, 89.12, 89.96, 89.98, 91.93, 91.79, 92.29, 92.38, 93.11, 93.17, 92.95, 93.4, 93.97, 94.7, 95.86, 95.82,
      96.24, 97.01, 97.95,
    ],
    [
      0.23, 0.69, 1.86, 3.15, 3.35, 3.58, 3.71, 4.11, 4.54, 5.42, 5.66, 6.32, 6.27, 7.16, 7.55, 7.74, 8.31, 8.6, 9.96,
      10.21, 10.36, 10.52, 11.57, 12.59, 12.61, 12.53, 13.17, 13.28, 13.58, 14.16, 15.77, 17.25, 17.42, 17.95, 18.83,
      18.92, 19.49, 19.27, 20.7, 21.15, 21.54, 21.69, 21.74, 22.05, 22.04, 22.32, 22.28, 22.57, 22.85, 23.15, 23.08,
      23.16, 23.19, 23.21, 23.23, 23.26, 23.9, 23.98, 24.92, 25.14, 25.26, 26.31, 26.29, 26.8, 27.99, 27.96, 28.13,
      28.49, 28.57, 28.85, 29.63, 29.59, 34.27, 35.15, 35.27, 34.85, 35.42, 36.53, 37.11, 37.11, 37.25, 38.35, 38.96,
      39.18, 39.43, 40.44, 41.35, 41.56, 42.71, 43.06, 43.72, 45.8, 46.42, 46.75, 47.93, 47.95, 48.11, 48.65, 51.59,
      51.48, 53.15, 54.42, 55.55, 56.08, 56.24, 56.56, 57.19, 58.4, 61.13, 61.74, 62.26, 66.21, 66.45, 66.98, 66.98,
      69.57, 69.92, 70.13, 71.52, 71.73, 71.73, 71.84, 72.23, 72.23, 72.84, 74.91, 75.33, 75.48, 75.51, 75.48, 75.53,
      75.55, 75.56, 75.63, 76.08, 76.23, 76.23, 76.72, 76.71, 76.83, 77.53, 82.24, 82.52, 83.19, 83.8, 83.82, 84.05,
      84.27, 86.41, 86.77, 87.71, 89.41, 89.7, 89.94, 90.06, 90.71, 91.67, 92.06, 92.92, 93.12, 93.28, 94.21, 94.33,
      94.32, 95.46, 96.69, 96.51, 97.63, 98.54, 99.58, 100.52, 101.2, 101.4, 101.56, 102.16, 102.62, 103.92,
    ],
    [
      0.28, 0.28, 0.6, 0.59, 0.84, 1.1, 1.72, 1.82, 1.91, 4.27, 5.02, 5.69, 6.59, 7.19, 7.73, 7.59, 8.18, 8.58, 8.83,
      9.7, 10.24, 10.54, 10.97, 11.03, 11.3, 11.75, 12.19, 12.2, 12.58, 13.33, 15.21, 16.03, 16.01, 16.11, 17.34, 18.08,
      18.77, 18.67, 19.33, 19.53, 19.65, 20.16, 20.17, 20.26, 20.78, 20.8, 21.09, 21.1, 21.13, 21.19, 21.31, 21.63,
      22.15, 22.07, 23.87, 24.06, 24.68, 24.73, 24.82, 25.42, 25.92, 25.87, 26.54, 28.53, 28.84, 28.97, 29.28, 29.4,
      29.43, 29.82, 31.15, 31.24, 32.65, 33.76, 34.47, 35.57, 37.44, 39.05, 39.27, 40.15, 40.29, 40.9, 41.55, 42.06,
      42.16, 42.41, 42.42, 42.87, 44.41, 46.39, 46.38, 48.79, 50, 50.15, 51.41, 51.37, 52.27, 52.66, 54.62, 54.84,
      54.91, 56.18, 56.75, 57.61, 57.51, 58.11, 58.01, 59.55, 60.04, 60.42, 61.19, 61.32, 61.42, 62.81, 63.18, 64.17,
      65.32, 66.48, 66.48, 66.57, 66.55, 67.14, 69.09, 69.52, 70.06, 70.69, 70.88, 71.42, 71.55, 72.32, 74.14, 74.47,
      74.85, 74.89, 74.93, 74.87, 75.45, 75.76, 75.79, 75.83, 75.86, 76.5, 78.15, 78.17, 79.4, 80.59, 80.56, 80.73,
      80.73, 80.85, 81.1, 81.08, 82.2, 82.33, 83.59, 83.99, 83.92, 84.29, 84.99, 85.16, 85.1, 87.75, 87.83, 88.32, 88.5,
      88.56, 89.01, 89.71, 90.95, 91.17, 93.14, 93.33, 93.47, 93.83, 93.85, 94.2, 95.62, 96.75, 99.02, 100.28, 101.35,
      101.55, 101.48, 102.18, 102.46, 103.55,
    ],
    [
      0.22, 0.41, 0.82, 1.87, 2.55, 6.05, 6.25, 7.49, 7.57, 7.61, 7.71, 9, 8.97, 9.87, 10.06, 10.45, 11.24, 11.52,
      12.25, 12.82, 13.06, 13.75, 14.01, 14.43, 14.56, 14.64, 15.45, 15.18, 15.71, 16.37, 18.21, 18.04, 18.11, 18.91,
      18.97, 20.04, 20.53, 21, 21.01, 21.41, 21.6, 21.97, 21.88, 21.86, 22.03, 22.12, 22.16, 22.61, 22.47, 22.64, 25.12,
      25.46, 26.51, 26.46, 26.7, 27.32, 27.34, 27.37, 27.38, 27.41, 27.44, 27.75, 27.92, 28.36, 28.56, 28.55, 28.67,
      28.92, 29.06, 28.87, 28.91, 30.06, 30.32, 31.43, 31.84, 31.78, 32.86, 32.86, 33.68, 34.15, 34.11, 34.11, 35.47,
      36.06, 38.01, 39.1, 39.25, 40.39, 40.74, 41.88, 41.9, 42.01, 44, 45.59, 45.84, 46.3, 46.89, 47.02, 47.11, 47.05,
      47.44, 47.47, 48.09, 48.67, 48.87, 49.29, 50.02, 49.95, 50.93, 51, 51.32, 51.57, 52.69, 53.24, 53.63, 53.77, 54.6,
      54.97, 55.2, 56.26, 57, 60.33, 60.74, 61.04, 61.14, 61.61, 61.9, 63.14, 64.23, 64.23, 64.62, 64.39, 65.15, 65.76,
      66.03, 65.97, 66.38, 67.63, 67.65, 67.69, 67.81, 70.51, 70.48, 70.48, 70.96, 71.65, 72.14, 72.98, 72.98, 72.96,
      73.38, 73.55, 73.71, 73.69, 73.79, 74.37, 76.66, 77.78, 78.52, 79.14, 79.33, 81.35, 81.99, 82.04, 82.1, 82.09,
      82.42, 82.93, 83.11, 83.23, 83.55, 83.67, 84.43, 85.12, 85.5, 86.04, 85.97, 86.17, 86.3, 87.17, 87.39, 87.99,
      89.55, 89.57, 89.76, 90.66, 91.41, 91.87, 92.17, 92.82, 93.5, 93.76, 94.37, 95.02, 96.02, 96.01, 96.61, 96.58,
      97.41, 99.27, 99.44, 99.69, 101.14, 101.71, 102.65, 104.11,
    ],
    [
      1.06, 2.16, 2.37, 5.14, 6.63, 7.01, 7.42, 7.51, 7.91, 8.14, 8.27, 8.27, 8.87, 8.88, 9.06, 9.92, 10.02, 10.02,
      10.63, 11.46, 12.49, 13.26, 13.72, 15.49, 17.1, 17.96, 18.93, 19.4, 19.24, 19.38, 21.06, 24.26, 25.67, 25.68,
      26.45, 26.48, 26.96, 26.97, 27.05, 27.12, 27.57, 27.66, 27.67, 27.64, 27.69, 27.72, 29.21, 30.79, 31.1, 32.57,
      33.28, 33.63, 35.31, 36.22, 36.47, 38.18, 38.8, 39.35, 43.84, 44.89, 45.69, 46.01, 46.15, 46.34, 49.02, 49.62,
      50.51, 50.59, 53.93, 53.89, 54.2, 55.86, 56.37, 56.45, 58.1, 59.48, 59.67, 60.09, 60.14, 60.17, 61.85, 61.99,
      62.47, 62.78, 62.96, 63.45, 63.48, 63.77, 63.89, 66.41, 66.67, 66.75, 66.95, 67.66, 67.93, 68.05, 67.78, 70.45,
      71.27, 73.05, 73.59, 73.6, 74.78, 74.7, 74.83, 75.21, 75.07, 76.4, 77.65, 77.63, 77.6, 78.06, 78.28, 78.41, 79.3,
      79.94, 79.97, 80.01, 80.04, 80.05, 80.35, 80.47, 80.87, 81.16, 81.33, 81.54, 81.66, 81.51, 83.32, 83.93, 84.37,
      85.11, 85.55, 85.78, 85.95, 86.48, 88.61, 90.03, 90.61, 90.52, 92.99, 92.68, 93.41, 94.6, 94.44, 94.38, 94.89,
      96.6, 96.91, 97.58, 97.78, 98.66, 98.68, 99.62, 99.71, 100.05, 100.26, 101.31, 101.96, 101.92, 102.42, 103.18,
    ],
    [
      0.98, 6.96, 7.06, 7.34, 7.41, 8.5, 9.05, 9.8, 10.09, 10, 10.91, 10.87, 11.21, 11.36, 12.11, 13.24, 13.22, 14.1,
      14.18, 14.34, 14.9, 16.73, 16.88, 24.61, 24.99, 25.56, 26.43, 26.59, 28.7, 35.38, 35.66, 36.83, 40.02, 44.39,
      45.9, 47.69, 47.91, 48.51, 50.2, 50.43, 50.73, 52.06, 52.24, 53.75, 54.04, 56.23, 59.58, 60.26, 60.58, 60.83,
      61.11, 61.51, 61.81, 62.42, 63.74, 64.68, 65.14, 65.09, 65.09, 65.46, 68.09, 68.09, 68.04, 68.3, 68.48, 68.48,
      69.81, 70.59, 71.17, 71.4, 72.16, 72.51, 72.83, 74.1, 75, 78.27, 78.51, 78.83, 79.57, 79.71, 79.72, 80.17, 80.29,
      80.32, 81.81, 82.31, 82.32, 83.1, 84.82, 85.9, 85.85, 86.06, 90.83, 93.54, 94.9, 96.94, 98.61, 99.45, 99.72,
      99.56, 100.89, 101.32, 101.28, 101.77, 102.01, 102.82, 103.53,
    ],
    [
      4.71, 4.68, 6.97, 8.68, 9.25, 11.69, 13.53, 16.89, 16.89, 19.58, 22.42, 25.33, 25.47, 25.9, 26.04, 26.62, 31.9,
      44.7, 48.93, 50.7, 51.8, 52.51, 52.6, 55.15, 59.56, 60.27, 62.06, 63.62, 65.84, 65.84, 65.75, 66.88, 67.5, 68.46,
      68.7, 69.37, 75.01, 77.21, 78, 78.16, 80.58, 84.8, 87.98, 88.26, 89.61, 89.6, 89.79, 93.49, 95.87, 100.03, 100.75,
      101.46, 102.68, 102.77, 103.3,
    ],
    [11.73, 36.11, 36.11, 63.53, 79.18, 89.78, 91.66, 93.8, 98.33, 98.86, 99.48, 101.45],
    [44.54],
  ],
  // row 12-done
  [
    [11.04, 19.38, 32.31, 40.5, 48.84, 67.44, 80.63],
    [4.17, 9.24, 17.74, 17.89, 20.45, 24.36, 44.78, 56.01, 58.64, 61.1, 65.83, 85.13, 86.43, 88.81],
    [
      1.39, 1.54, 1.67, 2.03, 5.02, 6.58, 8.76, 11.24, 15.79, 17.5, 18.45, 20.5, 22.39, 23.58, 23.78, 26.12, 27.73,
      27.79, 32.75, 38.53, 40.19, 40.88, 57.8, 58.58, 66.27, 68.05, 78.8, 81.48, 81.48, 86.44, 87.11, 87.09, 93.13,
    ],
    [
      0.2, 1.28, 2.64, 2.62, 2.93, 4.99, 7.47, 11.17, 11.19, 12.05, 12.44, 15.5, 15.44, 15.9, 16.04, 16.14, 16.14, 16.3,
      16.3, 17.55, 17.55, 18.22, 22.86, 23.89, 24.5, 25.9, 31.5, 35.44, 36.45, 36.85, 37.97, 39.29, 39.69, 40.06, 40.85,
      41.73, 42.15, 42.32, 44.03, 45.21, 45.46, 46.44, 47.19, 47.74, 48.37, 49.08, 52.62, 58.84, 60.68, 63.39, 65.33,
      65.48, 65.48, 66.95, 67.26, 69.56, 70.6, 80.35, 86.02, 86.56, 86.49, 87.34, 87.8, 88.57, 89.82,
    ],
    [
      2.39, 2.89, 3.72, 4.56, 5.09, 8.35, 9.69, 9.83, 10.93, 11.05, 11.11, 12.02, 13.16, 13.23, 13.4, 14.63, 15.18,
      15.38, 15.64, 16.05, 16.05, 16.55, 16.55, 17.92, 18.52, 18.99, 19.76, 19.6, 19.91, 20.49, 20.88, 22.1, 23.1,
      23.12, 23.63, 24.66, 26.39, 28.33, 28.69, 29.19, 29.57, 29.78, 31.07, 31.34, 31.94, 32.57, 32.95, 32.98, 32.99,
      33.21, 33.33, 35.98, 36.21, 36.81, 37.13, 37.03, 38.01, 39.2, 40.11, 40.11, 40.67, 41.12, 40.91, 41.03, 41.84,
      42.88, 42.92, 43.31, 43.42, 45.92, 47.34, 47.7, 47.98, 48.24, 48.05, 48.29, 48.6, 48.75, 50.24, 50.54, 53.56,
      56.83, 58.52, 58.77, 58.72, 59.97, 61.19, 62.96, 63.1, 63.03, 63.64, 63.68, 66.2, 71.51, 74.06, 74.25, 75.36,
      75.95, 77.8, 79.11, 79.23, 79.23, 82.4, 84.05, 84.45, 84.54, 85.49, 86.1, 86.82, 88.75, 89.35, 89.63, 91.2, 91.91,
      91.88, 92.64,
    ],
    [
      1.22, 1.73, 2.02, 2.8, 5.72, 5.82, 5.89, 6.18, 6.59, 6.93, 8.95, 9.31, 10.24, 10.66, 10.71, 11.07, 11.89, 11.99,
      12.21, 12.23, 12.83, 13.01, 13.32, 16.09, 16.37, 18.3, 18.3, 18.92, 19.85, 20.28, 20.86, 21.89, 21.98, 22.32,
      22.62, 22.81, 24.15, 24.19, 24.21, 25.03, 25.61, 25.72, 26.17, 26.85, 28.15, 29.95, 30.23, 32.25, 32.5, 34.33,
      35.15, 35.39, 35.72, 36.52, 37.21, 37.25, 38.11, 38.11, 38.32, 39.23, 39.19, 40.27, 41.81, 41.85, 42.29, 45.44,
      47.86, 48.01, 48.22, 48.39, 49.06, 49.83, 50.15, 50.21, 50.58, 50.72, 50.79, 52.5, 52.94, 53.06, 53.12, 54.73,
      55.84, 56.18, 56.54, 58.76, 59.72, 62.23, 62.39, 62.61, 62.85, 64.08, 64.37, 64.4, 65.17, 66.18, 68.26, 68.43,
      69.26, 69.55, 71.07, 72.07, 72.39, 73.63, 73.84, 74.14, 77.17, 77.38, 78.67, 79.4, 79.51, 79.52, 80.81, 80.93,
      81.61, 82.39, 83.11, 84.39, 84.56, 85.75, 86.01, 86.38, 87.04, 86.98, 87.97, 88.41, 89.81, 90.74, 91.25, 92.9,
      92.89, 93.64,
    ],
    [
      0.1, 0.16, 2.45, 3.81, 3.78, 3.75, 4.01, 5.22, 5.84, 6.23, 6.37, 6.26, 6.34, 6.75, 6.98, 7.73, 7.74, 8.5, 8.77,
      8.77, 9.44, 9.88, 10.9, 10.87, 11.27, 11.86, 12.04, 12.84, 15.29, 15.37, 15.47, 16.33, 16.68, 16.98, 16.87, 17.1,
      17.44, 18.37, 19.35, 20, 20.57, 21.39, 21.94, 23.47, 26.06, 30.2, 30.47, 31.95, 31.96, 31.97, 32, 32.39, 32.73,
      33.29, 33.29, 33.81, 34.53, 34.73, 34.94, 36.04, 36.29, 37.39, 37.81, 38.21, 38.72, 39.31, 41.63, 42.05, 42.8,
      43.27, 43.63, 44.38, 44.12, 44.74, 47.01, 47.24, 48.08, 49.93, 50.4, 50.68, 51.14, 51.15, 51.84, 52.99, 53.17,
      53.57, 53.89, 54.48, 55.39, 55.81, 56.91, 57.07, 57.93, 58.26, 58.67, 58.47, 59.19, 59.79, 59.83, 59.91, 60.81,
      61.23, 61.24, 61.25, 62.35, 62.73, 63.71, 64.17, 64.51, 64.83, 64.9, 65.04, 66.44, 67.71, 68.21, 69.65, 70.52,
      71.47, 71.81, 73.04, 73.15, 73.2, 73.85, 74.32, 74.83, 75.96, 76.1, 76.22, 76.33, 76.63, 76.52, 76.87, 77.45,
      77.73, 77.73, 77.83, 77.91, 77.79, 78.96, 79.33, 79.85, 80.1, 80.46, 80.26, 80.36, 81.34, 81.93, 82.3, 83.32,
      84.43, 84.7, 85.25, 85.47, 86.22, 86.45, 86.83, 86.97, 86.94, 87.36, 88.01, 89.19, 89.39, 90.71, 92.47, 92.56,
      92.83, 92.82, 93.35, 93.65, 93.79, 93.92,
    ],
    [
      0.71, 1.76, 2.43, 3.32, 3.39, 4.13, 4.13, 4.23, 4.65, 4.93, 6.16, 7.19, 7.45, 8.81, 9.27, 9.27, 10.09, 10.47,
      11.92, 12.39, 12.91, 13.68, 13.69, 13.72, 13.8, 14.53, 14.8, 15.82, 16.03, 17.27, 20.21, 21.47, 21.55, 21.81,
      24.88, 26.66, 26.84, 27.17, 27.68, 28.03, 28.02, 29.13, 30.42, 30.37, 30.59, 30.97, 33.48, 34.72, 36.66, 37.02,
      37.36, 37.36, 38.23, 38.47, 39.72, 40.14, 40.26, 40.7, 41.68, 41.77, 42.62, 43.37, 44.45, 45.34, 45.75, 46.46,
      50.66, 50.91, 51.39, 51.35, 52.14, 53.97, 54.01, 54.88, 54.93, 55.09, 56.06, 55.85, 55.99, 55.95, 56.42, 57.48,
      57.62, 58.87, 59.09, 59.66, 60.28, 60.7, 61.83, 62.97, 63.79, 65.67, 65.86, 67.54, 68.6, 69.01, 70.21, 70.66,
      72.92, 73.45, 73.81, 73.92, 73.97, 74.28, 74.9, 75.94, 76.39, 77.11, 78.97, 79.22, 79.65, 80.37, 80.89, 80.97,
      81.19, 82.13, 82.54, 82.49, 83.89, 84.25, 86.61, 86.9, 87.02, 87.48, 87.38, 88.36, 88.74, 88.73, 89.7, 90.4,
      91.23, 92.39, 92.55, 93.28, 93.21, 93.3, 93.76, 93.68,
    ],
    [
      3.48, 3.85, 5.51, 6.38, 6.79, 7.1, 7.71, 8.66, 8.75, 9.15, 9.14, 10.49, 10.8, 13.06, 12.95, 18.44, 18.95, 18.87,
      19.11, 19.14, 20.35, 22.47, 25.19, 25.86, 26.33, 26.73, 27.38, 28.24, 29.56, 29.52, 30.1, 30.17, 31.06, 33.23,
      34.43, 34.68, 38.04, 39.64, 40.89, 42.39, 42.66, 42.96, 42.93, 45.06, 45.32, 48.98, 49.78, 51.22, 51.36, 52.17,
      52.15, 52.29, 52.44, 53.03, 53.09, 53.65, 53.94, 54, 54.67, 55.19, 55.68, 56.07, 56.39, 56.99, 57.22, 59.99,
      60.22, 60.25, 61.3, 61.63, 61.74, 61.98, 63.42, 63.63, 64.87, 66.72, 68.54, 69.71, 70.29, 72.32, 72.48, 72.48,
      72.93, 73.29, 73.44, 73.32, 73.6, 74.1, 74.21, 74.68, 74.92, 77.56, 77.82, 77.97, 78.02, 78.57, 79.71, 79.8,
      82.09, 82.27, 83.38, 83.56, 84.85, 86.15, 88.11, 88.5, 88.64, 90, 90.31, 91.77, 92.61, 93.32, 93.56, 93.73,
    ],
    [
      1.81, 3.38, 5.86, 6.64, 7.33, 12.85, 13.43, 13.94, 14.69, 19.92, 21.25, 22.91, 23.62, 27.87, 28.08, 28.09, 29.53,
      29.81, 32.21, 32.52, 34.36, 34.36, 40.58, 44.5, 44.83, 45.26, 45.35, 46.09, 46.57, 46.66, 48.1, 50.88, 51.47,
      51.65, 51.57, 52.35, 53.14, 53.51, 53.91, 55.23, 56.35, 56.75, 57.09, 57.76, 61.69, 61.7, 64.26, 67.62, 69.1,
      69.07, 69.19, 69.51, 70.68, 75.65, 75.78, 77.16, 77.84, 80.76, 81.15, 82.03, 88.21, 88.98, 90.27, 90.56, 90.53,
      92.18, 92.17, 92.75, 93.69, 94.06,
    ],
    [
      3.74, 6.09, 6.11, 6.76, 14.4, 17.79, 20.46, 21.86, 24.59, 25.15, 26.04, 30.73, 34.47, 42.64, 43.95, 46.51, 50.94,
      51.13, 52.32, 57.79, 57.78, 59.34, 60.26, 60.45, 67.08, 69.46, 71.63, 71.7, 72.98, 72.98, 74.75, 75.7, 76.84,
      77.41, 81.96, 83.95, 84.44, 86.5, 88.18, 91.46, 91.53, 92.22, 93.06, 94.01,
    ],
    [
      6.77, 8.07, 26.81, 27.41, 29.15, 30.51, 34.56, 37.19, 46.54, 55.7, 69.77, 72.75, 75.31, 75.46, 77.65, 89.03,
      93.88, 93.98,
    ],
    [12.57, 69.7, 73.82, 82.16],
  ],
  // row 13-done
  [
    [33.92],
    [1.09, 19.27, 21.19, 28.72, 30.28, 61.32, 84.58],
    [
      2.28, 2.92, 3.51, 11.55, 15.62, 16.09, 19.05, 19.05, 19.46, 19.57, 20.27, 24.17, 28.73, 29.66, 30.93, 31.8, 32.49,
      35.42, 36.71, 38.6, 44.1, 56.99, 65.04, 67.65, 74.08, 83.16, 83.55,
    ],
    [
      4.12, 9.72, 10, 10.65, 14.47, 15.18, 15.29, 15.71, 15.84, 19.22, 19.15, 19.98, 23.57, 26.29, 26.38, 27.36, 28.8,
      33.98, 34.6, 37.27, 42.22, 42.57, 43.11, 45.25, 48.69, 48.93, 51.06, 57.09, 60.46, 61.92, 61.88, 64.28, 64.57,
      71.98, 71.98, 72.8, 75.94, 82.37, 82.45, 88.12, 88.59,
    ],
    [
      1.6, 3.41, 4.95, 9.13, 9.29, 10.89, 12.95, 13.54, 14.37, 15.86, 17.54, 17.73, 18.71, 19.42, 20.1, 20.43, 21.07,
      21.65, 22.43, 22.88, 23.23, 25.06, 25.47, 25.89, 27.12, 28.15, 29.33, 30.31, 30.66, 30.94, 33.01, 39.83, 40.3,
      40.56, 40.78, 41.42, 41.49, 41.72, 42.17, 42.72, 42.76, 45.18, 45.19, 46.97, 47.66, 47.81, 48.05, 49.12, 49.57,
      57.06, 60.18, 60.23, 62.23, 62.54, 63.04, 63.3, 64.09, 64.09, 64.22, 64.15, 65.07, 64.89, 67.46, 68.57, 73.8,
      74.98, 74.98, 75.3, 75.33, 75.97, 80.56, 81.37, 81.58, 82.3, 82.93, 83.11, 83.02, 83.22, 83.45, 84, 84.06,
    ],
    [
      0.9, 1.82, 3.07, 3.14, 4.16, 4.17, 5.36, 5.71, 6.23, 7.36, 7.42, 9.33, 10.3, 10.36, 10.66, 10.77, 10.88, 10.92,
      11.78, 12.08, 13.46, 15.45, 15.28, 16.26, 16.76, 17.16, 18.17, 18.73, 19.34, 19.76, 21.12, 21.63, 22.25, 22.73,
      23.27, 23.38, 23.71, 25.22, 27.97, 27.87, 28.02, 29.07, 30.06, 29.91, 31.18, 31.57, 32.84, 33.16, 33.74, 35.03,
      35.68, 36.82, 36.9, 37.48, 37.7, 38.03, 38.57, 39.57, 40.1, 40.48, 46.19, 46.55, 47.03, 47.32, 49.95, 51.58, 51.9,
      52.33, 52.69, 52.8, 53.15, 53.53, 53.74, 53.75, 54.13, 54.29, 56.53, 58.8, 59.76, 61.5, 62.68, 63.09, 63.25,
      63.39, 64.42, 70.58, 72.25, 73.92, 74.06, 75.42, 75.57, 75.66, 76, 79.55, 81.3, 81.99, 82.46, 83.26, 83.39, 85.3,
      85.35, 85.78, 86.46, 86.54, 87.18,
    ],
    [
      0.12, 1.72, 2.3, 2.69, 3.39, 4.8, 4.92, 5.6, 5.72, 5.86, 6.26, 7.26, 7.93, 8.26, 8.65, 10.29, 11.25, 11.28, 11.39,
      12.83, 13.03, 13.65, 13.78, 14.44, 14.94, 14.72, 14.77, 15.82, 15.48, 16.21, 16.59, 16.87, 17.09, 17.28, 17.48,
      20.9, 21.03, 21.94, 23.18, 23.52, 24.24, 24.21, 26.57, 26.76, 26.75, 27.17, 28.06, 27.9, 27.93, 28.23, 28.28,
      29.22, 29.4, 29.51, 29.59, 29.77, 30.15, 30.77, 30.79, 31.61, 31.75, 31.86, 32.33, 32.71, 34.61, 34.61, 34.97,
      35.07, 35.28, 35.74, 35.96, 36.04, 36.48, 37.05, 37.77, 38.61, 38.61, 39.16, 39.25, 39.76, 40.14, 40.31, 40.7,
      44.06, 44.5, 45.12, 45.27, 48.14, 49.08, 49.16, 49.17, 49.04, 49.62, 50.93, 51.24, 51.61, 51.67, 51.99, 53.27,
      54.66, 54.78, 54.73, 55.3, 55.36, 56.74, 56.78, 57, 57.14, 57.92, 58.57, 61.82, 62.69, 63.24, 63.65, 64.76, 65.44,
      66.39, 66.54, 66.56, 68.27, 68.38, 68.71, 69.82, 71.92, 72.75, 72.9, 72.87, 73.02, 73.57, 73.82, 74.79, 75.7,
      76.11, 76.57, 77.17, 77.72, 78.03, 79.5, 79.78, 81.63, 82.7, 85.48, 85.4, 86.1, 86.65, 88.52,
    ],
    [
      0.84, 0.92, 1.9, 1.96, 2.52, 3.71, 4.06, 6.37, 6.22, 8.5, 8.88, 10.06, 10.81, 11.43, 12.18, 12.3, 12.32, 13.48,
      13.93, 14.66, 14.98, 15.25, 15.13, 16.08, 16.74, 17.01, 17.37, 17.39, 17.44, 18.18, 19.29, 19.62, 19.73, 20.51,
      21.37, 23.24, 24.72, 26.16, 26.25, 26.32, 26.58, 27.58, 27.5, 28.57, 30.08, 30.59, 30.86, 32.7, 33.34, 33.27,
      33.42, 33.94, 34.73, 35.08, 35.51, 36.39, 37.44, 38.84, 38.83, 39.75, 39.86, 39.86, 40.28, 43.31, 44.77, 47.3,
      47.69, 47.73, 48.84, 48.82, 49.38, 49.39, 50.95, 51.52, 51.65, 52.04, 52.72, 53.03, 54.04, 55.29, 55.67, 56.25,
      56.39, 57.23, 58.78, 59.44, 59.53, 59.94, 59.77, 60.83, 62.53, 62.46, 63.7, 63.79, 65.01, 65.75, 66.03, 66.11,
      67.22, 67.62, 68.52, 68.61, 69.36, 70.19, 70.72, 70.91, 71.47, 71.37, 71.56, 73.06, 73.23, 73.26, 73.23, 73.28,
      73.47, 73.56, 74.22, 74.59, 76.61, 76.51, 76.75, 77.71, 77.3, 82.65, 83.2, 83.61, 84.26, 84.28, 84.45, 85.28,
      85.31, 85.42, 85.7, 88.3,
    ],
    [
      0.89, 1.3, 2.22, 2.7, 3.17, 4.74, 5.54, 6.08, 7.78, 8.25, 12, 12.56, 12.61, 12.74, 13.01, 14.25, 15.6, 15.75,
      21.57, 22.06, 23.58, 24.36, 24.78, 24.91, 26.46, 26.31, 27.24, 27.43, 28.24, 28.51, 29.2, 33.71, 34.26, 34.25,
      34.47, 35.2, 35.31, 35.67, 36.42, 38.05, 39.81, 39.8, 40.68, 40.97, 41.45, 43.68, 43.9, 45.14, 47.52, 48.43,
      48.59, 49, 49.43, 49.88, 50.3, 50.63, 50.75, 51.18, 51.14, 52.32, 52.59, 54.57, 55.54, 56.43, 56.72, 56.82, 56.75,
      58.45, 58.63, 58.93, 60.03, 59.98, 60.13, 62.07, 63.61, 64.29, 64.62, 64.73, 64.73, 67.63, 67.66, 68.87, 69.27,
      69.95, 70.81, 70.84, 72.55, 74.8, 75.19, 75.69, 75.91, 77.23, 77.23, 77.12, 79.11, 81.77, 83.84, 83.83, 83.96,
      84.86, 86.21, 87.76, 88.77, 88.93,
    ],
    [
      0.37, 1.01, 4, 5.07, 4.98, 5.59, 5.7, 7.44, 8.91, 10.12, 12.03, 13.02, 13.21, 17.59, 19.43, 21.41, 21.96, 22.58,
      22.93, 22.81, 22.67, 23.17, 23.78, 23.85, 23.91, 25.54, 25.48, 25.46, 26.42, 26.67, 27.06, 27.34, 27.82, 27.77,
      30.58, 30.45, 35.06, 35.58, 38.88, 39.58, 40.19, 41.03, 43.13, 44.41, 47.7, 48.45, 49.42, 57.34, 57.69, 62.29,
      65.57, 66.81, 67.59, 67.59, 67.9, 68.16, 68.58, 69.29, 70.53, 71.86, 72.14, 72.83, 75.05, 76.35, 77.11, 78.71, 79,
      78.87, 80.03, 82.5, 83.05, 84.59, 85.68, 86.07, 86.44, 86.7, 87.07, 88.54,
    ],
    [
      1.46, 4.77, 4.58, 5.33, 5.63, 5.74, 11.83, 12.06, 12.48, 12.92, 16.02, 16.04, 21.13, 21.35, 23.43, 26.12, 31.94,
      36.94, 37.08, 45.54, 49.91, 52.43, 52.87, 53.31, 53.91, 57.67, 59.2, 64.43, 67.03, 68.78, 68.85, 72.51, 72.71,
      72.82, 72.77, 73.53, 73.38, 76.98, 76.98, 77.35, 78.28, 78.35, 78.3, 83.88, 84.95, 85.67, 86.73, 87.87, 88.29,
    ],
    [
      0.35, 3.33, 4.45, 4.84, 22.96, 26.01, 27.23, 31.01, 34.22, 45.02, 49.4, 51.29, 55.64, 56.2, 59.27, 65.5, 67.81,
      68.43, 71.91, 84.49, 85.72,
    ],
    [0.4, 3.42, 12.2, 57.72, 67.72, 68.73, 68.73, 72.64, 86.57],
    [54.08],
  ],
  // row 14-done
  [
    [4.13, 4.13, 4.19, 66.97],
    [6.64, 18.49, 29.44, 30.4, 41.4],
    [2.78, 7.35, 14.97, 15.72, 25.98, 26.63, 31.44, 37.22, 36.95, 38.32, 38.38, 39.88],
    [0.27, 1.06, 5.8, 5.79, 7.95, 9.2, 26.14, 26.28, 26.81, 33.81, 54.45, 61.03],
    [
      2.88, 3.2, 3.34, 4.24, 4.72, 4.95, 6.36, 7, 8, 8.08, 10.16, 13.98, 16.01, 17.16, 18.7, 19.2, 21.67, 24.12, 25.1,
      25.47, 28.95, 29.39, 30.75, 34.82, 39.57, 43.26, 46.44, 46.8, 49.43, 52.78, 58.14, 59.55, 64.19, 65, 66, 69.48,
      69.48, 69.89, 69.96, 71.53, 73.73, 73.73, 75.27, 75.03, 77.81, 80.84,
    ],
    [
      0.33, 8.86, 8.7, 9.54, 9.67, 12.08, 12.63, 14.51, 14.96, 14.86, 15.04, 15.86, 16.43, 16.51, 23.3, 23.75, 24.93,
      25.15, 25.22, 26.1, 26.22, 26.58, 27.28, 29.45, 29.27, 30.99, 31.22, 31.76, 32.39, 33.97, 35.01, 35.18, 35.52,
      35.74, 35.93, 36.33, 37.23, 42.31, 48.79, 49.08, 52.38, 53.94, 54.54, 56.28, 59.42, 59.73, 59.72, 64.4, 65.82,
      67.43, 67.88, 74.88, 75.72, 75.75, 76.37, 76.47, 76.84,
    ],
    [
      0.22, 1.04, 1.39, 2.53, 2.68, 4.12, 4.62, 4.63, 4.63, 5.24, 5.34, 6.42, 9.84, 10.5, 11.13, 13.37, 13.18, 14.53,
      15.75, 15.92, 18.05, 18.05, 18.76, 19.64, 20.13, 22.58, 24.3, 24.65, 24.95, 25.27, 27.11, 27.73, 28.32, 29.9,
      30.93, 31.72, 32.11, 32.11, 33.35, 34.04, 34.04, 33.9, 34.73, 35.45, 36.97, 37.31, 37.82, 39.22, 41.12, 43.58,
      44.58, 44.73, 47.01, 49.16, 49.7, 49.68, 50.64, 50.82, 53.73, 54.16, 56.35, 56.91, 58.21, 58.49, 59.25, 59.27,
      59.76, 60.09, 61.4, 63.7, 64.45, 66.72, 68.33, 70.5, 71.02, 71.15, 71.55, 72.06, 72.45, 74.58, 77.43, 77.75,
      77.98, 77.98,
    ],
    [
      0.75, 1, 3.42, 3.96, 4.96, 6.7, 6.69, 7.18, 7.55, 8.07, 9.1, 10.21, 11.02, 12.68, 13.17, 14.7, 14.5, 14.99, 15.95,
      19.41, 20.12, 21.88, 22.59, 24.24, 24.84, 25, 25.04, 25.24, 26.49, 28.5, 31.03, 31.15, 31.84, 31.92, 32, 34.4,
      36.57, 36.9, 38.68, 38.56, 38.5, 39.69, 39.84, 41.91, 44.09, 46.73, 47.54, 47.63, 48.16, 50.66, 50.71, 50.72,
      51.03, 51.7, 52.63, 52.86, 53.46, 53.8, 53.63, 55.16, 55.48, 57.57, 58.25, 58.55, 58.81, 60.69, 61.13, 61.85,
      61.77, 65.3, 65.66, 67.54, 67.73, 67.73, 68.04, 70.07, 71.61, 71.94, 72.27, 72.95, 72.76, 75.04, 76.32, 77.51,
      77.64, 78.93, 78.63, 79.02, 80.25, 80.26, 80.88,
    ],
    [
      0.5, 0.82, 0.87, 1.95, 3.48, 4.88, 4.88, 5.44, 6.32, 9.36, 10.77, 10.68, 12.48, 12.83, 13.28, 13.41, 14.24, 15.14,
      18.02, 18.91, 18.89, 20.01, 21.95, 23.13, 24.47, 25.09, 28.35, 30.95, 31.64, 34.1, 35.75, 35.69, 36.66, 41.52,
      41.39, 45.78, 45.96, 46.35, 46.75, 47.03, 47.02, 48.49, 48.5, 49.39, 51.25, 52.27, 53.85, 53.98, 54.61, 54.73,
      54.64, 57.68, 61.95, 62.23, 62.41, 63.09, 65.18, 65.95, 67.04, 67.53, 68.37, 68.41, 68.68, 68.83, 70.16, 72.6,
      72.71, 73.46, 74.27, 75.42, 75.4, 76.59, 77.89, 79.61,
    ],
    [
      0.98, 4.18, 6.19, 6.22, 8.11, 9.47, 11.2, 12.49, 12.65, 14.71, 20.27, 20.58, 20.82, 20.92, 21.7, 23.87, 30.92,
      32.1, 34.16, 34.66, 35.25, 39.18, 40.43, 41.82, 42.48, 43.75, 44.48, 44.82, 44.83, 45.7, 47.05, 47.04, 47.13,
      47.65, 49.72, 50.49, 53.66, 53.78, 54.85, 57.22, 62.46, 63.04, 64.48, 64.48, 64.79, 67.97, 69.97, 70.11, 72.79,
      72.92, 73.77, 73.8, 74.69, 75.09, 74.87, 77.16, 77.45,
    ],
    [
      0.24, 0.51, 6.82, 8.38, 9.45, 23.62, 28.62, 29.52, 42.5, 45.16, 46.07, 49.99, 52.72, 55.21, 55.85, 58.33, 61.32,
      61.27, 61.87, 62.36, 63.2, 63.99, 66.18, 68.62, 70.14, 70.59, 73.34, 77.39, 80,
    ],
    [
      1.63, 1.92, 3.14, 6.12, 12.44, 13.27, 31.08, 33.61, 33.61, 39.85, 43.18, 45.44, 46.19, 60.97, 62.47, 70.78, 70.87,
      74.95,
    ],
    [21.93, 23.1, 41.56, 48.56, 66.48, 66.48, 71.86, 71.58],
    [1.22, 10.76, 42.44],
    [54.31, 75.87, 75.81],
  ],
  // row 15-done
  [
    [9.35],
    [4.85, 18.87, 39.86, 39.86, 60.57, 72.82],
    [25.47, 28.99, 29.05, 30.82, 35.08, 35.16, 67.62],
    [5.21, 19.11, 25.27, 27.35, 30.08, 31.14, 31.42, 35.94, 54.18],
    [
      0.76, 8.66, 10.68, 12.05, 16.62, 22.98, 23.13, 25.74, 34.51, 35.15, 35.68, 36.72, 37.76, 39.23, 39.78, 51.67,
      71.4, 71.73, 71.73,
    ],
    [
      0.68, 1.73, 2.52, 4.3, 5.04, 5.93, 7.13, 8.39, 9.25, 9.07, 9.56, 10.02, 12.15, 21.24, 22.25, 22.81, 23.29, 23.71,
      27.84, 29.7, 29.94, 30.72, 30.67, 31.56, 31.41, 32.75, 42.19, 42.73, 44.3, 45.64, 45.66, 46.06, 48.05, 49.4,
      51.73, 54.94, 58.54, 60.86, 62.77, 62.65, 62.7, 64.15, 65.37, 68.76, 68.83, 72.91,
    ],
    [
      1.06, 1.15, 1.15, 1.88, 3.15, 5.89, 14.06, 16.91, 16.87, 17.36, 18.02, 18.32, 19.1, 19.25, 20.1, 21.58, 21.47,
      24.2, 24.98, 25.99, 27.73, 29.54, 30.23, 30.74, 32.09, 33.29, 33.29, 34.48, 34.67, 34.46, 34.69, 35.48, 40.77,
      45.69, 45.93, 46.65, 46.47, 46.96, 50.08, 51.98, 53.36, 53.24, 54.15, 54.86, 58.14, 60.01, 60.17, 61.62, 61.61,
      62.03, 63.69, 68.96, 68.57, 71.31,
    ],
    [
      0.1, 3.01, 3.8, 6.1, 7.24, 7.26, 8.98, 10.16, 11.39, 16.09, 16.66, 20.72, 23.97, 24.57, 25.24, 28.12, 29.52,
      33.79, 33.79, 34.15, 38.05, 41.04, 44.51, 44.77, 45.61, 45.9, 46.55, 48.48, 50.4, 51.61, 54.27, 54.61, 55.88,
      55.85, 56.09, 57.51, 58.41, 58.97, 58.62, 60.74, 60.71, 60.96, 60.95, 61.4, 63.05, 63.95, 65.13, 65.7, 65.86,
      66.36, 67.32, 67.63, 68.37, 69.23, 69.23, 69.54, 70.82, 71.22, 72.09, 72.88, 73.56,
    ],
    [
      1.93, 2.36, 2.99, 4.66, 5.86, 6.82, 7.1, 7.67, 7.76, 9.75, 10.17, 11.03, 11.85, 12.09, 12.78, 13.04, 13.82, 13.51,
      16.92, 16.84, 17.42, 17.89, 20.89, 22.39, 26.9, 29.02, 29.83, 29.79, 32.36, 32.36, 33.93, 37.54, 37.97, 38.65,
      43.01, 43.17, 45.16, 48.89, 49.81, 51.58, 52.09, 52.25, 55.47, 56, 56.14, 57.66, 59.3, 62.07, 62.6, 66.27, 71.3,
      71.77, 72.36,
    ],
    [
      0.95, 2.7, 3.84, 6.35, 7.33, 9.69, 11.18, 11.34, 19.39, 19.8, 19.8, 20.14, 23.96, 24.78, 25.1, 26.15, 27.11, 27.6,
      28.86, 35.11, 35.11, 37.64, 37.86, 37.86, 38.66, 42.06, 42.86, 43.8, 44.49, 44.5, 45.07, 45.55, 49.24, 50.05,
      50.58, 51.23, 52.3, 52.41, 53.47, 55.37, 55.89, 62.5, 64.03, 64.71, 65.91, 69.65, 70.92, 71.83, 71.65, 72.31,
    ],
    [
      0.05, 2.16, 2.78, 3.53, 5.99, 8.83, 8.97, 10.52, 13.86, 15.51, 16.93, 19.03, 28.87, 28.73, 28.85, 29.01, 29.44,
      37.16, 39.3, 40.05, 41.24, 46.64, 47.09, 48.35, 54.78, 55.95, 57.21, 58.22, 60.15, 61.78, 63.55, 63.24, 64.27,
      64.96, 72.12, 72.41,
    ],
    [
      3.19, 4.15, 5.06, 5.29, 8.43, 10.15, 13.92, 15.62, 17.95, 23.79, 24.89, 25.52, 26.84, 29.06, 30.58, 33.02, 38.29,
      39.24, 45.56, 50.95, 52.96, 57.86, 64.14, 68.7,
    ],
    [9.96, 10.54, 15.36, 23.04, 23.92, 27.08, 29.69, 34.95, 36.86, 36.86, 51.49, 51.42, 55.48, 71.72],
    [1.19, 3.65, 11.07, 22.05, 38.31, 54.46, 63.41, 63.3],
    [5.16, 10.07, 32.94, 46.48, 61.37, 66.91],
    [58.31],
  ],
  // row 16-done
  [
    [21.69, 34.21],
    [34.22],
    [24.05, 45.61],
    [2.97, 26.21, 26.35, 60.13, 60.37, 63.74, 67.52],
    [
      2.21, 7.19, 10.23, 11.31, 20.06, 21.08, 22.36, 24.99, 27.14, 27.83, 30.66, 34.25, 34.39, 41.83, 42.28, 47.59,
      48.28, 48.44, 53.5, 55.79, 58.92, 59.68, 59.75, 60.09, 60.45, 65.16, 65.6,
    ],
    [
      4.16, 4.83, 5.08, 9.39, 11.85, 13.96, 16.4, 24.97, 25.11, 25.96, 27.05, 33.57, 35.37, 36.75, 38.55, 38.87, 43.57,
      47.36, 48.27, 49.52, 51.47, 53.85, 54.21, 55.94, 58.41, 59.58, 63.42, 65.2,
    ],
    [
      0.5, 1.92, 2.78, 3.9, 4.79, 7.5, 9.61, 10.64, 11.2, 16.36, 17.75, 21.75, 23.16, 24.86, 27.26, 31.13, 33.58, 35.8,
      43.59, 44.35, 50.46, 50.37, 51.99, 53.01, 54.49, 57.05, 58.76, 59.63, 61.17, 64.31, 64.9, 65.36, 66.05, 67.6,
      68.37,
    ],
    [
      1.46, 3.805, 3.795, 4.34, 4.93, 5.245, 5.505, 5.805, 7.26, 8.26, 8.37, 9.125, 9.36, 9.365, 10.905, 10.985, 12.29,
      15.265, 16.325, 17.02, 18.13, 18.215, 19.51, 19.875, 20.235, 20.485, 21.14, 23.14, 23.41, 23.62, 24.205, 24.335,
      24.725, 24.975, 25.77, 25.76, 26.555, 26.79, 27.565, 27.81, 30.29, 30.84, 31.88, 33.4, 33.435, 33.54, 34.17,
      35.945, 36.04, 36.655, 36.57, 36.96, 38.735, 38.68, 38.76, 39.92, 40.01, 40.48, 40.83, 40.96, 42.755, 43.405,
      43.575, 44.275, 46.275, 46.605, 46.84, 49.295, 50.585, 51.415, 52.09, 52.56, 53.95, 55.355, 56.365, 56.84, 56.945,
      57.11, 57.795, 59.74, 59.855, 60.79, 63.8, 64.855, 65.18, 65.335, 66.72, 68.17, 69.08, 70.86, 71.205,
    ],
    [
      0.34, 1.81, 2.29, 3.36, 3.605, 3.72, 3.685, 4.32, 6.415, 7.075, 8.675, 9.475, 9.52, 10.465, 10.73, 10.79, 11.075,
      11.135, 12.935, 14.305, 15.68, 16.645, 16.965, 18.045, 18.905, 19.38, 19.775, 20.005, 20.195, 21.07, 21.805,
      22.035, 22.8, 23.115, 23.36, 23.505, 23.585, 23.825, 24.55, 26.94, 27.645, 29.095, 29.43, 31.915, 33.525, 34.04,
      33.94, 34.245, 34.75, 35.48, 36.13, 36.42, 36.73, 37.11, 40.005, 39.995, 40.115, 40.12, 41, 41.085, 42.585,
      46.205, 47.6, 47.695, 48.265, 48.195, 49.01, 50.85, 51.87, 52.215, 53.09, 53.625, 53.81, 54.455, 55.825, 58.04,
      58.11, 59.33, 60.405, 60.925, 61.295, 62.475, 62.55, 63.055, 64.105, 64.26, 64.915, 64.599, 68.1, 68.415, 70.01,
      70.37, 70.745, 71.01, 71.145, 71.32,
    ],
    [
      0.08, 0.86, 1.185, 3.595, 4.24, 4.755, 5.185, 5.395, 6.22, 6.925, 8.805, 8.955, 10.76, 17.825, 20.28, 20.755,
      21.2, 23.025, 23.04, 24.44, 25.84, 25.92, 27.16, 27.72, 28.13, 29.335, 30.13, 30.89, 31.255, 31.57, 32.049, 32.14,
      36.635, 37.765, 39.23, 39.345, 40.485, 41.37, 42.14, 42.43, 43.295, 43.275, 44.45, 45.675, 46.5, 47.08, 47.335,
      47.8, 49.95, 50.185, 50.915, 52.96, 54.655, 55.285, 55.31, 55.455, 55.865, 56.17, 57.36, 57.585, 57.84, 58.065,
      60.23, 60.395, 62.38, 62.75, 63.275, 64.58, 64.905, 65.405, 66.09, 66.095, 66.465, 66.595, 66.605, 67.78, 69.765,
      70.24,
    ],
    [
      0.41, 0.65, 4.995, 5.3, 7.15, 8.73, 10.095, 10.21, 10.745, 11.725, 15.805, 16.765, 17.495, 17.8, 18.105, 20.35,
      21.455, 27.67, 28.535, 31.34, 31.975, 33.22, 35.04, 35.59, 37.955, 37.96, 38.98, 39.12, 39.245, 40.015, 41.745,
      43.545, 45.54, 45.915, 46.7, 46.86, 51, 51.98, 53.455, 53.415, 53.56, 54.425, 55.115, 57.09, 58.39, 58.485,
      58.555, 58.735, 59.02, 59.465, 59.76, 59.88, 62.04, 62.795, 64.39, 64.485, 65.4, 65.66, 67.95, 68.39, 69.63,
      69.96, 70.105, 70.315, 70.905,
    ],
    [
      1.275, 1.36, 2.04, 3.325, 5.55, 5.625, 8.345, 10.04, 11.44, 11.66, 15.86, 16.2, 19.19, 20.095, 21.94, 22.145,
      24.685, 24.915, 25.18, 28.205, 28.315, 28.755, 31.47, 33.635, 34.155, 34.975, 35.14, 37.905, 38.085, 38.13,
      39.225, 39.835, 41.195, 41.25, 43.81, 43.675, 44.3, 44.905, 46.875, 46.9, 48.87, 51.005, 51.755, 51.675, 52.075,
      52.815, 54, 56.41, 56.595, 56.71, 56.82, 63.08, 64.25, 64.355, 64.735, 65.31, 65.95, 69.54, 69.975, 71.105, 71.34,
    ],
    [
      6.655, 6.605, 8.865, 9.825, 14.69, 20.225, 24.805, 26.99, 27.275, 27.93, 28.125, 30.82, 37.595, 37.895, 38.195,
      38.27, 38.375, 39.13, 40.745, 41.515, 41.625, 41.77, 43.08, 44.14, 44.75, 47.33, 50.945, 50.95, 52.59, 53.42,
      55.175, 56.97, 57.255, 57.465, 58.59, 60.17, 61.94, 62.775, 63.83, 64.12, 64.129, 66.84, 67.505, 68.915, 69.425,
      69.94, 70.305, 70.96,
    ],
    [
      1.18, 4.545, 6.64, 10.03, 19.01, 20.085, 23.21, 25.005, 27.945, 29.23, 30.505, 31.05, 32.015, 37.49, 44.34,
      44.885, 44.975, 52.785, 61.36, 61.395, 63.045, 67.43, 71.185,
    ],
    [44.205, 45.7, 46.23, 58.32, 62.32, 66.985],
    [56.675],
    [55.405],
  ],
]
