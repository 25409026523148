import React from 'react'
import { useWheelCompletedBetListener } from 'views/InhouseGame/Wheel/hooks'

const WheelPayoutEventUpdater: React.FC = () => {
  useWheelCompletedBetListener((bet) => {
    const payoutElement = document.getElementById(`wheel-payout-${bet.result}`)

    if (payoutElement) {
      if (payoutElement.style) {
        if (!payoutElement.className.includes('highlight')) {
          payoutElement.className = `${payoutElement.className} highlight`
        }

        const timeout = setTimeout(() => {
          if (payoutElement.className.includes('highlight')) {
            payoutElement.className = payoutElement.className.replaceAll('highlight', '')
          }
        }, 300)

        return () => {
          clearTimeout(timeout)
          if (payoutElement) {
            if (payoutElement.className.includes('highlight')) {
              payoutElement.className = payoutElement.className.replaceAll('highlight', '')
            }
          }
        }
      }
    }
  })

  return null
}

export default WheelPayoutEventUpdater
