import { useCallback } from 'react'
import useIsMounted from './useIsMounted'

const usePaste = () => {
  const isMounted = useIsMounted()

  return useCallback(
    async (callback?: (pastedContent) => void) => {
      if (isMounted && window) {
        const pastedContent = await window.navigator.clipboard.readText()

        if (callback) {
          return callback(pastedContent)
        }
        return pastedContent || ''
      }
    },
    [isMounted],
  )
}

export default usePaste
