import Box from 'components/Box/Box'
import Link from 'components/Link'
import Text from 'components/Text'
import { RouteConfig } from 'config/constants/route'
import { ProfileStatisticGameDetailInfo } from 'config/types/profile'
import Column from 'layout/Components/Column'
import { RowBetween, RowCenter } from 'layout/Components/Row'
import { Trans, useTranslation } from 'react-i18next'
import { Icons } from 'svgs'
import { colors } from 'theme/colors'
import PlayedGameList from './PlayedGameList'

const PlayedGames: React.FC<{
  isOtherUser?: boolean
  playedGameCount: number
  mostWinGameInfoes: ProfileStatisticGameDetailInfo[]
  mostWageredGameInfoes: ProfileStatisticGameDetailInfo[]
}> = ({ isOtherUser, playedGameCount = 0, mostWageredGameInfoes, mostWinGameInfoes }) => {
  const { t } = useTranslation()

  return (
    <Column>
      <RowBetween>
        <Text fontSize="14px" color="textTertiary" fontWeight={600} lineHeight="normal" letterSpacing={-0.28}>
          <Trans>Played games</Trans>{' '}
          <Text
            fontSize="inherit"
            fontWeight="inherit"
            lineHeight="normal"
            color={playedGameCount ? 'inherit' : 'textAlt1'}
            as="span"
          >
            ({playedGameCount})
          </Text>
        </Text>

        {!!playedGameCount && !isOtherUser && (
          <Link href={RouteConfig.RecentGames}>
            <RowCenter>
              <Text
                fontSize="14px"
                fontWeight={400}
                lineHeight="normal"
                letterSpacing={-0.28}
                color="textAlt1"
                mr="3px"
              >
                <Trans>View list</Trans>
              </Text>
              <Icons.ChevronRightIcon fill={colors.textAlt1} width="10px" height="12px" />
            </RowCenter>
          </Link>
        )}
      </RowBetween>

      {!mostWageredGameInfoes?.length && !mostWageredGameInfoes?.length && (
        <RowBetween mt="12px">
          <Text fontSize="14px" color="textTertiary" fontWeight={400} lineHeight="normal" letterSpacing={-0.28}>
            {isOtherUser ? (
              <Trans>This player haven't played any game</Trans>
            ) : (
              <Trans>You haven't played any game</Trans>
            )}
          </Text>
          {!isOtherUser && (
            <Link href={RouteConfig.Games}>
              <Text color="primary" mr="4px" fontSize="14px" fontWeight={400} lineHeight="normal">
                <Trans>Play now</Trans>
              </Text>
              <Icons.ChevronRightIcon width="10px" height="10px" fill={colors.primary} />
            </Link>
          )}
        </RowBetween>
      )}

      <PlayedGameList title={t('Most wagered games')} games={mostWageredGameInfoes} type="MostWagered" />
      {!!mostWageredGameInfoes?.length && (
        <Box width="100%" height="1px" background={colors.modalHeaderBackground} my="6px" />
      )}
      <PlayedGameList title={t('Most win games')} games={mostWinGameInfoes} type="MostWin" />
    </Column>
  )
}

export default PlayedGames
