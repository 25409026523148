import { useVariableLoadedListener } from 'hooks/useVariableLoadedListener'
import { useEffect, useMemo, useRef } from 'react'
import { gravityPlinko } from '../Plinko/components/GameBoard/config'

export const useGlobalMatter = (): any => {
  const Matter = useVariableLoadedListener(() => window.Matter)

  return useMemo(() => {
    return Matter
  }, [Matter])
}

export const useMatter = (): {
  Matter: any
  World: any
  engine: any
  Engine: any
  world: any
  fps: number // only 60 or 120
  updateFPSLockStatus: (isPlay: boolean) => void
} => {
  const Matter = useGlobalMatter()
  const refFps = useRef(60)
  const refIsFPSLocked = useRef(false)

  const { World, engine, Engine, world, runner } = useMemo(() => {
    if (!Matter) return {}

    const Engine = Matter.Engine
    const World = Matter.World
    const Runner = Matter.Runner
    const Render = Matter.Render

    Matter.Common._seed = 12345678
    const engine = Engine.create()

    const world = engine.world
    engine.world.gravity.y = gravityPlinko

    const runner = Runner.create({
      delta: 1000 / refFps.current,
      deltaMin: 1000 / refFps.current,
      deltaMax: 1000 / refFps.current,
      isFixed: true,
    })

    Runner.run(runner, engine)

    return {
      Engine,
      World,
      world,
      engine,
      Runner,
      runner,
      Render,
    }
  }, [Matter])

  useEffect(() => {
    if (engine) {
      Matter.Events.on(runner, 'tick', () => {
        const fps = runner.fps >= 100 ? 120 : 60
        if (fps !== refFps.current && !refIsFPSLocked.current) {
          refFps.current = fps
          runner.delta = 1000 / refFps.current
          runner.deltaMin = 1000 / refFps.current
          runner.deltaMax = 1000 / refFps.current
        }
      })
    }
  }, [engine])

  useEffect(() => {
    return () => {
      if (World && Engine) {
        World.clear(engine.world, true)
        Engine.clear(engine)
      }
    }
  }, [])

  const updateFPSLockStatus = (isLock?: boolean) => {
    refIsFPSLocked.current = isLock
  }

  return useMemo(() => {
    return {
      Matter,
      World,
      engine,
      Engine,
      world,
      updateFPSLockStatus,
      fps: refFps.current,
    }
  }, [World, engine, Engine, world, refFps])
}
