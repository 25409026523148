import { AuthMethod } from 'config/constants/auth'
import { useCallback } from 'react'
import Authentication from 'views/Authentication'
import { AuthModalPageEnums, AuthModalMethodEnums } from 'views/Authentication/types'
import useModal from './useModal'
import useSuggestedLogin from './useSuggestedLogin'

const useAuthenticationModal = () => {
  const { previousAuthBy } = useSuggestedLogin()
  const [onPresentModal, onDismissModal] = useModal(Authentication)

  const handlePresentModal = useCallback(
    ({ page, method }: { page?: AuthModalPageEnums; method?: AuthModalMethodEnums } = {}) => {
      const previousLoginMethod =
        previousAuthBy === AuthMethod.Email ? AuthModalMethodEnums.TRADITIONAL : AuthModalMethodEnums.WEB3
      const loginMethod = method || previousLoginMethod || AuthModalMethodEnums.WEB3
      onPresentModal({ page, method: loginMethod })
    },
    [previousAuthBy],
  )

  return [handlePresentModal, onDismissModal]
}

export default useAuthenticationModal
