import { ChainIdEnum } from 'config/constants/network'
import { FiatCurrencyCountryCodes } from 'config/constants/payment'
import tokens, { HUSD_TOKEN } from 'config/constants/tokens'
import { UsableFiatCurrencies } from 'config/constants/tokens/fiat'
import { Token } from 'config/types'
import { useMemo } from 'react'
import { useAppSelector } from 'state'
import { useClientIpInfo, useFiatCurrencies, useListNetworks } from 'state/app/hooks'
import { useAuth } from 'state/auth/hooks'

export const useTokenSelected = (): Token => {
  const { hasSession } = useAuth()
  const selectToken = useAppSelector((state) => state.session.selectToken)
  const tokenInfos = useAppSelector((state) => state.app.tokenInfoes)

  const selectedToken = selectToken && tokens[selectToken.network] && tokens[selectToken.network][selectToken.token]

  return useMemo(() => {
    const selectedTokenInfo =
      selectedToken &&
      tokenInfos.find((info) => info.network === selectedToken.network && info.token === selectedToken.code)

    return !hasSession ? HUSD_TOKEN : (selectedTokenInfo && selectedToken) || HUSD_TOKEN
  }, [hasSession, selectedToken, tokenInfos])
}

export const useTokenSelectedExcludeFiat = () => {
  const token = useTokenSelected()
  const networks = useListNetworks()
  const alternativeFiatCurrency =
    tokens[
      networks.reduce(
        (chainId: ChainIdEnum, networks) =>
          [ChainIdEnum.BNB, ChainIdEnum.BNB_TESTNET].includes(networks.chainId as unknown as ChainIdEnum)
            ? (networks.chainId as unknown as ChainIdEnum)
            : chainId,
        null,
      ) as any
    ]['BNB']

  return useMemo(() => {
    if (UsableFiatCurrencies.includes(token.code)) return alternativeFiatCurrency
    return token
  }, [token, alternativeFiatCurrency])
}

export const usePaymentSelectedFiatCurrency = () => {
  const { registeredCountry } = useClientIpInfo()
  const fiatCurrencies = useFiatCurrencies()
  const userLatestSelectedCurrency = useAppSelector((state) => state.session.selectLatestFiatCurrency)

  return useMemo(() => {
    const defaultCurrency = fiatCurrencies[0]
    if (userLatestSelectedCurrency?.token) {
      return tokens[ChainIdEnum.FIAT][userLatestSelectedCurrency.token]
    }
    if (registeredCountry) {
      const userCountryCurrency = fiatCurrencies.find(
        (fiat) => FiatCurrencyCountryCodes[fiat.code] === registeredCountry,
      )
      return userCountryCurrency || defaultCurrency
    }

    return defaultCurrency
  }, [userLatestSelectedCurrency, registeredCountry, fiatCurrencies])
}

