import {
  BitKeepWalletAdapter,
  BitKeepWalletName,
  CloverWalletAdapter,
  CloverWalletName,
  Coin98WalletAdapter,
  Coin98WalletName,
  MathWalletAdapter,
  MathWalletName,
  PhantomWalletAdapter,
  PhantomWalletName,
  SafePalWalletAdapter,
  SafePalWalletName,
  SlopeWalletAdapter,
  SlopeWalletName,
  SolflareWalletAdapter,
  SolflareWalletName,
  SolflareWebWalletAdapter,
  SolflareWebWalletName,
  SolletExtensionWalletAdapter,
  SolletExtensionWalletName,
  SolletWalletAdapter,
  SolletWalletName,
} from '@solana/wallet-adapter-wallets'
import { WalletInfo, WalletType } from 'config/types/wallet'
import { ConnectorNameEnum } from 'packages/wagmi/types'
import { connectorsByName } from 'packages/wagmi/wagmi'

import {
  isBinanceWalletInstalled,
  isBitKeepInstalled,
  isCoin98Installed,
  isMathWalletInstalled,
  isMetamaskInstalled,
  isSafePalInstalled,
  isTokenPocketInstalled,
  isTrustWalletInstalled,
} from './validate-wallet'

export const createQrCode = async ({ connect }: { connect: (connector: any) => void }): Promise<string> => {
  return new Promise<string>((resolve) => {
    const connector = connectorsByName[ConnectorNameEnum.WalletConnectNoQrCode]

    connector.on('message', (message: { type: string; data?: unknown }) => {
      if (message.type === 'display_uri') {
        resolve(message.data as string)
      }
    })
    connect(connector)
  })
}

export const WALLET_INFO: WalletInfo[] = [
  // if update anything for this array, please also update for utils trackingWallet
  {
    name: 'Metamask',
    icon: 'MetamaskIcon',
    adapter: connectorsByName[ConnectorNameEnum.Injected],
    type: WalletType.EVM,
    get installed() {
      return isMetamaskInstalled()
    },
    options: {
      downloadLink: 'https://metamask.io/download/',
    },
  },
  {
    name: 'WalletConnect',
    icon: 'WalletConnect',
    adapter: connectorsByName[ConnectorNameEnum.WalletConnect],
    type: WalletType.EVM,
    installed: false,
  },
  {
    name: 'TrustWallet',
    icon: 'TrustWallet',
    adapter: connectorsByName[ConnectorNameEnum.TrustWallet],
    type: WalletType.EVM,
    get installed() {
      return isTrustWalletInstalled()
    },
    options: {
      downloadLink: 'https://trustwallet.com/',
    },
  },
  {
    name: 'MathWallet',
    icon: 'MathWallet',
    adapter: connectorsByName[ConnectorNameEnum.Injected],
    type: WalletType.EVM,
    get installed() {
      return isMathWalletInstalled()
    },
    options: {
      downloadLink: 'https://mathwallet.org/en-us/',
    },
  },
  {
    name: 'SafePal',
    icon: 'SafePal',
    adapter: connectorsByName[ConnectorNameEnum.Injected],
    type: WalletType.EVM,
    get installed() {
      return isSafePalInstalled()
    },
    options: {
      downloadLink: 'https://www.safepal.com/en/extension',
    },
  },
  {
    name: 'Binance Wallet',
    icon: 'BSCWallet',
    adapter: connectorsByName[ConnectorNameEnum.BSC],
    type: WalletType.BSC,
    get installed() {
      return isBinanceWalletInstalled()
    },
    options: {
      downloadLink: 'https://www.bnbchain.org/en/binance-wallet',
    },
  },
  {
    name: 'Coin98',
    icon: 'Coin98',
    adapter: connectorsByName[ConnectorNameEnum.Injected],
    type: WalletType.EVM,
    get installed() {
      return isCoin98Installed()
    },
    options: {
      downloadLink: 'https://coin98.com/wallet',
    },
  },
  {
    name: 'TokenPocket',
    icon: 'TokenPocket',
    adapter: connectorsByName[ConnectorNameEnum.Injected],
    type: WalletType.EVM,
    get installed() {
      return isTokenPocketInstalled()
    },
    options: {
      downloadLink: 'https://www.tokenpocket.pro/en/download/app',
    },
  },

  {
    name: 'BitKeep',
    icon: 'BitKeepIcon',
    adapter: connectorsByName[ConnectorNameEnum.Injected],
    type: WalletType.EVM,
    get installed() {
      return isBitKeepInstalled()
    },
    options: {
      downloadLink: 'https://bitkeep.com/en/download',
    },
  },
  {
    name: PhantomWalletName,
    adapter: new PhantomWalletAdapter(),
    icon: 'PhantomWallet',
    type: WalletType.SOL,
    installed: true,
  },
  {
    name: SolletWalletName,
    adapter: new SolletWalletAdapter({ provider: 'https://www.sollet.io' }),
    icon: 'SolletWallet',
    type: WalletType.SOL,
    installed: true,
  },
  {
    name: SolflareWalletName,
    adapter: new SolflareWalletAdapter(),
    icon: 'SolflareWallet',
    type: WalletType.SOL,
    installed: true,
  },

  {
    name: SlopeWalletName,
    adapter: new SlopeWalletAdapter(),
    icon: 'SlopeWallet',
    type: WalletType.SOL,
    installed: true,
  },

  {
    name: SolletExtensionWalletName,
    adapter: new SolletExtensionWalletAdapter({
      provider: typeof window === 'undefined' ? null : (window as any).sollet,
    }),
    icon: 'SolletWallet',
    type: WalletType.SOL,
    installed: true,
  },

  {
    name: SolflareWebWalletName,
    adapter: new SolflareWebWalletAdapter(),
    icon: 'SolflareWallet',
    type: WalletType.SOL,
    installed: true,
  },
  {
    name: `${BitKeepWalletName} (Solana)`,
    adapter: new BitKeepWalletAdapter(),
    icon: 'BitKeepIcon',
    type: WalletType.SOL,
    installed: true,
  },
  {
    name: CloverWalletName,
    adapter: new CloverWalletAdapter(),
    icon: 'CloverWallet',
    type: WalletType.SOL,
    installed: true,
  },
  {
    name: `${Coin98WalletName} (Solana)`,
    adapter: new Coin98WalletAdapter(),
    icon: 'Coin98',
    type: WalletType.SOL,
    installed: true,
  },
  {
    name: `${MathWalletName} (Solana)`,
    adapter: new MathWalletAdapter(),
    icon: 'MathWallet',
    type: WalletType.SOL,
    installed: true,
  },
  {
    name: `${SafePalWalletName} (Solana)`,
    adapter: new SafePalWalletAdapter(),
    icon: 'SafePal',
    type: WalletType.SOL,
    installed: true,
  },
]
