/* eslint-disable no-unused-expressions */
import { Howl } from 'howler'

export class HowlMapper {
  private _spinClickSoundPlayerInstance = null
  private _spinPlaySoundPlayerInstance = null
  private _spinQuickPlaySoundPlayerInstance = null
  private _spinWinSoundPlayerInstance = null

  private _plinkoPlaySoundInstance = null
  private _plinkoCompletedSoundInstance = null

  private _limboPlayingSoundInstance = null
  private _limboWinSoundInstance = null

  private _diceTickSoundInstance = null
  private _diceWinSoundInstance = null
  private _dicePlayingSoundInstance = null

  private _mineDiamondSoundInstance = null
  private _mineMineSoundInstance = null
  private _mineBlockSelectSoundInstance = null

  public get SpinClickSoundPlayer() {
    if (!this._spinClickSoundPlayerInstance) {
      this._spinClickSoundPlayerInstance = new Howl({ src: '/audio/luckyspin/click.mp3' })
    }

    return this._spinClickSoundPlayerInstance
  }

  public get SpinPlaySoundPlayer() {
    if (!this._spinPlaySoundPlayerInstance) {
      this._spinPlaySoundPlayerInstance = new Howl({ src: '/audio/luckyspin/play.mp3' })
    }

    return this._spinPlaySoundPlayerInstance
  }

  public get SpinQuickPlaySoundPlayer() {
    if (!this._spinQuickPlaySoundPlayerInstance) {
      this._spinQuickPlaySoundPlayerInstance = new Howl({ src: '/audio/luckyspin/quick-play.mp3' })
    }

    return this._spinQuickPlaySoundPlayerInstance
  }

  public get SpinWinSoundPlayer() {
    if (!this._spinWinSoundPlayerInstance) {
      this._spinWinSoundPlayerInstance = new Howl({ src: '/audio/luckyspin/win.mp3' })
    }

    return this._spinWinSoundPlayerInstance
  }

  public get InhouseGameBetPlay() {
    if (!this._plinkoPlaySoundInstance) {
      this._plinkoPlaySoundInstance = new Howl({ src: '/audio/plinko/play.mp3' })
    }

    return this._plinkoPlaySoundInstance
  }

  public get LimboGameBetPlaying() {
    if (!this._limboPlayingSoundInstance) {
      this._limboPlayingSoundInstance = new Howl({ src: '/audio/limbo/playing.mp3' })
    }

    return this._limboPlayingSoundInstance
  }

  public get LimboGameBetWin() {
    if (!this._limboWinSoundInstance) {
      this._limboWinSoundInstance = new Howl({ src: '/audio/limbo/win.mp3' })
    }

    return this._limboWinSoundInstance
  }

  public get PlinkoCompleted() {
    if (!this._plinkoCompletedSoundInstance) {
      this._plinkoCompletedSoundInstance = new Howl({ src: '/audio/plinko/completed.mp3' })
    }

    return this._plinkoCompletedSoundInstance
  }

  public get DiceTick() {
    if (!this._diceTickSoundInstance) {
      this._diceTickSoundInstance = new Howl({ src: '/audio/dice/tick.mp3' })
    }

    return this._diceTickSoundInstance
  }

  public get DicePlaying() {
    if (!this._dicePlayingSoundInstance) {
      this._dicePlayingSoundInstance = new Howl({ src: '/audio/dice/playing.mp3' })
    }

    return this._dicePlayingSoundInstance
  }

  public get DiceWin() {
    if (!this._diceWinSoundInstance) {
      this._diceWinSoundInstance = new Howl({ src: '/audio/dice/win.mp3' })
    }

    return this._diceWinSoundInstance
  }

  public get WheelWin() {
    return this.LimboGameBetWin
  }

  public get WheelPlaying() {
    return this.SpinQuickPlaySoundPlayer
  }

  public get MineDiamond() {
    if (!this._mineDiamondSoundInstance) {
      this._mineDiamondSoundInstance = new Howl({ src: '/audio/mines/diamond.mp3' })
    }

    return this._mineDiamondSoundInstance
  }

  public get MineEffect() {
    if (!this._mineMineSoundInstance) {
      this._mineMineSoundInstance = new Howl({ src: '/audio/mines/mine.mp3' })
    }

    return this._mineMineSoundInstance
  }

  public get MineWin() {
    return this.LimboGameBetWin
  }

  public get MineBlockSelect() {
    if (!this._mineBlockSelectSoundInstance) {
      this._mineBlockSelectSoundInstance = new Howl({ src: '/audio/mines/selected.mp3' })
    }

    return this._mineBlockSelectSoundInstance
  }

  public requestAllSpinAudio() {
    this.SpinWinSoundPlayer
    this.SpinPlaySoundPlayer
    this.SpinClickSoundPlayer
    this.SpinQuickPlaySoundPlayer
  }

  public clearAllSpinAudio() {
    this.SpinClickSoundPlayer?.stop()
    this.SpinPlaySoundPlayer?.stop()
    this.SpinWinSoundPlayer?.stop()
    this.SpinQuickPlaySoundPlayer?.stop()
  }
}

export const howlMapper = new HowlMapper()
