import Box from 'components/Box/Box'
import Image from 'components/Image'
import { ModalProps } from 'components/Modal'
import Tab from 'components/Tab'
import Text from 'components/Text'
import { DailyRewardPackageCode } from 'config/constants/dailyReward'
import { DailyRewardPackage } from 'config/types/bonus/DailyReward'
import useMatchBreakpoints from 'hooks/useMatchBreakpoints'
import { ColumnCenter } from 'layout/Components/Column'
import { RowBetween, RowCenter } from 'layout/Components/Row'
import React, { useMemo, useState } from 'react'
import { Trans } from 'react-i18next'
import { useDailyRewardCampaign, useTodayRewards } from 'state/bonus/hooks/dailyReward'
import theme from 'theme'
import { colors } from 'theme/colors'
import { formatDisplayDate } from 'utils/dateHelper'
import { TransactionWrapperModal } from 'views/Profile/Transactions/components/styled'
import DailyCheckinInfomation from './component/DailyCheckinInfomation'
import DailyCheckinRewards from './DailyCheckinRewards'

const DailyCheckinModal: React.FC<ModalProps> = ({ onDismiss }) => {
  const { campaign } = useDailyRewardCampaign()

  const { currentRewards } = useTodayRewards()

  const parsedPackages = useMemo((): (DailyRewardPackage & { isWaitingClaim: boolean })[] => {
    return campaign?.packages.map((item) => {
      const isCompletedPackages = item.rewards[item.rewards.length - 1]?.userData?.isClaimed

      return {
        ...item,
        isWaitingClaim: isCompletedPackages
          ? false
          : currentRewards.find((reward) => reward.packageCode === item.code).isWaitingClaim,
      }
    })
  }, [currentRewards, campaign])

  const selectedPackageWaitingClaim = useMemo(() => {
    return (
      parsedPackages &&
      (parsedPackages.find((item) => item.isWaitingClaim)?.code ||
        parsedPackages.find((item) => !item.isTodayClaimed)?.code ||
        parsedPackages[0].code)
    )
  }, [parsedPackages])

  const [selectedPackageCode, setSelectedPackageCode] = useState<DailyRewardPackageCode>(selectedPackageWaitingClaim)

  const rewardNumberOfPackage = useMemo(() => {
    return campaign?.packages.find((item) => item.code === selectedPackageCode).rewards.length
  }, [selectedPackageCode])

  const { isMobile } = useMatchBreakpoints()

  return (
    <TransactionWrapperModal
      title=""
      onDismiss={onDismiss}
      p="0px 24px 24px 24px"
      minWidth={['', '', '560px !important']}
      background={theme.colors.cardBackground}
      overflow="unset !important"
    >
      <Box>
        <ColumnCenter>
          <DailyCheckinInfomation
            rewardNumberOfPackage={rewardNumberOfPackage}
            finishTime={formatDisplayDate(new Date((campaign?.finishAt || 0) * 1000))}
          />

          <Box
            mt="24px"
            p={['4px', '4px', '8px', '8px']}
            background={theme.colors.backgroundAlt5}
            width="100%"
            borderRadius={theme.radii.tiny}
          >
            <RowCenter>
              {parsedPackages?.map((item, index) => {
                return (
                  <React.Fragment key={item.code}>
                    <Tab
                      value={item.code}
                      onTagChanged={() => {
                        setSelectedPackageCode(item.code)
                      }}
                      width={[
                        'calc((100% - 5px) / 2)',
                        'calc((100% - 5px) / 2)',
                        'calc((100% - 17px) / 2)',
                        'calc((100% - 17px) / 2)',
                      ]}
                    >
                      <ColumnCenter
                        background={selectedPackageCode === item.code ? 'rgba(255, 255, 255, 0.08)' : 'none'}
                        minHeight={isMobile ? '35px' : '59px !important'}
                        width="100%"
                        px={['4px', '4px', '12px', '12px']}
                        py="8px"
                        borderRadius={theme.radii.small}
                      >
                        <RowBetween>
                          {!isMobile && (
                            <Box width={28} height={28}>
                              <Image src={item.thumnail} width={100} height={100} />
                            </Box>
                          )}
                          <Text
                            fontSize={['12px', '12px', '14px', '14px']}
                            fontWeight={600}
                            ml="4px"
                            textTransform="uppercase"
                          >
                            <Trans>{item.name}</Trans>
                          </Text>
                          {item.isWaitingClaim && (
                            <Box width="8px" height="8px" background={colors.strokeAlt3} borderRadius="50%" ml="4px" />
                          )}
                        </RowBetween>
                        <Text
                          fontSize={['10px', '10px', '12px', '12px']}
                          fontWeight={400}
                          color={colors.textSubtle}
                          mt={['2px', '2px', '0px', '0px']}
                        >
                          <Trans>{item.description}</Trans>
                        </Text>
                      </ColumnCenter>
                    </Tab>

                    {index + 1 < parsedPackages.length && (
                      <Box
                        width="1px"
                        height="16px"
                        background={colors.backgroundAlt5}
                        mx={['2px', '2px', '8px', '8px']}
                      />
                    )}
                  </React.Fragment>
                )
              })}
            </RowCenter>
          </Box>

          <Box mt="24px" width="100%">
            <DailyCheckinRewards packageCode={selectedPackageCode} />
          </Box>
        </ColumnCenter>
      </Box>
    </TransactionWrapperModal>
  )
}

export default DailyCheckinModal
