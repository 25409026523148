import { APIEndpointEnum, INHOUSE_GAME_API } from 'config/constants/server'
import { HunnyPlayRequest } from 'services/HunnyPlayRequest'
import { InhouseGameProbilityFairBetInfo } from 'views/InhouseGame/config/types'

class InhouseGameService extends HunnyPlayRequest {
  public async getBetInfo(id: number): Promise<InhouseGameProbilityFairBetInfo> {
    const result = await this._request(
      APIEndpointEnum.GetBetInfo,
      { round_id: id },
      {
        baseURL: INHOUSE_GAME_API,
      },
    )

    if (!result || result.code !== 'success') {
      return null
    }

    return result.data
  }
}

const instance = new InhouseGameService()
export default instance
