import { GameType } from 'config/types/game'

export const getGameTypeDisplay = (gameType: GameType) => {
  let displayedGameType = ''

  switch (gameType) {
    case GameType.Casino:
      displayedGameType = 'Casiono'
      break
    case GameType.Casual:
      displayedGameType = 'Casual'
      break
    case GameType.Slot:
      displayedGameType = 'Slot'
      break
    case GameType.Sport:
      displayedGameType = 'Sport'
      break
    case GameType.Lobby:
      displayedGameType = 'Lobby'
      break
    default:
      break
  }

  return displayedGameType
}
