import BigNumber from 'bignumber.js'
import { BaseResponse } from 'services/types'

export const FiatCurrencyConversionRateInUSDMapper = (rawResponse: string) => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)
  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  const fiatCurrencyConversionRateInUSDResponse = response.data?.items.filter((item: any) => {
    return item.is_fiat
  })

  const fiatCurrencyConversionRateInUSDDataMapper = fiatCurrencyConversionRateInUSDResponse.reduce((result, value) => {
    const { code, ...rest } = value
    result[code] = new BigNumber(rest.price_usd)
    return result
  }, {})

  return {
    code: response.code,
    message: response.message,
    data: {
      fiatCurrencyConversionRateInUSD: { ...fiatCurrencyConversionRateInUSDDataMapper, USD: new BigNumber(1) },
    },
  }
}
