import Text from 'components/Text'
import { PrepareEnable2FA } from 'config/types/auth'
import useDebounceCallback from 'hooks/useDebounceCallback'
import useModal, { useCloseById } from 'hooks/useModal'
import { useRequest } from 'hooks/useRequest'
import { Trans } from 'react-i18next'
import AuthService from 'services/AuthenticationService'
import { useUserSettings } from 'state/profile/hooks'
import { SETTING_SECURITY_MODAL_KEY } from 'config/constants'
import { useWalletInfo } from 'state/auth/hooks'
import Enable2FAModal from './Enable2FAModal'
import WalletEnable2FASignMessageModal from './WalletEnable2FASignMessageModal'
import { StyledButton } from './styled'

const WalletEnable2FAButton: React.FC<{ onDisable2FA: () => void }> = ({ onDisable2FA }) => {
  const [onPresentSignWalletModal] = useModal(WalletEnable2FASignMessageModal)
  const [onPresentEnable2FAModal] = useModal(Enable2FAModal)

  const { userSettings } = useUserSettings()
  const isActive2FA = userSettings.isActive2FA

  const { wallet, onConnectByWalletType, account } = useWalletInfo()

  const { execute } = useRequest()
  const debounceCallback = useDebounceCallback()
  const closeModal = useCloseById()

  const handlePresentEnable2FAModal = async () => {
    const response = await execute(AuthService.prepareWalletEnable2FA({ walletType: wallet.type }))

    onPresentSignWalletModal(
      {
        messageText: response.data.acceptMessageText,
        onSignSuccess: async (signature) => {
          if (!signature) return
          const prepareData: PrepareEnable2FA = {
            key: response.data.key,
            url: response.data.url,
            expireTime: response.data.expireTime,
            sessionId: response.data.acceptMessage?.message?.session_id,
          }

          onPresentEnable2FAModal(
            {
              prepareData,
              verifyFunction: async ({ authCode, sessionId }) => {
                const enable2FAResponse = await AuthService.walletEnable2FA({
                  totpAuthcode: authCode,
                  sessionId,
                  acceptMessage: response.data.acceptMessage,
                  messageSignature: signature,
                })

                return enable2FAResponse
              },
              onRefetchPrepareData: async () => {
                const response = await execute(AuthService.prepareWalletEnable2FA({ walletType: wallet.type }))
                return response
              },
            },
            true,
          )

          closeModal(SETTING_SECURITY_MODAL_KEY)
        },
      },
      true,
    )

    return
  }

  const onAction2FA = () => {
    if (isActive2FA) {
      onDisable2FA()
      closeModal(SETTING_SECURITY_MODAL_KEY)
    } else debounceCallback(() => handlePresentEnable2FAModal(), 300)
  }

  return (
    <>
      <StyledButton
        variant={!isActive2FA ? 'primary' : 'error'}
        onClick={() => {
          if (!account) {
            onConnectByWalletType({
              callbackReconnect: onAction2FA,
            })
            return
          }
          onAction2FA()
        }}
      >
        <Text>
          <Trans>{!isActive2FA ? 'Enable' : 'Disable'}</Trans>
        </Text>
      </StyledButton>
    </>
  )
}

export default WalletEnable2FAButton
