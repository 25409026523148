import Text from 'components/Text'
import useDebounceCallback from 'hooks/useDebounceCallback'
import { RowCenter } from 'layout/Components/Row'
import { useEffect } from 'react'
import LimboGameService from 'services/InhouseGameService/LimboGameService'

const LimboVerifyForm: React.FC<{
  clientSeed: string
  serverSeed: string
  nonce: number
  onVerified: (result: any) => void
}> = ({ onVerified, clientSeed, serverSeed, nonce }) => {
  const debounceCallback = useDebounceCallback()

  useEffect(() => {
    debounceCallback(async () => {
      if (clientSeed && serverSeed && nonce >= 0) {
        const payoutMultiplier = await LimboGameService.verifyResult(clientSeed, serverSeed, nonce)

        onVerified(
          <RowCenter height="100%">
            <Text bold letterSpacing="4px" textAlign="center" fontWeight={700}>
              {`${payoutMultiplier}x`}
            </Text>
          </RowCenter>,
        )
      } else onVerified(null)
    }, 300)
  }, [clientSeed, serverSeed, nonce])

  return null
}

export default LimboVerifyForm
