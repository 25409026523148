import { FlexProps } from 'components/Box/types'
import { Position } from 'components/Popper/typpes'
import Text from 'components/Text'
import Column from 'layout/Components/Column'
import { RowMiddle } from 'layout/Components/Row'
import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { WHEEL_RISKS, WheelGameRiskEnum } from 'views/InhouseGame/Wheel/config'
import { InHouseLabel, WrapperFilter } from 'views/InhouseGame/components/styled'

const WheelRiskSelect: React.FC<
  {
    risk: WheelGameRiskEnum
    onSelect: (risk: WheelGameRiskEnum) => void
    disabled?: boolean
    dropdownPosition?: Position
  } & Omit<FlexProps, 'onSelect'>
> = ({ risk, onSelect, disabled, height = '40px', dropdownPosition, ...props }) => {
  const selected = useMemo(() => WHEEL_RISKS.find((r) => r.value === risk), [risk])
  const options = useMemo(
    () => (selected ? WHEEL_RISKS.filter((item) => item !== selected) : WHEEL_RISKS),
    [WHEEL_RISKS, selected],
  )

  return (
    <Column {...props}>
      <InHouseLabel>
        <Trans>Risk</Trans>
      </InHouseLabel>
      <WrapperFilter
        disabled={disabled}
        options={options}
        onSelect={onSelect}
        OptionItemComponent={FilterOptionItem}
        height={height}
        dropdownPosition={dropdownPosition}
      >
        {selected && <FilterOptionItem item={selected} />}
      </WrapperFilter>
    </Column>
  )
}

const FilterOptionItem = ({ item }) => {
  const { t } = useTranslation()
  return (
    <RowMiddle>
      <Text fontSize="1em">{t(item.name)}</Text>
    </RowMiddle>
  )
}

export default WheelRiskSelect
