export const targetBanner = {
  1: '_internal',
  2: '_blank',
}

export enum BannerTypeEnum {
  // Home
  MainBanner = 1,
  SubBanner = 10,
  SmallBanner = 20,
  // Free Bonus Campaign
  FreeBonusBanner = 30,
  FreeBonusCampaignPreview = 60,
  /// Authentication
  SignUp = 40,
  SignIn = 41,
  BonusCenter = 50,
}

export enum BannerTag {
  None = 1,
  WelcomePackage = 2,
  NonWelcomePackage = 3,
  BannerWithUserLogIn = 4,
  BannerWithUserNotLogIn = 5,
}
