import Button from 'components/Button/Button'
import Text from 'components/Text/Text'
import { WalletInfo } from 'config/types/wallet'
import Column, { ColumnCenter } from 'layout/Components/Column'
import { RowBetween } from 'layout/Components/Row'

import { isWalletConnect } from 'config/constants/validate-wallet'
import useMatchBreakpoints from 'hooks/useMatchBreakpoints'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import WalletConnectQRCode from './WalletConnectQRCode'

const ConnectToWalletByWalletConnect: React.FC<{
  wallet: WalletInfo
  onGetWallet: () => void
  onBack: () => void
  onWalletConnect: () => void
}> = ({ wallet, onGetWallet, onBack, onWalletConnect }) => {
  const { t } = useTranslation()
  const { isMobile } = useMatchBreakpoints()

  return (
    <ColumnCenter
      justifyContent="center !important"
      alignItems={['center !important', '', '', 'flex-start !important']}
      height="100%"
    >
      <WalletConnectQRCode wallet={wallet} size={isMobile ? 300 : 380} />

      <Column my="16px" width="100%">
        {isWalletConnect(wallet.adapter) ? (
          <RowBetween height="44px">
            <Text small color="textTertiary">
              <Trans>Need the official WalletConnect modal?</Trans>
            </Text>

            <StyledButton maxWidth="60px" onClick={onWalletConnect}>
              <Trans>Open</Trans>
            </StyledButton>
          </RowBetween>
        ) : (
          <RowBetween flex="unset !important" width="100%">
            <Text small fontWeight={300} color="textTertiary">
              {t("Don't have {{walletName}}", {
                walletName: wallet.name,
              })}
              ?
            </Text>
            <StyledButton maxWidth="60px" onClick={onGetWallet}>
              <Trans>GET</Trans>
            </StyledButton>
          </RowBetween>
        )}
      </Column>

      <StyledButtonBack onClick={onBack}>
        <Trans>Back</Trans>
      </StyledButtonBack>
    </ColumnCenter>
  )
}

const StyledButton = styled(Button)`
  padding: 4px 16px;
  width: 100%;
  max-width: 80px;
  height: 40px;
  border-radius: 40px;
  background: rgba(233, 96, 175, 0.12);
  color: ${({ theme }) => theme.colors.primary};

  font-weight: bold;
`

const StyledButtonBack = styled(Button)`
  width: 100%;
  background: ${({ theme }) => theme.colors.backgroundAlt5};
  color: ${({ theme }) => theme.colors.textSubtle};
`
export default ConnectToWalletByWalletConnect
