import Grid from 'components/Box/Grid'
import Image from 'components/Image'
import Text from 'components/Text'
import { ProfileStatisticBonusInfoes } from 'config/types/profile'
import Column from 'layout/Components/Column'
import { RowBetween, RowCenter } from 'layout/Components/Row'
import { useMemo } from 'react'
import { Trans } from 'react-i18next'
import USDAmountDisplay from 'views/Fiat/USDAmountDisplay'
import BonusCard from './BonusCard'

const Bonuses: React.FC<{ bonusInfoes: ProfileStatisticBonusInfoes; isMyBonusStatistics?: boolean }> = ({
  bonusInfoes,
  isMyBonusStatistics,
}) => {
  const totalClaimBonus = useMemo(() => {
    if (bonusInfoes) {
      const {
        cashbackAmount,
        husdBonusAmount,
        levelUpBonusAmount,
        referralAmount,
        voucherBonusAmount,
        dailyCheckinAmount,
      } = bonusInfoes
      return (
        cashbackAmount + husdBonusAmount + levelUpBonusAmount + referralAmount + voucherBonusAmount + dailyCheckinAmount
      )
    }

    return 0
  }, [bonusInfoes])

  return (
    <Column>
      <RowBetween>
        <Text fontSize="14px" color="textTertiary" fontWeight={600} lineHeight="normal" letterSpacing={-0.28}>
          <Trans>Bonuses</Trans>
        </Text>

        {isMyBonusStatistics && (
          <RowCenter>
            <Text color="textTertiary" fontSize="14px" lineHeight="normal" letterSpacing={-0.28} fontWeight={400}>
              <Trans>You have claimed</Trans>{' '}
              <Text as="span" fontSize="14px" fontWeight={600}>
                <USDAmountDisplay amount={totalClaimBonus} />
              </Text>
            </Text>
          </RowCenter>
        )}
      </RowBetween>

      <Grid gridTemplateColumns="1fr 1fr" gridGap="12px" mt="12px">
        <BonusCard
          IconComponent={<Image src="/images/bonus-center-gift.png" width={31.5} height={28} />}
          amount={Number(bonusInfoes?.voucherBonusAmount ?? 0)}
          title="Bonuses"
        />

        <BonusCard
          IconComponent={<Image src="/images/token/hpd.png" width={20} height={20} />}
          amount={Number(bonusInfoes?.husdBonusAmount ?? 0)}
          title="HUSD Bonus"
        />
        <BonusCard
          IconComponent={<Image src="/images/bonus/level-up-bonus.png" width={28} height={28} />}
          amount={Number(bonusInfoes?.levelUpBonusAmount ?? 0)}
          title="Level up Bonus"
        />
        <BonusCard
          IconComponent={<Image src="/images/bonus/cashback-bonus.png" width={28} height={28} />}
          amount={Number(bonusInfoes?.cashbackAmount ?? 0)}
          title="Cashback"
        />
        <BonusCard
          IconComponent={<Image src="/images/daily-checkin.png" width={28} height={28} />}
          amount={Number(bonusInfoes?.dailyCheckinAmount ?? 0)}
          title="Daily Checkin"
        />
        <BonusCard
          IconComponent={<Image src="/images/referral.png" width={28} height={28} />}
          amount={Number(bonusInfoes?.referralAmount ?? 0)}
          title="Referral"
        />
      </Grid>
    </Column>
  )
}

export default Bonuses
