import IconButton from 'components/Button/IconButton'
import Heading from 'components/Heading/Heading'
import { WALLET_INFO } from 'config/constants/wallet'
import { WalletInfo, WalletType } from 'config/types/wallet'
import { useConnectWallet } from 'hooks/useConnectWallet'
import useMatchBreakpoints from 'hooks/useMatchBreakpoints'
import useModal from 'hooks/useModal'
import useWalletAccount from 'hooks/useWalletAccount'
import { RowMiddle } from 'layout/Components/Row'
import { ConnectorNameEnum } from 'packages/wagmi/types'
import { connectorsByName } from 'packages/wagmi/wagmi'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Icons } from 'svgs'
import { AuthModalPageEnums, AuthWalletStepEnums } from 'views/Authentication/types'
import { useIsDisabledConnectWeb3 } from 'hooks/useTelegram'
import ConnectToWalletByWalletConnect from './ConnectToWalletByWalletConnect'
import HowToGetWallet from './HowToGetWallet'
import InstallWalletQRCode from './InstallWalletQRCode'
import SignInByWallet from './SignInByWallet'
import WalletConnecting from './WalletConnecting'
import WalletList from './WalletList'
import HowToLoginModal from './HowToLoginModal'

const HeaderStep: React.FC<{ onBack: () => void; title: string }> = ({ onBack, title }) => (
  <RowMiddle>
    <IconButton width="24px" height="24px" onClick={onBack}>
      <Icons.ArrowLeftIcon fill="#FFF" />
    </IconButton>

    <Heading ml="16px" scale="md">
      {title}
    </Heading>
  </RowMiddle>
)

export const validateWalletOutSideEvm = (wallet: WalletInfo) => [WalletType.SOL].includes(wallet?.type)

const AuthWallet: React.FC<{
  isShowMore: boolean
  setShowMore: (more: boolean) => void
  setSelectedWallet: (selected: boolean) => void
  page: AuthModalPageEnums
  isOnlyReConnect?: boolean
  onCallbackReConnect?: (data: { account: string; wallet: WalletInfo }) => void
}> = ({ isShowMore, setShowMore, setSelectedWallet: setSelect, page, isOnlyReConnect, onCallbackReConnect }) => {
  const [steps, setSteps] = useState([])
  const [selectedWallet, setSelectedWallet] = useState(null)
  const [signUpPromoCode, setSignUpPromoCode] = useState('')

  const accounts = useWalletAccount()
  const [onPresentSignInByWallet] = useModal(SignInByWallet)
  const [onPresent] = useModal(HowToLoginModal)
  const walletConnector = useConnectWallet()
  const [isConnecting, setIsConnecting] = useState(false)
  const { t } = useTranslation()
  const { isMobile } = useMatchBreakpoints()
  const isDisabledConnectWeb3 = useIsDisabledConnectWeb3()

  const handleClear = () => {
    setSteps([])
    setSelect(false)
    setSelectedWallet(null)
  }

  useEffect(() => {
    if (isOnlyReConnect && onCallbackReConnect && selectedWallet && accounts[selectedWallet?.type] && !isConnecting) {
      onCallbackReConnect({
        wallet: selectedWallet,
        account: accounts[selectedWallet.type],
      })
    }
  }, [isOnlyReConnect, accounts, selectedWallet, isConnecting])

  useEffect(() => {
    if (!selectedWallet || !accounts[selectedWallet?.type] || isConnecting || isOnlyReConnect) return
    onPresentSignInByWallet({ wallet: { ...selectedWallet }, signUpPromoCode }, true)

    if (selectedWallet && validateWalletOutSideEvm(selectedWallet)) {
      setSelectedWallet(null)
    }
  }, [accounts, selectedWallet, isConnecting, signUpPromoCode, isOnlyReConnect])

  const handleUpdateStep = (step: AuthWalletStepEnums) => {
    if (isOnlyReConnect) return

    if (!steps.includes(step)) {
      return setSteps((preState) => [...preState, step])
    }
  }

  const handleBackStep = () => {
    if (steps.length > 0) {
      const stepsPop = [...steps]
      stepsPop.pop()
      setSteps(stepsPop)

      if (stepsPop.length === 0) {
        handleClear()
      }
    } else {
      handleClear()
    }
  }
  const handleWalletConnect = async (wallet: WalletInfo) => {
    const _wallet = WALLET_INFO.find((w) => w.adapter === connectorsByName[ConnectorNameEnum.WalletConnect])
    setSelectedWallet({
      ...wallet,
      adapter: _wallet.adapter,
    })
    return walletConnector[WalletType.EVM].connect(_wallet.adapter)
  }

  const handleAdapterConnect = (wallet: WalletInfo) => {
    if (!wallet.adapter) return null
    return walletConnector[wallet.type].connect(wallet.adapter)
  }

  const onTryConnect = async (wallet: WalletInfo) => {
    setIsConnecting(true)

    if (wallet.installed) {
      await handleAdapterConnect(wallet)
    } else if (isMobile && wallet.type === WalletType.EVM) {
      await handleWalletConnect(wallet)
    }

    setIsConnecting(false)
  }

  const handleSelectWallet = async (wallet: WalletInfo) => {
    if (isDisabledConnectWeb3) {
      onPresent()
    } else {
      setSelectedWallet(wallet)

      if (validateWalletOutSideEvm(wallet)) {
        return handleAdapterConnect(wallet)
      }

      setSelect(!!wallet)
      if (wallet.installed || isMobile) {
        handleUpdateStep(AuthWalletStepEnums.CONNECTING)
        await onTryConnect(wallet)
      } else {
        handleUpdateStep(AuthWalletStepEnums.SCAN_APP)
      }
    }
  }

  const renderContent = useMemo(() => {
    switch (steps?.[steps.length - 1]) {
      case AuthWalletStepEnums.CONNECTING:
        return (
          <>
            <HeaderStep onBack={handleBackStep} title={''} />
            <WalletConnecting
              isLoading={isConnecting}
              retry={() => {
                onTryConnect(selectedWallet)
              }}
              wallet={selectedWallet}
            />
          </>
        )

      case AuthWalletStepEnums.SCAN_APP:
        return (
          <>
            <HeaderStep
              onBack={handleBackStep}
              title={t('Scan with {{walletName}}', {
                walletName: selectedWallet?.name || '',
              })}
            />
            <ConnectToWalletByWalletConnect
              onBack={handleBackStep}
              onGetWallet={() => {
                handleUpdateStep(AuthWalletStepEnums.GET_APP)
              }}
              wallet={selectedWallet}
              onWalletConnect={() => {
                handleAdapterConnect(selectedWallet)
              }}
            />
          </>
        )

      case AuthWalletStepEnums.GET_APP:
        return (
          <>
            <HeaderStep
              onBack={handleBackStep}
              title={t('Get {{walletName}}', {
                walletName: selectedWallet?.name || '',
              })}
            />
            <HowToGetWallet
              wallet={selectedWallet}
              onInstall={() => {
                handleUpdateStep(AuthWalletStepEnums.INSTALL_APP)
              }}
            />
          </>
        )

      case AuthWalletStepEnums.INSTALL_APP:
        return (
          <>
            <HeaderStep
              onBack={handleBackStep}
              title={t('Install {{walletName}}', {
                walletName: selectedWallet?.name || '',
              })}
            />
            <InstallWalletQRCode
              wallet={selectedWallet}
              onInstalled={() => {
                setSteps([AuthWalletStepEnums.SCAN_APP])
              }}
            />
          </>
        )

      default:
        return (
          <WalletList
            isShowMore={isShowMore}
            setShowMore={setShowMore}
            setSelectedWallet={handleSelectWallet}
            onSignUpPreparePayload={({ promoCode }) => {
              setSignUpPromoCode(promoCode)
            }}
            page={page}
            disabledCheckCondition={isOnlyReConnect}
          />
        )
    }
  }, [steps, isConnecting, selectedWallet, isShowMore, page, setShowMore, onTryConnect, setSelectedWallet])
  return <>{renderContent}</>
}

export default AuthWallet
