import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import CopyButton from 'components/Button/CopyButton'
import Image from 'components/Image/Image'
import { InputContainer } from 'components/Input/styled'
import Link from 'components/Link/Link'
import { ModalBody, ModalProps } from 'components/Modal'
import Modal from 'components/Modal/Modal'
import Text from 'components/Text/Text'
import useMatchBreakpoints from 'hooks/useMatchBreakpoints'
import { RowCenter } from 'layout/Components/Row'
import React, { useMemo } from 'react'
import { Trans } from 'react-i18next'
import base from 'theme/base'
import { useFetchDefaultCampaign } from 'views/Referral/hooks'
import { REFERRAL_SOCIAL_SHARE_CONTENT, getReferralShareUrl } from 'views/Referral/utils'

interface ShareGameModalProps {
  gameSlug: string
}

const ShareGameModal: React.FC<ModalProps<ShareGameModalProps>> = ({ data: { gameSlug }, onDismiss }) => {
  const { campaign } = useFetchDefaultCampaign()
  const appSite = typeof window !== 'undefined' ? window.location.origin : ''
  const fullGameSlug = useMemo(
    () => (campaign ? `${appSite}/${gameSlug}?join=${campaign?.code}` : `${appSite}/${gameSlug}`),
    [gameSlug, campaign],
  )
  const { isMobile, isTablet } = useMatchBreakpoints()
  const socialIconSize = isMobile ? 32 : isTablet ? 42 : 60

  return (
    <Modal
      minWidth={['', '', '', '', '728px !important']}
      minHeight={['', '', '', '', '497px !important']}
      onDismiss={onDismiss}
    >
      <ModalBody p="40px 24px">
        <Text fontSize={['18px', '', '', '24px']} fontWeight={800} textAlign="center" width="100%">
          <Trans>Share this game</Trans>
        </Text>

        <RowCenter width="100%" mt={40}>
          <InputContainer p="8px 10px" minWidth={['', '', '', '', 544]} width="100%" height="48px" mr={'8px'}>
            <Text fontSize="14px" fontWeight={400} ellipsis={1} style={{ whiteSpace: 'nowrap' }} display="block">
              {fullGameSlug}
            </Text>
          </InputContainer>
          <RowCenter background=" rgba(255, 255, 255, 0.08)" minWidth={48} height={48} borderRadius={base.radii.small}>
            <CopyButton text={fullGameSlug} />
          </RowCenter>
        </RowCenter>

        <Box width="100%" my={40}>
          <Flex alignItems="center" justifyContent="center">
            <Link
              mx="4px"
              href={getReferralShareUrl({
                content: REFERRAL_SOCIAL_SHARE_CONTENT,
                appSiteUrl: fullGameSlug,
                type: 'twitter',
              })}
              id={'enterBonusCodeModal-social-twitter'}
              external
            >
              <Box width={socialIconSize} height={socialIconSize}>
                <Image
                  src="/images/social/twitter-icon.png"
                  alt="Twitter Share"
                  width={socialIconSize}
                  height={socialIconSize}
                />
              </Box>
            </Link>
            <Link
              mx="4px"
              id={'enterBonusCodeModal-social-telegram'}
              href={getReferralShareUrl({
                content: REFERRAL_SOCIAL_SHARE_CONTENT,
                appSiteUrl: fullGameSlug,
                type: 'telegram',
              })}
              external
            >
              <Box width={socialIconSize} height={socialIconSize}>
                <Image
                  src="/images/bonus/telegram-icon.png"
                  alt="Telegram Share"
                  width={socialIconSize}
                  height={socialIconSize}
                />
              </Box>
            </Link>

            <Link
              mx="4px"
              href={getReferralShareUrl({
                content: REFERRAL_SOCIAL_SHARE_CONTENT,
                appSiteUrl: fullGameSlug,
                type: 'reddit',
              })}
              id={'enterBonusCodeModal-social-reddit'}
              external
            >
              <Box width={socialIconSize} height={socialIconSize}>
                <Image
                  src="/images/bonus/reddit-icon.png"
                  alt="Reddit Share"
                  width={socialIconSize}
                  height={socialIconSize}
                />
              </Box>
            </Link>
            <Link
              mx="4px"
              href={getReferralShareUrl({
                content: REFERRAL_SOCIAL_SHARE_CONTENT,
                appSiteUrl: fullGameSlug,
                type: 'facebook',
              })}
              id={'enterBonusCodeModal-social-facebook'}
              external
            >
              <Box width={socialIconSize} height={socialIconSize}>
                <Image
                  src="/images/bonus/facebook-icon.png"
                  alt="Facebook Share"
                  width={socialIconSize}
                  height={socialIconSize}
                />
              </Box>
            </Link>
          </Flex>
        </Box>

        <Image src="/images/share-game-desktop-banner.png" width={680} height={160} />
      </ModalBody>
    </Modal>
  )
}

export default ShareGameModal
