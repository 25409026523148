import { WalletInjecteName } from 'config/types/wallet'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { WalletConnectLegacyConnector } from 'wagmi/connectors/walletConnectLegacy'

const windowIsUnavailable = () => typeof window === 'undefined'

export const isInjectedWalletInstalled = (injectedNameWallet: WalletInjecteName, conditionNameWallet: string) => {
  if (windowIsUnavailable()) {
    return false
  }

  const injected = window?.[injectedNameWallet]

  if (!injected) {
    return false
  }

  if (injected?.[conditionNameWallet]) {
    return true
  }

  return !!injected?.providers?.some((p) => p?.[conditionNameWallet])
}

export const isMetamaskInstalled = () => {
  return isInjectedWalletInstalled(WalletInjecteName.ethereum, 'isMetaMask')
}

export const isMathWalletInstalled = () => {
  return isInjectedWalletInstalled(WalletInjecteName.ethereum, 'isMathWallet')
}

export const isSafePalInstalled = () => {
  return isInjectedWalletInstalled(WalletInjecteName.ethereum, 'isSafePal')
}

export const isBinanceWalletInstalled = () => {
  if (windowIsUnavailable()) {
    return false
  }

  return typeof window.BinanceChain?.on === 'function'
}

export const isCoin98Installed = () => {
  return isInjectedWalletInstalled(WalletInjecteName.coin98, 'isCoin98')
}

export const isTokenPocketInstalled = () => {
  return isInjectedWalletInstalled(WalletInjecteName.ethereum, 'isTokenPocket')
}

export const isBitKeepInstalled = () => {
  return isInjectedWalletInstalled(WalletInjecteName.ethereum, 'isBitKeep')
}

export const isWalletLinkInstalled = () => {
  if (windowIsUnavailable()) {
    return false
  }

  return !!(window?.WalletLink && window?.WalletLinkProvider)
}

export const isCoinBaseInstalled = () => {
  if (windowIsUnavailable()) {
    return false
  }

  return !!(isWalletLinkInstalled() && window?.coinbaseWalletExtension && window?.coinbaseWalletRequestProvider)
}

export const isTrustWalletInstalled = () => {
  const isTrustWallet = (ethereum: NonNullable<Window['ethereum']>) => {
    // Identify if Trust Wallet injected provider is present.
    const trustWallet = !!ethereum.isTrust

    return trustWallet
  }

  const injectedProviderExist = typeof window !== 'undefined' && typeof window.ethereum !== 'undefined'

  // No injected providers exist.
  if (!injectedProviderExist) {
    return
  }

  // Trust Wallet was injected into window.ethereum.
  if (isTrustWallet(window.ethereum as NonNullable<Window['ethereum']>)) {
    return window.ethereum
  }

  // Trust Wallet provider might be replaced by another
  // injected provider, check the providers array.
  if (window.ethereum?.providers) {
    return window.ethereum.providers.find(isTrustWallet)
  }

  // In some cases injected providers can replace window.ethereum
  // without updating the providers array. In those instances the Trust Wallet
  // can be installed and its provider instance can be retrieved by
  // looking at the global `trustwallet` object.
  return window.trustwallet
}

export const isWalletConnect = (adapter: any) =>
  adapter instanceof WalletConnectLegacyConnector || adapter instanceof WalletConnectConnector
