import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import Text from 'components/Text'
import { RouteConfig } from 'config/constants/route'
import { useRouter } from 'hooks/useRouter'
import { Trans } from 'react-i18next'
import { Icons } from 'svgs'
import { colors } from 'theme/colors'

const MilestoneReachSuccessToast = () => {
  const router = useRouter()

  return (
    <Box minWidth="250px" onClick={() => router.push(RouteConfig.BonusCenter)}>
      <Flex alignItems="flex-start">
        <Flex flexDirection="column" alignItems="flex-start" justifyContent="flex-end">
          <Flex alignItems="center">
            <Icons.SuccessCheckIcon height="24px" fill={colors.success} />
            <Text ml="8px" fontSize="16px" bold color="success">
              <Trans>Bonus’s milestone reach.</Trans>
            </Text>
          </Flex>
          <Flex mt="4px" ml="32px" alignItems="center">
            <Text ml="6px" fontSize="14px">
              <Trans>You can claim a part of bonus for reaching a bonus’s milestone</Trans>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  )
}

export default MilestoneReachSuccessToast

