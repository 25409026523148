import { useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import { Paging as APIPaging } from 'services/types'
import { range } from 'utils'
import {
  ColumnByNamesType,
  ColumnStateType,
  ColumnType,
  DataType,
  HeaderType,
  RowType,
  TableAction,
  TableState,
  UseTableOptionsType,
  UseTableReturnType,
} from './type'

const getPaginatedData = <T extends DataType>(rows: Record<number, RowType<T>>, perPage: number, page: number) => {
  const start = (page - 1) * perPage
  const end = start + perPage

  return range(start, end)
    .map((index) => rows[index])
    .filter((item) => item)
}

const sortDataInOrder = <T extends DataType>(data: T[], columns: ColumnType[]): T[] => {
  return data.map((row: any) => {
    columns.forEach((column) => {
      if (!(column.name in row)) {
        throw new Error(`Invalid row data, ${column.name} not found`)
      }
    })
    return row
  })
}

const getColumnsByName = (columns: ColumnType[]): ColumnByNamesType => {
  const columnsByName: ColumnByNamesType = {}
  columns.forEach((column) => {
    const col: ColumnType = {
      name: column.name,
      label: column.label,
    }
    columnsByName[column.name] = col
  })

  return columnsByName
}

const createReducer =
  <T extends DataType>() =>
  (state: TableState<T>, action: TableAction<T>): TableState<T> => {
    const firstPage = 1
    let lastPage =
      state.total &&
      Math.floor(state.total / state.pagination.perPage) + (state.total % state.pagination.perPage ? 1 : 0)
    let nextPage = 0
    let prevPage = 0

    switch (action.type) {
      case 'INSERT_ROWS':
        const paging = action.pagingData.paging
        const data = action.pagingData.items
        let total = paging.total || state.total
        lastPage = total && Math.floor(total / state.pagination.perPage) + (total % state.pagination.perPage ? 1 : 0)

        if (!total && data.length < state.pagination.perPage) {
          total = paging.offset + data.length
          lastPage = total && Math.floor(total / state.pagination.perPage) + (total % state.pagination.perPage ? 1 : 0)
        }

        range(paging.offset, paging.offset + paging.limit).forEach((index) => {
          state.originalRows[index] = data[index - paging.offset]
        })

        const rows = getPaginatedData(state.originalRows, state.pagination.perPage, state.pagination.page)
        const nextRows = getPaginatedData(state.originalRows, state.pagination.perPage, state.pagination.page + 1)

        return {
          ...state,
          rows,
          nextRows,
          total,
          columns: state.columns,
          hasTotalResponse: !!paging.total,
          pagination: {
            ...state.pagination,
            canNext: total ? state.pagination.page != lastPage : nextRows.length > 0,
            canGotoFirst: state.pagination.page != 1,
            canGotoLast: state.pagination.page !== lastPage,
            isGotoLastDisabled: !paging.total,
          },
        }

      case 'NEXT_PAGE':
        nextPage = state.pagination.page + 1
        return {
          ...state,
          rows: state.nextRows,
          nextRows: getPaginatedData(state.originalRows, state.pagination.perPage, nextPage + 1),
          pagination: {
            ...state.pagination,
            page: nextPage,
            canNext: state.total ? nextPage < lastPage : nextRows?.length > 0,
            canPrev: true,
            canGotoFirst: true,
            canGotoLast: nextPage !== lastPage,
            isGotoLastDisabled: !state.hasTotalResponse,
          },
        }

      case 'PREV_PAGE':
        prevPage = state.pagination.page === 1 ? 1 : state.pagination.page - 1

        return {
          ...state,
          rows: getPaginatedData(state.originalRows, state.pagination.perPage, prevPage),
          nextRows: state.rows,
          pagination: {
            ...state.pagination,
            page: prevPage,
            canNext: true,
            canPrev: prevPage > 1,
            canGotoFirst: prevPage !== 1,
            canGotoLast: nextPage !== lastPage,
            isGotoLastDisabled: !state.hasTotalResponse,
          },
        }

      case 'GOTO_FIRST_PAGE':
        return {
          ...state,
          rows: getPaginatedData(state.originalRows, state.pagination.perPage, firstPage),
          pagination: {
            ...state.pagination,
            page: firstPage,
            canGotoFirst: false,
            canGotoLast: true,
            canNext: true,
            canPrev: false,
            isGotoLastDisabled: !state.hasTotalResponse,
          },
        }

      case 'GOTO_LAST_PAGE':
        return {
          ...state,
          rows: getPaginatedData(state.originalRows, state.pagination.perPage, lastPage),
          pagination: {
            ...state.pagination,
            page: lastPage,
            canGotoFirst: true,
            canGotoLast: false,
            canNext: false,
            canPrev: true,
            isGotoLastDisabled: false,
          },
        }

      case 'RESET_TABLE':
        return {
          columns: state.columns,
          columnsByName: state.columnsByName,
          originalRows: {},
          rows: [],
          nextRows: [],
          hasTotalResponse: undefined,
          total: 0,
          pagination: {
            ...state.pagination,
            page: 1,
            perPage: state.pagination.perPage,
            canNext: false,
            canPrev: false,
            canGotoFirst: false,
            canGotoLast: false,
          },
        }

      default:
        throw new Error('Invalid reducer action')
    }
  }

export const useTable = <T extends DataType>(
  columns: ColumnType[],
  dataRequest?: (offset: number, limit: number) => Promise<APIPaging<T>>,
  options?: UseTableOptionsType<T>,
): UseTableReturnType<T> => {
  const [loadingCurrentPage, setLoadingCurrentPage] = useState(false)
  const [loadingNextPage, setLoadingNextPage] = useState(false)

  const parsedColumns: ColumnStateType[] = useMemo(
    () =>
      columns.map((column) => {
        return {
          ...column,
          label: column.label ? column.label : column.name,
        }
      }),
    [columns],
  )

  const columnsByName = useMemo(() => getColumnsByName(parsedColumns), [parsedColumns])

  const reducer = createReducer<T>()

  const [state, dispatch] = useReducer(reducer, {
    columns: parsedColumns,
    columnsByName,
    originalRows: {},
    rows: [],
    nextRows: [],
    total: 0,
    pagination: {
      page: 1,
      total: 0,
      rows: 0,
      perPage: options.displayPerPage || 10,
      canNext: false,
      canPrev: false,
      nextPage: () => {},
      prevPage: () => {},
      gotoLastPage: () => {},
      gotoFirstPage: () => {},
      canGotoFirst: false,
      canGotoLast: false,
      isGotoLastDisabled: false,
      hide: true,
    },
  })

  state.pagination.nextPage = useCallback(() => dispatch({ type: 'NEXT_PAGE' }), [dispatch])
  state.pagination.prevPage = useCallback(() => dispatch({ type: 'PREV_PAGE' }), [dispatch])
  state.pagination.gotoFirstPage = useCallback(() => dispatch({ type: 'GOTO_FIRST_PAGE' }), [dispatch])
  state.pagination.gotoLastPage = useCallback(() => dispatch({ type: 'GOTO_LAST_PAGE' }), [dispatch])

  const fetchData = async (page: number) => {
    const offset = page * state.pagination.perPage
    const limit = state.pagination.perPage
    const response = await dataRequest(offset, limit)
    const parsedData = sortDataInOrder(response.items, parsedColumns).map((row, idx) => {
      return {
        id: offset + idx,
        original: row,
        cells: Object.entries(row).map(([column, value]) => {
          return {
            field: column,
            value,
          }
        }),
      }
    })

    const paging = response.paging || {
      limit: parsedData.length,
      offset: 0,
      total: parsedData.length,
    }
    dispatch({ type: 'INSERT_ROWS', pagingData: { paging, items: parsedData } })
  }

  useEffect(() => {
    dispatch({ type: 'RESET_TABLE' })
    // pass 1 into fetchCurrentPage because fetchCurrentPage was called before state.pagination.page update
    fetchCurrentPage(1)
  }, [dataRequest])

  useEffect(() => {
    const fetch = async () => {
      setLoadingNextPage(true)
      await fetchData(state.pagination.page)
      setLoadingNextPage(false)
    }

    if (
      state.rows.length > 0 &&
      state.nextRows.length === 0 &&
      !state.hasTotalResponse &&
      state.pagination.canGotoLast
    ) {
      fetch()
    }
  }, [state.rows.length, state.nextRows.length])

  useEffect(() => {
    if (state.rows.length === 0 && state.pagination.page !== 1) {
      fetchCurrentPage()
    }
  }, [state.rows.length, state.pagination.page])

  const fetchCurrentPage = async (page?: number) => {
    setLoadingCurrentPage(true)
    await fetchData((page || state.pagination.page) - 1)
    setLoadingCurrentPage(false)
  }

  const headers: HeaderType[] = useMemo(() => {
    return [
      ...state.columns.map((column) => {
        const label = column.label ? column.label : column.name
        return {
          ...column,
          label,
        }
      }),
    ]
  }, [state.columns])
  return {
    headers,
    rows: state.rows,
    dispatch,
    pagination: {
      ...state.pagination,
      total: state.total,
      rows: state.rows.length,
      isCanNextChecking: loadingNextPage,
      hide: state.rows.length === 0,
    },
    isLoading: loadingCurrentPage || (state.rows.length === 0 && state.pagination.page !== 1),
  }
}
