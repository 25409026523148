import React, { useCallback, useEffect, useMemo, useState } from 'react'

import Box from 'components/Box/Box'
import { FlexProps } from 'components/Box/types'
import CircleLoader from 'components/Loader/CircleLoader'
import QRCode from 'components/QRCode'
import { ChainIdEnum } from 'config/constants/network'
import { createQrCode } from 'config/constants/wallet'
import { WalletInfo, WalletType } from 'config/types/wallet'
import { useConnectWallet } from 'hooks/useConnectWallet'
import useDebounce from 'hooks/useDebounce'
import Column from 'layout/Components/Column'
import { RowCenter } from 'layout/Components/Row'
import { useAppSelector } from 'state'
import { useTokenSelected } from 'state/session/hooks'
import styled from 'styled-components'
import { getIcon } from 'svgs'

const networkNotAcceptQR = [
  ChainIdEnum.SOL,
  ChainIdEnum.SOL_TESTNET,
  ChainIdEnum.BTC,
  ChainIdEnum.BTC_TESTNET,
  ChainIdEnum.TRX,
  ChainIdEnum.TRX_TESTNET,
]
const WalletConnectQRCode: React.FC<{ wallet: WalletInfo; size?: number } & Omit<FlexProps, 'size'>> = ({
  size = 380,
  wallet,
  ...props
}) => {
  const [uri, setUri] = useState('')
  const handleWallet = useConnectWallet()
  const selectedToken = useTokenSelected()
  const tokenInfoes = useAppSelector((state) => state.app.tokenInfoes)

  const fallbackChainId = useMemo(() => {
    const fallbackToken = tokenInfoes.find((token) => !networkNotAcceptQR.includes(token.network))
    return !networkNotAcceptQR.includes(selectedToken.network) ? fallbackToken.network : selectedToken.network
  }, [tokenInfoes])

  const chainId = useDebounce(fallbackChainId, 50)

  const fetchUri = useCallback(async () => {
    const walletAction = handleWallet?.[wallet.type]

    if (!walletAction) return

    const data = await createQrCode({ connect: handleWallet?.[wallet.type].connect })
    setUri(data)
  }, [handleWallet, chainId, wallet, setUri])

  useEffect(() => {
    let interval = null

    if ([WalletType.BSC, WalletType.EVM].includes(wallet?.type) && chainId) {
      fetchUri()
      interval = setInterval(() => fetchUri(), 5 * 60 * 1000)
    }
    return () => {
      clearInterval(interval)
    }
  }, [wallet, chainId])

  const Icon = getIcon(wallet.icon)

  return (
    <RowCenter width={size} height={size} {...props}>
      {uri ? (
        <Column position="relative">
          <QRCode size={size} image={Icon} url={uri} />
          <StyledImageIcon>
            <Icon width="60px" />
          </StyledImageIcon>
        </Column>
      ) : (
        <CircleLoader />
      )}
    </RowCenter>
  )
}

const StyledImageIcon = styled(Box)`
  position: absolute;
  left: 50%;
  top: 50%;

  transform: translate(-50%, -50%);
`
export default WalletConnectQRCode
