import { Token } from 'config/types'
import { ChainIdEnum } from '../network'

const path = '/images/token'

const tokens: Record<string, Token> = {
  ETH: {
    coinGeckoInfo: {
      id: 'ethereum',
    },
    logo: `${path}/eth.png`,
    name: 'ETH',
    code: 'ETH',
    network: ChainIdEnum.ARB,
    isNative: true,
    decimals: 18,
  },
  ARB: {
    coinGeckoInfo: {
      id: 'arbitrum',
    },
    logo: `${path}/arb.png`,
    name: 'ARB',
    code: 'ARB',
    network: ChainIdEnum.ARB,
    address: '0x912ce59144191c1204e64559fe8253a0e49e6548',
    decimals: 18,
    isStable: true,
  },
  USDC: {
    coinGeckoInfo: {
      id: 'usd-coin',
    },
    logo: `${path}/usdc.png`,
    name: 'USDC',
    code: 'USDC',
    network: ChainIdEnum.ARB,
    address: '0xaf88d065e77c8cc2239327c5edb3a432268e5831',
    decimals: 6,
    isStable: true,
  },
}

export default tokens
