import { ActionQueryEnum } from 'hooks/useActionQueryListener'
import { serialize } from 'utils/urlHelper'

export const REFERRAL_SOCIAL_SHARE_CONTENT =
  "Follow me on HunnyPlay. Let's hunt for $$$ together! Get an exciting experience on %231 Crypto iGaming platform with daily payouts, fastest deposits and withdrawals!"

export const getReferralShareUrl = ({
  content,
  appSiteUrl,
  type,
}: {
  content: string
  appSiteUrl: string
  type: 'facebook' | 'telegram' | 'twitter' | 'reddit' | 'messenger'
}): string => {
  if (type === 'facebook')
    return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(appSiteUrl)}&quote=${content}`
  if (type === 'twitter')
    return `https://twitter.com/intent/tweet?url=${encodeURIComponent(appSiteUrl)}&text=${content}`
  if (type === 'telegram') return `https://telegram.me/share/url?url=${encodeURIComponent(appSiteUrl)}&text=${content}`
  if (type === 'reddit') return `https://www.reddit.com/submit?url=${encodeURIComponent(appSiteUrl)}&text=${content}`
  if (type === 'messenger') return `fb-messenger://share/?link=${encodeURIComponent(appSiteUrl)}"`
}

export const getHunnyShareReferralUrl = (campaignCode: string): string => {
  if (!campaignCode) return

  return `${typeof window !== 'undefined' ? window.location.origin : ''}?${serialize({
    action: ActionQueryEnum.SignUp,
    join: campaignCode,
  }).toString()}`
}
