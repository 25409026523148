import Box from 'components/Box/Box'
import Button from 'components/Button'
import FormControl from 'components/FormControl'
import FormInput from 'components/FormControl/FormInput'
import FormInputPassword from 'components/FormControl/FormInputPassword'
import { InputContainer } from 'components/Input/styled'
import OpenEffect from 'components/OpenEffect'
import Text from 'components/Text'
import FormValidator, { commomErrorMessage } from 'config/constants/formValidator'
import { ValidationError } from 'config/types/validator'
import useCallbackValueOnDestroy from 'hooks/useCallbackValueOnDestroy'
import useForm, { FieldState } from 'hooks/useForm'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Icons } from 'svgs'
import { AuthModalPageEnums } from 'views/Authentication/types'

import CircleLoader from 'components/Loader/CircleLoader'
import useDebounceCallback from 'hooks/useDebounceCallback'
import { useIsomorphicEffect } from 'hooks/useIsomorphicEffect'
import useModal from 'hooks/useModal'
import { ConditionAcccess } from '../Template'
import { TraditionalFormProps } from './TraditionalSignUpForm'
import { useSignInWithEmail } from './hooks'
import PasswordRecoveryModal from './modals/PasswordRecoveryModal'

export const loginFormErrorMessages = {
  email: {
    [ValidationError.Email]: commomErrorMessage.Email,
    [ValidationError.Required]: 'Please enter your email',
  },
  password: {
    [ValidationError.Required]: 'Please enter your password',
    [ValidationError.WrongPassword]: 'Incorrect password',
  },
}

const TraditionalLoginForm: React.FC<TraditionalFormProps> = ({ initialEmail, saveEmail, setPage }) => {
  const { t } = useTranslation()
  const signIn = useSignInWithEmail()
  const debounceCallback = useDebounceCallback()

  const [submiting, setSubmiting] = useState(false)
  const [handlePresentPasswordRecoveryModal] = useModal(PasswordRecoveryModal)

  const { states, controls, validateAll, isValid } = useForm({
    email: {
      validators: [FormValidator.required, FormValidator.email],
      value: '',
    },
    password: {
      validators: [FormValidator.required],
      value: '',
    },
  })

  useIsomorphicEffect(() => {
    debounceCallback(() => {
      controls.email.onValueChanged(initialEmail)
    }, 150)
  }, [initialEmail])

  useCallbackValueOnDestroy(states.email.value, saveEmail)

  const formatEmailErrorMessage = (errors: ValidationError[]) => {
    // TODO set key translation
    //  Ref: https://stackoverflow.com/questions/61268001/react-i18n-trans-component-with-translations-that-contain-html-tags-not-working

    if (errors[0] === ValidationError.EmailNotExist) {
      return (
        <Trans>
          This email does not exist. Please enter a different email or{' '}
          <Text
            color="primary"
            display="inline"
            fontSize="12px"
            bold
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setPage(AuthModalPageEnums.SIGN_UP)
            }}
          >
            Sign up
          </Text>{' '}
          new account.
        </Trans>
      )
    }

    return t(loginFormErrorMessages.email[errors[0]])
  }

  const handleSignIn = async () => {
    setSubmiting(true)

    const isValid = await validateAll()
    if (!isValid) {
      setSubmiting(false)
      return
    }

    await signIn(states.password.value, states.email.value)
    setSubmiting(false)
  }

  return (
    <OpenEffect openType="slideBottomToTop" duration={0.5}>
      <Box
        as="form"
        onSubmit={(e) => {
          e.preventDefault()
          handleSignIn()
          return
        }}
      >
        <StyledFormControl state={states.email} label={t('Email')} formatErrorMessage={formatEmailErrorMessage}>
          <FormInput
            tabIndex={1}
            control={controls.email}
            placeholder={t('Email')}
            type="email"
            name="email"
            icon={<Icons.EmailIcon />}
          />
        </StyledFormControl>

        <StyledFormControl
          mt="12px"
          state={states.password}
          label={t('Password')}
          formatErrorMessage={(errors) => t(loginFormErrorMessages.password[errors[0]])}
        >
          <FormInputPassword
            control={controls.password}
            placeholder={t('Password')}
            icon={<Icons.PasswordIcon />}
            tabIndex={1}
          />
        </StyledFormControl>

        <StyledForgotPasswordText
          fontSize="12px"
          tabIndex={1}
          color="primary"
          mt="8px"
          id="forgotpassword-text"
          onClick={() => {
            handlePresentPasswordRecoveryModal({
              email: states.email.value,
              switchSignUpPage: (draftEmail) => {
                controls.email.onValueChanged(draftEmail)
                setTimeout(() => {
                  setPage(AuthModalPageEnums.SIGN_UP)
                })
              },
            })
          }}
        >
          <Trans>Forgot Password?</Trans>
        </StyledForgotPasswordText>

        <Button
          type="submit"
          disabled={!isValid || submiting}
          width="100%"
          mt="24px"
          tabIndex={1}
          id={'login-submit-button'}
        >
          {submiting && (
            <Box mr="12px">
              <CircleLoader />
            </Box>
          )}

          <Text bold fontSize="14px" color={submiting ? 'textSubtle' : 'text'}>
            <Trans>Log in</Trans>
          </Text>
        </Button>

        <ConditionAcccess />
      </Box>
    </OpenEffect>
  )
}

const StyledFormControl = styled(FormControl)<{ state: FieldState }>`
  ${({ state, theme }) =>
    state.errors.length === 0 && state.isDirty
      ? `
     ${InputContainer} {
        background-color: transparent !important;
        border-color: ${theme.colors.strokeAlt} !important;
      }
    `
      : ''}
`

const StyledForgotPasswordText = styled(Text)`
  text-decoration: underline;
  cursor: pointer;
`

export default TraditionalLoginForm

