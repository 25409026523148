import BigNumber from 'bignumber.js'
import { BIG_ZERO } from 'config/constants/number'
import {
  ReferralBalanceTokenAmount,
  ReferralBonus,
  ReferralCampaign,
  ReferralMetaData,
  ReferralOverview,
  ReferralOverviewResponse,
} from 'config/types/referral'
import { useRequest } from 'hooks/useRequest'
import { useEffect, useMemo, useState } from 'react'
import ReferralService from 'services/ReferralService'
import { BaseResponse, Paging } from 'services/types'
import { useTiers, useTokenUsdPrices } from 'state/app/hooks'
import { useAuth } from 'state/auth/hooks'
import { forkjoinRequest } from 'utils/requestHelper'

export const useFetchDefaultCampaign = () => {
  const { isSigned } = useAuth()
  const { execute } = useRequest()
  const [campaign, setCampaign] = useState<ReferralCampaign>(null)

  useEffect(() => {
    if (!isSigned) {
      setCampaign(null)
      return
    }
    const fetch = async () => {
      const response = await execute(ReferralService.getCampaignDefault())
      if (response && response.data) setCampaign(response.data)
    }

    fetch()
  }, [isSigned])

  return useMemo(() => ({ campaign }), [campaign])
}

export const useFetchMetaReferral = () => {
  const { execute } = useRequest()
  const [meta, setMeta] = useState<ReferralMetaData>({
    totalReferral: 0,
    totalRewardsUSD: 0,
    currenciesConvert: [],
  })

  useEffect(() => {
    const fetch = async () => {
      const response = await execute(ReferralService.getMeta())
      if (response && response?.data) setMeta(response?.data)
    }
    fetch()
  }, [])

  return useMemo(() => ({ meta }), [meta])
}

export const useFetchOverview = () => {
  const { execute } = useRequest()
  const [data, setData] = useState<{
    overview: ReferralOverview
    commissions: ReferralBalanceTokenAmount[]
    totalEarned: ReferralBalanceTokenAmount[]
    bonus: ReferralBonus
  }>({
    overview: null,
    commissions: [],
    totalEarned: [],
    bonus: {
      token: null,
      lockedAmount: '0',
      unlockedAmount: '0',
    },
  })
  const tiers = useTiers()

  const { hasSession } = useAuth()

  const fetch = async () => {
    if (!hasSession) return

    const [resultOverview, resultCommssion, totalEarned, resultBonus] = (await forkjoinRequest([
      execute(ReferralService.getOverview()),
      execute(ReferralService.getCommission()),
      execute(ReferralService.getTotalEarned()),
      execute(ReferralService.getReferralBonus()),
    ])) as [
      BaseResponse<ReferralOverviewResponse>,
      BaseResponse<Paging<ReferralBalanceTokenAmount>>,
      BaseResponse<Paging<ReferralBalanceTokenAmount>>,
      BaseResponse<ReferralBonus>,
    ]

    const referrer = resultOverview?.data?.referrer || null
    const userTier = referrer && tiers.find((tier) => tier.id === referrer.tierId)
    const overview = {
      ...resultOverview?.data,
      referrer: referrer
        ? {
            avatar: referrer.avatar,
            commissionEarnUsd: referrer.commissionEarnUsd,
            displayName: referrer.displayName,
            userCode: referrer.userCode,
            userTier,
          }
        : null,
    }

    setData({
      overview,
      commissions: resultCommssion?.data.items || [],
      totalEarned: totalEarned?.data?.items || [],
      bonus: resultBonus?.data,
    })
  }

  useEffect(() => {
    if (tiers.length > 0 || data?.overview) {
      fetch()
    }
  }, [hasSession, tiers])

  return useMemo(() => ({ ...data, refetch: fetch }), [data, fetch])
}

export const useTotalCommission = (commissions: ReferralBalanceTokenAmount[]) => {
  const prices = useTokenUsdPrices()
  return useMemo(() => {
    return commissions.length > 0
      ? commissions.reduce((state, commission) => {
          return state.plus(new BigNumber(commission.amount).multipliedBy(prices?.[commission.token.code] || 1))
        }, BIG_ZERO)
      : BIG_ZERO
  }, [commissions, prices])
}
