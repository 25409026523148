import { TokenAmount } from 'config/types'
import { useGetDisplayAmount } from 'state/profile/hooks'
import { getFullDisplayBalance } from 'utils/formatBalance'

const TokenAmountFiatDisplay: React.FC<{
  tokenAmount: TokenAmount
  showUsdAsDefault?: boolean
  limitDisplayDecimals?: number
}> = ({
  tokenAmount,
  showUsdAsDefault,
  limitDisplayDecimals = 6, // TODO
}) => {
  const { displayAmount, symbol, selectedFiat, isDisplayInFiat } = useGetDisplayAmount(tokenAmount, showUsdAsDefault)
  const displayDecimals = isDisplayInFiat
    ? selectedFiat.displayDecimals > limitDisplayDecimals
      ? limitDisplayDecimals
      : selectedFiat.displayDecimals
    : limitDisplayDecimals

  return <>{`${symbol}${getFullDisplayBalance(displayAmount, 0, displayDecimals)}`}</>
}

export default TokenAmountFiatDisplay

