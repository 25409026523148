/* eslint-disable no-template-curly-in-string */
import Box from 'components/Box/Box'
import Grid from 'components/Box/Grid'
import Button from 'components/Button'
import CircleLoader from 'components/Loader/CircleLoader'
import Progress from 'components/Progress'
import Text from 'components/Text'
import { DailyRewardPackageCode } from 'config/constants/dailyReward'
import { RouteConfig } from 'config/constants/route'
import useAuthenticationModal from 'hooks/useAuthenticationModal'
import useDetectComponentOnScreen from 'hooks/useDetectComponentOnScreen'
import useMatchBreakpoints from 'hooks/useMatchBreakpoints'
import { useCloseAllModal } from 'hooks/useModal'
import { useRouter } from 'hooks/useRouter'
import { ColumnCenter } from 'layout/Components/Column'
import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useAuth } from 'state/auth/hooks'
import { useDailyRewardPackage, useTodayRewards } from 'state/bonus/hooks/dailyReward'
import styled from 'styled-components'
import theme from 'theme'
import { colors } from 'theme/colors'
import CountdownToNextDay from './component/CountdownToNextDay'
import RewardCard from './component/RewardCard'

const DailyCheckinRewards: React.FC<{ packageCode: DailyRewardPackageCode }> = ({ packageCode }) => {
  const dailyRewardPackage = useDailyRewardPackage(packageCode)
  const { currentRewards, claimReward } = useTodayRewards()
  const [onPresentLoginModal] = useAuthenticationModal()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const currentReward = currentRewards?.find((item) => item.packageCode === packageCode)

  const { hasSession } = useAuth()
  const router = useRouter()
  const closeModal = useCloseAllModal()

  const { ref } = useDetectComponentOnScreen({ root: null })

  const { isMobile } = useMatchBreakpoints()

  const isCompletedCampaign = dailyRewardPackage.rewards[dailyRewardPackage.rewards.length - 1]?.userData?.isClaimed
  const { t } = useTranslation()

  const handleSubmit = () => {
    setIsSubmitting(true)
    if (dailyRewardPackage.isTodayClaimed) return

    if (currentReward.isWaitingClaim) {
      claimReward(packageCode)
    } else {
      closeModal()
      router.push(RouteConfig.Games)
    }
    setTimeout(() => {
      setIsSubmitting(false)
    }, 1000)
  }

  useEffect(() => {
    ref.current?.scrollIntoView()
  }, [currentReward])

  return (
    <ColumnCenter>
      <Grid
        gridTemplateColumns={['1fr 1fr 1fr 1fr', '1fr 1fr 1fr 1fr', '1fr 1fr 1fr 1fr']}
        width="100%"
        gridColumnGap="8px"
        gridRowGap="16px"
        mb="20px !important"
        maxHeight="246px"
        overflowY="scroll"
      >
        {dailyRewardPackage.rewards.map((item) => {
          return (
            <RewardCard
              key={`${packageCode}-${item.id}`}
              rewardDetail={item}
              isActive={currentReward.reward?.id === item.id}
              isHighlight={item.isHighlight}
              refReward={item.id === currentReward.reward?.id ? ref : null}
            />
          )
        })}
      </Grid>

      {hasSession ? (
        <StyledButton
          isMobile={isMobile}
          disabled={
            (!currentReward.isWaitingClaim && currentReward.allWagerAmountToClaim > 0) ||
            dailyRewardPackage?.isTodayClaimed ||
            isSubmitting ||
            isCompletedCampaign
          }
          onClick={() => {
            handleSubmit()
          }}
        >
          {isSubmitting && (
            <Box mr="12px">
              <CircleLoader />
            </Box>
          )}

          <Trans>
            {currentReward.isWaitingClaim ||
            currentReward.reward?.userData?.isClaimed ||
            isCompletedCampaign ||
            (!currentReward.isWaitingClaim && currentReward.allWagerAmountToClaim > 0)
              ? 'Claim'
              : 'Wager to claim'}
          </Trans>
        </StyledButton>
      ) : (
        <StyledButton
          isMobile={isMobile}
          onClick={() => {
            onPresentLoginModal()
          }}
        >
          <Trans>Login to claim</Trans>
        </StyledButton>
      )}

      {hasSession &&
        (dailyRewardPackage?.isTodayClaimed || currentReward?.todayWagerAmountToClaim > 0 || isCompletedCampaign) && (
          <ColumnCenter mt="12px">
            {dailyRewardPackage.wagerRequired.todayAmount.amount > 0 &&
              !currentReward.reward?.userData?.isClaimed &&
              currentReward.todayWagerAmountToClaim > 0 &&
              !isCompletedCampaign && (
                <Progress
                  scale={'md'}
                  primaryStep={currentReward.progressWagerCondition || 0}
                  height="4px !important"
                  maxWidth={['320px !important']}
                  background={'rgba(35, 44, 61, 0.8) !important'}
                  showProgress={false}
                  mb="4px"
                  barStyles={{
                    borderRadius: `${theme.radii.default} !important`,
                    background: `${colors.success} !important`,
                  }}
                />
              )}

            <CountdownToNextDay
              isTodayClaimed={dailyRewardPackage?.isTodayClaimed}
              wagerRequiredAmount={currentReward?.todayWagerAmountToClaim}
              isCompletedCampaign={isCompletedCampaign}
            />
          </ColumnCenter>
        )}

      {hasSession &&
        !currentReward.isWaitingClaim &&
        currentReward.allWagerAmountToClaim > 0 &&
        !dailyRewardPackage.isTodayClaimed && (
          <Text as="span" fontSize="12px" color="textSubtle" mt="12px">
            <Trans>
              {t('Your total wager must be at least ${{allWagerAmountRequired}} to claim this reward', {
                allWagerAmountRequired: currentReward.allWagerAmountToClaim,
              })}
            </Trans>
          </Text>
        )}
    </ColumnCenter>
  )
}

const StyledButton = styled(Button)<{ isMobile: boolean }>`
  background: ${(props) => (props.disabled ? `${colors.modalHeaderBackground} !important` : '')};
  width: ${(props) => (props.isMobile ? '100%' : '360px')};
  font-weight: 600;
`

export default DailyCheckinRewards
