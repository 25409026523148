import Box from 'components/Box/Box'
import Image from 'components/Image'
import BackgroundImage from 'components/Image/BackgroundImage'
import styled from 'styled-components'
import theme from 'theme'
import hunnyKeyframes from 'theme/keyframes'

const WheelBackground = () => {
  return (
    <Box width="100%" position="relative" height="100%" overflowX="hidden" overflowY="hidden">
      <StyledWheelRadius
        width={445}
        height={445}
        left="-18% !important"
        border={`4px solid ${theme.colors.strokeAlt2} !important`}
      />

      <StyledWheelRotateRadius src="/images/luckyspin/wheel-radius.png" alt="Wheel Background Radius" />
      <StyledWheelRotateRadius src="/images/luckyspin/wheel-radius.png" alt="Wheel Background Radius" $isReverted />

      <StyledWheelRadius width={600} height={600} left="-30% !important" />
      <Box
        position="absolute"
        left={0}
        top={0}
        width={450}
        height={620}
        display={['none', 'none', 'none', 'none', 'block']}
      >
        <Box position="absolute" right={20} top={30} width={120} height={55}>
          <Image width={120} height={55} src="/images/luckyspin/wheel-decor-text-small.png" />
        </Box>
      </Box>

      <StyledWheelRadius width={670} height={670} left="-30% !important" />
      <Box
        position="absolute"
        left={0}
        top={0}
        width={450}
        height={620}
        display={['none', 'none', 'none', 'none', 'block']}
      >
        <Box position="absolute" right={30} top={105} width={120} height={55}>
          <Image width={120} height={55} src="/images/luckyspin/wheel-decor-text-big.png" />
        </Box>
      </Box>
    </Box>
  )
}

const StyledWheelRadius = styled(Box)`
  position: absolute;
  display: none;

  border-radius: 50%;
  border: ${({ theme: { colors } }) => `0.5px solid ${colors.strokeAlt2}`};

  ${({ theme: { mediaQueries } }) => mediaQueries.lg} {
    display: block;
    top: 55%;
    transform: translateY(-50%);
  }

  z-index: 1;
`

const StyledWheelRotateRadius = styled(BackgroundImage)<{ $isReverted?: boolean }>`
  width: 500px;
  height: 500px;

  border-radius: 50%;
  z-index: 1;

  position: absolute;
  top: 12.5%;
  left: 50%;

  display: none;

  @keyframes rotate {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }

  animation: rotate 15s linear infinite forwards;
  animation-direction: ${({ $isReverted }) => ($isReverted ? 'reverse' : 'normal')};

  ${({ theme: { mediaQueries } }) => mediaQueries.sm} {
    display: block;
  }

  ${({ theme: { mediaQueries } }) => mediaQueries.lg} {
    top: 12.5%;
    left: -21.5%;
    transform: translate(-50%, -50%);

    animation: ${hunnyKeyframes.rotate} 15s linear infinite forwards;
  }
`

export default WheelBackground

