import BigNumber from 'bignumber.js'
import { USD_CURRENCY } from 'config/constants/tokens'
import { BaseResponse } from 'services/types'
import { PlinkoBetResponse } from 'views/InhouseGame/Plinko/types/Bet'

export const PlinkoSubmitMapper = (response: any): BaseResponse<PlinkoBetResponse> => {
  return {
    ...response,
    data: response.data &&
      response.data.payout_result && {
        id: response.data.payout_result.round_id,
        betAmount: {
          amount: new BigNumber(response.data.payout_result.payout),
          token: USD_CURRENCY,
        },
        payoutResult: Number(response.data.game_result.payout),
        userBalance: new BigNumber(response.data.payout_result.balance),
      },
  }
}
