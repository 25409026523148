import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import { BoxProps } from 'components/Box/types'
import Button from 'components/Button'
import NumericalInput from 'components/Input/NumericalInput'
import { InputContainer, StyledInput } from 'components/Input/styled'
import { Position } from 'components/Popper/typpes'
import Select, { SelectProps, StyledExpansitionIconContainer } from 'components/Select'
import Tab from 'components/Tab'
import Tabs from 'components/Tabs'
import Text from 'components/Text'
import { RowCenter, RowMiddle } from 'layout/Components/Row'
import React from 'react'
import styled, { css } from 'styled-components'
import { Icons, getIcon } from 'svgs'
import theme from 'theme'

export const InHouseLabel = styled(Text).attrs({ color: 'textSubtle' })`
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 6px;
`

const StyledWrapperSelect = styled(Box)<{ dropdownPosition?: Position }>`
  font-size: 14px;

  .select-dropdown {
    font-size: 14px;
    border: none;

    ${({ dropdownPosition }) =>
      /bottom/.test(dropdownPosition)
        ? css`
            margin-top: 6px;
          `
        : css`
            margin-bottom: 6px;
          `}

    border-radius: ${({ theme }) => theme.radii.tiny};
    background: #22262e;

    .option-items {
      padding: 10px 14px;
      font-size: 14px;

      &:focus-visible {
        background-color: transparent;
      }
    }
  }
`
const StyledSelect = styled(Select)<{ disabled?: boolean }>`
  padding: 8px 12px;
  border-radius: 4px;
  border: 2px solid ${({ theme }) => theme.colors.backgroundAlt8};
  background: ${({ theme }) => theme.colors.backgroundAlt9};
  font-size: 14px;
  min-height: 100%;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.12);

  ${StyledExpansitionIconContainer} {
    margin: 0;
    padding: 0;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
    `}
`

export const WrapperFilter: React.FC<
  React.PropsWithChildren<
    SelectProps &
      Omit<BoxProps, 'onSelect' | 'options'> & {
        disabled?: boolean
      }
  >
> = ({ disabled, options, onSelect, children, OptionItemComponent, dropdownPosition = 'bottom', ...props }) => {
  return (
    <StyledWrapperSelect dropdownPosition={dropdownPosition} {...props}>
      <StyledSelect
        disabled={disabled}
        dropdownPosition={dropdownPosition}
        dropdownContentProps={{
          maxHeight: '160px !important',
          className: 'select-dropdown',
        }}
        options={options}
        onSelect={(data) => {
          if (!disabled) {
            onSelect(data.value)
          }
        }}
        OptionItemComponent={OptionItemComponent}
      >
        {children}
      </StyledSelect>
    </StyledWrapperSelect>
  )
}

export const InHouseTabs = styled(Tabs)<{ $disabled?: boolean }>`
  padding: 4px;
  background: rgba(255, 255, 255, 0.04);
  border-radius: ${({ theme }) => theme.radii.tiny};
  max-width: fit-content;

  box-shadow: 0px 2px 4px 4px rgba(0, 0, 0, 0.05);

  width: 100%;
  max-width: 100%;

  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.5;
      user-select: none;
      cursor: not-allowed;

      ${InHouseTab} {
        user-select: none;
        cursor: not-allowed;
      }
    `}
`

export const InHouseTab = styled(Tab)<{ $active: boolean }>`
  padding: 8px 12px;
  width: 100%;

  line-height: 16px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  transition: ${({ theme }) => theme.transitions.fast};
  color: ${({ theme, $active }) => ($active ? `${theme.colors.text}` : theme.colors.textSubtle)};
  white-space: pre !important;
  font-weight: 600;
  font-size: 12px;

  ${({ theme }) => theme.mediaQueries.sm} {
    font-size: 14px;
  }
`
export const InHouseIndicator = styled(Box)`
  border-radius: ${({ theme }) => theme.radii.tiny};
  background: ${({ theme }) => theme.colors.backgroundAlt8};

  height: 100%;
  width: 100%;
`

export const InhouseGameInputContainer = styled(InputContainer)<{ disabled?: boolean; $error?: boolean }>`
  width: 100%;
  height: ${({ height }) => height || '40px'};

  border-color: ${({ theme }) => theme.colors.backgroundAlt8};
  border-radius: ${({ theme }) => theme.radii.tiny};
  border-width: 2px;

  background: ${({ theme }) => theme.colors.backgroundAlt9};

  ${({ $error, theme }) =>
    $error
      ? css`
          border-color: ${theme.colors.error};
        `
      : ''}

  &:has(input:disabled) {
    opacity: 0.5;
    border: 2px solid ${({ theme }) => theme.colors.backgroundAlt8} !important;
    &:hover {
      border: 2px solid ${({ theme }) => theme.colors.backgroundAlt8} !important;
    }
  }
`

export const InHouseGameNumericalInput = styled(NumericalInput)<BoxProps>`
  min-height: calc(100% - 4px);
  text-align: right;
  height: auto !important;
  color: ${({ theme: { colors } }) => colors.text};
  height: ${({ height }) => height};
`

export const InHouseGameInput = styled(StyledInput)<BoxProps>`
  min-height: calc(100% - 4px);
  text-align: left;
  height: auto !important;
  color: ${({ theme: { colors } }) => colors.text};
  height: ${({ height }) => height};
`

const StyledMenuSettingItem = styled(RowMiddle)<{ $active: boolean }>`
  color: ${({ theme }) => theme.colors.text};
  padding: 12px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;

  ${({ $active, theme }) =>
    $active &&
    css`
      color: ${theme.colors.primary};

      svg {
        fill: ${theme.colors.primary} !important;

        path {
          fill: ${theme.colors.primary} !important;
        }
      }
    `};

  ${({ theme }) => theme.mediaQueries.lg} {
    &:hover {
      background: ${({ theme: { colors } }) => colors.backgroundAlt4};
    }
  }
`

export const MenuSettingItem: React.FC<
  React.PropsWithChildren<{ icon: string; active?: boolean; onClick: () => void }>
> = ({ icon, active, onClick, children }) => {
  const Icon = getIcon(icon)

  return (
    <StyledMenuSettingItem $active={active} onClick={onClick}>
      <RowCenter mr="8px" width="16px" minWidth="16px">
        <Icon />
      </RowCenter>

      {children}
    </StyledMenuSettingItem>
  )
}

export const InHouseGameButton = styled(Button)`
  font-weight: 600;
  border-radius: ${({ theme }) => theme.radii.tiny};
  font-size: 14px;
`

export const StyledBounce = styled(Box)`
  animation: bounce 1.1s infinite;

  @keyframes bounce {
    10% {
      transform: translateY(-60%);
    }

    30% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-40%);
    }

    70% {
      transform: translateY(0);
    }

    90% {
      transform: translateY(-20%);
    }
  }
`
export const StyledZoom = styled(Box)`
  animation: zoom 0.5s infinite;

  @keyframes zoom {
    0% {
      transform: scale(1);
    }

    30% {
      transform: scale(0.9);
    }

    60% {
      transform: scale(1);
    }

    90% {
      transform: scale(1.1);
    }
  }
`

export const StyledRotate = styled(Box)`
  transform-origin: center;
  animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }

    90%,
    100% {
      transform: rotate(360deg);
    }
  }
`

export const StyledBoom = styled(Box)`
  animation-name: boom;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.87, -0.41, 0.19, 1.44);

  @keyframes boom {
    25% {
      transform: scale(1.3);
    }
    50% {
      transform: scale(1.3) rotate(-10deg);
    }
    75% {
      transform: scale(1.3) rotate(10deg);
    }
  }
`

export const StyledRoll = styled(Box)`
  width: 16px;
  height: 16px;
  transform-origin: center;
  animation-name: roll;
  animation-duration: 1.6s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @keyframes roll {
    0%,
    5% {
      transform: rotate(0) translate(0);
    }
    15% {
      transform: rotate(45deg) translate(-10%, -10%);
    }
    20%,
    30% {
      transform: rotate(90deg) translate(0);
    }
    37.5% {
      transform: rotate(135deg) translate(-10%, 10%);
    }
    45%,
    55% {
      transform: rotate(180deg) translate(0);
    }
    62.5% {
      transform: rotate(225deg) translate(10%, 10%);
    }
    70%,
    80% {
      transform: rotate(270deg) translate(0);
    }
    87.5% {
      transform: rotate(315deg) translate(10%, -10%);
    }
    95%,
    100% {
      transform: rotate(360deg) translate(0);
    }
  }

  @keyframes zoom {
    0% {
      transform: rotate(0);
    }

    11% {
      transform: rotate(90deg);
    }

    25% {
      transform: rotate(90deg);
    }

    37.5% {
      transform: rotate(180deg);
    }

    60% {
      transform: rotate(180deg);
    }

    87.5% {
      transform: rotate(270deg);
    }

    100% {
      transform: rotate(270deg);
    }
  }
`

export const PlinkoAnimationBounce: React.FC<BoxProps> = ({ color = theme.colors.text, ...props }) => {
  return (
    <StyledBounce as="span" minWidth="16px" minHeight="16px" {...props}>
      <Icons.PlinkoLoadingIcon fill={color} />
    </StyledBounce>
  )
}

export const LimboAnimation: React.FC<BoxProps> = ({ color = theme.colors.text, ...props }) => {
  return (
    <StyledZoom as="span" minWidth="16px" minHeight="16px" {...props}>
      <Icons.LimboLoadingIcon fill={color} />
    </StyledZoom>
  )
}

export const DiceAnimation: React.FC<BoxProps> = ({ color = theme.colors.text, ...props }) => {
  return (
    <StyledRoll as="span" minWidth="16px" minHeight="16px" {...props}>
      <Icons.DiceLoadingIcon fill={color} />
    </StyledRoll>
  )
}

export const WheelAnimation: React.FC<BoxProps> = ({ color = theme.colors.text, ...props }) => {
  return (
    <StyledRotate as="span" width="16px" height="16px" {...props}>
      <Icons.WheelLoadingIcon fill={color} />
    </StyledRotate>
  )
}

export const MineAnimation: React.FC<BoxProps> = ({ color = theme.colors.text, ...props }) => {
  return (
    <StyledBoom as="span" width="16px" height="16px" {...props}>
      <Icons.MineLoadingIcon fill={color} />
    </StyledBoom>
  )
}

export const StyledInhouseGameContainer = styled(Flex)`
  align-items: center;
  flex-direction: column-reverse;
  overflow: hidden;
  position: relative;
  flex: 1;

  width: 100%;
  height: 100%;

  ${({ theme }) => theme.mediaQueries.lg} {
    display: grid;
    grid-template-columns: 300px 1fr;
  }
`
