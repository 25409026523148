import { getAddress, isAddress } from '@ethersproject/address'
import { PublicKey } from '@solana/web3.js'
import validate from 'bitcoin-address-validation'
import { ChainIdEnum } from 'config/constants/network'
import { UsableFiatCurrencies } from 'config/constants/tokens/fiat'
import { NetworkType, Token } from 'config/types'
import TonWeb from 'tonweb'
import { WalletType } from 'config/types/wallet'

export const isSolToken = (token: Token) => {
  return token?.network === ChainIdEnum.SOL || token?.network === ChainIdEnum.SOL_TESTNET
}
export const isFiatCurrency = (tokenCode: string) => UsableFiatCurrencies.includes(tokenCode)

export const isFiatCurrencyV2 = (token: Token) => token.network === ChainIdEnum.FIAT

export const checkTokenEqual = (token1: Token, token2: Token): boolean => {
  return token1 && token2 && token1.code === token2.code && token1.network === token2.network
}

export const getNetworkType = (chainId: ChainIdEnum) => {
  if (chainId === ChainIdEnum.SOL || chainId === ChainIdEnum.SOL_TESTNET) return NetworkType.SOLANA
  if (chainId === ChainIdEnum.HPN) return NetworkType.HPN
  if (chainId === ChainIdEnum.BTC || chainId === ChainIdEnum.BTC_TESTNET) return NetworkType.BTC
  if (chainId === ChainIdEnum.TRX || chainId === ChainIdEnum.TRX_TESTNET) return NetworkType.TRX
  if (chainId === ChainIdEnum.TON || chainId === ChainIdEnum.TON_TESTNET) return NetworkType.TON
  return NetworkType.EVM
}

export const getNetworkCodeType = (chainId: ChainIdEnum) => {
  const networkType = getNetworkType(chainId)
  switch (networkType) {
    case NetworkType.BTC:
      return 'utxo-bitcoin'
    case NetworkType.SOLANA:
      return 'solana'
    case NetworkType.TRX:
      return 'tron'
    case NetworkType.TON:
      return 'ton'
    default:
      return 'evm'
  }
}

export const validateUserAddressByNetwokType = (address: string, type: NetworkType) => {
  if (!address) return false
  try {
    switch (type) {
      case NetworkType.TON:
        return TonWeb.utils.Address.isValid(address)

      case NetworkType.SOLANA:
        const owner = new PublicKey(address)
        return PublicKey.isOnCurve(owner.toBytes())

      case NetworkType.BTC:
        return validate(address)

      case NetworkType.TRX: // TODO
        if (!/T[A-Za-z1-9]{33}/.test(address)) {
          return false
        }
        return true

      case NetworkType.EVM:
        if (!/^(0x)?[0-9a-f]{40}$/i.test(address)) {
          return false
        }

        // Check for checksum validity
        const cleanAddress = address.toLowerCase().replace('0x', '')
        const addressWithChecksum = getAddress(cleanAddress)
        return address.toLowerCase() === addressWithChecksum.toLowerCase()
      default:
        return false
    }
  } catch {
    return false
  }
}
export const getWalletTypeByAddress = (address: string) => {
  if (isAddress(address)) {
    return WalletType.EVM
  }

  if (PublicKey.isOnCurve(new PublicKey(address).toBytes())) {
    return WalletType.SOL
  }

  return null
}
export const getTokenName = (token: Token) => token?.name || 'Unknown'
