import { WageringConditionBonus } from 'config/types/bonus/userBonus'
import useDebounceCallback from 'hooks/useDebounceCallback'
import { useEffect, useRef, useState } from 'react'
import BonusService from 'services/BonusService'
import { useActivatedBonuses } from 'state/bonus/hooks'

export const emptyBonus = { id: 0 } as any

export const useWaitingDepositToActiveBonus = () => {
  const [bonuses, setBonuses] = useState<WageringConditionBonus[]>([])
  const [selectedBonus, setSelectedBonus] = useState<WageringConditionBonus>(emptyBonus)
  const [isSelectedBonusExpired, setIsSelectedBonusExpired] = useState(false)

  const { activatedBonuses } = useActivatedBonuses()
  const debounceCallback = useDebounceCallback()
  const currentSelectedBonusRef = useRef(emptyBonus)

  useEffect(() => {
    const fetch = async () => {
      const response = await BonusService.getWaitingDepositUserBonuses().call()
      const bonuses = response?.data?.items || []

      if (bonuses.length > 0)
        setBonuses([
          emptyBonus,
          ...(bonuses.filter((bonus) => bonus instanceof WageringConditionBonus) as WageringConditionBonus[]),
        ])
      else setBonuses([])

      const selectedBonus = bonuses.find((bonus) => bonus.id === response.data?.selectedDepositBonusId)
      currentSelectedBonusRef.current = selectedBonus || emptyBonus
      setSelectedBonus(selectedBonus || emptyBonus)
    }
    fetch()
  }, [activatedBonuses])

  useEffect(() => {
    if (selectedBonus.id === 0) {
      setIsSelectedBonusExpired(false)
      return
    }

    setIsSelectedBonusExpired(selectedBonus.condition.isExpired)
    const interval = setInterval(() => setIsSelectedBonusExpired(selectedBonus.condition.isExpired), 1000)

    return () => clearInterval(interval)
  }, [selectedBonus])

  const handleSelectedBonus = async (bonus: WageringConditionBonus) => {
    setSelectedBonus(bonus)

    debounceCallback(async () => {
      const isSuccess = await BonusService.prepareDepositBonus(bonus.id)
      if (isSuccess) {
        currentSelectedBonusRef.current = bonus
      } else {
        setSelectedBonus(currentSelectedBonusRef.current)
      }
    }, 500)
  }

  return { bonuses, handleSelectedBonus, selectedBonus, isSelectedBonusExpired }
}

