import BigNumber from 'bignumber.js'
import { FetchingStatus } from 'config/constants'
import { CountryEnum } from 'config/constants/country'
import { ChainIdEnum, FIAT_NETWORK, NETWORK_MAP } from 'config/constants/network'
import { BIG_ZERO } from 'config/constants/number'
import tokens, { HUSD_TOKEN } from 'config/constants/tokens'
import { UsableFiatCurrencies } from 'config/constants/tokens/fiat'
import { DepositedTokenInfo, Network, PaymentMethod, ProviderInfo, Token, TokenValidationResolve } from 'config/types'
import { WagerAdventure } from 'config/types/bonus/WagerAdventure'
import { GameDetail } from 'config/types/game'
import { WalletType } from 'config/types/wallet'
import { useIsomorphicEffect } from 'hooks/useIsomorphicEffect'
import useMatchBreakpoints from 'hooks/useMatchBreakpoints'
import { useRequest } from 'hooks/useRequest'
import { useRouter } from 'hooks/useRouter'
import { useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import MetaService from 'services/MetaService'
import { AppState, useAppDispatch, useAppSelector } from 'state'
import { useAuthBy, useUserInfo } from 'state/auth/hooks'
import theme from 'theme'
import { breakpointMap } from 'theme/base'
import { getNetworkInfo, isTonChain } from 'utils/network'
import { buildResponsiveStyledSystemWithExceptedWidth } from 'utils/styled'
import { checkTokenEqual, isSolToken } from 'utils/token'
import { setCurrencyConversionRateInUSD, setMenuOpen, setPlayingGame, updateTokenUsdPrices } from './actions'
import { fetchTokenInUsdPrices } from './calls/fetchPriceInUsd'

export const useSolNetworkInfo = () => {
  const networkIds = useAppSelector((state) => state.app.networkIds)
  const solChain = networkIds?.find((chain) => chain === 'SOL_TESTNET' || chain === 'SOL')

  return NETWORK_MAP[ChainIdEnum[solChain] || ChainIdEnum.SOL]
}

export const usePollTokenUsdPrices = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    const fetch = async () => {
      const response = await fetchTokenInUsdPrices()
      if (response) dispatch(updateTokenUsdPrices({ data: response.data }))
    }

    const interval = setInterval(fetch, 30000)
    return () => {
      clearInterval(interval)
    }
  }, [tokens])
}

export const useFetchTokenUsdPrices = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    const fetch = async () => {
      const response = await fetchTokenInUsdPrices()
      if (response) dispatch(updateTokenUsdPrices({ data: response.data }))
    }

    fetch()
  }, [])
}

export const useTokenUsdPrices = () => {
  const tokenUsdPrices = useSelector((state: AppState) => state.app.tokenUsdPrices)

  return useMemo(() => ({ ...tokenUsdPrices, [HUSD_TOKEN.code]: '1' }), [tokenUsdPrices])
}

export const useTokenUsdPrice = (token: Token) => {
  const tokenUsdPrices = useSelector((state: AppState) => state.app.tokenUsdPrices)

  return token ? new BigNumber(tokenUsdPrices[token.code]) : BIG_ZERO
}

export const useTokenUsdPriceCallback = () => {
  const tokenUsdPrices = useSelector((state: AppState) => state.app.tokenUsdPrices)
  const getter = useCallback(
    (selectedToken: Token) => {
      return selectedToken ? new BigNumber(tokenUsdPrices[selectedToken.code]) : BIG_ZERO
    },
    [tokenUsdPrices],
  )

  return getter
}

export const useMenuContainer = () => {
  const dispatch = useAppDispatch()
  const isMenuOpen = useAppSelector((state) => state.app.isMenuOpen)
  const toggleMenu = useCallback(() => {
    dispatch(setMenuOpen({ isOpen: !isMenuOpen }))
  }, [isMenuOpen])

  const menuWidth = useMemo(() => {
    if (window.document.body.offsetWidth > breakpointMap.xl) {
      return isMenuOpen ? theme.navigationWidth : theme.navigationSmallWidth
    }
    if (window.document.body.offsetWidth > breakpointMap.md) {
      return theme.navigationSmallWidth
    }

    return 0
  }, [isMenuOpen])

  return { toggleMenu, isMenuOpen, menuWidth }
}

export const useResponsiveLayout = () => {
  return useCallback((responsive: any[]) => buildResponsiveStyledSystemWithExceptedWidth(responsive), [])
}

export const useMenuToggleUpdater = () => {
  const dispatch = useAppDispatch()
  const router = useRouter()
  const { isXxl, isXxxl } = useMatchBreakpoints()

  useIsomorphicEffect(() => {
    if (!isXxl && !isXxxl) {
      dispatch(setMenuOpen({ isOpen: false }))
    } else {
      dispatch(setMenuOpen({ isOpen: true }))
    }
  }, [])

  useEffect(() => {
    if (!isXxxl && !isXxl) {
      dispatch(setMenuOpen({ isOpen: false }))
    }
  }, [router.pathname])
}

export const useListNetworks = (
  filter: { excludeChains?: ChainIdEnum[]; selectChains?: ChainIdEnum[]; includeFiat?: boolean } = {},
): Network[] => {
  const { excludeChains, selectChains, includeFiat } = filter

  const networkIds = useAppSelector((state) => state.app.networkIds)

  return useMemo(() => {
    return networkIds
      ?.filter((network) => !selectChains || selectChains.includes(ChainIdEnum[network]))
      .filter((network) => !excludeChains || !excludeChains.includes(ChainIdEnum[network]))
      .map((network) => NETWORK_MAP[ChainIdEnum[network]])
      .concat(includeFiat ? [FIAT_NETWORK] : [])
      .filter((item) => item)
  }, [networkIds])
}

export const useAllTokens = (
  condition: (token: Token, index?: number, array?: Token[], info?: DepositedTokenInfo) => boolean = () => true,
  dependencies: any[] = [],
): Token[] => {
  const tokenInfoes = useAppSelector((state) => state.app.tokenInfoes)
  const tokenCodes = tokenInfoes?.map((token) => `${token.network}-${token.token}`).join(',')
  return useMemo(() => {
    return tokenInfoes
      ?.map((tokenInfo) => {
        return tokens[tokenInfo.network] && tokens[tokenInfo.network][tokenInfo.token]
      })
      .filter((token, index, array) => {
        return condition(token, index, array, tokenInfoes[index])
      })
  }, [tokenCodes, ...dependencies])
}

export const useTokenInfo = (token: Token): DepositedTokenInfo => {
  const tokenInfoes = useAppSelector((state) => state.app.tokenInfoes)

  return useMemo(() => {
    return tokenInfoes?.find((tokenInfo) => {
      return tokenInfo.network === token.network && tokenInfo.token === token.code
    })
  }, [token, tokenInfoes])
}

export const useAllBlacklistWithdrawalTokens = () => {
  const { authBy } = useUserInfo()
  const blacklistTokens = useAllTokens(
    (_token, _, _array, info) => {
      // Disable wallet user withdraw BTC
      return info.disabledWithdrawal
    },
    [authBy],
  )
  return blacklistTokens
}

export const useTokenPaymentMethod = (token: Token): PaymentMethod => {
  const { isAuthByEmail, isAuthByTelegram } = useAuthBy()
  const selectedTokenInfo = useTokenInfo(token)
  const { wallet } = useAppSelector((state) => state.auth)
  const tokenBlackList = useAllBlacklistWithdrawalTokens()

  return useMemo(() => {
    if (isTonChain(token.network)) return PaymentMethod.TransferToken

    if (isAuthByEmail || isAuthByTelegram) return PaymentMethod.TransferToken

    if (!selectedTokenInfo?.contractAddress) return PaymentMethod.TransferToken

    if (wallet?.type !== WalletType.SOL && isSolToken(token)) return PaymentMethod.TransferToken

    if (wallet?.type === WalletType.SOL && !isSolToken(token)) return PaymentMethod.TransferToken

    return PaymentMethod.CallContract
  }, [tokenBlackList, token])
}

export const useValidateWithdrawToken = (token: Token): TokenValidationResolve => {
  const tokenBlackList = useAllBlacklistWithdrawalTokens()
  const { wallet } = useAppSelector((state) => state.auth)

  return useMemo(() => {
    const tokenInBlackList = tokenBlackList.find((tokenBlackList) => checkTokenEqual(tokenBlackList, token))
    return {
      isLogicLocked: token !== HUSD_TOKEN && wallet?.type === WalletType.SOL && !isSolToken(token),
      isBlackList: token !== HUSD_TOKEN && !!tokenInBlackList,
    }
  }, [tokenBlackList, token])
}

export const useAllBlacklistDepositTokens = () => {
  const { authBy } = useUserInfo()

  const blacklistTokens = useAllTokens(
    (_token, _, _array, info) => {
      return info.disabledDeposit
    },
    [authBy],
  )
  return blacklistTokens
}

export const useValidateDepositToken = (token: Token): TokenValidationResolve => {
  const { wallet } = useAppSelector((state) => state.auth)

  const tokenBlackList = useAllBlacklistDepositTokens()

  return useMemo(() => {
    const tokenInBlackList = tokenBlackList.find((tokenBlackList) => checkTokenEqual(tokenBlackList, token))
    return {
      isLogicLocked: token !== HUSD_TOKEN && wallet?.type === WalletType.SOL && !isSolToken(token),
      isBlackList: !!tokenInBlackList,
    }
  }, [tokenBlackList, token, wallet])
}

export const useAllBlacklistPlayTokens = () => {
  const blacklistTokens = useAllTokens((_token, _, _array, info) => {
    return info.disabledPlay
  })
  return blacklistTokens
}

export const useValidatePlayToken = (token?: Token): TokenValidationResolve => {
  const tokenBlackList = useAllBlacklistPlayTokens()

  const validate = useCallback(
    (token: Token) => {
      return !tokenBlackList.find((tokenBlackList) => checkTokenEqual(tokenBlackList, token))
    },
    [tokenBlackList],
  )

  return useMemo(() => {
    return {
      isLogicLocked: false,
      isBlackList: !validate(token),
      validate,
    }
  }, [validate, token])
}

export const useTokenDetails = (network: ChainIdEnum, code: string) => {
  return useMemo(() => {
    return tokens[network] && tokens[network][code]
  }, [network, code])
}

export const useListTokens = (networkId?: ChainIdEnum): Token[] => {
  const tokenInfoes = useAllTokens((token: Token) => !networkId || token.network === networkId, [networkId])

  return tokenInfoes
}

export const useFiatCurrencies = () => {
  const tokenInfoes = useAllTokens((token: Token) => UsableFiatCurrencies.includes(token.code), [])
  return tokenInfoes
}

export const useNetwork = (chainId: ChainIdEnum): { network: Network; native: Token } => {
  const { network, native } = getNetworkInfo(chainId)

  return useMemo(() => {
    return { network, native }
  }, [network, native])
}

export const useConnectedSystem = () => {
  const serverTime = useAppSelector((state) => state.app.serverTime)

  return !!serverTime
}

export const useClientIpInfo = (): { country: CountryEnum; registeredCountry: CountryEnum } => {
  const country = useAppSelector((state) => state.app.clientCountry)
  const registeredCountry = useAppSelector((state) => state.profile.registeredCountry)

  return {
    country,
    registeredCountry,
  }
}

export const useProviderInfo = (providerCode: string) => {
  const providers = useAppSelector((state) => state.app.providers)
  return useMemo(
    () => providers?.find((provider) => provider.code === providerCode) || ({} as ProviderInfo),
    [providerCode, providers],
  )
}

export const usePlayingGame = () => {
  const playingGame = useAppSelector((state) => state.app.playingGame)
  const dispatch = useAppDispatch()
  const update = useCallback((selectedGame: GameDetail) => dispatch(setPlayingGame({ game: selectedGame })), [])

  return useMemo(() => ({ playingGame, update }), [playingGame, update])
}

export const useAppStatus = () => {
  const initialSiteStatus = useAppSelector((state) => state.app.initialSiteStatus)

  const isFinishFetch =
    initialSiteStatus === FetchingStatus.Fetched ||
    initialSiteStatus === FetchingStatus.Failed ||
    initialSiteStatus === FetchingStatus.Banned ||
    initialSiteStatus === FetchingStatus.Maintenance

  return useMemo(() => ({ status: initialSiteStatus, isFinishFetch }), [initialSiteStatus, isFinishFetch])
}

export const useGetPromotionMenu = () => {
  const promotions = useSelector((state: AppState) => state.app.pinnedPromotions || [])
  return promotions.slice(0, 2)
}

export const useTiers = () => {
  const tiers = useSelector((state: AppState) => state.app.tiers)
  return tiers || []
}

export const useTier = (id: number) => {
  const tiers = useTiers()
  return useMemo(() => tiers.find((tier) => tier.id === id), [id])
}

export const useInitializeFiatCurrencyConversionRateInUSD = () => {
  const dispatch = useAppDispatch()
  const { execute } = useRequest()

  useEffect(() => {
    const fetch = async () => {
      const res = await execute(MetaService.getFiatCurrencyRate())
      if (res) dispatch(setCurrencyConversionRateInUSD({ data: res.data?.fiatCurrencyConversionRateInUSD || {} }))
    }
    fetch()
  }, [])
}
export const useWagerAdventure = (): WagerAdventure => {
  const wagerAdventure = useAppSelector((state) => state.app.wagerAdventure)
  return useMemo(() => wagerAdventure, [wagerAdventure])
}
