import Button from 'components/Button'
import Text from 'components/Text'
import {
  DepositFiatTransaction,
  PendingTransaction,
  Transaction,
  TransactionStatusEnum,
} from 'config/types/transaction'
import Column from 'layout/Components/Column'
import { Trans } from 'react-i18next'
import styled from 'styled-components'
import BonusDetailsStepCard, {
  StyledInProgressIcon,
} from 'views/BonusCenter/components/BonusDetailsModal/BonusDetailStepCard'
import { useResumeProgressingDepositFiat } from 'views/Payment/components/Deposit/DepositByFiat/hooks'

const DepositFiatProgressInfo: React.FC<{
  transaction: Transaction
  isDisableResumeTransaction: boolean
}> = ({ transaction, isDisableResumeTransaction }) => {
  const { onResume, countdownTime } = useResumeProgressingDepositFiat(transaction.createTime)
  return (
    <Column mt="20px">
      <StyledStepContainer isActive disabledCollapse>
        <Column>
          <StyledStep>
            <Trans>Step {{ stepNumber: 1 }}</Trans>
          </StyledStep>
          <StyledTitle>
            <Trans>Deposit Order Created</Trans>
          </StyledTitle>

          {!isDisableResumeTransaction && PendingTransaction.includes(transaction.status) && (
            <Text color="textSubtle" fontSize="14px" lineHeight={1.2} fontWeight={300} letterSpacing={-0.28} mt="6px">
              <Trans
                i18nKey="resumeDeposit"
                components={{
                  button: (
                    <Button
                      variant="text"
                      onClick={() => {
                        if (countdownTime >= new Date() && PendingTransaction.includes(transaction.status))
                          onResume(transaction as unknown as DepositFiatTransaction, true)
                      }}
                      height="fit-content"
                      padding="0px !important"
                      mx="2px !important"
                    />
                  ),
                  text: (
                    <Text
                      as="span"
                      color="primary"
                      style={{
                        textDecoration: 'underline',
                      }}
                      fontSize="14px"
                      fontWeight="inherit"
                      ml="4px"
                    />
                  ),
                }}
              />
            </Text>
          )}
        </Column>
      </StyledStepContainer>

      <StyledStepContainer
        isProcessing={PendingTransaction.includes(transaction.status)}
        isActive={
          transaction.status === TransactionStatusEnum.Succeeded || transaction.status === TransactionStatusEnum.Failed
        }
        isInvalid={transaction.status === TransactionStatusEnum.Expired}
        disabledCollapse
        my="20px"
      >
        <Column>
          <StyledStep>
            <Trans>Step {{ stepNumber: 2 }}</Trans>
          </StyledStep>
          <StyledTitle>
            <Trans>3rd Merchant Processing</Trans>
          </StyledTitle>

          {transaction.status === TransactionStatusEnum.Expired && (
            <Text color="textSubtle" fontSize="14px" fontWeight={300} letterSpacing={-0.28} mt="4px">
              <Trans>The order has expired, please contact us if you have any question</Trans>.
            </Text>
          )}
        </Column>
      </StyledStepContainer>

      <StyledStepContainer
        isActive={transaction.status === TransactionStatusEnum.Succeeded}
        isInvalid={
          transaction.status === TransactionStatusEnum.Expired || transaction.status === TransactionStatusEnum.Failed
        }
        disabledCollapse
      >
        <Column>
          <StyledStep>
            <Trans>Step {{ stepNumber: 3 }}</Trans>
          </StyledStep>
          <StyledTitle>
            {(transaction.status === TransactionStatusEnum.Succeeded ||
              PendingTransaction.includes(transaction.status)) && <Trans>Transaction Completed</Trans>}
            {transaction.status === TransactionStatusEnum.Expired && <Trans>Transaction Expired</Trans>}
            {transaction.status === TransactionStatusEnum.Failed && <Trans>Transaction Failed</Trans>}
          </StyledTitle>
        </Column>
      </StyledStepContainer>
    </Column>
  )
}

const StyledStepContainer = styled(BonusDetailsStepCard)`
  svg {
    width: 16px !important;
    height: 16px !important;
  }

  ${StyledInProgressIcon} {
    width: 16px;
    height: 16px;
  }
`

const StyledStep = styled(Text)`
  color: ${({ theme: { colors } }) => colors.textAlt1};
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.2;
  line-height: normal;
`

const StyledTitle = styled(Text)`
  color: ${({ theme: { colors } }) => colors.textTertiary};
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.2;
`

export default DepositFiatProgressInfo

