import BigNumber from 'bignumber.js'
import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import Button from 'components/Button'
import FormControl from 'components/FormControl'
import FormInput from 'components/FormControl/FormInput'
import { StyledControlContainer } from 'components/FormControl/styled'
import InputAdornment from 'components/Input/InputAdornment'
import RemoveValueInputAdornment from 'components/Input/InputAdornment/RemoveValueInputAdornment'
import { InputContainer, InputLabel, InputMessage, StyledInput } from 'components/Input/styled'
import Text from 'components/Text'
import TokenInput from 'components/TokenInput'
import TokenLogo from 'components/TokenLogo'
import FormValidator from 'config/constants/formValidator'
import { ChainIdEnum } from 'config/constants/network'
import { Token, TokenAmount } from 'config/types'
import { ValidationError } from 'config/types/validator'
import useForm, { FieldState } from 'hooks/useForm'
import useModal from 'hooks/useModal'
import BaseBalanceSelect from 'layout/Components/BalanceSelect/BaseBalanceSelect'
import { RowBetween, RowCenter, RowMiddle } from 'layout/Components/Row'
import { useCallback, useMemo, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import ProfileService from 'services/ProfileService'
import { useTokenUsdPrice } from 'state/app/hooks'
import { useUserInfo } from 'state/auth/hooks'
import { useTokenAppBalance } from 'state/profile/hooks'
import { useTokenSelected } from 'state/session/hooks'
import styled from 'styled-components'
import { getFullDisplayBalance } from 'utils/formatBalance'
import { transformHunnyRequest } from 'utils/requestHelper'
import { useLockAmountTipInfo } from '../hooks'
import ConfirmTipModal from './ConfirmTipModal'

const MAX_MESSAGE_CHARACTERS = 200
const MINIMUM_TIP_AMOUNT = 0.1

const TipSendForm: React.FC<{ initialToken?: Token; userName: string; isAcceptTip: boolean }> = ({
  initialToken,
  userName,
  isAcceptTip,
}) => {
  const playToken = useTokenSelected()
  const [handlePresentTipConfirmModal] = useModal(ConfirmTipModal)
  const { t } = useTranslation()
  const { displayName, avatar } = useUserInfo()
  const [selectedToken, setSelectedToken] = useState<Token>(initialToken || playToken)
  const selectedBalance = useTokenAppBalance(selectedToken)
  const { lockAmount } = useLockAmountTipInfo(selectedToken)

  const availableSendTipAmount = useMemo(
    () => selectedBalance?.amount?.minus(lockAmount?.lockBonusAmount || 0),
    [selectedBalance, lockAmount],
  )

  const selectedTokenUsdAmount = useTokenUsdPrice(selectedToken)
  const minimumSelectedTokenUsdAmount = new BigNumber(MINIMUM_TIP_AMOUNT).dividedBy(selectedTokenUsdAmount)
  const payloadUserRef = useRef(null)

  const buildUsernameExistedFn = (value: string) => {
    const request = ProfileService.findUserData(value)
    return transformHunnyRequest(request, (res) => {
      if (res?.data) {
        payloadUserRef.current = {
          avatar: res.data.avatar,
          uid: res.data.uid,
          levelId: res.data.levelId,
        }
      }

      return res?.code === 'error_data_not_found'
    })
  }

  const handleCopySampleMessage = (message: string) => {
    const translatedByContextMessage = t([message])
    controls.message.onValueChanged(translatedByContextMessage)
  }

  const { states, controls, isValid, validateAll } = useForm({
    username: {
      validators: [
        FormValidator.required,
        FormValidator.notEqual(displayName),
        FormValidator.existUsername(buildUsernameExistedFn),
      ],
      value: userName,
      validateOnChange: false,
    },
    message: {
      value: '',
      validateOnChange: true,
    },
    amount: {
      validators: [
        FormValidator.required,
        FormValidator.min(minimumSelectedTokenUsdAmount.toNumber()),
        FormValidator.max(availableSendTipAmount),
      ],
      value: '',
    },
  })

  const parseErrorMessageFallback = useCallback(
    (errorKey: ValidationError, messageContent: React.ReactNode, customFields?: Record<string, string>) => {
      if (customFields?.[errorKey]) return customFields[errorKey]
      return messageContent
    },
    [],
  )

  const formatErrorMessage = (errors: ValidationError[], customFields?: Record<string, string>) => {
    if (errors[0] === ValidationError.Required) {
      return parseErrorMessageFallback(errors[0], <Trans>Please enter a username</Trans>, customFields)
    }
    if (errors[0] === ValidationError.Insufficient)
      return (
        <Trans>
          Insufficient funds. Available balance is {{ amount: getFullDisplayBalance(availableSendTipAmount, 0, 3) }}{' '}
          {{ currency: selectedToken.name }}
        </Trans>
      )
    if (errors[0] === ValidationError.NotEnough)
      return t(' The minimum value is {{minimumAmount}} {{currency}}', {
        minimumAmount: getFullDisplayBalance(minimumSelectedTokenUsdAmount.toFixed(6, BigNumber.ROUND_UP), 0, 6),
        currency: selectedToken.name,
      })
    if (errors[0] === ValidationError.Equal) {
      return <Trans>Cannot tip your self</Trans>
    }
    if (errors[0] === ValidationError.UserNotExist) {
      return <Trans>User not existed</Trans>
    }
  }

  return (
    <>
      <Box>
        <StyledFormControl
          state={states.username}
          label={t('Receiver Username')}
          formatErrorMessage={formatErrorMessage}
        >
          <FormInput
            tabIndex={1}
            control={controls.username}
            placeholder={t('Enter receiver username')}
            name="username"
          />
        </StyledFormControl>

        <Box mt="20px">
          <Text fontSize="12px" fontWeight={500} color="textSubtle">
            <Trans>Select Coin</Trans>
          </Text>
          <StyledBalanceSelect
            selectedToken={selectedToken}
            handleSelectBalance={(amount: TokenAmount) => {
              setSelectedToken(amount.token)
              controls.amount.clear()
            }}
            maxHeight={180}
          >
            <Flex alignItems="center" height="100%" width="100%">
              <TokenLogo token={selectedToken} minWidth={20} size={20} />
              <Text ml="8px" fontSize="14px" fontWeight={600}>
                {getFullDisplayBalance(
                  availableSendTipAmount,
                  0,
                  availableSendTipAmount.gt(1) ? 6 : selectedBalance.token.decimals,
                )}
              </Text>
            </Flex>
          </StyledBalanceSelect>
        </Box>

        <StyledControlContainer state={states.amount} mt="20px">
          <RowBetween>
            <InputLabel>
              <Trans>Amount</Trans>
            </InputLabel>
            <InputLabel color="#6C727E !important">
              <Trans>Transfer at least ${{ amount: MINIMUM_TIP_AMOUNT }}, no tip fee</Trans>
            </InputLabel>
          </RowBetween>
          <TokenInput
            tabIndex={1}
            value={states.amount.value}
            token={selectedToken}
            errors={states.amount.errors}
            validators={controls.amount.validators}
            onErrorChanged={controls.amount.onErrorChanged}
            onValueChanged={(value) => {
              controls.amount.onValueChanged(value)
            }}
            max={availableSendTipAmount}
          />
          <RowMiddle mt="6px">
            <Text fontSize="12px" color="textSubtle" mr="4px" lineHeight="normal">
              <Trans>Locked by Active Bonus</Trans>:
            </Text>
            <Text fontSize="12px" color="textSubtle" as="span" lineHeight="normal">
              {Number.isNaN(lockAmount.lockBonusAmount.toNumber()) ? '...' : lockAmount.lockBonusAmount.toNumber()}{' '}
              {selectedToken?.name}
            </Text>
          </RowMiddle>
          <RowBetween width="100%">
            {states.amount.errors[0] ? (
              <InputMessage color="error" maxWidth="85%">
                {formatErrorMessage(states.amount.errors, { [ValidationError.Required]: 'Please enter the amount' })}
              </InputMessage>
            ) : (
              <Box />
            )}
            {Number(states.amount.value) > 0 && (
              <Text fontSize="12px" fontWeight={400} textAlign="right" mt="4px" color="textAlt1">
                ~$
                {getFullDisplayBalance(
                  new BigNumber(states.amount.value || 0).multipliedBy(selectedTokenUsdAmount),
                  0,
                  2,
                )}
              </Text>
            )}
          </RowBetween>
        </StyledControlContainer>

        <StyledFormControl
          state={states.message}
          label={t('Message ({{currentAmount}}/{{total}})', {
            total: MAX_MESSAGE_CHARACTERS,
            currentAmount: states.message.value?.length || 0,
          })}
          formatErrorMessage={formatErrorMessage}
          mt="20px"
        >
          <InputContainer pl={2}>
            <InputAdornment
              p="0px 12px"
              height="100%"
              endAdornment={
                <RemoveValueInputAdornment
                  onRemove={() => controls.message.onValueChanged('')}
                  value={states.message.value}
                />
              }
            >
              <StyledInput
                tabIndex={1}
                value={states.message.value}
                autoComplete="off"
                onValueChanged={(value, event) => {
                  if (value?.length >= MAX_MESSAGE_CHARACTERS + 1) {
                    event.preventDefault()
                    event.stopPropagation()
                  } else {
                    controls.message.onValueChanged(value)
                  }
                }}
                placeholder={t('Enter message')}
                name="message"
                maxLength={MAX_MESSAGE_CHARACTERS}
              />
            </InputAdornment>
          </InputContainer>

          <RowMiddle mt="8px">
            <StyledSuggestionMessage onClick={() => handleCopySampleMessage('You deserve it!')}>
              <Text>
                <Trans>You deserve it!</Trans>
              </Text>
            </StyledSuggestionMessage>
            <StyledSuggestionMessage onClick={() => handleCopySampleMessage('Thank you!')}>
              <Text>
                <Trans>Thank you!</Trans>
              </Text>
            </StyledSuggestionMessage>
            <StyledSuggestionMessage onClick={() => handleCopySampleMessage('In appreciation')}>
              <Text>
                <Trans>In appreciation</Trans>
              </Text>
            </StyledSuggestionMessage>
          </RowMiddle>
        </StyledFormControl>

        <StyledNextStepButton
          onClick={async () => {
            const isValid = await validateAll()
            if (!isValid) return

            handlePresentTipConfirmModal({
              details: {
                sender: {
                  avatar,
                  displayName,
                },
                receiver: {
                  displayName: states.username.value,
                  ...payloadUserRef.current,
                },
                currency: selectedToken.code,
                network: ChainIdEnum[selectedToken.network],
                amount: states.amount.value,
                message: states.message.value?.trim(),
                createTime: Date.now(),
                id: null,
              },
            })
          }}
          disabled={!isValid || !states.username.value || !states.amount.value || !isAcceptTip}
        >
          <Text fontSize="14px" lineHeight="normal" letterSpacing={-0.28} fontWeight={600}>
            <Trans>Next</Trans>
          </Text>
        </StyledNextStepButton>
      </Box>
    </>
  )
}

const StyledFormControl = styled(FormControl)<{ state: FieldState }>`
  ${({ state, theme }) =>
    state.errors.length === 0 && state.isDirty
      ? `
     ${InputContainer} {
        background-color: transparent !important;
        border-color: ${theme.colors.strokeAlt} !important;
      }
    `
      : ''}
`

const StyledSuggestionMessage = styled(RowCenter)`
  padding: 4px 8px;
  background: ${({ theme: { colors } }) => colors.backgroundAlt5};
  border-radius: 40px;
  cursor: pointer;
  transition: ${({ theme: { transitions } }) => transitions.fast};
  margin-right: 4px;

  &:hover {
    transform: scale(1.05);
  }

  ${Text} {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: -0.24;
    line-height: normal;
  }
`

const StyledNextStepButton = styled(Button)`
  width: 100%;
  border-radius: 4px !important;
  margin-top: 40px;
`

const StyledBalanceSelect = styled(BaseBalanceSelect)`
  background: ${({ theme }) => theme.colors.inputBackground};
  width: 100% !important;
  backdrop-filter: blur(15px);
  position: relative;
  border: 1px solid #272f52;

  border-radius: ${({ theme }) => theme.radii.small};
  padding-left: 8px;
  height: 48px !important;
  margin-top: 8px;
  padding: 14px 20px;
`

export default TipSendForm

