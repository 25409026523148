import BigNumber from 'bignumber.js'
import AboutHUSDModal from 'components/AboutHUSDModal'
import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import Button from 'components/Button'
import CircleLoader from 'components/Loader/CircleLoader'
import Text from 'components/Text'
import { BIG_ZERO } from 'config/constants/number'
import useModal from 'hooks/useModal'
import { useRequest } from 'hooks/useRequest'
import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import BonusService from 'services/BonusService'
import { useAuth } from 'state/auth/hooks'
import { useActiveHUSDBoosterBonus, useHUSDLockBonus, useUpdateHUSDBonus } from 'state/bonus/hooks'
import { Icons } from 'svgs'
import { colors } from 'theme/colors'
import { getFullDisplayBalance } from 'utils/formatBalance'
import { HunnyToast } from 'utils/toastify'
import HUSDDisplayBalance from './HUSDDisplayBalance'
import UnlockFormulaDisplay from './UnlockFormulaDisplay'

const HUSDClaim: React.FC<{ setTotalHUSDClaimed: (value: BigNumber) => void }> = ({ setTotalHUSDClaimed }) => {
  const { execute, cancelAllRequest } = useRequest()

  const { claimableBalance, unlockableBalance } = useHUSDLockBonus()
  const { fetchHUSDBonusBalance } = useUpdateHUSDBonus()
  const husdBoosterBonus = useActiveHUSDBoosterBonus()
  const [handlePresentHUSDInfo] = useModal(AboutHUSDModal)

  const [isBalanceFetching, setIsBalanceFetching] = useState(false)
  const [submiting, setSubmiting] = useState(false)

  const { isSigned } = useAuth()
  const { t } = useTranslation()

  useEffect(() => {
    if (isSigned) {
      fetchBalance()
    }

    return () => {
      cancelAllRequest()
    }
  }, [isSigned])

  const fetchBalance = async () => {
    setIsBalanceFetching(true)
    const totalClaimedRes = await execute(BonusService.getTotalHUSDClaimed())
    setTotalHUSDClaimed(new BigNumber(totalClaimedRes.data || BIG_ZERO))

    setIsBalanceFetching(false)
  }

  const handleClaimHUSD = async () => {
    setSubmiting(true)
    const claimInfo = await BonusService.getHUSDClaimableAmount().call()
    if (!claimInfo?.data) {
      setSubmiting(false)
      return
    }

    const response = await BonusService.claimHUSD(claimInfo.data.amount, claimInfo.data.wager)

    if (response.data) {
      HunnyToast.success(
        t('Claim successful!'),
        t('You have claimed {{amount}} HUSD. The funds have been added your account balance.', {
          amount: getFullDisplayBalance(new BigNumber(claimInfo.data.amount), 0, 18),
        }),
      )

      fetchHUSDBonusBalance()
      fetchBalance()
    }

    setSubmiting(false)
  }

  return (
    <Box>
      <Text fontSize="14px" color="textSubtle">
        <Trans>Unlockable Amount</Trans>
      </Text>
      <HUSDDisplayBalance balance={unlockableBalance.minus(claimableBalance)} loading={isBalanceFetching} />

      <Box
        my="16px"
        background="rgba(198, 215, 254, 0.04)"
        borderRadius="4px"
        width="calc(100% + 12px)"
        ml="-12px"
        p="8px 8px 8px 20px"
        style={{ cursor: 'pointer' }}
        onClick={handlePresentHUSDInfo}
      >
        <Flex alignItems="center" justifyContent="space-between">
          <Flex flexWrap="wrap">
            <Text fontSize="12px" color="textSubtle" mr="4px" my="2px">
              <Trans>Start playing games to unlock HUSD</Trans>.
            </Text>
            <Text fontSize="12px" color="secondary" my="2px">
              <Trans>How to unlock</Trans>?
            </Text>
          </Flex>

          <Icons.ChevronRightIcon width="16px" height="16px" fill={colors.secondary} />
        </Flex>
      </Box>

      <Text fontSize="14px" color="textSubtle" mt="12px">
        <Trans>Unlock Amount</Trans> (<Trans>Claimable</Trans>)
      </Text>
      <HUSDDisplayBalance balance={claimableBalance} color="success" loading={isBalanceFetching} />
      {husdBoosterBonus && (
        <Flex alignItems="center" mt="12px">
          <Icons.CouponIcon />
          <Text fontSize="12px" color="textSubtle" ml="4px">
            <Trans>
              Bonus
              <Text display="inline-block" fontSize="12px" color="textTertiary" mx="4px">
                HUSD unlock booster
              </Text>
              applied!
            </Trans>
          </Text>
        </Flex>
      )}
      <Button my="24px" width="100%" onClick={handleClaimHUSD} disabled={claimableBalance.eq(0) || submiting}>
        {submiting && (
          <Box mr="12px">
            <CircleLoader />
          </Box>
        )}

        <Text bold fontSize="14px" color={claimableBalance.eq(0) || submiting ? 'textSubtle' : 'text'}>
          <Trans>Claim</Trans>
        </Text>
      </Button>
      <UnlockFormulaDisplay />
    </Box>
  )
}

export default HUSDClaim

