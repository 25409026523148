import useDebounceCallback from 'hooks/useDebounceCallback'
import React, { forwardRef, useRef, useState } from 'react'
import { forkjoinRequest } from 'utils/requestHelper'

import { InputValidator, ValidationError } from 'config/types/validator'
import useIsMounted from 'hooks/useIsMounted'
import { useIsomorphicEffect } from 'hooks/useIsomorphicEffect'
import { useRequest } from 'hooks/useRequest'
import { HunnyRequest } from 'services/types'

export interface InputProps {
  value: any
  onValueChanged: (value: any, event?: any) => void
  onErrorChanged?: (errors: ValidationError[]) => void
  onValidating?: () => void
  clear?: () => void
  onValidated?: () => void
  validators?: InputValidator[]
  validateOnchange?: boolean
  disabled?: boolean
  setDisabled?: (value: any) => void
  onBlur?: (event: any) => void
}

export const validateFormControl = async (validators: Promise<ValidationError>[]): Promise<ValidationError[]> => {
  const result = await forkjoinRequest(validators)
  return result.filter((error) => error)
}

const Input = forwardRef<
  HTMLInputElement,
  InputProps & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
>(
  (
    {
      value,
      validators,
      validateOnchange,
      type,
      onValueChanged,
      onErrorChanged,
      onValidating,
      onValidated,
      onBlur,
      ...props
    },
    ref,
  ) => {
    const debounceCallback = useDebounceCallback()
    const [errors, setErrors] = useState([])
    const validatedValue = useRef(null)
    const isMounted = useIsMounted()

    const { execute, cancelAllRequest } = useRequest()

    const validate = async () => {
      if (validators && validators.length > 0 && onErrorChanged) {
        if (validatedValue.current === value) return errors
        let hasAsyncValidator = false
        const parsedValidators = validators
          .map((validator) => {
            const result = validator(value)
            const hunnyRequest = result as HunnyRequest

            if (hunnyRequest?.call && hunnyRequest?.cancel) {
              hasAsyncValidator = true
              if (onValidating) onValidating()
              return execute(hunnyRequest)
            }

            return Promise.resolve(result as ValidationError)
          })
          .filter((validator) => validator)
        const validateErrors = await validateFormControl(parsedValidators)

        validatedValue.current = value
        if (hasAsyncValidator && onValidated) {
          onValidated()
        }

        onErrorChanged(validateErrors)
        setErrors(validateErrors)
      }
    }

    useIsomorphicEffect(() => {
      if (!isMounted()) {
        return
      }

      if (errors.length > 0 || validateOnchange) {
        debounceCallback(() => {
          validate()
        }, 50)
      }

      return () => {
        cancelAllRequest()
      }
    }, [value])

    const handleOnBlur = async (e: any) => {
      validate()
      if (onBlur) onBlur(e)
    }

    const onChange = (e: any) => {
      if (type === 'checkbox') {
        onValueChanged(e.target.checked, e)
      } else {
        onValueChanged(e.target.value, e)
      }
    }

    const valueProps = type === 'checkbox' ? { checked: value } : { value }

    return <input ref={ref} {...props} onBlur={handleOnBlur} onChange={onChange} type={type} {...valueProps} />
  },
)

export default Input

