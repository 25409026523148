import { Token } from 'config/types'
import { ChainIdEnum } from '../network'

const path = '/images/token'

const tokens: Record<string, Token> = {
  ETH: {
    coinGeckoInfo: {
      id: 'ethereum',
    },
    logo: `${path}/eth.png`,
    name: 'ETH',
    code: 'ETH',
    network: ChainIdEnum.ETH_TESTNET,
    isNative: true,
    decimals: 18,
  },
  USDT: {
    coinGeckoInfo: {
      id: 'tether',
    },
    logo: `${path}/usdt.png`,
    name: 'USDT',
    code: 'USDT',
    network: ChainIdEnum.ETH_TESTNET,
    address: '0xE84f469e6B55935183377a6DBE0De6bD12df4448',
    decimals: 6,
    isStable: true,
  },
  USDC: {
    coinGeckoInfo: {
      id: 'usd-coin',
    },
    logo: `${path}/usdc.png`,
    name: 'USDC',
    code: 'USDC',
    network: ChainIdEnum.ETH_TESTNET,
    address: '0x565cb101a8a035cff569a9cb159271bc374157b9',
    decimals: 6,
    isStable: true,
  },
}

export default tokens
