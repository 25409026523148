import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import Button from 'components/Button'
import CircleLoader from 'components/Loader/CircleLoader'
import Text from 'components/Text'
import useModal from 'hooks/useModal'
import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import InhouseGameService from 'services/InhouseGameService/InhouseGameService'
import theme from 'theme'
import MineGameGraphic from 'views/InhouseGame/Mine/components/GameBoard/components/MineGameGraphic'
import FairnessModal from 'views/InhouseGame/components/FairnessModal'
import { FairInfo } from 'views/InhouseGame/components/FairnessModal/styled'
import { MineProbilityFairBetInfo } from 'views/InhouseGame/config/types'

const MineGameContent: React.FC<{ inhouseBetId: number; betDetails?: MineProbilityFairBetInfo }> = ({
  inhouseBetId,
  betDetails,
}) => {
  const { t } = useTranslation()
  const [presentFairnessModal] = useModal(FairnessModal)

  const [bet, setBet] = useState<MineProbilityFairBetInfo>(betDetails)

  useEffect(() => {
    if (betDetails) return
    const fetch = async () => {
      const betDetails = await InhouseGameService.getBetInfo(inhouseBetId)
      if (betDetails) {
        setBet(betDetails as MineProbilityFairBetInfo)
      }
    }

    fetch()
  }, [inhouseBetId, betDetails])
  return (
    <Box position="relative">
      {!bet && (
        <Box position="absolute" left="50%" top="50%" style={{ transform: 'translate(-50%, -50%)' }}>
          <CircleLoader />
        </Box>
      )}

      <Box style={{ opacity: bet ? 1 : 0 }} width="100%" mt="24px">
        <Flex justifyContent="center" width="100%">
          {bet?.data?.bet && (
            <MineGameGraphic
              currentBet={bet.data?.bet}
              mines={bet.data.mines}
              viewMode
              hideMultiplierResult
              width="100%"
            />
          )}
        </Flex>

        <FairInfo labelColor="textAlt1" label="Client Seed" value={bet?.seed.clientSeed || ''} mt="24px" />
        <FairInfo
          labelColor="textAlt1"
          label="Server Seed (Hashed)"
          value={bet?.seed.hashedServerSeed || ''}
          mt="24px"
        />
        <FairInfo
          labelColor="textAlt1"
          label="Server Seed"
          value={bet?.seed.serverSeed || t("Seed hasn't been revealed yet")}
          mt="24px"
          disabledCopy={!bet?.seed.serverSeed}
        />
        <FairInfo labelColor="textAlt1" label="Nonce" value={bet?.seed.nonce.toString()} mt="24px" />

        {bet?.seed.serverSeed ? (
          <Box mt="12px" width="100%">
            <Button
              width="100%"
              my="8px"
              style={{
                background: 'rgba(255, 255, 255, 0.12)',
                borderRadius: theme.radii.tiny,
                fontSize: '14px',
                lineHeight: '16px',
                fontWeight: 700,
              }}
              height={['32px', '', '40px']}
              onClick={() => {
                presentFairnessModal({ inhouseBet: bet })
              }}
            >
              <Trans>Verify</Trans>
            </Button>
          </Box>
        ) : (
          <Text mt="12px" width="100%" fontSize="12px" color="textSubtle">
            <Trans>Server seed needs to be changed to verify bet...</Trans>
          </Text>
        )}
      </Box>
    </Box>
  )
}

export default MineGameContent
