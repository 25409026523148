import Box from 'components/Box/Box'
import { BoxProps } from 'components/Box/types'
import CopySuccessLoader from 'components/Loader/CopySuccessLoader'
import useCopy from 'hooks/useCopy'
import { RowCenter } from 'layout/Components/Row'
import { Icons } from 'svgs'
import theme from 'theme'
import IconButton from './IconButton'

interface CopyButtonProps extends BoxProps {
  text: string
  icon?: React.ReactNode
  iconSize?: number
}

const CopyButton: React.FC<CopyButtonProps> = ({ text, children, icon, iconSize = 24, ...props }) => {
  const { copied, handleCopy } = useCopy()

  return (
    <Box {...props}>
      <IconButton
        onClick={(e) => {
          e?.stopPropagation()
          handleCopy(text)
        }}
      >
        <RowCenter width="100%">
          {!copied &&
            (children || icon || <Icons.CopyIcon fill={theme.colors.textSubtle} width={iconSize} height={iconSize} />)}
          {copied && <CopySuccessLoader />}
        </RowCenter>
      </IconButton>
    </Box>
  )
}

export default CopyButton
