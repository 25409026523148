import Text from 'components/Text'
import { TextProps } from 'components/Text/types'
import { useTranslation } from 'react-i18next'

const TransText: React.FC<{ children: string } & TextProps> = ({ children, ...props }) => {
  const { t } = useTranslation()

  return (
    <Text lineHeight="normal" {...props}>
      {t(children)}
    </Text>
  )
}

export default TransText

