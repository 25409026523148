import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import { FlexProps } from 'components/Box/types'
import Image from 'components/Image'
import Skeleton from 'components/Skeleton'
import Text from 'components/Text'
import { DepositFiatPaymentMethod, WithdrawFiatPaymentMethod } from 'config/types/payment'
import useDebounceCallback from 'hooks/useDebounceCallback'
import Column from 'layout/Components/Column'
import { AppSearch } from 'layout/Components/GameSearch/styled'
import { RowBetween, RowCenter } from 'layout/Components/Row'
import React, { PropsWithChildren } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

interface PaymentMethodCardProps<T> {
  method: T
  onItemSelected?: (item: T) => void
  onSearchChanged?: (value) => void
}

export const Loader = () => {
  return (
    <Column style={{ gap: '20px' }}>
      <StyledCardContainer>
        <StyledCardWrapper height="40px" width="100%" padding="12px" style={{ gap: '10px' }}>
          <Skeleton width="32px" height="32px" />
          <Skeleton width="100%" />
        </StyledCardWrapper>
        <StyledCardWrapper height="40px" width="100%" padding="12px" style={{ gap: '10px' }}>
          <Skeleton width="32px" height="32px" />
          <Skeleton width="100%" />
        </StyledCardWrapper>
      </StyledCardContainer>

      <StyledCardContainer>
        <StyledCardWrapper height="40px" width="100%" padding="12px" style={{ gap: '10px' }}>
          <Skeleton width="32px" height="32px" />
          <Skeleton width="100%" />
        </StyledCardWrapper>
        <StyledCardWrapper height="40px" width="100%" padding="12px" style={{ gap: '10px' }}>
          <Skeleton width="32px" height="32px" />
          <Skeleton width="100%" />
        </StyledCardWrapper>
        <StyledCardWrapper height="40px" width="100%" padding="12px" style={{ gap: '10px' }}>
          <Skeleton width="32px" height="32px" />
          <Skeleton width="100%" />
        </StyledCardWrapper>
      </StyledCardContainer>

      <StyledCardContainer>
        <StyledCardWrapper height="40px" width="100%" padding="12px" style={{ gap: '10px' }}>
          <Skeleton width="32px" height="32px" />
          <Skeleton width="100%" />
        </StyledCardWrapper>
      </StyledCardContainer>
    </Column>
  )
}

export const PaymentMethodCardContainer: React.FC<
  PropsWithChildren & {
    title: string
    Icon: any
    overflowY: string
    onBankSearchChanged?: (value) => void
  } & FlexProps
> = ({ title, Icon, children, onBankSearchChanged, ...props }) => {
  const debounce = useDebounceCallback()
  const { t } = useTranslation()

  return (
    <StyledCardContainer>
      <Flex alignItems="center">
        {<Icon size={24} />}
        <Text ml="8px" color="textTertiary" fontSize="14px" fontWeight={500} letterSpacing={-0.24} lineHeight="normal">
          {t(title)}
        </Text>
      </Flex>
      {onBankSearchChanged && (
        <AppSearch
          placeholder={t('Search bank')}
          onValueChanged={(value) => {
            debounce(() => onBankSearchChanged(value), 300)
          }}
        />
      )}
      <StyledScroll {...props}>{children}</StyledScroll>
    </StyledCardContainer>
  )
}

const PaymentMethodCard = <T extends DepositFiatPaymentMethod | WithdrawFiatPaymentMethod>({
  method,
  onItemSelected,
  ...props
}: PaymentMethodCardProps<T> & FlexProps) => {
  return (
    <StyledCardWrapper
      maxHeight={52}
      onClick={() => {
        if (method.isActive) onItemSelected(method)
      }}
      {...props}
      $disabled={!method.isActive}
    >
      <RowCenter>
        <Box width={'34px'} height={'32px'} mr="5px">
          <Image src={method.thumbnail} width={32} height={30} />
        </Box>
        <Column>
          <Text fontSize="14px" fontWeight={500} letterSpacing={-0.28} lineHeight="normal">
            {method.name}
          </Text>

          {!method.isActive && (
            <Text color="warning" fontSize="12px" fontWeight={500} mt="4px">
              <Trans>This channel is under mantainance</Trans>
            </Text>
          )}
        </Column>
      </RowCenter>
    </StyledCardWrapper>
  )
}

const StyledCardContainer = styled(Column)`
  border-radius: ${({ theme: { radii } }) => radii.small};
  background: ${({ theme: { colors } }) => colors.backgroundAlt5};
  gap: 8px;
  padding: 8px;
  border: ${({ theme: { colors } }) => `1px solid ${colors.textDisable}`};
`

const StyledCardWrapper = styled(RowBetween)<{ $disabled?: boolean }>`
  padding: 4px;
  border-radius: ${({ theme: { radii } }) => radii.tiny};
  cursor: pointer;
  margin: 4px 0;

  &:hover {
    background: ${({ theme: { colors } }) => colors.backgroundAlt8};
  }
  ${({ $disabled }) =>
    $disabled &&
    css`
      cursor: not-allowed;
    `};
`

const StyledScroll = styled(Column)`
  ::-webkit-scrollbar {
    width: 3px !important;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #2d3137 !important;
    visibility: visible;
  }
`

export default PaymentMethodCard

