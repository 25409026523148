import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import Text from 'components/Text'
import { ProfileStatisticGameDetailInfo } from 'config/types/profile'
import Column from 'layout/Components/Column'
import { Trans } from 'react-i18next'
import { colors } from 'theme/colors'
import { delineate } from 'utils/formatBalance'
import USDAmountDisplay from 'views/Fiat/USDAmountDisplay'
import BigRewardGameCard from './BigRewardGameCard'
import StatisticTagInfo from './StatisticTagInfo'
import StatisticsUserLogin from './StatisticsUserLogin'

const Statistics: React.FC<{
  bigWinGameInfo: ProfileStatisticGameDetailInfo
  luckyWinGameInfo: ProfileStatisticGameDetailInfo
  score: number
  totalBet: number
  isStatisticTagColumn?: boolean
  isStatisticsUserLogin?: boolean
}> = ({ bigWinGameInfo, luckyWinGameInfo, score, totalBet, isStatisticTagColumn, isStatisticsUserLogin = false }) => {
  return (
    <Column>
      <Text fontSize="14px" color="textTertiary" fontWeight={600} lineHeight="normal" letterSpacing={-0.28}>
        <Trans>Statistics</Trans>
      </Text>

      {isStatisticsUserLogin ? (
        <StatisticsUserLogin totalBet={totalBet} />
      ) : (
        <Flex
          mt="12px"
          flexDirection={isStatisticTagColumn ? 'column' : ['column', 'column', 'row', 'row']}
          alignItems={isStatisticTagColumn ? 'flex-start' : ['flex-start', 'flex-start', 'center', 'center']}
        >
          <StatisticTagInfo
            flexDirection="row"
            minWidth={['40%', '40%', 'unset !important']}
            title={'Total wagered'}
            amount={score}
            marginRight="12px"
            mb={isStatisticTagColumn ? '12px' : ['12px', '12px', '0px', '0px']}
          />
          <StatisticTagInfo
            flexDirection="row"
            minWidth={['40%', '40%', 'unset !important']}
            title={'Total bets'}
            amount={totalBet}
            mt={['12px', '12px', '0px', '0px']}
            disabledFiatDisplay
          />
        </Flex>
      )}

      <BigRewardGameCard
        title={'Big win'}
        amountDisplay={bigWinGameInfo?.amount ? <USDAmountDisplay amount={bigWinGameInfo.amount} /> : '0'}
        {...bigWinGameInfo}
        logo={'/images/profile/big-win-icon.png'}
      />
      <Box width="100%" height="1px" background={colors.modalHeaderBackground} my="12px" />
      <BigRewardGameCard
        title={'Lucky win'}
        amountDisplay={
          luckyWinGameInfo?.betDetail ? `X${delineate(luckyWinGameInfo.betDetail.multiplier.toString(), 2)}` : '0'
        }
        {...luckyWinGameInfo}
        logo={'/images/profile/firework-icon.png'}
      />
    </Column>
  )
}

export default Statistics
