import { useRef } from 'react'
import useDebounceCallback from './useDebounceCallback'
import { useIsomorphicEffect } from './useIsomorphicEffect'
import { useVariableLoadedListener } from './useVariableLoadedListener'

const useResizeEventListener = (callback: () => void, debounceTime = 100) => {
  const elementRef = useRef<HTMLDivElement | null>(null)

  const debounce = useDebounceCallback()

  const element = useVariableLoadedListener(() => {
    return elementRef.current
  })

  useIsomorphicEffect(() => {
    if (!element) return

    const observer = new ResizeObserver(() => {
      debounce(() => {
        callback()
      }, debounceTime)
    })
    observer.observe(element)

    return () => {
      observer.disconnect()
    }
  }, [element, callback, debounceTime])
  return { elementRef }
}

export default useResizeEventListener
