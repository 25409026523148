import { targetBanner } from 'config/constants/banner'
import { BannerDetail, BannerDetailType, HomeCategory } from 'config/types/home'
import { BaseResponse, Paging } from 'services/types'

export const HomeCategoryMapper = (rawResponse: string): BaseResponse<Paging<HomeCategory>> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)
  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      items: response.data.items.map((item) => ({
        title: item.title,
        priority: item.priority,
        iconSrc: item.icon_url,
        url: item.redirect_url,
      })),
    },
  }
}

export const BannerMapper = (rawResponse: string): BaseResponse<BannerDetailType> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }
  const dataBanner = response.data.items.map(parsedBanner)

  const mapBanner = dataBanner.reduce((state: { [x: string]: any[] }, banner: { type: string | number }) => {
    if (!state?.[banner.type]) {
      state[banner.type] = []
    }
    state[banner.type].push(banner)

    return state
  }, {})

  return {
    code: response.code,
    message: response.message,
    data: mapBanner,
  }
}

export const parsedBanner = (data: any, index: number): BannerDetail => {
  const urlDesktopBanner = data['thumb_desktop_url']
  const urlMobileBanner = data['thumb_mobile_url']

  return {
    id: index,
    priority: data.priority,
    imageSrc: urlDesktopBanner,
    mobileImageSrc: urlMobileBanner || urlDesktopBanner,

    target: targetBanner[data['display_target'] || 1],
    url: data['redirect_url'],
    type: data.type || null,
    title: data.title || null,
    tag: data.tag || null,
    subtitleInfo: data.type_subtitle_data
      ? {
          backgroundColor: data.type_subtitle_data.background_color,
          spaceBanner: data.type_subtitle_data.two_space_banner,
          content: data.type_subtitle_data.sub_title,
        }
      : null,
  }
}
