import { Token } from 'config/types'
import { InhouseGameType } from 'config/types/game'
import GameService from 'services/GameService'
import { DiceBet, DiceBetResponse, DiceDraftBet } from 'views/InhouseGame/Dice/config/types'
import { InhouseBetResponse } from 'views/InhouseGame/config/types'
import { BaseResponse } from 'services/types'
import { INHOUSE_GAME_LAUNCH_MAPPER_KEY } from 'views/InhouseGame/Mine/config'
import { InhouseLaunchSession } from 'views/InhouseGame/Mine/config/types'
import BaseInhouseGameService from './BaseInhouseGameService'

class DiceGameService extends BaseInhouseGameService {
  _game = InhouseGameType.Dice
  _currentTokenSession = null
  _sessionId = null

  async _verify(gameCode: string, playToken: Token): Promise<InhouseLaunchSession> {
    const result = await GameService.createGameSession(gameCode, playToken.code, playToken.network).call()
    const { gameUrl } = result?.data || {}
    const res: BaseResponse<InhouseLaunchSession> = await this._get(
      gameUrl,
      {},
      { withCredentials: true },
      INHOUSE_GAME_LAUNCH_MAPPER_KEY,
    )
    this._currentTokenSession = playToken
    this._sessionId = res.data?.sessionId
    return res.data
  }

  public async launchGame(gameCode: string, playToken: Token): Promise<InhouseLaunchSession> {
    return this._verify(gameCode, playToken)
  }

  public async submitBet(
    draftBet: DiceDraftBet,
    gameCode?: string,
    playToken?: Token,
  ): Promise<InhouseBetResponse<DiceBet>> {
    const { bet, result }: InhouseBetResponse<DiceBetResponse> = await this._submitBet(
      draftBet.betAmount,
      {
        target: draftBet.target.value.toString(),
        roll: draftBet.target.type,
      },
      true,
      gameCode,
      playToken,
    )
    return {
      result,
      bet: bet && {
        ...draftBet,
        id: bet.id,
        result: bet.result,
      },
    }
  }

  public async verifyResult(clientSeed: string, serverSeed: string, nonce: number): Promise<number> {
    const result = await this._verifyResult(clientSeed, serverSeed, nonce, {})
    return result?.data?.result && Number(result?.data?.result)
  }
}

const instance = new DiceGameService()
export default instance
