import { Token } from 'config/types'
import { InhouseGameType } from 'config/types/game'
import GameService from 'services/GameService'
import { Risk, RiskMaperEnum, payoutDefaultValue } from 'views/InhouseGame/Plinko/components/GameBoard/config'
import { PlinkoBet, PlinkoDraftBet } from 'views/InhouseGame/Plinko/types/Bet'
import { InhouseBetResponse, InhouseGameBetResult } from 'views/InhouseGame/config/types'
import { BaseResponse } from 'services/types'
import { INHOUSE_GAME_LAUNCH_MAPPER_KEY } from 'views/InhouseGame/Mine/config'
import { InhouseLaunchSession } from 'views/InhouseGame/Mine/config/types'
import BaseInhouseGameService from './BaseInhouseGameService'

class PlinkoGameService extends BaseInhouseGameService {
  _game = InhouseGameType.Plinko
  _currentTokenSession = null
  _sessionId = null

  async _verify(gameCode: string, playToken: Token): Promise<InhouseLaunchSession> {
    const result = await GameService.createGameSession(gameCode, playToken.code, playToken.network).call()
    const { gameUrl } = result?.data || {}
    const res: BaseResponse<InhouseLaunchSession> = await this._get(
      gameUrl,
      {},
      { withCredentials: true },
      INHOUSE_GAME_LAUNCH_MAPPER_KEY,
    )

    this._currentTokenSession = playToken
    this._sessionId = res.data?.sessionId

    return res.data
  }

  public async launchGame(gameCode: string, playToken: Token): Promise<InhouseLaunchSession> {
    return this._verify(gameCode, playToken)
  }

  public async submitBet(
    draftBet: PlinkoDraftBet,
    gameCode?: string,
    playToken?: Token,
  ): Promise<InhouseBetResponse<PlinkoBet>> {
    const { bet, result } = await this._submitBet(
      draftBet.betAmount,
      {
        risk: RiskMaperEnum[draftBet.risk],
        rows: draftBet.row,
      },
      draftBet.disabledDisplay,
      gameCode,
      playToken,
    )
    if (result !== InhouseGameBetResult.Succeed) {
      return {
        bet,
        result,
      }
    }
    const listIndex = payoutDefaultValue[draftBet.row - 8][draftBet.risk]
      .map((value, index) => (value === bet.payoutResult ? index : null))
      .filter((value) => value)
    const randomIndex = listIndex[Math.floor(Math.random() * listIndex.length - 1) + 1]

    return {
      bet: bet && {
        ...draftBet,
        id: bet.id,
        payoutIndex: randomIndex,
      },
      result,
    }
  }

  public async verifyResult(
    risk: Risk,
    rows: number,
    clientSeed: string,
    serverSeed: string,
    nonce: number,
  ): Promise<number> {
    const result = await this._verifyResult(clientSeed, serverSeed, nonce, {
      rows,
      risk: RiskMaperEnum[risk],
    })

    return result?.data?.payout && Number(result?.data?.payout)
  }
}

const instance = new PlinkoGameService()
export default instance
