import { DailyRewardPackageCode, DailyRewardPackagesName } from 'config/constants/dailyReward'
import { ChainIdEnum } from 'config/constants/network'
import tokens from 'config/constants/tokens'

import { AirdropBox, ReceivedAirdropSummary } from 'config/types'
import {
  DailyCheckinTransaction,
  DailyReward,
  DailyRewardCampaign,
  RewardTypeEnum,
} from 'config/types/bonus/DailyReward'
import { BaseResponse, HUSDClaimedTransaction, Paging } from 'services/types'
import { formatApiNetworkField } from 'utils'

export const LockBalanceMapper = (rawResponse: string): BaseResponse<string> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && response.data.items[0]?.amount,
  }
}

export const ClaimableBalanceMapper = (rawResponse: string): BaseResponse<{ amount: string; wager: string }> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }
  return {
    code: response.code,
    message: response.message,
    data: response.data && { amount: response.data.amount, wager: response.data.after_wager },
  }
}

export const ClaimBonusMapper = (rawResponse: string): BaseResponse<boolean> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.code === 'success',
  }
}

export const SwapBonusMapper = (rawResponse: string): BaseResponse<boolean> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.code === 'success',
  }
}

export const UnlockFormulaMapper = (rawResponse: string): BaseResponse<{ houseEdge: number; const: number }> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      const: Number(response.data.const),
      houseEdge: Number(response.data.house_edge) / 100,
    },
  }
}

export const HUSDClaimedAmountMapper = (rawResponse: string): BaseResponse<string> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && response.data.amount,
  }
}

export const HUSDClaimedHistoryMapper = (rawResponse: string): BaseResponse<Paging<HUSDClaimedTransaction>> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      paging: response.data.paging,
      items: response.data.items.map((item) => ({
        createdAt: item.create_time * 1000,
        amount: Math.abs(item.value),
        token: tokens?.[ChainIdEnum[item.network]]?.[item.currency],
        status: item.type,
        note: item?.note || '',
      })),
    },
  }
}

export const ClaimAirdropMapper = (rawResponse: string): BaseResponse<boolean> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: false,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.code === 'success',
  }
}

export const AirdropListMapper = (rawResponse: string): BaseResponse<AirdropBox[]> => {
  const response: any = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data:
      (response.data &&
        response.data.items?.map((item) => ({
          amount: item.amount,
          code: item.code,
          currency: item.currency,
          network: item.network,
          uid: item.uid,
        }))) ||
      [],
  }
}

export const ReceivedAirdropSummaryMapper = (rawResponse: string): BaseResponse<ReceivedAirdropSummary> => {
  const response: any = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      totalReceivedAirdrop: Number(response.data.received_count),
      totalAmount: Number(response.data.total_amount),
    },
  }
}

export const DailyCheckinMapper = (rawResponse: string): BaseResponse<DailyRewardCampaign> => {
  const response: any = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  const convertReward = (rewardDetails: any): DailyReward => {
    const baseReward = {
      id: `${rewardDetails.reward.type}_${rewardDetails.day}`,
      userData: {
        isClaimed: rewardDetails.received,
      },
      day: rewardDetails.day,
      isHighlight: rewardDetails.reward.is_highlight,
      displayName: rewardDetails.reward.display_name,
      rewardType: rewardDetails.reward.type,
    }

    switch (rewardDetails.reward.type) {
      case RewardTypeEnum.Bonus:
        return {
          reward: {
            bonusId: rewardDetails.reward.voucher_id,
            bonusImage: rewardDetails.reward.icon_url,
          },
          ...baseReward,
        }
      case RewardTypeEnum.HUSDLock:
        return {
          reward: rewardDetails.reward.amount,
          ...baseReward,
        }
      case RewardTypeEnum.Cash:
        return {
          reward: {
            token:
              tokens[ChainIdEnum[formatApiNetworkField(rewardDetails.reward.network)]]?.[rewardDetails.reward.currency],
            amount: rewardDetails.reward.amount,
          },
          ...baseReward,
        }

      default:
        return null
    }
  }

  return {
    code: response.code,
    message: response.message,

    data: response.data &&
      response.data.from_time && {
        name: response.data.title,
        startAt: response.data.from_time,
        finishAt: response.data.to_time,
        userData: {
          todayWagerAmount: Number(response.data.total_wager),
        },

        packages: [
          {
            id: DailyRewardPackageCode.Super,
            code: DailyRewardPackageCode.Super,
            name: DailyRewardPackagesName.Super,
            thumnail: '/images/dailyCheckin/daily-reward-icon.png',
            rewards: (response.data?.normal?.rewards || []).map((item) => convertReward(item)).filter((item) => item),
            wagerRequired: {
              allAmount: {
                amount: response.data.normal.lifetime_wager_required.amount,
                targetDay: response.data.normal.lifetime_wager_required.from_reward_day,
              },
              todayAmount: {
                amount: Number(response.data.normal.wager_condition || 0),
              },
            },
            description: 'Login to claim',
            isTodayClaimed: response.data.checkin_normal,
          },
          {
            id: DailyRewardPackageCode.Mega,
            code: DailyRewardPackageCode.Mega,
            name: DailyRewardPackagesName.Mega,
            thumnail: '/images/dailyCheckin/mega-reward-icon.png',
            rewards: (response.data?.mega?.rewards || [])
              .map((item) => {
                return convertReward(item)
              })
              .filter((item) => item),
            wagerRequired: {
              allAmount: {
                amount: 0,
                targetDay: 0,
              },
              todayAmount: {
                amount: Number(response.data.mega.wager_condition || 0),
              },
            },
            description: 'Wager to claim',
            isTodayClaimed: response.data.checkin_mega,
          },
        ],
      },
  }
}

export const DailyCheckinHistoryMapper = (rawResponse: string): BaseResponse<Paging<DailyCheckinTransaction>> => {
  const response: any = JSON.parse(rawResponse)
  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  const convertReward = (rewardDetails: any): DailyCheckinTransaction => {
    const baseReward = {
      checkinType: rewardDetails.checkin_type,
      displayName: rewardDetails.reward.display_name,
      createdAt: rewardDetails.create_time * 1000,
      rewardType: rewardDetails.reward.type,
    }

    switch (rewardDetails.reward.type) {
      case RewardTypeEnum.Bonus:
        return {
          reward: {
            bonusId: rewardDetails.reward.voucher_id,
            bonusImage: rewardDetails.reward.icon_url,
          },
          ...baseReward,
        }
      case RewardTypeEnum.HUSDLock:
        return {
          reward: rewardDetails.reward.amount,
          displayAmount: rewardDetails.reward.amount,
          ...baseReward,
        }
      case RewardTypeEnum.Cash:
        return {
          reward: {
            token:
              tokens[ChainIdEnum[formatApiNetworkField(rewardDetails.reward.network)]]?.[rewardDetails.reward.currency],
            amount: rewardDetails.reward.amount,
          },
          displayAmount: rewardDetails.reward.amount,
          ...baseReward,
        }
      default:
        return null
    }
  }

  return {
    code: response.code,
    message: response.message,

    data: response.data && {
      paging: response.data.paging,
      items: response.data.items.map((item) => convertReward(item)).filter((item) => item),
    },
  }
}
