import { DailyRewardPackageCode } from 'config/constants/dailyReward'
import { TokenAmount } from '..'

export enum RewardTypeEnum {
  Bonus = 1,
  HUSDLock = 2,
  Cash = 3,
}

export type DailyRewardCampaign = {
  name: string
  startAt: number
  finishAt: number
  packages: DailyRewardPackage[]
  userData: {
    todayWagerAmount: number
  }
}

export type DailyRewardPackage = {
  id: DailyRewardPackageCode
  code: DailyRewardPackageCode
  name: string
  description: string
  thumnail: string
  rewards: DailyReward[]
  wagerRequired: {
    allAmount: {
      amount: number
      targetDay: number
    }
    todayAmount: {
      amount: number
    }
  }
  isTodayClaimed: boolean
}

export type DailyReward<T = any> = {
  id: string
  displayName: string
  reward: T
  rewardType: RewardTypeEnum.Bonus | RewardTypeEnum.Cash | RewardTypeEnum.HUSDLock
  userData: {
    isClaimed: boolean
  }
  isHighlight: boolean
  day: number
}

export type CurrentReward<T = DailyReward> = {
  reward: T
  isWaitingClaim?: boolean
  packageCode: DailyRewardPackageCode
  progressWagerCondition: number
  todayWagerAmountToClaim: number
  allWagerAmountToClaim: number
  isCompletedPackage: boolean
}

export type DailyBonusReward = {
  rewardType: RewardTypeEnum.Bonus
} & DailyReward<{ bonusId: string; bonusImage: string }>

export type DailyHUSDLockReward = {
  rewardType: RewardTypeEnum.HUSDLock
} & DailyReward<number>

export type DailyCashReward = {
  rewardType: RewardTypeEnum.Cash
} & DailyReward<TokenAmount>

export type DailyCheckinTransaction<T = any> = {
  checkinType: DailyRewardPackageCode
  displayName: string
  reward: T
  rewardType: RewardTypeEnum.Bonus | RewardTypeEnum.Cash | RewardTypeEnum.HUSDLock
  displayAmount?: string
  createdAt: number
}
