import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import Modal, { ModalBody } from 'components/Modal'
import { ModalProps } from 'components/Modal/Modal'
import Tab from 'components/Tab'
import Tabs from 'components/Tabs'
import Text from 'components/Text'
import { RouteConfig } from 'config/constants/route'
import { UsableFiatCurrencies } from 'config/constants/tokens/fiat'
import { Token } from 'config/types'
import { useIsDisabledFeaturedOnTelegram } from 'hooks/useTelegram'
import { RowCenter } from 'layout/Components/Row'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { Trans } from 'react-i18next'
import { useProfileBaseTier } from 'state/profile/hooks'
import { useTokenSelected } from 'state/session/hooks'
import styled from 'styled-components'
import theme from 'theme'
import base from 'theme/base'
import WarningOnTelegram from 'views/Authentication/components/Wallet/WarningOnTelegram/WarningOnTelegram'
import Menu, { DepositOptionEnums, PaymentPageEnums, WithdrawOptionEnums } from './Menu'
import Badge2FA from './components/Badge2FA'
import DepositByBuyCrypto from './components/Deposit/DepositByBuyCrypto'
import DepositByFiat from './components/Deposit/DepositByFiat'
import DepositCryptos from './components/Deposit/DepositCryptos'
import Swap from './components/Swap'
import Tip from './components/Tip'
import TipAcceptModal from './components/Tip/components/TipAcceptModal'
import WithdrawByFiat from './components/Withdraw/WithdrawByFiat'
import WithdrawCrypto from './components/Withdraw/WithdrawCryptos'

export const TransactionUrls = {
  [PaymentPageEnums.Deposit]: RouteConfig.DepositTransactionHistory,
  [PaymentPageEnums.Withdraw]: RouteConfig.WithdrawTransactionHistory,
  [PaymentPageEnums.Swap]: RouteConfig.SwapTransactionHistory,
  [PaymentPageEnums.Tip]: RouteConfig.TipTransactionHistory,
}

interface PaymentProps {
  defaultPage?: PaymentPageEnums
  initialToken?: Token
  pageData?: { tipUserName?: string; depositSubTag?: DepositOptionEnums }
}

const TIP_LEVEL_CONDITION = 4

const Payment: React.FC<ModalProps<PaymentProps>> = ({ data: { defaultPage, initialToken, pageData }, onDismiss }) => {
  const selectedToken = useTokenSelected()

  const isFiatCurrency = UsableFiatCurrencies.includes(selectedToken.code)

  const [paymentPage, setPaymentPage] = useState<PaymentPageEnums>(defaultPage || PaymentPageEnums.Deposit)
  const [depositOption, setDepositOption] = useState<DepositOptionEnums>(
    pageData?.depositSubTag || (isFiatCurrency ? DepositOptionEnums.Fiat : DepositOptionEnums.Crypto),
  )
  const [withdrawOption, setWithdrawOption] = useState<WithdrawOptionEnums>(
    isFiatCurrency ? WithdrawOptionEnums.Fiat : WithdrawOptionEnums.Crypto,
  )
  const router = useRouter()
  const userBaseTier = useProfileBaseTier()
  const isAcceptTip = userBaseTier?.level >= TIP_LEVEL_CONDITION

  const isDisabledFeaturedOnTelegram = useIsDisabledFeaturedOnTelegram()

  return (
    <StyledModal id="payment" onDismiss={onDismiss}>
      <Flex
        alignItems="center"
        display={['flex !important', '', 'none !important']}
        position="absolute"
        zIndex={1}
        top="24px"
        style={{
          transform: 'translateY(-50%)',
        }}
        left="20px"
      >
        <Text bold mt="1px">
          <Trans>Wallet</Trans>
        </Text>
        <Badge2FA />
      </Flex>
      <ModalBody height="100%" overflowY="auto">
        <Flex height="100%" flexDirection={['column', 'column', 'row']} pt={['48px', '', '0px']} overflow="hidden">
          <Menu
            paymentPage={paymentPage}
            depositSubTabOption={depositOption}
            withdrawSubTabOption={withdrawOption}
            onDepositSubTabChanged={setDepositOption}
            onWithdrawSubTabChanged={setWithdrawOption}
            onPaymentPageChanged={setPaymentPage}
          />

          <StyledContent
            p={['20px', '', '40px 24px']}
            overflowY="auto"
            pt={['20px !important', '', '40px !important']}
            position="relative"
          >
            {paymentPage === PaymentPageEnums.Deposit && !isDisabledFeaturedOnTelegram && (
              <Box pb="20px" display={['block', '', 'none']}>
                <StyledMobileDepositOptionTabs value={depositOption} onTagChanged={() => {}} p={[3, 3, 0]}>
                  <Tab value={DepositOptionEnums.Crypto} width="60px" borderRadius={base.radii.tiny} height="100%">
                    <Text
                      p="8px 0"
                      fontSize={['14px', '', '16px']}
                      mx="auto"
                      fontWeight={depositOption === DepositOptionEnums.Crypto ? 500 : 300}
                      color={depositOption === DepositOptionEnums.Crypto ? theme.colors.text : theme.colors.textSubtle}
                      textAlign="center"
                      onClick={() => {
                        setDepositOption(DepositOptionEnums.Crypto)
                      }}
                      style={{
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <Trans>Crypto</Trans>
                    </Text>
                  </Tab>

                  <Tab value={DepositOptionEnums.Fiat} width="60px" borderRadius={base.radii.tiny}>
                    <Text
                      p="8px 0"
                      fontSize={['14px', '', '16px']}
                      mx="auto"
                      fontWeight={depositOption === DepositOptionEnums.Fiat ? 500 : 300}
                      color={depositOption === DepositOptionEnums.Fiat ? theme.colors.text : theme.colors.textSubtle}
                      textAlign="center"
                      onClick={() => {
                        setDepositOption(DepositOptionEnums.Fiat)
                      }}
                      style={{
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <Trans>Fiat</Trans>
                    </Text>
                  </Tab>

                  <Tab value={DepositOptionEnums.BuyCrypto} width="60px" borderRadius={base.radii.tiny}>
                    <Text
                      p="8px 0"
                      fontSize={['14px', '', '16px']}
                      mx="auto"
                      fontWeight={depositOption === DepositOptionEnums.BuyCrypto ? 500 : 300}
                      color={
                        depositOption === DepositOptionEnums.BuyCrypto ? theme.colors.text : theme.colors.textSubtle
                      }
                      textAlign="center"
                      onClick={() => {
                        setDepositOption(DepositOptionEnums.BuyCrypto)
                      }}
                      style={{
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <Trans>Buy crypto</Trans>
                    </Text>
                  </Tab>
                </StyledMobileDepositOptionTabs>
              </Box>
            )}

            {paymentPage === PaymentPageEnums.Withdraw && !isDisabledFeaturedOnTelegram && (
              <Box pb="20px" display={['block', '', 'none']}>
                <StyledMobileWithdrawOptionTabs value={withdrawOption} onTagChanged={() => {}} p={[3, 3, 0]}>
                  <Tab value={WithdrawOptionEnums.Crypto} width="60px" borderRadius={base.radii.tiny} height="100%">
                    <Text
                      p="8px 0"
                      fontSize={['14px', '', '16px']}
                      mx="auto"
                      fontWeight={withdrawOption === WithdrawOptionEnums.Crypto ? 500 : 300}
                      color={
                        withdrawOption === WithdrawOptionEnums.Crypto ? theme.colors.text : theme.colors.textSubtle
                      }
                      textAlign="center"
                      onClick={() => {
                        setWithdrawOption(WithdrawOptionEnums.Crypto)
                      }}
                      style={{
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <Trans>Crypto</Trans>
                    </Text>
                  </Tab>

                  <Tab value={WithdrawOptionEnums.Fiat} width="60px" borderRadius={base.radii.tiny}>
                    <Text
                      p="8px 0"
                      fontSize={['14px', '', '16px']}
                      mx="auto"
                      fontWeight={withdrawOption === WithdrawOptionEnums.Fiat ? 500 : 300}
                      color={withdrawOption === WithdrawOptionEnums.Fiat ? theme.colors.text : theme.colors.textSubtle}
                      textAlign="center"
                      onClick={() => {
                        setWithdrawOption(WithdrawOptionEnums.Fiat)
                      }}
                      style={{
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <Trans>Fiat</Trans>
                    </Text>
                  </Tab>
                </StyledMobileWithdrawOptionTabs>
              </Box>
            )}

            <Box>
              {/** Tab page title */}
              <Text display={['none', '', 'block']} fontWeight="600" mb="20px">
                {paymentPage === PaymentPageEnums.Deposit && (
                  <Trans>
                    <Trans>
                      {depositOption === DepositOptionEnums.BuyCrypto ? 'Buy crypto using cards' : 'Deposit'}
                    </Trans>
                  </Trans>
                )}
                {paymentPage === PaymentPageEnums.Withdraw && <Trans>Withdraw</Trans>}
                {paymentPage === PaymentPageEnums.Swap && <Trans>Swap</Trans>}
                {paymentPage === PaymentPageEnums.Tip && <Trans>Tip</Trans>}
              </Text>

              {paymentPage === PaymentPageEnums.Deposit &&
                (isDisabledFeaturedOnTelegram ? (
                  <WarningOnTelegram />
                ) : (
                  <>
                    {depositOption === DepositOptionEnums.Crypto && <DepositCryptos initialToken={initialToken} />}
                    {depositOption === DepositOptionEnums.Fiat && <DepositByFiat />}
                    {depositOption === DepositOptionEnums.BuyCrypto && <DepositByBuyCrypto />}
                  </>
                ))}

              {paymentPage === PaymentPageEnums.Withdraw &&
                (isDisabledFeaturedOnTelegram ? (
                  <WarningOnTelegram />
                ) : (
                  <>
                    {withdrawOption === WithdrawOptionEnums.Crypto && (
                      <WithdrawCrypto initialToken={initialToken} onDismiss={onDismiss} />
                    )}
                    {withdrawOption === WithdrawOptionEnums.Fiat && <WithdrawByFiat />}
                  </>
                ))}

              {paymentPage === PaymentPageEnums.Swap && <Swap />}
              {paymentPage === PaymentPageEnums.Tip &&
                (isDisabledFeaturedOnTelegram ? (
                  <WarningOnTelegram />
                ) : (
                  <Box position={['relative', 'relative', 'unset']}>
                    <Tip initialToken={initialToken} userName={pageData?.tipUserName || ''} isAcceptTip={isAcceptTip} />
                    {!isAcceptTip && <TipAcceptModal />}
                  </Box>
                ))}
            </Box>

            {!isDisabledFeaturedOnTelegram && (
              <RowCenter
                mt={
                  paymentPage === PaymentPageEnums.Deposit && depositOption === DepositOptionEnums.Crypto
                    ? '12px'
                    : '32px'
                }
                mb="32px"
                justifyContent="flex-end"
                alignItems="center"
                onClick={() => {
                  router.push(TransactionUrls[paymentPage])
                  onDismiss()
                }}
                style={{
                  cursor: 'pointer',
                  lineHeight: '24px',
                }}
              >
                <Text color="primaryAlt" small>
                  <Trans>Transaction History</Trans>
                </Text>
              </RowCenter>
            )}
          </StyledContent>
        </Flex>
      </ModalBody>
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  padding: 0;

  box-sizing: border-box;

  background: ${({ theme: { colors } }) => colors.modalBackground};

  overflow-y: hidden;
  border-top-left-radius: ${({ theme }) => theme.radii.large};
  border-top-right-radius: ${({ theme }) => theme.radii.large};

  height: max(calc(var(--screen-height) - 20vh), min(820px, calc(var(--screen-height) - 40px)));

  & .modal-closebutton {
    right: 12px;
    top: 12px;
  }

  ${({ theme: { mediaQueries } }) => mediaQueries.sm} {
    min-width: 600px;
    max-width: 600px;
    border-radius: ${({ theme }) => theme.radii.large};
    height: max(calc(var(--screen-height) - 20vh), min(760px, calc(var(--screen-height) - 40px)));
  }
`

const StyledMobileDepositOptionTabs = styled(Tabs)`
  padding: 0;
  max-width: 100%;
`
const StyledMobileWithdrawOptionTabs = StyledMobileDepositOptionTabs

const StyledContent = styled(Box)`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  ::-webkit-scrollbar {
    width: 3px !important;
  }
`

export default Payment
