import { AuthMethod } from 'config/constants/auth'
import { useCallback, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'state'
import { removeSuggestedLoginInfo } from 'state/auth/action'

const useSuggestedLogin = () => {
  const previousUserEmail = useAppSelector((state) => state.auth.email)
  const previousAuthBy = useAppSelector((state) => state.auth.authBy)
  const dispatch = useAppDispatch()

  const clearSuggessLoginInfo = useCallback(() => {
    dispatch(removeSuggestedLoginInfo())
  }, [])

  return useMemo(
    () => ({
      previousUserEmail: previousAuthBy === AuthMethod.Email ? previousUserEmail : null,
      previousAuthBy,
      clearSuggessLoginInfo,
    }),
    [previousUserEmail, previousAuthBy, clearSuggessLoginInfo],
  )
}

export default useSuggestedLogin
