import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import { BoxProps } from 'components/Box/types'
import Image from 'components/Image/Image'
import Text from 'components/Text/Text'
import { USD_CURRENCY } from 'config/constants/tokens'
import { WageringConditionBonus } from 'config/types/bonus/userBonus'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useTokenUsdPrice } from 'state/app/hooks'
import styled from 'styled-components'
import { Icons } from 'svgs'
import { colors } from 'theme/colors'
import { getFullDisplayBalance } from 'utils/formatBalance'

interface FreespinBonusBodyProps {
  bonus: WageringConditionBonus
}

const FreespinBonusBody: React.FC<BoxProps & FreespinBonusBodyProps> = ({ bonus, ...props }) => {
  const bonusTokenPriceInUsd = useTokenUsdPrice(bonus.bonusAmount?.token)
  const bonusAmountInUsd =
    bonus.bonusAmount?.token === USD_CURRENCY
      ? bonus.bonusAmount.amount
      : bonusTokenPriceInUsd.multipliedBy(bonus.bonusAmount.amount)
  const { t } = useTranslation()

  return (
    <Box {...props}>
      <>
        <Box>
          <Flex justifyContent="center" alignItems="center" mb="8px">
            <Box
              width={bonus.bonusAmount?.token !== USD_CURRENCY ? '16px' : '24px'}
              height={bonus.bonusAmount?.token !== USD_CURRENCY ? '16px' : '24px'}
            >
              <Image src="/images/usd-icon.png" width={64} height={64} />
            </Box>
            <Text
              fontWeight="800"
              fontSize={bonus.bonusAmount?.token !== USD_CURRENCY ? '16px' : '24px'}
              ml="8px"
              lineHeight="1.2"
            >
              {getFullDisplayBalance(bonusAmountInUsd, 0, 2)} USD
            </Text>
          </Flex>
          {bonus.bonusAmount?.token !== USD_CURRENCY && (
            <Flex justifyContent="center" alignItems="center">
              <Text>~</Text>
              <Box width="16px" height="16px" ml="8px">
                <Image src={bonus.bonusAmount?.token?.logo} width={64} height={64} />
              </Box>
              <Text fontWeight="800" fontSize="16px" ml="8px" lineHeight="19.5px">
                {getFullDisplayBalance(bonus.bonusAmount.amount, 0, 9)} {bonus.bonusAmount?.token?.name}
              </Text>
            </Flex>
          )}
        </Box>

        <StyledDescriptionBody justifyContent="center" mt="20px">
          <Box width="16px" height="16px" mr="5px">
            <Icons.InfoCircleIcon width="32px" fill={colors.textDisable} />
          </Box>
          <Text fontWeight="400" fontSize="12px" color="textSubtle" lineHeight="14.63px">
            {bonus.isCreaditReward ? (
              <Trans>
                {t(
                  'Claim the Bonus to add to your real balance for games and complete the wagering within {{wagerDurationInDays}} days for withdrawal.',
                  { wagerDurationInDays: bonus.wagerDurationInDays },
                )}
              </Trans>
            ) : (
              <Trans>
                {t(
                  'Complete the necessary wagering requirements within {{wagerDurationInDays}} days to get the bonus on your balance',
                  { wagerDurationInDays: bonus.wagerDurationInDays },
                )}
              </Trans>
            )}
          </Text>
        </StyledDescriptionBody>
      </>
    </Box>
  )
}

const StyledDescriptionBody = styled(Flex)`
  background: rgba(255, 255, 255, 0.02);
  border-radius: 4px;
  padding: 8px;
`

export default FreespinBonusBody
