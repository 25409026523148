import { WebApp } from 'config/types/telegram'

export const isWindowExisted = () => typeof window !== 'undefined' && !!window

export const teleAppUtils = (): WebApp => (window.Telegram.WebApp || {}) as WebApp
export const readyAppTele = () => isWindowExisted() && teleAppUtils()?.ready()
export const expandTeleApp = () => isWindowExisted() && teleAppUtils()?.expand()

export const getPlatformTelegram = () => isWindowExisted() && teleAppUtils()?.platform
export const detectIsTelegramOrigin = () => {
  const platform = getPlatformTelegram()
  return platform && platform !== 'unknown'
}

export const teleAppOpenLink = (url: string, options?: { try_instant_view?: boolean }) =>
  isWindowExisted() && teleAppUtils()?.openLink(url, options)
