import Box from 'components/Box/Box'
import Button from 'components/Button/Button'
import BackgroundImage from 'components/Image/BackgroundImage'
import Image from 'components/Image/Image'
import Modal, { ModalProps } from 'components/Modal/Modal'
import Text from 'components/Text/Text'
import { RouteConfig } from 'config/constants/route'
import useModal, { useCloseAllModal } from 'hooks/useModal'
import { useRouter } from 'hooks/useRouter'
import { ColumnCenter } from 'layout/Components/Column'
import { useCallback } from 'react'
import { Trans } from 'react-i18next'
import styled from 'styled-components'
import { removeHUSDBonusStorage } from 'utils/signupBonus'

export interface SignUpSuccessWithBonusModalProps {
  isSignUpWithBonus: boolean
  husdBonusAmount?: number
}

const SignUpSuccessWithBonusModal: React.FC<ModalProps<SignUpSuccessWithBonusModalProps>> = ({
  onDismiss,
  data: { isSignUpWithBonus, husdBonusAmount },
}) => {
  const router = useRouter()
  const handleRemoveStorageBonus = useCallback(() => {
    removeHUSDBonusStorage()
  }, [])
  const closeModal = useCloseAllModal()

  return (
    <Modal
      onDismiss={() => {
        onDismiss()
        handleRemoveStorageBonus()
      }}
      minWidth={['', '', '', '660px !important']}
      minHeight={[]}
    >
      <BackgroundImage src="/images/bonusCenter/background-sign-up-with-bonus.png" width="100%" paddingBottom={450}>
        <Box position="absolute" top={40} right={0} bottom={0} left={0} minWidth="auto" minHeight={450} paddingTop={50}>
          <ColumnCenter marginBottom={50}>
            <Box>
              <StyledTextTitle fontSize="24px">
                <Trans>Welcome to</Trans>
              </StyledTextTitle>
              <Box width={[340, 360, 380]} height={120} position="relative" top={-40}>
                <Image src="/images/bonusCenter/title-tutorial.png" width={380} height={120} />
              </Box>
            </Box>
          </ColumnCenter>
          <ColumnCenter position="relative" top={-40}>
            <Text fontSize={['16px', '16px', '20px']} fontWeight={900} color="text" textAlign="center">
              {isSignUpWithBonus && husdBonusAmount ? (
                <Trans>Congratulations on receiving {{ bonusAmount: husdBonusAmount }} HUSD</Trans>
              ) : (
                <Trans>Ready for a world of rewards?</Trans>
              )}
            </Text>

            <Text
              fontSize={['12px', '12px', '14px']}
              color="textTertiary"
              mt="8px"
              mb={40}
              maxWidth={isSignUpWithBonus ? 320 : 400}
              textAlign="center"
              lineHeight="16px"
            >
              {isSignUpWithBonus ? (
                <Trans>
                  But that’s not all, let’s head over to the Bonus Center to discover even more exciting rewards.
                </Trans>
              ) : (
                <Trans>Head over to our Bonus Center now and discover all the exciting ways to win.</Trans>
              )}
            </Text>

            <Button
              width="320px"
              height="48px"
              mb={20}
              onClick={() => {
                router.push(RouteConfig.BonusCenter)
                handleRemoveStorageBonus()
                closeModal()
              }}
            >
              <Text fontWeight={700} fontSize="14px">
                <Trans>Go to Bonus Center</Trans>
              </Text>
            </Button>
          </ColumnCenter>
        </Box>
      </BackgroundImage>
    </Modal>
  )
}

const StyledTextTitle = styled(Text)`
  transform: rotate(-7deg);
  font-weight: 500;
  line-height: 29.26px;
  text-align: center;
  text-transform: uppercase;
`

export const SIGN_UP_SUCCESS_MODAL = 'signup-success'

const useSignUpSuccessWithBonusModal = () => {
  const modal = useModal(SignUpSuccessWithBonusModal, SIGN_UP_SUCCESS_MODAL)

  return modal
}

export default useSignUpSuccessWithBonusModal
