export type WalletInfo = {
  name: string
  adapter: any
  icon: string
  type: WalletType

  installed?: boolean
  options?: {
    downloadLink?: string
  }
}

export enum WalletType {
  EVM,
  BSC,
  SOL,
}

export enum WalletInjecteName {
  ethereum = 'ethereum',
  coin98 = 'coin98',
}
