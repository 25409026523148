import Button from 'components/Button'
import Text from 'components/Text'
import styled from 'styled-components'

export const StyledButton = styled(Button).attrs({ variants: 'error' })<{ $isActive: boolean }>`
  border-radius: ${({ theme: { radii } }) => radii.tiny};
  margin-top: 12px;

  width: 160px;
  height: 32px;
  padding: 4px 16px;

  ${Text} {
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.28;
  }
`

