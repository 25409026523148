import { ModalProps } from 'components/Modal'
import { useCallback, useContext, useState } from 'react'
import { delayed } from 'utils'
import { v4 as uuidv4 } from 'uuid'
import { Context } from '../contexts/Modals'

function useModal<S>(
  Component: React.FC<ModalProps<S>>,
  key?: string,
): [(data?: S, disableCloseByBackdrop?: boolean) => { waitingClose: () => Promise<any> | any }, () => void] {
  const { onDismiss, onPresent, contentsRef } = useContext(Context)
  const [id, setId] = useState(key)

  const handlePresent = useCallback(
    (data?: S, disableCloseByBackdrop = false) => {
      const id = key || uuidv4()
      const ids = contentsRef.current.map((content) => content.id) || []
      if (ids.find((item) => item === id))
        return {
          waitingClose: () => false,
        }

      setId(id)
      onPresent(id, Component, data || {}, disableCloseByBackdrop)

      return {
        waitingClose: !key
          ? () => true
          : async () => {
              await delayed(200)
              return new Promise((resolve) => {
                const timer = setInterval(() => {
                  const _ids = contentsRef.current.map((content) => content.id) || []
                  if (!_ids.find((item) => item === key)) {
                    resolve(true)
                    clearInterval(timer)
                  }
                }, 200)
              })
            },
      }
    },
    [onPresent, key],
  )

  const handleOnDimiss = useCallback(() => {
    onDismiss(id)
  }, [onDismiss, id])

  return [handlePresent, handleOnDimiss]
}

export function useCloseAllModal() {
  const { closeModal } = useContext(Context)

  return closeModal
}

export function useCloseById() {
  const { onDismiss } = useContext(Context)

  return useCallback((id) => onDismiss(id), [onDismiss])
}

export function useModalDisplayedCount() {
  const { contentIds } = useContext(Context)

  return contentIds.length
}

export default useModal
