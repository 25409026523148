import Flex from 'components/Box/Flex'
import { FlexProps } from 'components/Box/types'
import { InputProps } from 'components/Input'
import Text from 'components/Text'
import Toggle from 'components/Toggle'
import { RowMiddle } from 'layout/Components/Row'
import { Trans } from 'react-i18next'

const TwoFactorConfigToggle: React.FC<{ control: InputProps; content: string; isBlur: boolean } & FlexProps> = ({
  control,
  content,
  isBlur,
  ...props
}) => {
  return (
    <Flex mt="12px" {...props}>
      <Toggle
        width="36px"
        defaultColor="textDisable"
        checkedColor="textTertiary"
        backgroundColor="modalHeaderBackground"
        checkedBackgroundColor="primary"
        disabled={control.disabled}
        isBlur={isBlur}
        {...control}
      />
      <RowMiddle ml="8px">
        <Text fontSize="12px" fontWeight={400} color="textSubtle">
          <Trans>{content}</Trans>
        </Text>
      </RowMiddle>
    </Flex>
  )
}

export default TwoFactorConfigToggle
