import { howlMapper } from 'config/constants/audio'
import { InhouseGameDetails } from 'config/types/game'
import React, { createContext, useCallback, useContext, useMemo, useRef, useState } from 'react'
import WheelGameService from 'services/InhouseGameService/WheelGameService'
import { useTokenSelected } from 'state/session/hooks'
import { delayed } from 'utils'
import { forkjoinRequest } from 'utils/requestHelper'
import { InhouseBetResponse, InhouseGameBetResult } from 'views/InhouseGame/config/types'
import { WheelBet, WheelDraftBet } from '../config/types'
import { WheelEventContext } from './WheelEventProvider'

interface IWheelActionContext {
  isPlaying: boolean
  submitBet: (bet: WheelDraftBet, volumeEnable?: boolean) => Promise<InhouseGameBetResult>
  finishBet: () => void
}

export const WheelActionContext = createContext<IWheelActionContext>({
  submitBet: async () => null,
  finishBet: () => {},
  isPlaying: false,
})

const WheelActionProvider: React.FC<React.PropsWithChildren & { game: InhouseGameDetails }> = ({ children, game }) => {
  const { newBet, completedBet, setCompletedBet, setNewBet } = useContext(WheelEventContext)
  const [isBetSubmitting, setBetSubmitting] = useState(null)

  const playToken = useTokenSelected()
  const isLockedBetRef = useRef(false)

  const processingBetRef = useRef<WheelBet>(null)
  const processingBet = useMemo(() => {
    return processingBetRef.current
  }, [newBet, completedBet])

  const finishBet = useCallback(() => {
    if (!processingBetRef.current) return
    setCompletedBet(processingBetRef.current)

    if (processingBetRef.current.betAmount.amount.eq(0) || processingBetRef.current.disabledDisplay) {
      processingBetRef.current = null
      return
    }
    WheelGameService.finishBet(processingBetRef.current.id)
    processingBetRef.current = null
  }, [setCompletedBet])

  const _handleBet = async (draftBet: WheelDraftBet): Promise<InhouseBetResponse<WheelBet>> => {
    const [bet] = await forkjoinRequest([WheelGameService.submitBet(draftBet, game.code, playToken), delayed(100)])
    return bet
  }

  const submitBet = useCallback(
    async (newBet: WheelDraftBet, volumeEnable?: boolean) => {
      if (isLockedBetRef.current) return
      setBetSubmitting(true)
      isLockedBetRef.current = true

      if (volumeEnable) {
        howlMapper.InhouseGameBetPlay?.play()
      }
      const { bet, result } = await _handleBet(newBet)

      if (result === InhouseGameBetResult.Succeed) {
        setNewBet(bet)
        processingBetRef.current = bet
      }

      setBetSubmitting(false)
      isLockedBetRef.current = false
      return result
    },
    [setNewBet, playToken],
  )

  const value = useMemo(() => {
    return {
      finishBet,
      submitBet,
      isPlaying: isBetSubmitting || processingBet,
    }
  }, [isBetSubmitting || processingBet, finishBet, submitBet])

  return <WheelActionContext.Provider value={value}>{children}</WheelActionContext.Provider>
}

export default WheelActionProvider
