import BigNumber from 'bignumber.js'
import { DISPLAY_AMOUNT } from 'config/constants'
import { BIG_TEN, BIG_ZERO } from 'config/constants/number'

type Amount = BigNumber | number | string

/**
 * Take a formatted amount, e.g. 15 BNB and convert it to full decimal value, e.g. 15000000000000000
 */
export const getDecimalAmount = (amount: Amount, decimals = 18) => {
  return new BigNumber(amount).times(BIG_TEN.pow(decimals))
}

export const getBalanceAmount = (amount: Amount, decimals = 18) => {
  const parsedAmount = new BigNumber(amount)
  return Number.isNaN(parsedAmount.toNumber()) ? BIG_ZERO : new BigNumber(amount).dividedBy(BIG_TEN.pow(decimals))
}

/**
 * This function is not really necessary but is used throughout the site.
 */
export const getBalanceNumber = (balance: Amount, decimals = 18) => {
  const parsedBalance = getBalanceAmount(balance, decimals).toNumber()
  return Number.isNaN(parsedBalance) ? 0 : parsedBalance
}

export function delineate(bnStr: string, decimalDigitTake?: number) {
  const parts = bnStr.split('.')
  if (decimalDigitTake && parts[1]) {
    parts[1] = parts[1].substr(0, decimalDigitTake)
  }

  const formatDecimal = parts[1] && Number(parts[1]) >= 0 && decimalDigitTake !== 0 ? `.${parts[1]}` : ''

  return parts[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + formatDecimal.replace(/(?:\.0*|(\.\d+?)0+)$/, '$1')
}

export const getFullDisplayBalance = (balance: Amount, decimals = 0, displayDecimals?: number) => {
  return delineate(getBalanceAmount(balance, decimals).toString(10), displayDecimals)
}

export const getFullDisplayFixedDisplayBalance = (
  balance: Amount,
  decimals = 0,
  { min = 1, middle = DISPLAY_AMOUNT, max = 18, padZero = false },
) => {
  const balanceAmount = getBalanceAmount(balance, decimals)

  const numString = balanceAmount.toString(10)
  const parts = numString.split('.')

  const partInteger = parts[0] || ''
  const partFraction = parts[1] || ''
  const integerLength = partInteger.length

  const step = 1

  let fractionLength = Math.min(Math.max(middle + 1 - integerLength, min), max)

  let formatBalance = balanceAmount.toFixed(fractionLength, BigNumber.ROUND_DOWN)

  let fractionAmount = formatBalance.split('.')[1] || ''

  while (
    Number(partFraction) > 0 &&
    Number(fractionAmount) === 0 &&
    fractionAmount.length < fractionLength + step &&
    fractionLength < max
  ) {
    fractionLength += step

    formatBalance = balanceAmount.toFixed(fractionLength, BigNumber.ROUND_DOWN)

    fractionAmount = formatBalance.split('.')[1] || ''
  }
  const _parts = formatBalance.split('.')
  const displayBalance = `${_parts[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}.${_parts[1]}`

  return padZero ? displayBalance : delineate(new BigNumber(formatBalance).toString(10), fractionLength)
}

export const formatNumber = (number: number, minPrecision = 2, maxPrecision = 2) => {
  const options: Intl.NumberFormatOptions = {
    minimumFractionDigits: minPrecision,
    maximumFractionDigits: maxPrecision,
  }
  return number.toLocaleString('en-En', options)
}

export const formatLocalisedCompactNumber = (number: number): string => {
  return new Intl.NumberFormat('en-US', {
    notation: 'compact',
    compactDisplay: 'short',
    maximumSignificantDigits: 2,
  }).format(number)
}

export default formatLocalisedCompactNumber
