import React from 'react'
import useEventListener from './useEventListener'
import useWindowRef from './useWindowRef'

export default (callback: (event: React.KeyboardEvent) => void) => {
  useEventListener(
    'keydown',
    (event) => {
      if (!event.target.matches('input') && !event.target.matches('textarea')) {
        callback(event)
      }
    },
    useWindowRef(),
  )
}
