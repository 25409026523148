import Flex from 'components/Box/Flex'
import Button from 'components/Button'
import CopyButton from 'components/Button/CopyButton'
import { Checkbox } from 'components/Checkbox'
import FormControl from 'components/FormControl'
import FormInput from 'components/FormControl/FormInput'
import Dots from 'components/Loader/Dots'
import Modal, { ModalBody, ModalProps } from 'components/Modal'
import QRCode from 'components/QRCode'
import Text from 'components/Text'
import FormValidator from 'config/constants/formValidator'
import { PrepareEnable2FA } from 'config/types/auth'
import { ValidationError } from 'config/types/validator'
import { useCountDown } from 'hooks/useCountdown'
import useForm from 'hooks/useForm'
import { useIsomorphicEffect } from 'hooks/useIsomorphicEffect'
import useToggle from 'hooks/useToggle'
import Column from 'layout/Components/Column'
import { RowCenter, RowMiddle } from 'layout/Components/Row'
import { useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import AuthService from 'services/AuthenticationService'
import { BaseResponse } from 'services/types'
import { useAppDispatch } from 'state'
import { useAuthBy } from 'state/auth/hooks'
import { updateUserSetting } from 'state/profile/actions'
import styled from 'styled-components'
import base from 'theme/base'
import { HunnyToast } from 'utils/toastify'

interface Enable2FAModalProps {
  verifyFunction: ({ authCode, authEmailCode, sessionId }) => Promise<BaseResponse<unknown>>
  prepareData: PrepareEnable2FA
  onRefetchPrepareData?: () => Promise<BaseResponse<any>>
}

const Enable2FAModal: React.FC<ModalProps<Enable2FAModalProps>> = ({
  data: { prepareData, verifyFunction, onRefetchPrepareData },
  onDismiss,
}) => {
  const { isAuthByEmail, isAuthByTelegram } = useAuthBy()

  const { t } = useTranslation()
  const [submiting, toggleSubmiting] = useToggle(false)
  const dispatch = useAppDispatch()
  const [isAbleResetSession, setIsAbleResetSession] = useState(false)
  const [verification, setVerfication] = useState(prepareData)
  const [canResend, setCanResend] = useState(false)
  const [requestingResend, setRequestingResend] = useState(false)

  useIsomorphicEffect(() => {
    setCanResend(false)
    setRequestingResend(false)
  }, [verification])

  const expireTimeTextRef = useCountDown(
    verification?.expireTime,
    (s, m) => `${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`,
    () => setIsAbleResetSession(true),
  )

  const resendCodeTextRef = useCountDown(
    verification?.nextResentTime,
    (s, m) => `${s + m * 60}`,
    () => {
      setCanResend(true)
    },
  )

  const { states, controls, isValid } = useForm({
    isSaveRecoveryCode: {
      value: false,
      validators: [FormValidator.checked],
    },
    code: {
      value: '',
      validators: [FormValidator.required],
    },
    totpCode: {
      value: '',
      validators: [FormValidator.required],
    },
  })

  const handleResendEmailCode = useCallback(async () => {
    const response = await AuthService.prepareEnable2FAResendEmail(verification.sessionId)

    if (response?.data) {
      setVerfication({ ...verification, nextResentTime: response.data.nextResentTime })
    }
  }, [verification])

  const handleRefetchPrepareData = async () => {
    setIsAbleResetSession(true)
    const response = await onRefetchPrepareData()
    if (response.data) {
      setVerfication(response.data)
    }
    setIsAbleResetSession(false)
  }
  const handleEnable2FA = useCallback(async () => {
    toggleSubmiting()
    const response = await verifyFunction({
      authCode: states.totpCode.value,
      authEmailCode: states.code.value,
      sessionId: verification.sessionId,
    })

    if (response.code === 'error_auth_input') {
      controls.code.onErrorChanged([ValidationError.InCorrect])
    } else if (response.code === 'error_auth_totp') {
      controls.totpCode.onErrorChanged([ValidationError.InCorrect2FA])
    } else if (response.code === 'error_data_expired') {
      controls.totpCode.onErrorChanged([ValidationError.DataExpired])
    } else if (response.code === 'success') {
      onDismiss()
      HunnyToast.success(t('Enable 2FA Authentication Successfully!'))
      dispatch(updateUserSetting({ settings: { isActive2FA: true } }))
    }

    toggleSubmiting()
  }, [toggleSubmiting, states.code, states.totpCode, verification?.sessionId])

  const verificationErrorMessages = useMemo(
    () => ({
      [ValidationError.Required]: 'Please provide verification code',
      [ValidationError.IncorrectLength]: 'Please enter the 6-digits verification code',
      [ValidationError.InCorrect]: isAuthByTelegram
        ? 'Telegram Verification Code is incorrect, please check the code again'
        : 'Email Verification Code is incorrect, please check the code again',
      [ValidationError.InCorrect2FA]: 'Two-Factor Authentication Code is incorrect, please check the code again',
      [ValidationError.DataExpired]: 'Your OTP is expired. Please resend new OTP',
    }),
    [isAuthByTelegram],
  )

  return (
    <StyledModal p="12px" maxWidth="440px !important" minWidth="unset !important" onDismiss={onDismiss}>
      <ModalBody
        borderRadius="0px !important"
        p={['8px 6px 6px 6px', '12px']}
        style={{ flexShrink: 0 }}
        overflow="visible !important"
      >
        <Text fontSize="16px" fontWeight={600} lineHeight="normal" letterSpacing={-0.32}>
          <Trans>Enable Two-Factor Authentication</Trans>
        </Text>

        <Text color="textTertiary" fontSize="14px" fontWeight={300} lineHeight="normal" letterSpacing={-0.28} my="24px">
          {t(
            'When you enable Two-Factor authentication, additional security step to withdrawals, tips, login and  everything regarding you sending funds is added.',
          )}
        </Text>

        <Text fontSize="14px" fontWeight={400} lineHeight="normal" letterSpacing={-0.28}>
          <Trans>Scan this QR code with Authentication app or enter the code manually.</Trans>
        </Text>

        <Flex width="100%" ml="-7px">
          <StyledQRCode size={150} width={'150px'} url={verification.url} borderRadius={base.radii.tiny} />

          <Column maxWidth={[180, 'calc(100% - 150px)']} pt={['6px', '', '12px']} pl={['6px', '', '0px']}>
            <RowCenter
              p={['6px 8px', , '12px 8px']}
              background="rgba(255, 255, 255, 0.04)"
              height={['32px', '', '44px']}
            >
              <Text fontSize={['12px', '', '14px']} fontWeight={400} letterSpacing={-0.28} mr="4px">
                {verification.key}
              </Text>
              <CopyButton text={verification.key} width="20px" height="20px" />
            </RowCenter>

            <Text
              color="textAlt1"
              fontWeight={400}
              fontSize={'12px'}
              letterSpacing={-0.24}
              my="8px"
              lineHeight="normal"
            >
              <Trans>
                *Save this code, it is the only way to regain access to your account if you don't have access to your
                authenticator
              </Trans>
            </Text>

            <RowMiddle>
              <Checkbox
                value={states.isSaveRecoveryCode.value}
                onValueChanged={controls.isSaveRecoveryCode.onValueChanged}
                width="16.6px"
                height="16.6px"
              />
              <Text
                fontSize="12px"
                color="textTertiary"
                letterSpacing={-0.24}
                fontWeight={300}
                lineHeight="normal"
                ml="4px"
              >
                <Trans>I've saved recovery code</Trans>
              </Text>
            </RowMiddle>
          </Column>
        </Flex>

        {(isAuthByEmail || isAuthByTelegram) && (
          <FormControl
            label={isAuthByTelegram ? t('Telegram Authentication Code') : t('Email Authentication Code')}
            state={states.code}
            formatErrorMessage={(e) => t(verificationErrorMessages[e[0]])}
            m="24px 0 6px 0"
            position="relative"
          >
            <StyledFormInput
              tabIndex={1}
              control={controls.code}
              placeholder={isAuthByTelegram ? t('Enter telegram code') : t('Enter email code')}
              adornment={null}
              adornmentType="PasteFromClipboard"
            />
          </FormControl>
        )}

        <FormControl
          label={t('Two-Factor Authentication Code')}
          state={states.totpCode}
          formatErrorMessage={(e) => t(verificationErrorMessages[e[0]])}
          position="relative"
          m="6px 0 12px 0"
        >
          <StyledFormInput
            tabIndex={1}
            control={controls.totpCode}
            placeholder={t('Enter 2FA code')}
            adornment={null}
            adornmentType="PasteFromClipboard"
          />
        </FormControl>

        <StyledConfirmButton
          onClick={handleEnable2FA}
          disabled={!isValid || submiting || !states.isSaveRecoveryCode.value || !states.totpCode.value}
          mb="24px"
          mt="12px"
        >
          <Text fontSize="14px" fontWeight={600} letterSpacing={-0.28}>
            <Trans>Activate</Trans>
          </Text>
        </StyledConfirmButton>

        {isAuthByEmail && (
          <StyledSubtleText mt="6px" mb="10px" fontSize="14px" textAlign="center">
            <Trans>Haven’t receives email verification code</Trans>?{' '}
            {canResend ? (
              <Button
                variant="text"
                p="0px !important"
                height="auto !important"
                onClick={handleResendEmailCode}
                disabled={requestingResend}
              >
                {requestingResend ? (
                  <Text fontSize="14px" color="textSubtle" bold>
                    <Dots>
                      <Trans>Sending</Trans>
                    </Dots>
                  </Text>
                ) : (
                  <Text fontSize="14px" color="primary" bold>
                    <Trans>Resend now</Trans>
                  </Text>
                )}
              </Button>
            ) : (
              <>
                <Trans>Resend in</Trans> <span ref={resendCodeTextRef}>...</span> <Trans>seconds</Trans>
              </>
            )}
          </StyledSubtleText>
        )}
        {!isAbleResetSession && (
          <Text fontSize="14px" color="textSubtle" textAlign="center">
            <Trans>Session will be expired in</Trans> <strong ref={expireTimeTextRef}>...</strong>
          </Text>
        )}
        {isAbleResetSession && (
          <RowCenter>
            <Text fontSize="14px" color="textSubtle" textAlign="center">
              <Trans>Session has been expired</Trans>.
            </Text>
            <Button
              variant="text"
              p="0px !important"
              height="auto !important"
              onClick={handleRefetchPrepareData}
              disabled={requestingResend}
              ml="4px"
            >
              <Text fontSize="14px" color="primary" bold>
                <Trans>Refresh now</Trans>
              </Text>
            </Button>
          </RowCenter>
        )}
      </ModalBody>
    </StyledModal>
  )
}

const StyledQRCode = styled(QRCode)`
  svg {
    path {
      &:first-child {
        fill: transparent;
      }
      &:nth-child(2) {
        fill: ${({ theme: { colors } }) => colors.text};
      }
    }
  }
`

const StyledConfirmButton = styled(Button).attrs({ variants: 'primary' })`
  border-radius: ${({ theme: { radii } }) => radii.tiny};
  max-width: 100%;
  overflow-x: hidden;
  height: 46px;
`
const StyledFormInput = styled(FormInput)`
  padding-right: 28px;
`

const StyledSubtleText = styled(Text).attrs({ color: 'textSubtle' })`
  strong {
    color: ${({ theme }) => theme.colors.text};
  }
`

const StyledModal = styled(Modal)`
  overflow-y: auto !important;
`

export default Enable2FAModal
