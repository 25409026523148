import { range } from 'utils'
import { MINE_GAME_SIZE, mineGameReturnToPlayer } from '.'

export const getMinesOptions = () => {
  const result: { value: number; display: string }[] = []
  for (let index = 1; index < MINE_GAME_SIZE * MINE_GAME_SIZE; index++) {
    result.push({
      value: index,
      display: index.toString(),
    })
  }

  return result
}

export const generateMineGameMultiplier = (mines) => {
  const size = MINE_GAME_SIZE * MINE_GAME_SIZE
  let currentMultiplier = mineGameReturnToPlayer
  return range(0, size - mines).map((item) => {
    currentMultiplier *= 1 / ((size - mines - item) / (size - item))
    return currentMultiplier
  })
}
