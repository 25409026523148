import { useRequest } from 'hooks/useRequest'
import Column from 'layout/Components/Column'
import { RowBetween } from 'layout/Components/Row'
import { useEffect, useState } from 'react'
import ProfileService from 'services/ProfileService'
import StatisticTagInfo from '../StatisticTagInfo'
import StatisticsTimeSelect from './StatisticsSelect'
import { StatisticsTimeFilterEnum, StatisticsTimeFilterList, StatisticsTimeFilterType } from './StatisticsSelect/type'

const DisplayTotalWager = () => {
  const [wagerAmount, setWagerAmount] = useState(0)
  const [statisticsTimeSelect, setStatisticsTimeSelect] = useState<StatisticsTimeFilterType>(
    StatisticsTimeFilterList[0],
  )

  const { execute } = useRequest()

  useEffect(() => {
    fetchAllTotalWager()
  }, [])

  const fetchAllTotalWager = async () => {
    const result = await execute(ProfileService.getTotalWager())
    if (result?.data) {
      setWagerAmount(result.data)
    }
  }

  const handleChangeTransactionType = async (option: StatisticsTimeFilterType) => {
    setStatisticsTimeSelect(option)

    if (option.value === StatisticsTimeFilterEnum.All) {
      fetchAllTotalWager()
      return
    }

    const now = new Date().getTime()
    const timeStampAtFromTimeFilter = new Date(new Date().setDate(new Date().getDate() - option.value)).getTime()
    const result = await execute(ProfileService.getTotalWager(timeStampAtFromTimeFilter, now))
    if (result?.data) {
      setWagerAmount(result.data)
    }
  }

  return (
    <RowBetween
      minHeight="57px"
      background="rgba(35, 44, 61, 0.20)"
      p="8px 12px"
      borderRadius="4px"
      alignItems="flex-start !important"
    >
      <StatisticTagInfo
        flexDirection="column"
        alignItems="flex-start !important"
        title={'Total wagered'}
        amount={wagerAmount}
        marginRight="12px"
        background="none !important"
        pl="0px !important"
      />
      <StatisticsTimeSelect timeSelected={statisticsTimeSelect} handleChangeOption={handleChangeTransactionType} />
    </RowBetween>
  )
}

const StatisticsUserLogin: React.FC<{
  totalBet: number
}> = ({ totalBet }) => {
  return (
    <Column mt="12px">
      <DisplayTotalWager />

      <StatisticTagInfo
        flexDirection="row"
        title={'Total bets'}
        amount={totalBet}
        mt="12px"
        height="36px"
        background="rgba(35, 44, 61, 0.20) !important"
        minWidth="100%"
        pl="0px"
        borderRadius="4px !important"
        disabledFiatDisplay
      />
    </Column>
  )
}

export default StatisticsUserLogin
