import { RouteConfig } from 'config/constants/route'
import { useRouter } from 'hooks/useRouter'
import { useCallback, useMemo } from 'react'
import { useAuth } from 'state/auth/hooks'
import { useUserOnboardingStatus } from 'state/profile/hooks'
import { AuthModalMethodEnums, AuthModalPageEnums } from 'views/Authentication/types'
import useDailyCheckinModal from 'views/DailyCheckin'
import HUSDUnlock from 'views/HUSDUnlock'
import Payment from 'views/Payment'
import { DepositOptionEnums, PaymentPageEnums } from 'views/Payment/Menu'
import { usePresentTipDetailModal } from 'views/Payment/components/Tip/hooks'
import AllLevelModal from 'views/Profile/Dashboard/components/AllLevelModal'
import useAuthenticationModal from './useAuthenticationModal'
import { useCrisp, useOpenCrisp } from './useCrisp'
import { useIsomorphicEffect } from './useIsomorphicEffect'
import useLuckySpinModal from './useLuckySpinModal'
import useModal from './useModal'
import useOpenBetDetails from './useOpenBetDetails'
import useQueryParam from './useQueryParam'
import useDebounceCallback from './useDebounceCallback'

export enum ActionQueryEnum {
  Login = 'onLogin',
  LoginEmail = 'onEmailLogin',
  SignUp = 'onSignUp',
  UnlockHUSD = 'unlockHUSD',
  Deposit = 'deposit',
  FiatDeposit = 'fiat-deposit',
  CryptoDeposit = 'crypto-deposit',
  Withdraw = 'withdraw',
  Tip = 'tip',
  OpenLuckySpin = 'openLuckySpin',
  OpenAllLevel = 'openAllLevel',
  OpenWelcomePackage = 'openWelcomePackage',
  OpenSupportChat = 'openSupportChat',
  OpenBetTransaction = 'onBetDetail',
  OpenTipDetails = 'openTipDetails',
  OpenDailyCheckin = 'openDailyCheckin',
}

export const useActionQueryHandler = () => {
  const [onPresentAuthModal] = useAuthenticationModal()
  const [onPresentHUSDModal] = useModal(HUSDUnlock)
  const [onPressentModalPaymnet] = useModal(Payment)
  const [onPressentModalLuckySpin] = useLuckySpinModal()
  const [onPresentAllLevelModal] = useModal(AllLevelModal)
  const { presentBetModal } = useOpenBetDetails()
  const onPresentTipDetailModal = usePresentTipDetailModal()
  const [onPresentDailyCheckinModal] = useDailyCheckinModal()
  const onOpenSupportChatBox = useOpenCrisp()
  const crisp = useCrisp()
  const router = useRouter()
  const { removeParam } = useQueryParam()

  const { isOnboarding } = useUserOnboardingStatus()
  const { isSigned, hasSession } = useAuth()

  const openBet = useCallback(async () => {
    const betId = router.query?.betId
    if (betId) {
      await removeParam('betId')
      presentBetModal(Number(betId.toString()))
    }
  }, [removeParam, router.query?.betId])

  const actions = useMemo(
    () => ({
      [ActionQueryEnum.Login]: {
        call: () => onPresentAuthModal({ page: AuthModalPageEnums.LOG_IN }),
        condition: !isSigned && !hasSession,
      },
      [ActionQueryEnum.LoginEmail]: {
        call: () => onPresentAuthModal({ page: AuthModalPageEnums.LOG_IN, method: AuthModalMethodEnums.TRADITIONAL }),
        condition: !isSigned && !hasSession,
      },
      [ActionQueryEnum.Deposit]: {
        call: () => onPressentModalPaymnet(),
        condition: isSigned && !isOnboarding,
        fallback: () => (!isSigned ? onPresentAuthModal({ page: AuthModalPageEnums.LOG_IN }) : null),
      },
      [ActionQueryEnum.FiatDeposit]: {
        call: () => onPressentModalPaymnet({ pageData: { depositSubTag: DepositOptionEnums.Fiat } }),
        condition: isSigned && !isOnboarding,
        fallback: () => (!isSigned ? onPresentAuthModal({ page: AuthModalPageEnums.LOG_IN }) : null),
      },
      [ActionQueryEnum.CryptoDeposit]: {
        call: () => onPressentModalPaymnet({ pageData: { depositSubTag: DepositOptionEnums.Crypto } }),
        condition: isSigned && !isOnboarding,
        fallback: () => (!isSigned ? onPresentAuthModal({ page: AuthModalPageEnums.LOG_IN }) : null),
      },
      [ActionQueryEnum.Withdraw]: {
        call: () => onPressentModalPaymnet({ defaultPage: PaymentPageEnums.Withdraw }),
        condition: isSigned && !isOnboarding,
        fallback: () => (!isSigned ? onPresentAuthModal({ page: AuthModalPageEnums.LOG_IN }) : null),
      },
      [ActionQueryEnum.Tip]: {
        call: () => onPressentModalPaymnet({ defaultPage: PaymentPageEnums.Tip }),
        condition: isSigned && !isOnboarding,
        fallback: () => (!isSigned ? onPresentAuthModal({ page: AuthModalPageEnums.LOG_IN }) : null),
      },
      [ActionQueryEnum.UnlockHUSD]: {
        call: () => onPresentHUSDModal(),
        condition: isSigned && !isOnboarding,
        fallback: () => (!isSigned ? onPresentAuthModal({ page: AuthModalPageEnums.LOG_IN }) : null),
      },
      [ActionQueryEnum.OpenLuckySpin]: {
        call: () => onPressentModalLuckySpin(),
      },
      [ActionQueryEnum.OpenAllLevel]: {
        call: () => onPresentAllLevelModal(),
      },
      [ActionQueryEnum.SignUp]: {
        call: () => onPresentAuthModal({ page: AuthModalPageEnums.SIGN_UP }),
        condition: !isSigned && !hasSession,
      },
      [ActionQueryEnum.OpenWelcomePackage]: {
        call: () => {
          router.push(RouteConfig.BonusCenter)
        },
      },
      [ActionQueryEnum.OpenSupportChat]: {
        call: () => onOpenSupportChatBox(),
      },
      [ActionQueryEnum.OpenBetTransaction]: {
        call: () => openBet(),
      },
      [ActionQueryEnum.OpenTipDetails]: {
        condition: isSigned && !isOnboarding,
        call: () => onPresentTipDetailModal(),
      },
      [ActionQueryEnum.OpenDailyCheckin]: {
        call: () => onPresentDailyCheckinModal(),
      },
    }),
    [isSigned, hasSession, crisp, openBet, isOnboarding],
  )

  const handle = useCallback(
    (action: ActionQueryEnum) => {
      if (!action) return
      const selectedAction = actions[action.toString()]
      if (!selectedAction || selectedAction.condition === false) {
        if (selectedAction?.fallback) {
          selectedAction.fallback()
        }
      } else selectedAction.call()
    },
    [actions],
  )

  return handle
}

const useActionQueryListener = () => {
  const router = useRouter()
  const { removeParam } = useQueryParam()
  const handleAction = useActionQueryHandler()
  const debounceCallback = useDebounceCallback()

  useIsomorphicEffect(() => {
    const handle = async () => {
      const { action } = router.query
      if (!action) return
      await removeParam('action')

      debounceCallback(() => {
        handleAction(action as ActionQueryEnum)
      }, 300)
    }
    handle()
  }, [router.query.action, handleAction])
}

export default useActionQueryListener
