import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import { BoxProps } from 'components/Box/types'
import Button from 'components/Button'
import CircleLoader from 'components/Loader/CircleLoader'
import Text from 'components/Text/Text'
import { WageringBonusStatusEnums } from 'config/types/bonus'
import { UserBonus, WageringConditionBonus } from 'config/types/bonus/userBonus'
import { Trans, useTranslation } from 'react-i18next'
import { useBonusAction } from 'state/bonus/hooks'
import styled from 'styled-components'
import { Icons } from 'svgs'
import { colors } from 'theme/colors'

interface BonusActionProps {
  bonus: UserBonus
  onBonusSubmitted: (isSucceed: boolean) => void
  disabled?: boolean
  trackingModule?: string
}

const BonusAction: React.FC<BonusActionProps & BoxProps> = ({
  bonus,
  onBonusSubmitted,
  trackingModule = 'default',
  disabled,
  ...props
}) => {
  const { actionContent, actionType, submit, submitting } = useBonusAction(bonus)
  const { t } = useTranslation()
  const handleApplyBonus = async (e) => {
    e.stopPropagation()

    const result = await submit()

    if (result) {
      onBonusSubmitted(true)
    } else onBonusSubmitted(false)
  }

  const isWageringButNotActive =
    bonus instanceof WageringConditionBonus &&
    !bonus.isActiveWagering &&
    bonus.wageringBonusStatus === WageringBonusStatusEnums.InProgressWagering

  return !isWageringButNotActive ? (
    actionType && (
      <StyledButton
        className={`${trackingModule}-bonusCTA-${actionType}`}
        disabled={
          disabled ||
          submitting ||
          (bonus instanceof WageringConditionBonus &&
            !bonus.isActiveWagering &&
            bonus.wageringBonusStatus === WageringBonusStatusEnums.InProgressWagering)
        }
        onClick={handleApplyBonus}
        {...props}
      >
        {submitting && (
          <Box mr="12px">
            <CircleLoader />
          </Box>
        )}

        {t(actionContent)}
      </StyledButton>
    )
  ) : (
    <>
      <Flex
        data-tooltip-id={'common-tip-bottom'}
        data-tooltip-content={t(
          'To wager on this bonus, you must either complete and claim the currently selected wagering bonus or cancel it.',
        )}
        background="rgba(255, 255, 255, 0.08) !important"
        height="40px"
        borderRadius="4px"
        alignItems="center"
        justifyContent="center"
        style={{ cursor: 'help' }}
        {...props}
      >
        <Icons.InfoCircleIcon fill={colors.textTertiary} />
        <Text ml="4px" small fontWeight="300" color="textTertiary">
          <Trans>Waiting</Trans>
        </Text>
      </Flex>
    </>
  )
}

export const StyledButton = styled(Button)`
  &:disabled {
    color: ${({ theme }) => theme.colors.textSubtle};
  }
  width: 100%;
  border-radius: ${({ theme: { radii } }) => radii.tiny} !important;
`

export default BonusAction

