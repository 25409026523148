import { Token } from 'config/types'
import { ChainIdEnum } from '../network'

const path = '/images/token'

const tokens: Record<string, Token> = {
  HUSD: {
    logo: `${path}/hpd.png`,
    name: 'HUSD',
    code: 'HUSD',
    network: ChainIdEnum.HPN,
    isNative: true,
    decimals: 1,
  },
  USD: {
    logo: `${path}/usd.png`,
    name: 'USD',
    code: 'USD',
    network: ChainIdEnum.HPN,
    isNative: true,
    decimals: 1,
  },
}

export default tokens
