import BigNumber from 'bignumber.js'
import { USD_CURRENCY } from 'config/constants/tokens'
import { BaseResponse } from 'services/types'
import { WheelBetResponse } from 'views/InhouseGame/Wheel/config/types'

export const WheelSubmitMapper = (response: any): BaseResponse<WheelBetResponse> => {
  return {
    ...response,
    data: response.data &&
      response.data.payout_result && {
        id: response.data.payout_result.round_id,
        betAmount: {
          amount: new BigNumber(response.data.payout_result.payout),
          token: USD_CURRENCY,
        },
        result: Number(response.data.game_result.result),
        userBalance: new BigNumber(response.data.payout_result.balance),
      },
  }
}
