import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import Modal, { ModalBody, ModalProps } from 'components/Modal'
import Text from 'components/Text'
import TokenLogo from 'components/TokenLogo'
import { ChainIdEnum } from 'config/constants/network'
import tokens from 'config/constants/tokens'
import { Token } from 'config/types'
import { TipDetails } from 'config/types/payment'
import { TipTransactionFilterTypeEnums } from 'config/types/transaction'
import useModal from 'hooks/useModal'
import { RowMiddle } from 'layout/Components/Row'
import { Trans } from 'react-i18next'
import { useAuthBy } from 'state/auth/hooks'
import styled from 'styled-components'
import { Icons } from 'svgs'
import { colors } from 'theme/colors'
import { delayed } from 'utils'
import { getFullDisplayBalance } from 'utils/formatBalance'
import { showToast } from 'utils/toastify'
import TipDetailInfoes from './TipDetailInfoes'
import TipDetailModal from './TipDetailModal'
import TraditionalConfirmTipButton from './TraditionalConfirmTipbutton'
import WalletConfirmTipButton from './WalletConfirmTipButton'

interface ConfirmTipModalProps {
  details: TipDetails
}

const ConfirmTipToast: React.FC<{ token: Token; displayAmount: string }> = ({ token, displayAmount }) => {
  return (
    <Box minWidth="250px">
      <Flex alignItems="flex-start">
        <Flex flexDirection="column" alignItems="flex-start" justifyContent="flex-end">
          <Flex alignItems="center">
            <Icons.SuccessCheckIcon height="24px" fill={colors.success} />

            <Text ml="8px" fontSize="16px" bold color="success">
              <Trans>Tip sent successfully!</Trans>
            </Text>
          </Flex>

          <Flex mt="4px" ml="32px" alignItems="center">
            <TokenLogo token={token} width="20px" />
            <Text ml="6px" fontSize="14px">
              {displayAmount} {token.name}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  )
}

const ConfirmTipModal: React.FC<ModalProps<ConfirmTipModalProps>> = ({ data: { details }, onDismiss }) => {
  const { isAuthByEmail, isAuthByWallet,isAuthByTelegram } = useAuthBy()
  const [onPresentTipDetailModal] = useModal(TipDetailModal)

  const handlePresentTipDetailModal = async (responseData: TipDetails) => {
    if (responseData) {
      showToast(
        <ConfirmTipToast
          token={tokens[ChainIdEnum[details.network]]?.[details.currency]}
          displayAmount={getFullDisplayBalance(details.amount, 0)}
        />,
      )

      onPresentTipDetailModal({
        details: responseData,
        target: TipTransactionFilterTypeEnums.Send,
        activeDirectSendForm: true,
      })
    }

    await delayed(500)
    onDismiss()
  }

  return (
    <Modal p="24px" width={['100%', '', '', '380px !important']} minWidth="unset !important" onDismiss={onDismiss}>
      <ModalBody overflow="unset !important">
        <RowMiddle position="relative" top="-6px" onClick={onDismiss}>
          <StyledBackIcon />
          <Text fontSize="16px" letterSpacing={-0.32} lineHeight="normal" fontWeight={600} ml="4px">
            <Trans>Confirm Tip</Trans>
          </Text>
        </RowMiddle>

        <TipDetailInfoes details={details} target={TipTransactionFilterTypeEnums.Send} hideCreateTime title="Tip" />

        {(isAuthByEmail || isAuthByTelegram) && <TraditionalConfirmTipButton details={details} onConfirmed={handlePresentTipDetailModal} />}
        {isAuthByWallet && <WalletConfirmTipButton onConfirmed={handlePresentTipDetailModal} details={details} />}
      </ModalBody>
    </Modal>
  )
}

const StyledBackIcon = styled(Icons.ArrowLeftIcon)`
  transform: scale(0.8);
  width: 24px;
  height: 24px;
  cursor: pointer;
`

export default ConfirmTipModal
