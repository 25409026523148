import BigNumber from 'bignumber.js'
import {
  Referee,
  ReferralBalanceTokenAmount,
  ReferralBonus,
  ReferralTxnBonusClaim,
  ReferralCampaign,
  ReferralMetaData,
  ReferralOverviewResponse,
  ReferralBonusTypeEnums,
  ReferralLiveReward,
} from 'config/types/referral'
import { BaseResponse, Paging, PrepareReferralClaimRes } from 'services/types'
import { ChainIdEnum } from 'config/constants/network'
import { formatApiNetworkField } from 'utils'
import { parseRefereeList, parseReferralCampaign, parseToken } from './utils'

export const ReferralMetaMapper = (rawResponse: string): BaseResponse<ReferralMetaData> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  const data = response.data

  return {
    code: response.code,
    message: response.message,
    data: data && {
      totalReferral: data.total_referral,
      totalRewardsUSD: data.total_rewards,
      currenciesConvert: data.allow_coins.map((coin) => {
        const network = ChainIdEnum[formatApiNetworkField(coin.network)]
        return {
          token: coin.currency,
          network,
        }
      }),
    },
  }
}
export const ReferralOverviewMapper = (rawResponse: string): BaseResponse<ReferralOverviewResponse> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  const data = response.data

  return {
    code: response.code,
    message: response.message,
    data: data && {
      userReferredCount: data.referee_count,
      referrer: data.referrer
        ? {
            avatar: data.referrer.avatar_url,
            displayName: data.referrer.display_name,
            userCode: data.referrer.code,
            tierId: data.referrer.tier_id,
            commissionEarnUsd: data.referrer.game_commission_earn_sum_usd,
          }
        : null,
      campaign: parseReferralCampaign(data.default_campaign),
      gameCommissionClaimedUSD: data.commission_claimed_usd,
      totalProfitUSD: new BigNumber(data.commission_claimed_usd || '0')
        .plus(data.referral_tier_bonus?.amount_usd || '0')
        .toString(),
    },
  }
}

export const ReferralBalanceMapper = (rawResponse: string): BaseResponse<Paging<ReferralBalanceTokenAmount>> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      items: response.data.items
        .map((item) => ({
          token: parseToken(item.network, item.currency),
          amount: item.value,
        }))
        .filter((item) => Number(item.amount) > 0),
    },
  }
}

export const ReferralBalanceEarnedMapper = (rawResponse: string): BaseResponse<Paging<ReferralBalanceTokenAmount>> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      items: response.data.items.map((item) => ({
        token: parseToken(item.network, item.currency),
        amount: item.amount,
      })),
    },
  }
}

export const ReferralPrepareClaimMapper = (rawResponse: string): BaseResponse<PrepareReferralClaimRes> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      sum: response.data.sum,
      items: response.data.items
        .filter((item) => item.currency)
        .map((item) => ({
          token: parseToken(item.network, item.currency),
          amount: item.value,
          convertedValue: item.converted_value,
        })),
    },
  }
}

export const ReferralListCampaignMapper = (rawResponse: string): BaseResponse<Paging<ReferralCampaign>> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }
  const campaigns: ReferralCampaign[] = response.data.items.map((item) => parseReferralCampaign(item))

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      paging: response.data.paging,
      items: campaigns,
    },
  }
}
export const ReferralCampaignMapper = (rawResponse: string): BaseResponse<ReferralCampaign> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && parseReferralCampaign(response.data),
  }
}

export const RefereeListMapper = (rawResponse: string): BaseResponse<Paging<Referee>> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      paging: response.data.paging,
      items: parseRefereeList(response.data) || [],
    },
  }
}

export const ReferralBonusMapper = (rawResponse: string): BaseResponse<ReferralBonus> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }
  return {
    code: response.code,
    message: response.message,
    data: {
      token: parseToken(response.data.network, response.data.currency),
      lockedAmount: response.data.locked_amount,
      unlockedAmount: response.data.unlocked_amount,
    },
  }
}

export const ReferralBonusClaimMapper = (rawResponse: string): BaseResponse<Paging<ReferralTxnBonusClaim>> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }
  return {
    code: response.code,
    message: response.message,
    data: {
      paging: response.data.paging,
      items: response.data.items.map((item) => ({
        token: parseToken(item.network, item.currency),
        amount: item.amount,
        amountUSD: item.amount_usd,
        type: item.type === ReferralBonusTypeEnums.Bonus ? 'Referral bonus' : 'Referral commission',
        createTime: item.create_time * 1000,
      })),
    },
  }
}
export const ReferralLiveRewardMapper = (rawResponse: string): BaseResponse<ReferralLiveReward[]> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }
  return {
    code: response.code,
    message: response.message,
    data: response.data.live_rewards.map((item) => ({
      avatar: item.avatar_url,
      amountUSD: item.amount_usd,
    })),
  }
}
