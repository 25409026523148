import React from 'react'
import styled from 'styled-components'
import Text from 'components/Text'
import { Icons } from 'svgs'
import Flex from 'components/Box/Flex'
import { Trans } from 'react-i18next'
import { FlexProps } from 'components/Box/types'
import Button from 'components/Button'
import CircleLoader from 'components/Loader/CircleLoader'
import { UserTableReturnPagingType } from './type'

export interface PagingProps extends FlexProps {
  pagination: UserTableReturnPagingType
}

export const StyledButtonPaging = styled(Button)`
  background: ${({ background }) => background || '#1d2533'} !important;
  border-radius: ${({ theme: { radii } }) => radii.tiny};
  height: 32px;
  padding: 6px 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    background: ${({ background }) => background || 'rgba(225, 225, 225, 0.05)'} !important;

    svg {
      fill: ${({ theme }) => theme.colors.textSubtle} !important;
    }
  }

  svg {
    fill: ${({ theme }) => theme.colors.textSubtle};
  }

  ${Text} {
    color: ${({ theme }) => theme.colors.textSubtle};
  }

  &:hover {
    ${Text} {
      color: ${({ theme, disabled }) => !disabled && theme.colors.text};
    }

    svg {
      fill: ${({ theme }) => theme.colors.text};
    }
  }
`

const Paging: React.FC<PagingProps> = ({ pagination, ...props }) => {
  return (
    !pagination.hide && (
      <Flex alignItems="center" {...props}>
        <StyledButtonPaging
          background="transparent"
          mr="8px"
          disabled={!pagination.canGotoFirst}
          onClick={() => pagination.gotoFirstPage()}
        >
          <Text fontSize="14px" color="textSubtle">
            <Trans>First</Trans>
          </Text>
        </StyledButtonPaging>

        <StyledButtonPaging
          variant="subtle"
          padding="4px"
          disabled={!pagination.canPrev}
          onClick={() => pagination.prevPage()}
        >
          <Icons.ChevronLeftIcon width="16px" height="16px" />
        </StyledButtonPaging>
        <Text fontSize="12px" color="textTertiary" fontWeight={600} mx="12px">
          <Trans>Page {{ currentPage: pagination.page }}</Trans>
        </Text>
        <StyledButtonPaging
          variant="subtle"
          disabled={!pagination.canNext || pagination.isCanNextChecking}
          onClick={() => pagination.nextPage()}
        >
          {pagination.isCanNextChecking ? <CircleLoader /> : <Icons.ChevronRightIcon width="16px" height="16px" />}
        </StyledButtonPaging>

        {!pagination.isGotoLastDisabled && (
          <StyledButtonPaging
            ml="8px"
            background="transparent"
            disabled={!pagination.canGotoLast}
            onClick={() => pagination.gotoLastPage()}
          >
            <Text fontSize="14px" color="textSubtle">
              <Trans>Last</Trans>
            </Text>
          </StyledButtonPaging>
        )}
      </Flex>
    )
  )
}

export default Paging
