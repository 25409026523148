import BigNumber from 'bignumber.js'
import { useUsdFiatConversionAmount } from 'state/profile/hooks'
import { getFullDisplayBalance } from 'utils/formatBalance'

const USDAmountDisplay: React.FC<{ amount: BigNumber | number | string; limitDisplayDecimals?: number }> = ({
  amount,
  limitDisplayDecimals = 6, // TODO
}) => {
  const { fiatCurrency, amount: displayAmount, isDisplayInFiat } = useUsdFiatConversionAmount(amount)
  const displayDecimals = isDisplayInFiat
    ? fiatCurrency.displayDecimals > limitDisplayDecimals
      ? limitDisplayDecimals
      : fiatCurrency.displayDecimals
    : limitDisplayDecimals

  return <>{`${fiatCurrency.symbol}${getFullDisplayBalance(displayAmount, 0, displayDecimals)}`}</>
}

export default USDAmountDisplay
