import { Token } from 'config/types'
import { InhouseGameType } from 'config/types/game'
import GameService from 'services/GameService'
import { LimboBet, LimboBetResponse, LimboDraftBet } from 'views/InhouseGame/Limbo/config/types'
import { InhouseBetResponse } from 'views/InhouseGame/config/types'
import { InhouseLaunchSession } from 'views/InhouseGame/Mine/config/types'
import { BaseResponse } from 'services/types'
import { INHOUSE_GAME_LAUNCH_MAPPER_KEY } from 'views/InhouseGame/Mine/config'
import BaseInhouseGameService from './BaseInhouseGameService'

class LimboGameService extends BaseInhouseGameService {
  _game = InhouseGameType.Limbo
  _currentTokenSession = null
  _sessionId = null

  async _verify(gameCode: string, playToken: Token): Promise<InhouseLaunchSession> {
    const result = await GameService.createGameSession(gameCode, playToken.code, playToken.network).call()
    const { gameUrl } = result?.data || {}
    const res: BaseResponse<InhouseLaunchSession> = await this._get(
      gameUrl,
      {},
      { withCredentials: true },
      INHOUSE_GAME_LAUNCH_MAPPER_KEY,
    )
    this._currentTokenSession = playToken
    this._sessionId = res.data?.sessionId
    return res.data
  }

  public async launchGame(gameCode: string, playToken: Token): Promise<InhouseLaunchSession> {
    return this._verify(gameCode, playToken)
  }

  public async submitBet(
    draftBet: LimboDraftBet,
    gameCode?: string,
    playToken?: Token,
  ): Promise<InhouseBetResponse<LimboBet>> {
    const result: InhouseBetResponse<LimboBetResponse> = await this._submitBet(
      draftBet.betAmount,
      {
        payout: draftBet.targetMultiplier.toString(),
      },
      draftBet.disabledDisplay,
      gameCode,
      playToken,
    )

    return {
      result: result.result,
      bet: result.bet && {
        ...draftBet,
        id: result.bet.id,
        resultMultiplier: result.bet.resultMultiplier,
      },
    }
  }

  public async verifyResult(clientSeed: string, serverSeed: string, nonce: number): Promise<number> {
    const result = await this._verifyResult(clientSeed, serverSeed, nonce, {})
    return result?.data?.payout && Number(result?.data?.payout)
  }
}

const instance = new LimboGameService()
export default instance
